import { Button, Container, ImageList, ImageListItem } from "@mui/material";
import React, { useState } from "react";
import { chatConstants } from "../../../constants";
import AautiDialog from "../../../../globalComponents/AautiDialog";
import { MessageText } from "../../../chat_messages/custom_messages/messages_container/MessageText";
import { DownloadRounded } from "@mui/icons-material";
import { StyledMsgContainer } from "../../styled_components";

function MessageImage({ currentMessage, position, reply = false, searchText }) {
  const [selectedImg, setSelectedImg] = useState(undefined);
  const renderImage = (file) => (
    <ImageListItem key={file.uri}>
      <img
        srcSet={`${file.uri}?w=80&h=80&fit=crop&auto=format&dpr=2 2x`}
        src={`${file.uri}?w=80&h=80&fit=crop&auto=format`}
        alt={file.name}
        loading="lazy"
        onClick={() => setSelectedImg(file)}
        style={{
          borderRadius: 4,
          height: "100%",
          width: "100%",
        }}
      />
    </ImageListItem>
  );

  const { files, ...rest } = currentMessage;
  return (
    <StyledMsgContainer>
      <ImageList
        cols={files?.length >= 2 ? 2 : 1}
        sx={{
          width: files.length >= 2 ? 240 : "auto",
          height: files?.length > 2 ? 186 : 160,
        }}
        rowHeight={files?.length > 2 ? 90 : 160}
      >
        {files.map(renderImage)}
      </ImageList>
      {currentMessage.text && (
        <MessageText
          {...{
            currentMessage: rest,
            position,
            reply,
            searchText,
          }}
        />
      )}
      <AautiDialog
        open={Boolean(selectedImg)}
        onClose={() => {
          setSelectedImg(undefined);
        }}
        dialogTitle={chatConstants?.previewImage}
      >
        <img
          srcSet={`${selectedImg?.uri}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
          src={`${selectedImg?.uri}?w=164&h=164&fit=crop&auto=format`}
          alt={"alt"}
          loading="lazy"
          key={selectedImg?._id}
          style={{
            borderRadius: "5px",
            height: "100%",
            width: "100%",
          }}
        />
        <Container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            color="primary"
            href={selectedImg?.uri}
            target="_blank"
            download={selectedImg?.name}
          >
            <DownloadRounded /> Download
          </Button>
        </Container>
      </AautiDialog>
    </StyledMsgContainer>
  );
}

export default MessageImage;
