import React from "react";
import AautiText from "../../../../globalComponents/AautiText";
import { messageTypes } from "../../../constants";
import MessageImage from "../image";
import { MessageText } from "../../../chat_messages/custom_messages/messages_container/MessageText";
import MessageAudio from "../audio";
import MessageVideo from "../video";
import { NotInterested } from "@mui/icons-material";
import { BiCross } from "react-icons/bi";
import { MdNotInterested } from "react-icons/md";
import { IoCloseCircle } from "react-icons/io5";

const styles = {
  right: {
    backgroundColor: '#cce1fc',
      width: "100%",
      borderRadius: 5,
      rowGap: 5,
      height:'100%'
  },
  left: {
    backgroundColor: '#ffffff',
    width: "100%",
    borderRadius: 5,
    rowGap: 5,
    height:'100%'
  },

  userName: {
    color: "black",
    padding: 8,
    paddingBottom: 0,
  },
  cancelBtn: {
    fontSize: 18,
    top: 3,
    right: 8,
    backgroundColor: "transparent",

  },
};

function AbstractReply({
  onDismissReply = null,
  message,
  position = "left",
  searchText
}) {
  const userId = localStorage.getItem("USER_ID");
  const isMe = userId === message?.sender?._id;
  const replyPosition = isMe ? "left" : "right";

  function renderMessageText() {
    if (message && message.text) {
        return (
          <MessageText
            {...{
              reply: true,
              currentMessage: message,
              position: replyPosition,
              textProps: {
                numberOfLines: 3,
              },
              searchText,
            }}
          />
        )
    }
    return null;
  }
  function renderMessageImage() {
    if (message && message?.files?.length) {
      return (
        <MessageImage
          key={`reply-image-message-${message._id}`}
          {...{
            currentMessage: message,
            position: replyPosition,
            reply: true,
            searchText,
          }}
        />
      );
    }
    return null;
  }
  function renderMessageVideo() {
    if (message && message?.files?.length) {
        return (
          <MessageVideo
            key={`reply-video-message-${message._id}`}
            {...{
              currentMessage: message,
              position: replyPosition,
              reply: true,
              searchText,
            }}
          />
        )
    }
    return null;
  }
  function renderMessageAudio() {
    if (message && message.files?.length) {
      return (
        <MessageAudio
        key={`reply-audio-message-${message._id}`}
          {...{
            currentMessage: message,
            position: replyPosition,
            reply: true,
            searchText,
          }}
        />
      )
    }
    return null;
  }

  function renderMessageMedia() {
    // const type = getImageType(message?.files[0])
    // const placeholderImageSrc = getDefaultThumbnail(message?.files[0], type)
    // if (message && message?.files?.length) {
    //   return (
    //     <MessageDocument
    //       {...{
    //         currentMessage: message,
    //         position: replyPosition,
    //         reply: true,
    //         placeholderImageSrc,
    //         searchText,
    //       }}
    //     />
    //   )
    // }
    return null;
  }

  function renderBubbleContent() {
    switch (message?.type) {
      case messageTypes.IMAGE:
        return renderMessageImage();
      case messageTypes.AUDIO:
        return renderMessageAudio();
      case messageTypes.OTHER:
      case messageTypes.DOCUMENT:
        return renderMessageMedia();
      case messageTypes.VIDEO:
        return renderMessageVideo();
      default:
        return renderMessageText();
    }
  }

  const isReplyDeleted =
    (message?.deleted || message?.deletedFor?.includes(userId)) &&
    message?.type !== "TEXT";
  const mainStyle = isReplyDeleted ? styles[position]:{...styles[position]}
  return (
    <div style={mainStyle}>
      {!isMe && (
        <AautiText
          size={"semi"}
          textStyle={styles.userName}
          title={message?.sender?.displayName}
        />
      )}
      <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',padding:4}}>
        {isReplyDeleted ? (
          <MdNotInterested/>
        ) : renderBubbleContent()}
        {!!onDismissReply && (<IoCloseCircle style={{alignSelf:'flex-start'}} onClick={onDismissReply} />)}
      </div>
    </div>
  );
}

export default AbstractReply;
