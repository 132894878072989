import moment from "moment";
import React from "react";
import AautiText from "../../globalComponents/AautiText";

const SlotFormats = ({ timeSlots }) => {
  const renderTimeSlots = () => {
    return Object.keys(timeSlots).map((day) => {
      const slots = timeSlots[day];
      return slots.map((slot, index) => {
        const formattedStartTime = moment(slot.startTime).format("hh:mm A");
        const formattedEndTime = moment(slot.endTime).format("hh:mm A");
        return (
          <div
            key={`${day}-${index}`}
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 5,
              alignItems: "center",
              marginBottom: 4,
            }}
          >
            <AautiText title={`${day}:`} size={"semi"} weight={"bold"} />
            <AautiText
              title={`${formattedStartTime}-${formattedEndTime}`}
              size={"semi"}
            />
          </div>
        );
      });
    });
  };

  return <div>{renderTimeSlots()}</div>;
};

export default SlotFormats;
