import moment from "moment";

export const levelStrings = ["Beginner", "Intermediate", "Advanced", "Expert"];

const date = new Date();
const today = moment(date).format("YYYY-MM-DDT00:00:00");
let endDateFormat = moment(date)?.format("YYYY-MM-DDT23:59:59");

const todayDate = new Date();
const currentHour = todayDate.getHours();
const nextStartingHour = (currentHour + 1) % 24;
todayDate.setHours(nextStartingHour, 0, 0, 0);
const formattedDate = todayDate.toISOString();

const nextHour = new Date(formattedDate);
nextHour.setHours(nextHour.getHours() + 1);
const formattedDate2 = nextHour.toISOString();

export function getNextTimeSlot() {
  const presentTime = moment();
  const currentTime = presentTime.add(1, "hour");
  // const currentDate = new Date();
  // const date = new Date();
  // const currentMinutes = currentDate.getMinutes();
  // let nextHours = currentDate.getHours();
  // let nextMinutes;
  // if (currentMinutes < 20) {
  //   nextMinutes = 30;
  // } else {
  //   nextMinutes = 0;
  //   nextHours++;
  // }
  // nextHours = nextHours % 24;
  // const formattedHours = String(nextHours).padStart(2, "0");
  // const formattedMinutes = String(nextMinutes).padStart(2, "0");
  // date.setHours(formattedHours);
  // date.setMinutes(formattedMinutes);
  // const utcTimeString = moment(date)?.format("YYYY-MM-DDTHH:mm:ss");
  const utcTimeString = moment(currentTime).format("YYYY-MM-DDTHH:00:00");
  return utcTimeString;
}

const nextTimeSlot = getNextTimeSlot();

export const courseTypes = ["Individual", "Group"];

export const classesType = ["Virtual", "Inperson"];

export const targetedAgeGroup = ["Below 6Y", "6Y-18Y", "19Y-30Y", "Above 30Y"];

export const chooseLessonPlans = [
  {
    id: 0.12321498,
    head: "Aauti Content ",
    desc: "Find Pre-existing Aauti Content Here for the Course You Want To Teach. You Can Edit and Adopt This Content for YOur Lesson Plan.",
  },
  {
    id: 456789.23,
    head: "Third Party Content",
    desc: "Find the Content Given By the Third Party Here for the Course You Want To Teach. You Cn Edit and Adopt This Content for Your Lesson.",
  },
];

export const weekName = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const weekMTObj = {
  instructors: [],
  instructorDetails: [],
  batchName: "",
  startDate: today,
  endDate: endDateFormat,
  excludedDates: [],
  discountStartDate: today,
  discountEndDate: endDateFormat,
  discountType: "Percentage",
  inPerson: false,
  virtual: false,
};

export const dailyMTObj = {};

export const commonBatchObject = {
  instructors: [],
  instructorDetails: [],
  batchName: "",
  startDate: today,
  endDate: endDateFormat,
  discountStartDate: today,
  discountEndDate: endDateFormat,
  discountType: "Percentage",
  inPerson: false,
  virtual: false,
};
export const createCourseStrings = {
  coverImage: "Cover Image",
  uploadGigCoverImage: "Upload Gig Cover Image*",
  onlyImagesWithTheFollowingRatioAreAllowed4IsTo3:
    "Only images with the following ratio are allowed:4:3",
  previewVideo: "Preview Video",
  uploadPreviewVideo: "Upload Preview Video*",
  uploadGig: "Upload Gig",
  uploadGigImp: "Upload Gig*",
  uploadMediaAndFiles: "Upload Media & Files",
  price: "Price",
  discount: "Discount",
  discountValue: "Discount Value",
  uploadVideoImp: "Upload video*",
  pasteURLHere: "Paste url here",
  videoTitle: "Video Title",
  saveAddressAs: "Save Address as",
  addressName: "Address Name",
  completeAddress: "Complete Address",
  floorOptional: "Floor(Optional)",
  nearByLandMarkOptional: "Near by LandMark (Optional)",
  saveAddress: "Save Address",
  addNewAddress: "Add New Address",
  myAddress: "My Address",
  noAddressesFound: "No Addresses Found",
  clientLocation: "Client Location",
  topicDuration: "Topic Duration",
  topicDescription: "Topic Description",
  topicContent: "Topic Content ",
  videoUrl: "Video Url",
  subtopicDescription: "Subtopic Description",
  subTopicContent: "SubTopic  Content",
  chooseLessonPlan: "Choose Lesson Plan",
  selectedLessonPlan: "Selected Lesson Plan",
  createLessonPlan: "Create Lesson Plan",
  enterSessionManually: "Enter Session Manually",
  selectedImageMustHaveA4IsTo3AspectRatio:
    "Selected image must have a 4:3 aspect ratio.",
  onlyImagesWithTheFollowingRatioAreAllowed16IsTo9:
    "Only Images with the following ratio are allowed: 16:9",
  previewImage: "Preview Image",
  uploadCoverImageImp: "Upload Cover Image",
  uploadRelatedImgs: "Upload Related Images",

  relatedImages: "Related Images",
  uploadMin2RelatedImages: "Upload Min 2 Related Images",
  uploadMin2RelatedImagesImp: "Upload Min 2 Related Images*",
  uploadMediaFiles: "Upload Media Files",
  coverPage: "Cover Page",
  uploadCoverPageOfTheBook: "Upload Cover Page of the book",
  PlusSymAddTopic: "+ Add Topic",
};
