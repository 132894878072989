import Badge from "@mui/material/Badge";
import React, { useState } from "react";
import "./styles.scss";
import { Tooltip } from "@mui/material";
import AautiText from "../../../globalComponents/AautiText";
import { conversationTypes, messageTypes } from "../../constants";
import { getDateTimeDifference } from "../../../../utils/Helpers";
import { useSelector } from "react-redux";
import AautiAvatar from "../AautiAvatar";
import { StyledBadge } from "../../chat_messages/chat_profile/container";

const getMessage = (latestMsg) => {
  let message = null;
  let text = latestMsg?.text;
  switch (latestMsg?.type) {
    case messageTypes.IMAGE:
      message = `📷 ${text ?? "Image"}`;
      break;
    case messageTypes.DOCUMENT:
      message = `📁 ${text ?? "Document"}`;
      break;
    case messageTypes.VIDEO:
      message = `🎥 ${text ?? "Video"}`;
      break;
    case messageTypes.AUDIO:
      message = `🎵 ${text ?? "Audio"}`;
      break;
    default:
      message = text;
      break;
  }
  return message;
};

export default function ChatListItem({ conversation, onClickChat,selected }) {
  const { message = {}, type } = conversation ?? {};
  let messageText = getMessage(message);
  const [showAvatarView, setShowAvatarView] = useState(false);

  const isOnToOne = type === conversationTypes.ONE_TO_ONE;
  const isInstituteAdmin = useSelector(
    (state) => state.app.userRole === "instituteAdmin"
  );

  const key = conversation?._id;

  const avatar = isOnToOne
    ? conversation?.user?.profileImage
    : conversation?.avatar;

  const name = isOnToOne
    ? conversation?.user?.displayName ?? ""
    : conversation?.name ?? "";

  const renderAvatar = () => {
    if (!name) return;
    let Avatar = () => (
      <AautiAvatar {...{ height: 45, width: 45, src: avatar, alt: name }} />
    );
    if (isOnToOne) {
      return (
        <StyledBadge
          overlap="circular"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          variant={"dot"}
          onlineStatus={conversation?.user?.onlineStatus}
        >
          <Avatar />
        </StyledBadge>
      );
    }
    return <Avatar />;
  };

  const showInstituteIcon =
    conversation?.instituteId &&
    !isInstituteAdmin &&
    conversation?.type !== conversationTypes.ONE_TO_ONE;

  return (
    <li className="item_container" onClick={onClickChat}>
      <div className="item_content_container" style={selected?{backgroundColor:'#e6ebff'}:{}}>
        <div className={"item_left_wrapper"}>{renderAvatar()}</div>
        <div className={"item_right_wrapper"}>
          <div className={"item_right_top_wrapper"}>
            <Tooltip
              placement="right"
              arrow
              title={name}
              style={{ marginBottom: 2.5,marginRight:0}}
            >
              <div>
                <AautiText
                  id={`room-name-${key}`}
                  title={name}
                  size={"normal"}
                  weight={"medium"}
                  textAlign={"left"}
                  textStyle={{
                    color: "#1e1e1e",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: "210px",
                  }}
                />
              </div>
            </Tooltip>
            {!!conversation?.unreadCount && (
              <Badge color="primary" badgeContent={conversation?.unreadCount} sx={{top:0,right:8}} style={{padding:0, }} />
            )}
          </div>
          <div className={"item_right_top_wrapper"}>
            <AautiText
              id={`msg-text-${key}`}
              title={messageText}
              size={"small"}
              weight={"normal"}
              textAlign={"left"}
              textStyle={{
                color: "#575b5c",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                width: "150px",
                fontSize: 12,
              }}
            />
            <AautiText
              id={`msg-date-${key}`}
              title={
                message?.createdAt && getDateTimeDifference(message?.createdAt)
              }
              size={"normal"}
              weight={"normal"}
              textAlign={"right"}
              textStyle={{
                color: "#575b5c",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "100px",
                fontSize: 11,
              }}
            />
          </div>
        </div>
      </div>
      <hr
        style={{
          border: "solid 0.1px whitesmoke",
          marginTop: 4,
          marginBottom: 4,
        }}
      />
    </li>
  );
}
