import moment from "moment";
import {
  AllCategories,
  setAllLanguages,
  setBuyNowdata,
  setCartsDataBeforeLogin,
  setCountriesArray,
  setCountryObject,
  setPricingRates,
  setUserdetails,
  setUserLocation,
  updateMembersList,
} from "../redux/reducer/appReducer";
import { AuthService } from "../service";
import { appStorageServices } from "../storage";
import { StorageConstants } from "../storage";
import _ from "lodash";
import { GetAllCountries } from "./availability/Services";
import html2pdf from "html2pdf.js";
import { useDispatch, useSelector } from "react-redux";
import { getAllLanguages } from "./course/createCourse/Services";
import Avatar from "@mui/material/Avatar";
import { deepOrange, deepPurple } from "@mui/material/colors";
import VirtualIcon from "./globalComponents/icons/VirtualIcon";
import InPerson from "./globalComponents/icons/InPerson";
import VirtualInPerson from "./globalComponents/icons/VirtualInPersonIcon";
import GigImage from "./globalComponents/icons/GigIcon";
import { IconButton, Tooltip } from "@mui/material";
import virtual from "../assets/images/monitor.png";
import inperson from "../assets/images/drop-inperson.png";
import vi from "../assets/images/Inperson_Virtual.svg";
import { IPStickAccessKey } from "../service/api-constants";
import AautiText from "./globalComponents/AautiText";
import { MdOutlineAccessTime } from "react-icons/md";
import { globalColors } from "../themes/GlobalColors";
import { levelStrings } from "./createLessonPlan/Constants";

export const cardsCount = 6;

export function truncateDescription(description) {
  const maxLength = 100;
  if (description.length > maxLength) {
    return description.substring(0, maxLength - 3) + "...";
  }
  return description;
}

export const groupDaysByTimings = (schedule) => {
  const grouped = new Map();
  for (const [day, timeSlots] of Object?.entries(schedule ?? {})) {
    for (const slot of timeSlots) {
      const { startTime, endTime } = slot;
      const key = `${startTime}-${endTime}`;

      if (!grouped.has(key)) {
        grouped.set(key, { startTime, endTime, days: [] });
      }
      grouped.get(key).days.push(day);
    }
  }
  return Array.from(grouped.values());
};

export const returnWeek = (weeks) => {
  const combineWeeks = groupDaysByTimings(weeks);
  return (
    <div>
      {combineWeeks?.map((week, index) => {
        return (
          <div
            key={index}
            className="some-box"
            style={{ border: "none", padding: 0 }}
          >
            <div className="weekName-item">
              <MdOutlineAccessTime size={20} />
              <AautiText
                title={returnTime(week.startTime)}
                weight={"bold"}
                size={"semi"}
                textStyle={{ marginLeft: 10 }}
              />
              <AautiText
                title={" - "}
                weight={"bold"}
                size={"semi"}
                textStyle={{ marginRight: 10, marginLeft: 10 }}
              />
              <AautiText
                title={returnTime(week?.endTime)}
                weight={"bold"}
                size={"semi"}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 2,
                marginTop: 10,
              }}
            >
              {week?.days?.map((each, index) => (
                <div className="weekNaem" key={index}>
                  <AautiText
                    size={"small"}
                    weight={"bold"}
                    title={each.slice(0, 3)}
                    textStyle={{ color: "#ffffff" }}
                  />
                </div>
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export const returnTime = (date) => {
  const time = moment.utc(date).local().format("hh:mmA");
  return time;
};

const colorCodes = [
  globalColors.avatarGreen,
  globalColors.avatarPink,
  globalColors.avatarInfo,
  globalColors.avatarPrimary,
  globalColors.avatarRed,
  globalColors.avatarOrange,
];

export const getFirstTwoChars = (str) => {
  if (!str) {
    return null;
  }
  let a = str.split(" ").map((word) => word.slice(0, 1).toUpperCase());
  return <AautiText title={a.slice(0, 2)} size={"semi"} />;
};

export const capitalizeEntireStr = (str) => {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};
export const returnColorbasedName = (userName) => {
  if (!userName) {
    return null;
  }
  let hash = 0;
  for (let i = 0; i < userName.length; i++) {
    hash = userName.charCodeAt(i) + ((hash << 5) - hash);
  }
  if (!colorCodes || !colorCodes.length) {
    throw new Error("colorCodes array is not defined or empty");
  }
  const colorIndex = Math.abs(hash % colorCodes.length);
  return colorCodes[colorIndex];
};

export const weekKeys = [
  "sunTimeSlots",
  "monTimeSlots",
  "tueTimeSlots",
  "wedTimeSlots",
  "thuTimeSlots",
  "friTimeSlots",
];

export const sortClassType = (arr) => {
  const sortedStrings = _.sortBy(arr).reverse();

  return sortedStrings;
};

export const handleDocuments = (relatedDocuments) => {
  let images = [];
  let videos = [];
  let pdfs = [];

  relatedDocuments?.map((each) => {
    if (
      each.split(".").pop() === "jpeg" ||
      each.split(".").pop() === "jpg" ||
      each.split(".").pop() === "png" ||
      each.split(".").pop() === "jfif"
    ) {
      images.push(each);
    } else if (each.split(".").pop() === "mp4") {
      videos.push(each);
    } else if (each.split(".").pop() === "pdf") {
      pdfs.push(each);
    }
  });
  return [images, videos, pdfs];
};

export const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
export const phoneRegex = /^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;

export const CapitalizeFirstLetter = (text) => {
  if (!text) return text;
  if (!isNaN(text)) return text;
  return text?.charAt(0)?.toUpperCase() + text?.slice(1);
};

export const getFirstLetters = (sentence) => {
  const words = sentence?.split(" ");
  let word = "";
  if (words?.length == 1) {
    word = words[0].substring(0, 1);
  } else {
    word = words
      .slice(0, 2)
      .map((word) => word?.substring(0, 1))
      .join("");
  }
  return (
    <Avatar
      sx={{
        // bgcolor: deepOrange[500],
        height: 20,
        width: 20,
        p: 1,
        fontSize: 12,
      }}
    >
      {word?.toLocaleUpperCase()}
    </Avatar>
  );
};

export function getTimeDifference(targetDate) {
  const now = new Date();
  const target = new Date(targetDate);
  const difference = target - now;

  if (difference <= 0) {
    return "The time has already passed!";
  }

  const millisecondsInMinute = 60 * 1000;
  const millisecondsInHour = 60 * millisecondsInMinute;
  const millisecondsInDay = 24 * millisecondsInHour;
  const millisecondsInMonth = 30 * millisecondsInDay; // Approximation

  const months = Math.floor(difference / millisecondsInMonth);
  const days = Math.floor(difference / millisecondsInDay);
  const hours = Math.floor(difference / millisecondsInHour);
  const minutes = Math.floor(difference / millisecondsInMinute);

  let result = "";

  if (months > 0) {
    result = `${months} month${months > 1 ? "s" : ""} left at this price!`;
  } else if (days > 0) {
    result = `${days} day${days > 1 ? "s" : ""} left at this price!`;
  } else if (hours > 0) {
    result = `${hours} hour${hours > 1 ? "s" : ""} left at this price!`;
  } else {
    result = `${minutes} minute${minutes > 1 ? "s" : ""} left at this price!`;
  }

  return result;
}

const deepClone = (obj) => JSON.parse(JSON.stringify(obj));

export const formatDatesAndTimes = (arr) => {
  return arr.map((item) => {
    const formattedItem = deepClone(item);

    // Format dates to "YYYY-MM-DDT00:00:00"
    if (formattedItem?.startDate) {
      formattedItem.startDate = moment(formattedItem.startDate).format(
        "YYYY-MM-DDT00:00:00"
      );
    }
    if (formattedItem?.endDate) {
      formattedItem.endDate = moment(formattedItem.endDate).format(
        "YYYY-MM-DDT23:59:59"
      );
    }
    if (formattedItem?.discountStartDate) {
      formattedItem.discountStartDate = moment(
        formattedItem.discountStartDate
      ).format("YYYY-MM-DDT00:00:00");
    }
    if (formattedItem?.discountEndDate) {
      formattedItem.discountEndDate = moment(
        formattedItem.discountEndDate
      ).format("YYYY-MM-DDT23:59:59");
    }

    // Format times to "YYYY-MM-DDTHH:mm:ss"
    if (formattedItem?.startTime) {
      formattedItem.startTime = moment(formattedItem.startTime).format(
        "YYYY-MM-DDTHH:mm:ss"
      );
    }
    if (formattedItem?.endTime) {
      formattedItem.endTime = moment(formattedItem.endTime).format(
        "YYYY-MM-DDTHH:mm:ss"
      );
    }
    if (formattedItem?.startDateTime) {
      formattedItem.startDateTime = moment(formattedItem.startDateTime).format(
        "YYYY-MM-DDTHH:mm:ss"
      );
    }
    if (formattedItem?.endDateTime) {
      formattedItem.endDateTime = moment(formattedItem.endDateTime).format(
        "YYYY-MM-DDTHH:mm:ss"
      );
    }

    if (formattedItem?.recurringDays) {
      Object.keys(formattedItem?.recurringDays)?.forEach((day) => {
        formattedItem.recurringDays[day] = formattedItem.recurringDays?.[
          day
        ]?.map((slot) => ({
          ...slot,
          startTime: moment(slot.startTime).format("YYYY-MM-DDTHH:mm:ss"),
          endTime: moment(slot.endTime).format("YYYY-MM-DDTHH:mm:ss"),
        }));
      });
    }
    if (formattedItem?.customDates) {
      Object.keys(formattedItem.customDates).forEach((date) => {
        formattedItem.customDates[date].startTime = moment(
          formattedItem.customDates[date].startTime
        ).format("YYYY-MM-DDTHH:mm:ss");
        formattedItem.customDates[date].endTime = moment(
          formattedItem.customDates[date].endTime
        ).format("YYYY-MM-DDTHH:mm:ss");
      });
    }

    if (formattedItem.excludedDates) {
      formattedItem.excludedDates = formattedItem.excludedDates.map(
        (dateObj) => ({
          ...dateObj,
          date: moment(dateObj.date).format("YYYY-MM-DDT00:00:00"),
        })
      );
    }
    return formattedItem;
  });
};

export const compareLevelArrays = (array) => {
  if (
    array?.length === levelStrings.length &&
    array.every((item) => levelStrings.includes(item))
  ) {
    return ["All Levels"];
  } else {
    return array;
  }
};

export const returnIcon = (each) => {
  if (each?.virtual && each?.inPerson) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
          gap: 1,
        }}
      >
        <VirtualInPerson />
      </div>
    );
  } else if (each?.virtual) {
    return <VirtualIcon />;
  } else if (each?.inPerson) {
    return <InPerson />;
  } else if (each.gigImage) {
    return <GigImage />;
  }
};

export const virtualInPersonIcon = (each) => {
  let title =
    each?.virtual && each?.inPerson
      ? "Virtual, InPerson"
      : each?.virtual
      ? "Virtual"
      : "InPerson";

  return (
    <Tooltip title={title}>
      <img
        src={require(each?.virtual && each?.inPerson
          ? "../assets/images/Inperson_Virtual.svg"
          : each?.virtual
          ? "../assets/images/monitor.png"
          : "../assets/images/drop-inperson.png")}
        alt={`${title}`}
        style={{
          height: "20px",
          width: "20px",
          zIndex: 100,
        }}
      />
    </Tooltip>
  );
};

const defaultCountry = {
  _id: "624c1d2a28bc5658e358b969",
  name: "USA",
  timeZone: "UTC-10:00",
  countryCode: "US",
  countryCurrency: "USD",
  countryCurrencySymbol: "$",
  flag: "https://svbk-lms.s3.ap-south-1.amazonaws.com/aautie/2807790_18165.jpg",
  phoneCode: "+1",
  gmtDifference: -180,
  description: "USA",
  deleted: false,
  region: "North America",
};

// export const setBuyNowDataAfterRefresh = (data, dispatch, type) => {
//   dispatch(setBuyNowdata(data));
//   localStorage.setItem("BuyNowDataAfterRefresh", JSON.stringify(data));
// };

export const loadScript = (src, id) => {
  return new Promise((resolve, reject) => {
    if (document.getElementById(id)) {
      return resolve();
    }

    const script = document.createElement("script");
    script.src = src;
    script.id = id;
    script.async = true;
    script.onload = () => resolve();
    script.onerror = () => reject(new Error(`Failed to load script ${src}`));
    document.body.appendChild(script);
  });
};

export const logoutFunction = async () => {
  appStorageServices.storeData(StorageConstants.siteVisited, false);
  appStorageServices.storeData(StorageConstants.ROLE, "");
  appStorageServices.storeData(StorageConstants.FIRSTNAME, "");
  appStorageServices.storeData(StorageConstants.LASTNAME, "");
  appStorageServices.storeData(StorageConstants.EMAIL, "");
  appStorageServices.storeData(StorageConstants.IS_LOGGED_IN, false);
  appStorageServices.storeData(StorageConstants.USER_ID, "");
  //   const admin = response.result.role[0] === "serviceprovider" ? true : false;
  appStorageServices.storeData(StorageConstants.IS_SP, "");
  appStorageServices.storeData(StorageConstants.COUNTRY, "");
  appStorageServices.removeItem("JwtToken");
  appStorageServices.removeItem("room");
  //   webSocketLoginStatusUpdate(response.result._id);
  //   if (admin) {
  //     navigation.replace("ServiceProviderDashboardNavigator");
  //   } else {
  //     dispatch(setHomeActive());
  //     navigation.replace("ServiceProviderDashboardNavigator");
  //   }
  await window.location.reload();
  // showToast("info", "Logout success");
};

export const getMembersList = (id, dispatch) => {
  AuthService.getAllMembers(
    id,
    (resp) => {
      dispatch(updateMembersList(resp?.result || []));
    },
    (error) => {
      console.log(error, "error");
    }
  );
};

export const getBatchDetailsById = (batchId, batchDetails) => {
  const batches = batchDetails;
  let temp = {};
  if (batches) {
    if (!Object.keys(temp).length > 0 && batches?.individualDaily) {
      const match = batches?.individualDaily?.find(
        (each) => each._id === batchId
      );
      if (match) temp = { ...match, type: "individualDaily" };
    }
    if (!Object.keys(temp).length > 0 && batches?.individualWeekly) {
      const match = batches?.individualWeekly?.find(
        (each) => each._id === batchId
      );
      if (match) temp = { ...match, type: "individualWeekly" };
    }
    if (!Object.keys(temp).length > 0 && batches?.groupDaily) {
      const match = batches?.groupDaily?.find((each) => each._id === batchId);
      if (match) temp = { ...match, type: "groupDaily" };
    }
    if (!Object.keys(temp).length > 0 && batches?.groupWeekly) {
      const match = batches?.groupWeekly?.find((each) => each._id === batchId);
      if (match) temp = { ...match, type: "groupWeekly" };
    }
  }
  return temp;
};

export const validateTitle = (name) => name.replace(/[^a-zA-Z0-9]/g, "");

export const setBuyNowDataAfterRefresh = (data, dispatch, type) => {
  dispatch(setBuyNowdata(data));
  localStorage.setItem("BuyNowDataAfterRefresh", JSON.stringify(data));
};

export const setInLocalBeforeLogin = (data, dispatch, type) => {
  // console.log({ data }, dispatch, type, "dispatchhh");
  const prevData = localStorage.getItem("CartDataBeforeLogin");
  const isIn = _.some(JSON.parse(prevData), (item) => {
    if (item?.gigId) {
      return item?.gigId == data?.gigId;
    } else {
      return item?.courseId == data?.courseId;
    }
  });
  if (!isIn) {
    let newItems = [];
    if (prevData) {
      newItems = [...JSON.parse(prevData), data];
    } else {
      newItems.push(data);
    }
    dispatch(setCartsDataBeforeLogin(newItems));
    localStorage.setItem("CartDataBeforeLogin", JSON.stringify(newItems));
  } else {
    const returnFilter = JSON.parse(prevData).filter((each, index) => {
      if (type == "gig") {
        return each?.gigId != data?.gigId;
      } else if (type == "course") {
        return each?.courseId != data?.courseId;
      }
    });
    dispatch(setCartsDataBeforeLogin(returnFilter));
    localStorage.setItem("CartDataBeforeLogin", JSON.stringify(returnFilter));
  }
};

export const removePlusAtStart = (value) => {
  const string = value || "";
  return string.startsWith("+") ? string.slice(1) : string;
};

export const getCountryPhoneCode = (countries, value) => {
  const result = countries?.filter(
    (each) =>
      each?.name?.toLowerCase() === value?.toLowerCase() ||
      each?.countryCode === value ||
      each?.phoneCode === value
  );
  return result;
};

const countyObj = {
  _id: "624c1d2a28bc5658e358b969",
  name: "USA",
  timeZone: "UTC-10:00",
  countryCode: "US",
  countryCurrency: "USD",
  flag: "https://svbk-lms.s3.ap-south-1.amazonaws.com/aautie/2807790_18165.jpg",
  phoneCode: "+1",
  gmtDifference: -180,
  description: "USA",
  deleted: false,
  region: "North America",
};

// export const convertedPrice = (rates, price, obj = countyObj) => {
//   const { countryCurrency } = obj;
//   const convertedPrice = price * rates[countryCurrency];
//   if (price) {
//     return convertedPrice;
//   }
// };

export const formatPrice = (pricingRates, price, countryObject = countyObj) => {
  const { countryCurrency } = countryObject;
  const convertedPrice = price * pricingRates[countryCurrency];
  if (convertedPrice) {
    if (convertedPrice >= 1000000) {
      return (convertedPrice / 1000000).toFixed(1) + "M";
    } else if (convertedPrice >= 1000) {
      return (convertedPrice / 1000).toFixed(1) + "K";
    } else if (convertedPrice === 0) {
      return 0;
    } else {
      return convertedPrice;
    }
  }
};

// const convertToShort = (convertedPrice) => {
//   if (convertedPrice) {
//     if (convertedPrice >= 1000000) {
//       return (convertedPrice / 1000000).toFixed(2) + "M";
//     } else if (convertedPrice >= 1000) {
//       return (convertedPrice / 1000).toFixed(2) + "K";
//     } else if (convertedPrice === 0) {
//       return 0;
//     } else {
//       return convertedPrice % 2 == 0
//         ? convertedPrice
//         : convertedPrice.toFixed(2);
//     }
//   }
//   return 0;
// };

const convertToShort = (number, roundOf = 1) => {
  if (number) {
    const convertedNumber = Math.abs(Number(number)).toFixed(roundOf); // Convert to absolute number

    if (convertedNumber >= 1.0e9) {
      // Nine Zeroes for Billions
      return parseFloat((convertedNumber / 1.0e9).toFixed(roundOf)) + "B";
    } else if (convertedNumber >= 1.0e6) {
      // Six Zeroes for Millions
      return parseFloat((convertedNumber / 1.0e6).toFixed(roundOf)) + "M";
    } else if (convertedNumber >= 1.0e3 || convertedNumber >= 999.5) {
      // Round close to 1000 as 1K
      return parseFloat((convertedNumber / 1.0e3).toFixed(roundOf)) + "K";
    }

    // Return the original number if below 999.5
    return parseFloat(convertedNumber);
  }

  return 0;
};

// Changes numbers to 1K,1M,1B format, If number < 1000 returns number
export const convertToMillionBillionsPrice = (number, roundOf = 1) => {
  if (number) {
    const convertedNumber = Math.abs(Number(number));
    if (convertedNumber >= Number(1.0e9) - 500000) {
      // Nine Zeroes for Billions
      return parseFloat((convertedNumber / 1.0e9).toFixed(roundOf)) + "B";
    } else if (convertedNumber >= Number(1.0e6) - 50) {
      // Six Zeroes for Millions
      return parseFloat((convertedNumber / 1.0e6).toFixed(roundOf)) + "M";
    } else if (convertedNumber >= 1.0e3) {
      // Three Zeroes for Thousands
      return parseFloat((convertedNumber / 1.0e3).toFixed(roundOf)) + "K";
    }
    return convertedNumber % 1 === 0
      ? convertedNumber
      : parseFloat(convertedNumber.toFixed(roundOf));
  }
};

export const timeZone = () => {
  let str = moment().format("Z");
  let opposite = "";
  if (str[0] == "+") {
    opposite = str.replace("+", "-");
  } else {
    opposite = str.replace("-", "+");
  }
  return {
    localZone: str,
    opposite,
  };
};

export const checkYoutubeVideo = (url) => {
  const urlValidate =
    /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|live\/|v\/)?)([\w\-]+)(\S+)?$/;
  return urlValidate.test(url);
};

export const validateUrl = (url, type = "") => {
  if (type === "image") {
    let urlValidate = /^(ftp|http|https):\/\/.*\/.*\.(png|jpeg|jpg)/;
    return urlValidate.test(url);
  } else if (type === "video") {
    // let urlValidate = /^(ftp|http|https):\/\/[^ "]+$/
    let urlValidate = /^((ftp|http|https)?:\/\/.*\.(mp4|3gp|avi|mov|flv|wmv))/;
    return urlValidate.test(url);
  } else {
    let urlValidate = /^(ftp|http|https):\/\/[^ "]+$/;
    return urlValidate.test(url);
  }
};

export const PriceConversions = () => {
  const { pricingRates, countryObject } = useSelector((state) => state.app);
  const { countryCurrency, countryCurrencySymbol } =
    countryObject ?? defaultCountry;
  const convertPriceWithSpCode = (currencyCode, amount) => {
    const countryPrice = amount * pricingRates?.[currencyCode];
    return countryPrice || 0;
  };

  const convertPricetoUSD = (currencyCode, amount) => {
    const convertedPrice = amount / pricingRates?.[currencyCode];
    return convertedPrice || 0;
  };
  const convertToMillionBillions = (number, roundOf = 1) => {
    if (number) {
      const convertedNumber = Math.abs(Number(number));
      if (convertedNumber >= Number(1.0e9) - 500000) {
        // Nine Zeroes for Billions
        return parseFloat((convertedNumber / 1.0e9).toFixed(roundOf)) + "B";
      } else if (convertedNumber >= Number(1.0e6) - 50) {
        // Six Zeroes for Millions
        return parseFloat((convertedNumber / 1.0e6).toFixed(roundOf)) + "M";
      } else if (convertedNumber >= 1.0e3) {
        // Three Zeroes for Thousands
        return parseFloat((convertedNumber / 1.0e3).toFixed(roundOf)) + "K";
      }
      return `${countryCurrencySymbol}${
        convertedNumber % 1 === 0
          ? convertedNumber
          : parseFloat(convertedNumber.toFixed(roundOf))
      }`;
    }
  };
  const onlyPriceConvert = (amount) => {
    const convertedPrice = amount * pricingRates?.[countryCurrency];
    return convertedPrice || 0;
  };
  const convertPriceWithCurrency = (
    amount,
    roundOf = 1,
    convertToShortFormat = true
  ) => {
    const convertedAmount = onlyPriceConvert(amount);
    const formatAmount = convertToShortFormat
      ? convertToShort(convertedAmount, roundOf)
      : convertedAmount.toFixed(roundOf);
    return `${countryCurrencySymbol}${formatAmount}`;
  };

  const cartConvertPriceWithCurrency = (amount) => {
    const convertedAmount = Math.ceil(onlyPriceConvert(amount));
    return `${countryCurrencySymbol}${convertedAmount}`;
  };
  const convertOnlyPriceInShort = (amount) => {
    const convertedAmount = onlyPriceConvert(amount);

    const formatAmount = convertToShort(convertedAmount);
    return formatAmount;
  };
  const onlyPriceWithCurrency = (amount) => {
    return `${countryCurrencySymbol}${amount}`;
  };
  const convertIndianAmount = (amount) => {
    if (countryCurrency == "USD") {
      return amount;
    }
    const inrToUsdRate = pricingRates[countryObject?.countryCurrency];
    const usdAmount = amount / inrToUsdRate;
    return usdAmount.toFixed(3);
  };

  const returnPriceWithCurrencyCode = (price, roundOf = 2) => {
    if (Number(price) > 0) {
      return countryCurrencySymbol + price.toFixed(roundOf);
    }
    return 0;
  };

  const dashBoardRevenueconvertToMillionBillions = (number, roundOf = 1) => {
    if (number) {
      const convertedNumber = Math.abs(Number(number));
      if (convertedNumber >= Number(1.0e9) - 500000) {
        // Nine Zeroes for Billions
        return parseFloat((convertedNumber / 1.0e9).toFixed(roundOf)) + "B";
      } else if (convertedNumber >= Number(1.0e6) - 50) {
        // Six Zeroes for Millions
        return parseFloat((convertedNumber / 1.0e6).toFixed(roundOf)) + "M";
      } else if (convertedNumber >= 1.0e3) {
        // Three Zeroes for Thousands
        return parseFloat((convertedNumber / 1.0e3).toFixed(roundOf)) + "K";
      }
      return convertedNumber % 1 === 0
        ? convertedNumber
        : parseFloat(convertedNumber.toFixed(roundOf));
    }
    return 0;
  };

  return {
    convertPriceWithCurrency,
    cartConvertPriceWithCurrency,
    onlyPriceWithCurrency,
    onlyPriceConvert,
    convertOnlyPriceInShort,
    convertIndianAmount,
    convertPriceWithSpCode,
    convertToMillionBillions,
    convertPricetoUSD,
    convertToShort,
    returnPriceWithCurrencyCode,
    dashBoardRevenueconvertToMillionBillions,
  };
};

export const CommonFunctions = () => {
  const dispatch = useDispatch();

  const GetCountriesApi = (country = defaultCountry?.name) => {
    const localCountry = localStorage.getItem("country");
    const searchCountry = localCountry || country;
    GetAllCountries(
      (response) => {
        if (response?.result) {
          let userCountry = _.filter(response?.result, {
            name: searchCountry,
          });
          dispatch(setCountryObject(userCountry[0]));

          dispatch(setCountriesArray(response?.result));
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const getCurrencyGeneratorApi = async () => {
    AuthService.getConversionRates(
      (resp) => {
        if (resp?.result !== null) {
          dispatch(setPricingRates(resp?.result?.exchangeRates));
        }
      },
      (error) => {
        console.log(error, "error-getting price conversion rates");
      }
    );
  };

  const fetchUserDetails = (loggedinUserId) => {
    AuthService.GetuserDataById(
      loggedinUserId,
      (response) => {
        if (response?.result !== null) {
          dispatch(setUserdetails(response?.result));
          GetCountriesApi(response?.result.country);
          getMembersList(response?.result?._id, dispatch);
        }
      },
      (error) => {
        console.error("Error fetching user details:", error);
      }
    );
  };

  const getIpbaseLocation = async (resolve, reject) => {
    try {
      const ipRes = await fetch("https://api.ipify.org?format=json");
      const { ip } = await ipRes.json();
      const coordsRes = await fetch(
        `https://api.ipstack.com/${ip}?access_key=${IPStickAccessKey}`
      );
      const { latitude, longitude } = await coordsRes.json();

      if (latitude && longitude) {
        AuthService.getUserLocationByLatLang(
          latitude,
          longitude,
          (response) => {
            if (response?.result) {
              const locationData = { ...response.result, latitude, longitude };
              dispatch(setUserLocation(locationData));
              GetCountriesApi(locationData?.country);
            } else {
              console.log("User location not found");
            }
          }
        );
      } else {
        console.log("Coordinates not found");
        getUserLocation()
          .then((location) => {
            dispatch(setUserLocation(location));
            GetCountriesApi(location?.country);
          })
          .catch((error) => {
            console.error("Error getting user location:", error);
          });
      }
    } catch (error) {
      console.log(error);
      getUserLocation()
        .then((location) => {
          dispatch(setUserLocation(location));
          GetCountriesApi(location?.country);
        })
        .catch((error) => {
          console.error("Error getting user location:", error);
        });
    }
  };

  const getUserLocation = async () => {
    return new Promise((resolve, reject) => {
      if (navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            if (latitude && longitude) {
              AuthService.getUserLocationByLatLang(
                latitude,
                longitude,
                (response) => {
                  if (response?.result !== null) {
                    const obj = {
                      ...response.result,
                      latitude,
                      longitude,
                    };
                    resolve(obj);
                  } else {
                    reject("User location not found");
                  }
                },
                (error) => {
                  reject(error);
                }
              );
            } else {
              reject("Latitude and longitude not found");
            }
          },

          (error) => {
            if (error.code === error.PERMISSION_DENIED) {
              getIpbaseLocation();
            } else if (error.code === error.POSITION_UNAVAILABLE) {
              alert("Location information is unavailable.");
            } else if (error.code === error.TIMEOUT) {
              alert("The request to get your location timed out.");
            } else {
              alert("An unknown error occurred.");
            }
            reject(error);
          }
        );
      } else {
        getIpbaseLocation();
        reject("Navigator not available");
      }
    });
  };

  const getAllCategories = async () => {
    AuthService.getAllCategories(
      (response) => {
        dispatch(AllCategories(response?.result));
      },
      (error) => {
        console.log(error, "error");
      }
    );
  };
  const storeAllLanguages = () => {
    getAllLanguages(
      (response) => {
        if (response?.status === "Success") {
          dispatch(setAllLanguages(response?.result));
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  return {
    GetCountriesApi,
    getCurrencyGeneratorApi,
    fetchUserDetails,
    getAllCategories,
    storeAllLanguages,
    getUserLocation,
    getIpbaseLocation,
  };
};

// const useClickOutSide = (callback) => {
//   useEffect(() => {
//     const handlePopstate = (event) => {
//       const confirmationMessage = "Do you want to save changes as Drafts";
//       if (window.confirm(confirmationMessage)) {
//         callback();
//       } else {
//         event.preventDefault();
//       }
//     };
//     const handleNavigation = (event) => {
//       const confirmationMessage = "Do you want to save changes as Drafts";
//       if (event.target.closest("header") || event.target.closest("sidebar")) {
//         if (window.confirm(confirmationMessage)) {
//           callback();
//         }
//       }
//     };
//     window.addEventListener("popstate", handlePopstate);
//     document.addEventListener("click", handleNavigation);
//     return () => {
//       window.removeEventListener("popstate", handlePopstate);
//       document.removeEventListener("click", handleNavigation);
//     };
//   }, []);
// };

// export default useClickOutSide;

export const isBeforeTime = (dateTime) => {
  return moment(dateTime).diff(moment(), "minutes") > 15;
};

export const isAlreadyPurchased = (
  purchasedArray = [],
  presentUserObj = {},
  type = "course"
) => {
  let filter = purchasedArray?.filter((each) => {
    if (each?.userId === presentUserObj?.userId) {
      if (type == "course") {
        if (each?.batchId == presentUserObj?.batchId) {
          return true;
        }
        return false;
      }
      return true;
    }
    return false;
  });

  if (filter?.length > 0) {
    return true;
  } else {
    return false;
  }
};
export const removeSpacesBetweenString = (value) => {
  return value?.replace(/ +/g, "");
};
export const emailTextInputMiddleWare = (text) => {
  return removeSpacesBetweenString(
    text?.toLocaleLowerCase()?.trimStart()
  )?.replace(/\uFFFC/g, "");
};
export function isValidEmail(text) {
  const reg =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return reg.test(text);
}

export function getDateTimeDifference(dateTime) {
  if (!moment(dateTime).isValid()) {
    return "";
  }
  const momentDate = moment.utc(dateTime).local();
  const currentDate = moment().local();
  if (momentDate.isValid()) {
    if (momentDate.isSame(currentDate, "date")) {
      if (Math.abs(momentDate.diff(currentDate)) < 6000) {
        return "just now";
      } else {
        const hoursDiff = Math.abs(momentDate.diff(currentDate, "h"));
        if (hoursDiff < 1) {
          const minutesDiff = Math.abs(momentDate.diff(currentDate, "minutes"));
          if (minutesDiff < 1) {
            return "just now";
          }
          return `${minutesDiff} min${minutesDiff > 1 ? "s" : ""} ago`;
        }
        return `${hoursDiff} hr${hoursDiff > 1 ? "s" : ""} ago`;
      }
    }
    if (momentDate.isBefore(currentDate, "date")) {
      const diff = Math.abs(momentDate.diff(moment().local(), "days"));
      if (diff == 1) {
        return "Yesterday";
      }
      return momentDate.format("DD/MM/YYYY");
    }
  }
  return "N/A";
}

export const isOnDiscountDate = (discountStartDate, discountEndDate) => {
  let val =
    moment(discountStartDate).isBefore(moment(new Date())) &&
    moment(discountEndDate).isAfter(moment(new Date()));
  return val;
};

export const batchExpired = (endDateTime) => {
  const timeZone = moment().utcOffset();
  return moment(endDateTime)
    .add(timeZone, "m")
    .isBefore(moment().add(timeZone, "m"));
};
