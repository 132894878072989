import React from 'react';
import AautiText from '../../../../globalComponents/AautiText';
const styles = {
    container: {
        marginTop: 5,
        marginBottom: 10,
    },
    text: {
        backgroundColor: 'transparent',
        color: 'black',
        fontSize: 11,
        fontWeight: 500,
        textAlign:'center'
    },
};
export function SystemMessage({ currentMessage }) {
    if (currentMessage == null || currentMessage.system == false) {
        return null;
    }
    return (<div style={styles.container}>
        <p style={styles.text}>{currentMessage.text}</p>
    </div>);
}