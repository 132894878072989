import { ApiRequest } from "../../service";
import { ApiMethods, ApiPaths } from "../../service/api-constants";

export function getMembers(parentId, onSuccess, onFailure) {
  ApiRequest({
    url: "user/getMembersList",
    method: ApiMethods.GET,
    params: { parentId },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
export function getInviteMembers(parentId, onSuccess, onFailure) {
  ApiRequest({
    url: "invite/getAllInviteMembersByParentId",
    method: ApiMethods.GET,
    params: { parentId },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export function getGrades(onSuccess, onFailure) {
  ApiRequest({
    url: "grades/getAllGrades",
    method: ApiMethods.GET,
    // params: { parentId },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export function transferMember(
  userId,
  parentId,
  childId,
  onSuccess,
  onFailure
) {
  ApiRequest({
    url: "user/transferChild?",
    method: ApiMethods.GET,
    params: { userId, parentId, childId },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export function getInviteMemberEliibility(data, onSuccess, onFailure) {
  ApiRequest({
    url: "invite/inviteMemberEligibility",
    method: ApiMethods.GET,
    params: data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export function DeleteMembers(data, onSuccess, onFailure) {
  ApiRequest({
    url: "user/deleteUser",
    method: ApiMethods.POST,
    data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export function SaveMembers(userObj, onSuccess, onFailure) {
  ApiRequest({
    url: "user/saveUser",
    method: ApiMethods.POST,
    data: userObj,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export function checkMobileIfUserHasAnyData(data, onSuccess, onFailure) {
  ApiRequest({
    url: "user/getUserByMobile",
    method: ApiMethods.GET,
    params: data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export function getUserByMail(email, onSuccess, onFailure) {
  ApiRequest({
    url: "user/getUserByEmail",
    method: ApiMethods.GET,
    params: { email },
  }).then(
    (response) => {
      onSuccess(response.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export function FindMember(params, onSuccess, onFailure) {
  ApiRequest({
    url: "invite/searchEmail",
    method: ApiMethods.GET,
    params: params,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export function senInvitation(data, onSuccess, onFailure) {
  ApiRequest({
    url: "invite/send",
    method: ApiMethods.POST,
    data: data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
export function sendVerification(email, onSuccess, onFailure) {
  ApiRequest({
    url: "app/sendMail",
    method: ApiMethods.GET,
    params: { email },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export function VerifyMobileOtp(mobile, otp, onSuccess, onFailure) {
  ApiRequest({
    url: `sms/verifySms?toPhoneNumber=${mobile}&otp=${otp}`,
    method: ApiMethods.POST,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export function sendSmstoPhone(toPhoneNumber, onSuccess, onFailure) {
  ApiRequest({
    url: `sms/sendSms?toPhoneNumber=${toPhoneNumber}`,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export function VerifyEmailOtp(email, otp, onSuccess, onFailure) {
  ApiRequest({
    url: "app/verifyOtp",
    method: ApiMethods.POST,
    params: { email, otp },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export function getUserById(id, onSuccess, onFailure) {
  ApiRequest({
    url: "user/getUserById",
    method: ApiMethods.GET,
    params: { id },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export function UpdateMember(data, onSuccess, onFailure) {
  ApiRequest({
    url: "user/updateUser",
    method: ApiMethods.PUT,
    data: data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export function getChildsubscriptions(endUserId, onSuccess, onFailure) {
  ApiRequest({
    url: "subscription/getAllSubscriptionsByEndUser?",
    method: ApiMethods.GET,
    params: { endUserId },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export function getChildOngoinCourses(endUserId, onSuccess, onFailure) {
  ApiRequest({
    url: "myCourse/getOngoingCoursesByUserId",
    method: ApiMethods.GET,
    params: { endUserId },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export function getChildAttendance(endUserId, noOfDays, onSuccess, onFailure) {
  ApiRequest({
    url: "attendance/getEndUserAttendanceForSession",
    method: ApiMethods.GET,
    params: { endUserId, noOfDays },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export function getChildNotifications(userId, onSuccess, onFailure) {
  ApiRequest({
    url: "notification/getAllNotificationsByUser",
    method: ApiMethods.GET,
    params: { userId },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
