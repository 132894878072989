import React, { useState } from "react";
import AautiText from "../../globalComponents/AautiText";
import moment from "moment";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  Tooltip,
} from "@mui/material";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { options } from "./feed/poll/constants";
import noImage from "../../../assets/images/no-image.svg";
import { useSelector } from "react-redux";
import { AuthService } from "./feed/poll/Service";
import dayjs from "dayjs";
import { showToast } from "../../globalComponents/Toast";
import AautiDialog from "../../globalComponents/AautiDialog";
import { isEmpty } from "lodash";
import EmptyData from "../../globalComponents/EmptyData";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CapitalizeFirstLetter } from "../../CommonFunctions";

const PollCard = (props) => {
  const {
    screen,
    cardDetails,
    UserRole,
    index,
    states = {},
    AllSessions,
    handleClickOption,
  } = props;
  const {
    openMenu = false,
    setOpenMenu,
    setMenuActiveIndex,
    setPollDialog,
    switchChange,
    setConfirmDialog,
    setSubmitted,
    setSelectedEndTime,
    setSelectedStartTime,
    setDropObj,
  } = states;
  const [saveNewObject, setSaveNewObject] = React.useState({});
  const { loggedinUserId, userDetails } = useSelector((state) => state.app);
  const [endUserSubmit, setEndUserSubmit] = useState(false);
  const [pollDetailDialog, setPollDetailDialog] = useState(false);
  const [pollObjectLoad, setPollObjectLoad] = useState(false);
  const [pollDetailsObj, setPollDetailsObj] = useState([]);
  const [sessionForEU, setSessionForEU] = useState("");
  const [endUserPollDetails, setEndUserPollDetails] = useState({});
  const [afterSaveObj, setAfterSaveObj] = useState({});
  const submitPoll = {
    answers: [],
    _id: "",
    batchId: cardDetails?.batchId,
    sessionId: cardDetails?._id,
    serviceProviderId: "",
    // question: "",
    userObj: {
      _id: userDetails?._id || "",
      displayName: userDetails?.displayName || "",
      profileImage: userDetails?.profileImage || "",
      submittedAt: new Date().toISOString(),
    },
  };

  const [submitApiPoll, setSubmitApiPoll] = useState(submitPoll);

  const renderValidations = (each) => {
    if (UserRole === "serviceprovider") {
      if (each.serviceProviderId === loggedinUserId) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const onSuccessSPPollDetails = (response) => {
    if (response?.data?.status === "Success") {
      setPollDetailsObj(response?.data?.result[0]);
      setPollObjectLoad(false);
      setSaveNewObject(response?.data?.result[0]);
    } else {
      setPollObjectLoad(false);
    }
  };

  const onFailure = (error) => {
    setSubmitted(false);
  };

  const handlePollDetailDialogClose = (e, reason) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      return;
    } else {
      setPollDetailDialog(false);
    }
  };

  const renderSubmittedTime = (inputDate) => {
    const date = new Date(inputDate);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";

    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedDate = `${day} ${month} ${formattedHours}:${formattedMinutes} ${ampm}`;
    return formattedDate;
  };

  const handleCheckboxChange = (event, each) => {
    const selectedOptionId = each._id;

    // Toggle the isSelected property for the checkbox
    const updatedOptions = endUserPollDetails.options.map((option) => {
      if (option._id === selectedOptionId) {
        return { ...option, isSelected: event.target.checked };
      }
      return option;
    });

    // Update submitApiPoll to include or exclude the selected option
    const updatedAnswers = event.target.checked
      ? [...submitApiPoll.answers, selectedOptionId] // Add the selected option
      : submitApiPoll.answers.filter((id) => id !== selectedOptionId); // Remove if unchecked

    // Update the state for endUserPollDetails and submitApiPoll
    setEndUserPollDetails((prevDetails) => ({
      ...prevDetails,
      options: updatedOptions,
    }));

    setSubmitApiPoll((prevState) => ({
      ...prevState,
      answers: updatedAnswers,
    }));
  };

  const handleRadioChange = (event, each) => {
    const selectedOptionId = each._id;
    const updatedAnswers = [selectedOptionId];

    // Update isSelected property in endUserPollDetails
    const updatedOptions = endUserPollDetails.options.map((option) => ({
      ...option,
      isSelected: option._id === selectedOptionId,
    }));

    // Update endUserPollDetails with the modified options
    setEndUserPollDetails((prevDetails) => ({
      ...prevDetails,
      options: updatedOptions,
    }));

    // Update submitApiPoll with the selected option
    setSubmitApiPoll((prevState) => ({
      ...prevState,
      answers: updatedAnswers,
    }));
  };

  const handleSubmitPoll = () => {
    const newApiPoll = {
      ...submitApiPoll,
      _id: endUserPollDetails._id,
      serviceProviderId: endUserPollDetails?.serviceProviderId,
    };
    AuthService.submitPoll(newApiPoll, onSuccessSubmitPoll, onFailure);
    setPollDetailDialog(false);
  };

  const onSuccessSubmitPoll = (response) => {
    if (response?.status === "Success") {
      setAfterSaveObj(response.result);
      setEndUserSubmit(true);
      showToast("success", response?.message);
    } else {
      setEndUserSubmit(false);
    }
  };

  const handleEndUserViewResults = (each) => {
    // setPollDetailsObj(each);
    setPollDetailDialog(true);
    const startTime = new Date(each?.sessionDate);
    const formattedSession = `${CapitalizeFirstLetter(
      each?.sessionName
    )} (${startTime.getDate().toString().padStart(2, "0")}-${(
      startTime.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${startTime.getFullYear()})`;
    // console.log({startTime.getDate().toString().padStart(2, "0")}-${(startTime.getMonth() + 1).toString().padStart(2, "0")}-${startTime.getFullYear()},'sdsdk')
    setSessionForEU(formattedSession);

    AuthService.getEndUserPollDetails(
      loggedinUserId,
      each._id,
      onSuccessEnduserPollDetails,
      onFailure
    );
  };
  const onSuccessEnduserPollDetails = (response) => {
    if (response.data.status === "Success") {
      setEndUserPollDetails(response?.data?.result[0]);
    } else {
      console.log(response?.data?.message);
    }
  };

  const handleViewResults = (each) => {
    setPollDetailDialog(true);
    if (switchChange) {
      setPollObjectLoad(true);
      AuthService.getDraftsSpIdPollDetails(
        each.sessionId,
        each.batchId,
        loggedinUserId,
        (response) => onSuccessSPDraftPollDDetals(response, each),
        onFailure
      );
    } else {
      setPollObjectLoad(true);
      AuthService.getSpIdPollDetails(
        loggedinUserId,
        each._id,
        onSuccessSPPollDetails,
        onFailure
      );
    }
  };

  const onSuccessSPDraftPollDDetals = (response, item) => {
    if (response?.data?.status === "Success") {
      if (!isEmpty(response?.data?.result)) {
        const filteredOBJJ = response?.data?.result?.filter(
          (each) => each._id === item._id
        );
        setPollDetailsObj(filteredOBJJ[0]);
        setPollObjectLoad(false);
      }
    } else {
      setPollObjectLoad(false);
    }
  };

  return (
    <>
      <div className="poll-card" key={cardDetails?._id}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <AautiText
            textAlign={"left"}
            size={"small"}
            weight={"normal"}
            textStyle={{
              color: "#828282",
            }}
            title={`Created at ${moment(cardDetails?.createdAt).format(
              "MM/DD/YY"
            )}
    ${moment(cardDetails?.createdAt).format("hh:mm A")}`}
          />
          {screen === "feed" && (
            <AautiText
              size={"small"}
              weight={"normal"}
              textStyle={{
                color: "#828282",
              }}
              title={"Poll"}
            />
          )}
        </div>

        <div className="poll-card-inner">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              width: "100%",
            }}
          >
            <AautiText
              textAlign={"left"}
              size={"medium"}
              weight={"bold"}
              textStyle={{
                color: "#1E1E1E",
                width: "98%",
              }}
              title={`${cardDetails?.question}`}
            />
            {renderValidations(cardDetails) && screen !== "feed" && (
              <>
                <Tooltip
                  title={
                    <div style={{ padding: "5px" }}>
                      {options?.map((option) => (
                        <p
                          style={{
                            cursor: "pointer",
                            textAlign: "center",
                            padding: "2px",
                            marginLeft: "5px",
                            fontSize: "14px",
                          }}
                          key={option}
                          onClick={(e) =>
                            handleClickOption(e, cardDetails, index)
                          }
                        >
                          {option}
                        </p>
                      ))}
                    </div>
                  }
                >
                  <div>
                    <HiOutlineDotsVertical
                      key={cardDetails._id.$oid}
                      size={18}
                      onClick={() => {
                        setOpenMenu(!openMenu);
                        setMenuActiveIndex(index);
                      }}
                      style={{
                        alignSelf: "center",
                        // cursor: "pointer",
                      }}
                    />
                  </div>
                </Tooltip>
              </>
            )}
          </div>
          <div className="ans-cont">
            {cardDetails?.options?.map((item) => (
              <div className="each-ans" key={item?._id}>
                {item.image !== "" && (
                  <img
                    src={item?.image === "" ? noImage : item?.image}
                    alt="ans"
                    className="image-each-ans"
                  />
                )}

                <AautiText
                  textAlign={"left"}
                  size={"normal"}
                  weight={"medium"}
                  textStyle={{
                    color: "#1E1E1E",
                  }}
                  title={`${item?.answer}`}
                />
              </div>
            ))}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            {UserRole !== "endUser" ? (
              <button
                className="all-votes-cont"
                onClick={() => handleViewResults(cardDetails)}
              >
                View Results
              </button>
            ) : (
              <>
                {cardDetails?.options?.some((each) => each.isSelected) ? (
                  <button
                    className="all-votes-cont"
                    onClick={() => handleEndUserViewResults(cardDetails)}
                  >
                    View Results
                  </button>
                ) : (
                  <button
                    className="all-votes-cont"
                    onClick={() => handleEndUserViewResults(cardDetails)}
                  >
                    View
                  </button>
                )}
              </>
            )}
            <AautiText
              textAlign={"left"}
              size={"normal"}
              weight={"medium"}
              textStyle={{
                color: "#1E1E1E",
              }}
              title={`Total Votes: ${cardDetails?.totalVotes}`}
            />
          </div>
        </div>
      </div>
      <AautiDialog
        onClose={(e, reason) => handlePollDetailDialogClose(e, reason)}
        open={pollDetailDialog}
        dialogTitle={"Poll Details"}
      >
        {pollObjectLoad ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "20vh",
            }}
          >
            <CircularProgress />
          </div>
        ) : (isEmpty(pollDetailsObj) || pollDetailsObj === undefined) &&
          UserRole !== "endUser" ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              height: "20vh",
            }}
          >
            <EmptyData text="No Details" showExplore={true} />
          </div>
        ) : (
          <div className="dialog-main" style={{ flexDirection: "column" }}>
            <AautiText
              textAlign={"left"}
              size={"normal"}
              weight={"bold"}
              textStyle={{
                color: "#4B4D4D",
              }}
              title={sessionForEU}
            />
            <div className="quest-dialog-cont">
              <div className="poll-card-inner">
                <AautiText
                  textAlign={"left"}
                  size={"normal"}
                  weight={"bold"}
                  textStyle={{
                    color: "#4B4D4D",
                    width: "100%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "normal",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 2,
                    lineHeight: "1.5em",
                  }}
                  title={
                    UserRole !== "endUser"
                      ? `${pollDetailsObj?.question}`
                      : `${endUserPollDetails?.question}`
                  }
                />

                <div className="ans-cont" style={{ marginTop: 8 }}>
                  {UserRole !== "endUser" ? (
                    <>
                      {pollDetailsObj?.options?.map((each, index) => (
                        <>
                          <Accordion sx={{ width: "100%" }} key={index}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1bh-content"
                              id="panel1bh-header"
                            >
                              <div
                                className="each-ans1"
                                key={index}
                                style={{ width: "100%" }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                  }}
                                >
                                  {each.image !== "" && (
                                    <img
                                      src={
                                        each.image === "" ? noImage : each.image
                                      }
                                      alt="ans"
                                      className="image-each-ans"
                                    />
                                  )}
                                  <AautiText
                                    textAlign={"left"}
                                    size={"normal"}
                                    weight={"bold"}
                                    textStyle={{
                                      color: "#4B4D4D",
                                    }}
                                    title={`${each.answer || "N/A"}`}
                                  />
                                </div>
                                {!switchChange && (
                                  <AautiText
                                    size={"small"}
                                    weight={"medium"}
                                    textStyle={{
                                      color: "#4B4D4D",
                                    }}
                                    title={`${each.usersList.length} ${
                                      each.usersList.length > 1
                                        ? "Votes"
                                        : "Vote"
                                    }`}
                                  />
                                )}
                              </div>
                            </AccordionSummary>
                            {UserRole !== "endUser" && (
                              <AccordionDetails>
                                {each?.usersList.length > 0 ? (
                                  each?.usersList?.map((item) => (
                                    <div
                                      key={item._id}
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          paddingLeft: 10,
                                        }}
                                      >
                                        <Avatar
                                          alt={item.displayName}
                                          src={item.profileImage}
                                        />
                                        <AautiText
                                          size={"small"}
                                          weight={"medium"}
                                          textStyle={{
                                            color: "#4B4D4D",
                                            marginLeft: 10,
                                          }}
                                          title={`${item.displayName}`}
                                        />
                                      </div>
                                      <AautiText
                                        size={"small"}
                                        weight={"medium"}
                                        textStyle={{
                                          color: "#4B4D4D",
                                        }}
                                        title={`${renderSubmittedTime(
                                          item.submittedAt
                                        )}`}
                                      />
                                    </div>
                                  ))
                                ) : (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      width: "100%",
                                    }}
                                  >
                                    <AautiText
                                      size={"semi"}
                                      weight={"normal"}
                                      textStyle={{
                                        color: "#4B4D4D",
                                      }}
                                      title={`No Data`}
                                    />
                                  </div>
                                )}
                              </AccordionDetails>
                            )}
                          </Accordion>
                        </>
                      ))}
                    </>
                  ) : (
                    <>
                      {endUserPollDetails?.options?.map((each, index) => (
                        <>
                          <div key={index} style={{ width: "100%" }}>
                            <div
                              className="each-ans1"
                              key={each?._id}
                              style={{ width: "100%" }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                  width: "100%",
                                }}
                              >
                                {endUserPollDetails.allowMultiple ? (
                                  <FormControl
                                    sx={{ m: 3 }}
                                    component="fieldset"
                                    variant="standard"
                                  >
                                    <FormGroup>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={each.isSelected}
                                            onChange={(e) =>
                                              handleCheckboxChange(e, each)
                                            }
                                            name={each.answer}
                                          />
                                        }
                                        label={
                                          <>
                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "flex-start",
                                                alignItems: "center",
                                              }}
                                            >
                                              {" "}
                                              {each.image !== "" && (
                                                <img
                                                  src={
                                                    each.image === ""
                                                      ? noImage
                                                      : each.image
                                                  }
                                                  alt="ans"
                                                  className="image-each-ans"
                                                />
                                              )}
                                              <AautiText
                                                textAlign={"left"}
                                                size={"normal"}
                                                weight={"bold"}
                                                textStyle={{
                                                  color: "#4B4D4D",
                                                }}
                                                title={each.answer}
                                              />
                                            </div>
                                          </>
                                        }
                                      />
                                    </FormGroup>
                                  </FormControl>
                                ) : (
                                  <>
                                    {!endUserPollDetails?.isSubmitted ? (
                                      <FormControl key={each.answer}>
                                        <RadioGroup
                                          aria-labelledby="demo-controlled-radio-buttons-group"
                                          name="controlled-radio-buttons-group"
                                          style={{
                                            marginTop: each.image && 10,
                                          }}
                                          value={each.isSelected}
                                          onChange={(e) =>
                                            handleRadioChange(e, each)
                                          }
                                        >
                                          <FormControlLabel
                                            value={each.answer}
                                            control={
                                              <Radio
                                                checked={each.isSelected}
                                              />
                                            }
                                            label={
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "row",
                                                  justifyContent: "flex-start",
                                                  alignItems: "center",
                                                }}
                                              >
                                                {" "}
                                                {each.image !== "" && (
                                                  <img
                                                    src={
                                                      each.image === ""
                                                        ? noImage
                                                        : each.image
                                                    }
                                                    alt="ans"
                                                    className="image-each-ans"
                                                  />
                                                )}
                                                <AautiText
                                                  textAlign={"left"}
                                                  size={"normal"}
                                                  weight={"bold"}
                                                  textStyle={{
                                                    color: "#4B4D4D",
                                                  }}
                                                  title={each.answer}
                                                />
                                              </div>
                                            }
                                          />
                                        </RadioGroup>
                                      </FormControl>
                                    ) : (
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                          width: "100%",
                                          backgroundColor: each.isSelected
                                            ? "#cad9ed"
                                            : "white",
                                          margin: "5px",
                                          padding: "8px",
                                          borderRadius: "5px",
                                          paddingLeft: "10px",
                                          border:
                                            each.isSelected &&
                                            "1px solid #3083EF",
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "flex-start",
                                            alignItems: "center",
                                          }}
                                        >
                                          {each.image !== "" && (
                                            <img
                                              src={
                                                each.image === ""
                                                  ? noImage
                                                  : each.image
                                              }
                                              alt="ans"
                                              className="image-each-ans"
                                            />
                                          )}
                                          <AautiText
                                            textAlign={"left"}
                                            size={"normal"}
                                            weight={"normal"}
                                            textStyle={{
                                              color: "#4B4D4D",
                                            }}
                                            title={`${each.answer}`}
                                          />
                                        </div>

                                        <AautiText
                                          textAlign={"left"}
                                          size={"normal"}
                                          weight={"normal"}
                                          textStyle={{
                                            color: "#4B4D4D",
                                            marginLeft: "5px",
                                          }}
                                          title={`${Math.round(
                                            each.votePercentage
                                          )}%`}
                                        />
                                      </div>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </>
                      ))}
                    </>
                  )}
                </div>
              </div>
              {!endUserPollDetails?.isSubmitted && UserRole === "endUser" && (
                <button
                  className="create-button"
                  style={{
                    backgroundColor: endUserPollDetails?.options?.some(
                      (each) => each.isSelected === true
                    )
                      ? "#3083EF"
                      : "lightgrey",
                  }}
                  onClick={handleSubmitPoll}
                >
                  Submit
                </button>
              )}
            </div>
          </div>
        )}
      </AautiDialog>
    </>
  );
};

export default PollCard;
