import React from "react";
import { Cropper } from "react-cropper";
import "./Style.scss";
import AautiText from "../../globalComponents/AautiText";
import "cropperjs/dist/cropper.css";
import { isEmpty } from "lodash";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { AautiStrings } from "../../globalComponents/AautiStrings";

const ImageCropper = ({
  cropperRef,
  image,
  cropObject,
  handleUploadCroppedImage,
  uploadedFile,
  cancelCrop,
  isPackage = false,
  multipleUploads,
  setImage,
  setBackdropOpen,
  handleRemoveMultipleImages,
  aiImages,
  removeWhenUploaded = false,
}) => {
  const imageFields = ["courseImage", "bookCoverPage"];
  const relatedImages = imageFields?.includes(cropObject?.field || aiImages);
  const returnVideoName = (video) => {
    var filename = video.substring(video.lastIndexOf("/") + 1);
    var videoName = filename.split(".").slice(0, -1).join(".");
    return videoName;
  };

  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      const croppedCanvas = cropperRef.current?.cropper.getCroppedCanvas();
      if (croppedCanvas) {
        const fileName = uploadedFile?.name;
        croppedCanvas?.toBlob((blob) => {
          const croppedFile = new File(
            [blob],
            fileName ?? `AiImages-${new Date().toUTCString()}.jpg`,
            {
              type: "image/png",
              lastModified: Date.now(),
            }
          );
          //   setCropData(croppedFile);
          handleUploadCroppedImage(croppedFile, cropObject?.field || aiImages);
        }, "image/png");
      }
    }
  };
  const returnBool = aiImages ? false : true;

  return (
    <div style={{ width: "100%" }}>
      <>
        <Cropper
          ref={cropperRef}
          style={{ height: 400, width: "100%" }}
          zoomTo={0.5}
          initialAspectRatio={
            imageFields?.includes(cropObject?.field) ? 4 / 3 : 16 / 9
          }
          aspectRatio={
            imageFields?.includes(cropObject?.field) ? 4 / 3 : 16 / 9
          }
          preview=".img-preview"
          src={image}
          viewMode={1}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background={false}
          responsive={true}
          autoCropArea={1}
          checkOrientation={false}
          guides={true}
          checkCrossOrigin={returnBool}
        />

        {!isEmpty(multipleUploads) && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: 6,
              marginTop: 8,
              width: "100%",
              justifyContent: "center",
            }}
          >
            {multipleUploads?.map((each, index) => (
              <div
                onClick={() => {
                  setImage(each);
                }}
                style={{
                  border:
                    image === each
                      ? "2px solid #3399ff"
                      : "1px solid lightgrey",
                  borderRadius: 6,
                  padding: 5,
                  position: "relative",
                  height: 80,
                  width: 80,
                }}
              >
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRemoveMultipleImages(each);
                  }}
                  sx={{
                    position: "absolute",
                    right: 1,
                    background: "#fff",
                    padding: 0.5,
                    "&:hover": {
                      background: "#fff",
                    },
                  }}
                >
                  <CloseIcon fontSize="small" sx={{ fontSize: 14 }} />
                </IconButton>
                <img
                  src={each}
                  style={{ height: "100%", width: "100%" }}
                  alt="gigImage"
                />
              </div>
            ))}
          </div>
        )}

        <div style={{ width: "100%", marginTop: 15 }}>
          <AautiText title={AautiStrings?.previewString} weight={"bold"} />

          <div
            // className="box"
            style={{
              width: relatedImages ? "100%" : "300px",
              //   float: "left",
              display: "flex",
              flexDirection: relatedImages ? "row" : "column",
              gap: 5,
              border: "1px solid lightgrey",
              padding: 4,
              borderRadius: 4,
              marginTop: 3,
            }}
          >
            <div
              style={{
                width: relatedImages ? "160px" : "100%",
                height: !relatedImages ? "160px" : "90px",
              }}
            >
              <div
                className="img-preview"
                style={{
                  width: "100%",
                  float: "left",
                  height: "100%",
                  borderRadius: 5,
                  // backgroundSize: "contain",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 10,
                width: "100%",
              }}
            >
              <div>
                <AautiText title={"Lorem Ipsum"} weight={"bold"} />
                <AautiText
                  size={"small"}
                  title={
                    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
                  }
                  textStyle={{
                    width: "100%",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    WebkitLineClamp: 2,
                    textOverflow: "ellipsis",
                    maxWidth: "100%",
                  }}
                />

                {!relatedImages && <div style={{ height: 20 }} />}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            width: "100%",
            alignItems: "center",
            marginTop: 20,
            gap: 10,
          }}
        >
          <button
            style={{
              border: "1px solid lightgrey",
              color: "#000",
              fontSize: "13px",
              fontWeight: "500",
              borderRadius: 4,
              padding: "5px 15px 5px 15px",
              cursor: "pointer",
            }}
            onClick={() => {
              cancelCrop();
            }}
          >
            Cancel
          </button>
          <button
            style={{
              background: "#3399ff",
              color: "#fff",
              fontSize: "13px",
              fontWeight: "500",
              border: "none",
              borderRadius: 4,
              padding: "5px 15px 5px 15px",
              cursor: "pointer",
            }}
            onClick={() => {
              getCropData();
              if (!isEmpty(multipleUploads) || removeWhenUploaded) {
                handleRemoveMultipleImages(image);
              }
              isPackage && setBackdropOpen(true);
            }}
          >
            {multipleUploads?.length > 1 ? "Next" : "Proceed"}
          </button>
        </div>
      </>
    </div>
  );
};

export default ImageCropper;
