import {
  IconButton,
  Tooltip,
  styled,
  tooltipClasses,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./Style.scss";
import AautiText from "../globalComponents/AautiText";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { ChevronRight } from "@mui/icons-material";
import { getActiveCategory, getAllCategories } from "../homeScreen/reducer";
import { isEmpty } from "lodash";

const HtmlTooltip = styled(({ className, isOpen, onToggle, ...props }) => {
  const isMobile = useMediaQuery("(max-width:600px)");
  return (
    <Tooltip
      open={isMobile ? isOpen : undefined} // Control open state for mobile
      onClick={isMobile ? onToggle : undefined} // Click to open on mobile
      disableFocusListener={isMobile}
      disableHoverListener={isMobile}
      disableTouchListener={isMobile}
      placement="bottom"
      enterDelay={0}
      arrow
      {...props}
      classes={{ popper: className }}
      slotProps={{
        popper: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, -15],
              },
            },
          ],
        },
      }}
    />
  );
})(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#fff",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",

    boxShadow: theme.shadows[1],
  },
}));

const HeadCategories = () => {
  const [id, setId] = useState("");
  const [cat, setCat] = useState(false);
  const { activeCategories } = useSelector((state) => state.homePageReducer);
  const [catCurrent, setCatCurrent] = useState(0);
  const catRef = useRef();
  const navigate = useNavigate();
  const [openTooltipId, setOpenTooltipId] = useState(null); // Track the open tooltip's ID
  const dispatch = useDispatch();

  useEffect(() => {
    if (isEmpty(activeCategories)) {
      dispatch(getActiveCategory());
    }
  }, []);

  const handleTooltipToggle = (id) => {
    // If the tooltip with `id` is already open, close it. Otherwise, open it.
    setOpenTooltipId((prev) => (prev === id ? null : id));
  };
  const {
    mobileDevices,
    ipodDevices,
    miniLapys,
    aboveLargeMobileDevices,
    lapy,
  } = useSelector((state) => state.responsive);
  const handleCatDirec = (direc) => {
    if (direc === "right") {
      catRef?.current?.slickNext();
    } else {
      catRef?.current?.slickPrev();
    }
  };

  const handleAfterChange = (current) => {
    setCatCurrent(current);
  };

  const [disabledLeftButton, setDisableLeftButton] = useState(true);
  const [disabledRight, setDisableRight] = useState(false);
  const numOfSlides = mobileDevices
    ? 2.5
    : aboveLargeMobileDevices
    ? 3
    : ipodDevices
    ? 4
    : miniLapys
    ? 5
    : 7;
  const handleBeforeChange = (slide, nextSlide) => {
    if (nextSlide === 0 || slide === 0) {
      setDisableLeftButton(true);
      setDisableRight(false);
    } else if (nextSlide + numOfSlides === activeCategories?.length) {
      setDisableRight(true);
      setDisableLeftButton(false);
    } else {
      setDisableRight(false);
      setDisableLeftButton(false);
    }
  };
  const settingsCategories = {
    dots: false,
    infinite: false,
    arrows: false,
    slidesToShow: numOfSlides,
    slidesToScroll: numOfSlides - 1 || numOfSlides,
    speed: 500,
    indicators: true,
    scroll: true,
    swipable: true,
    beforeChange: handleBeforeChange,
    variableWidth: mobileDevices ? true : false,
  };
  return (
    <div
      className="HeadCat-div1"
      // style={{ flexWrap: !mobileDevices && "wrap" }}
      onMouseEnter={() => setCat(true)}
      onMouseLeave={() => setCat(false)}
    >
      <IconButton
        style={{ display: (mobileDevices || !cat) && "none", color: "#3083EF" }}
        size="small"
        sx={{
          padding: 0,
          zIndex: 100,
          background: disabledLeftButton ? "#ddd" : "#3997eadb",
          boxShadow: "2px 2px 10px 2px #dddddd",
          // top: "46%",
          // left: "3px",
          marginTop: "6px",
          color: "#fff",
          "&:hover": { backgroundColor: !disabledLeftButton && "#3997eadb" },
        }}
        // disabled={id == ""}
        onClick={() => handleCatDirec("left")}
        aria-label="delete"
      >
        <ChevronLeftIcon
          fontSize="medium"
          sx={{ color: disabledLeftButton ? "#ffffff" : "#fff" }}
        />
      </IconButton>

      <div
        style={{
          width: mobileDevices ? "99%" : "94%",
          display: "flex",
          alignItems: "flex-start",
          marginTop: "0px",
        }}
      >
        <Slider
          {...settingsCategories}
          ref={catRef}
          style={{ width: "100%", overflow: "scroll" }}
        >
          {activeCategories?.map((each, index) => (
            <HtmlTooltip
              isOpen={openTooltipId === each?._id} // Tooltip 1 is open if openTooltipId is 1
              onToggle={() => handleTooltipToggle(each?._id)}
              key={each?._id}
              id="head-categories"
              title={
                <div className="HeadCat-div2" style={{ width: "auto" }}>
                  {each?.subCategories?.map((item, index) => (
                    <div
                      className="sub-cat"
                      style={{ width: "auto" }}
                      key={index}
                      onClick={() => {
                        const newArray = {
                          subCategory: item?.subCategoryName,
                          category: each?.categoryName,
                        };
                        navigate(`/${item?.subCategoryName}/courses`, {
                          state: newArray,
                        });
                      }}
                    >
                      <AautiText
                        title={item?.subCategoryName}
                        size={"semi"}
                        className={"HeadCat-text1"}
                      />
                    </div>
                  ))}
                </div>
              }
            >
              <div
                key={each?._id || index}
                onMouseEnter={() => {
                  setId(each?._id);
                }}
                onMouseLeave={() => {
                  setId("");
                }}
                // onClick={() =>
                //   // navigate(`/sub-categories/${each?._id}`, {
                //   //   state: { id: each?._id, batch: "home" },
                //   // })
                // }
                className="HeadCat-div3"
                style={{
                  width: "-webkit-fill-available",
                  top: "10px",
                  // boxShadow:
                  //   each._id === id
                  //     ? ""
                  //     : "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                  // display: "flex !important",
                  // justifyContent: "center !important",
                  // background: "red !important",
                }}
              >
                <AautiText
                  title={each?.categoryName}
                  size={"semi"}
                  className={"HeadCat-text2"}
                  textStyle={{
                    color: each._id === id ? "#2076E5" : "#626262",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    // overflow: "hidden",
                    WebkitLineClamp: 1,
                    textOverflow: "ellipsis",
                    maxWidth: "100%",
                  }}
                />
              </div>
            </HtmlTooltip>
          ))}
        </Slider>
      </div>
      <IconButton
        aria-label="delete"
        size="small"
        onClick={() => handleCatDirec("right")}
        sx={{
          padding: "0px",
          marginTop: "10px",
          display: (mobileDevices || !cat) && "none",
          zIndex: 100,
          background: disabledRight ? "#dddddd" : "#3997eadb",
          boxShadow: "2px 2px 10px 2px #dddddd",
          // top: "45%",
          right: 0,
          "&:hover": { backgroundColor: !disabledRight && "#3997eadb" },
        }}
      >
        <ChevronRight
          fontSize="medium"
          sx={{ color: disabledRight ? "#ffffff" : "#fff" }}
        />{" "}
      </IconButton>
    </div>
  );
};

export default HeadCategories;
