import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import Badge from "@mui/material/Badge";
export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: 25,
    minWidth: 440,
  },
  "& .MuiDialogActions-root": {
    padding: 25,
  },
}));
export const StyledBadge = styled(Badge)(({ onlineStatus }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: onlineStatus ? "#44b700" : "#ff0000",
    color: onlineStatus ? "#44b700" : "#ff0000",
    boxShadow: `0 0 0 2px #ffffff`,
    "&::after": {
      position: "relative",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: onlineStatus? "ripple 1.2s infinite ease-in-out":'none',
      border: onlineStatus? "1px solid currentColor":'none',
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));
