import { Box } from "@mui/material";
import React from "react";
import AautiText from "../../globalComponents/AautiText";
import moment from "moment";
import { isEmpty } from "lodash";

const CustomBatchDetails = ({ courseBatch, showRanges = true }) => {
  const formatedDates = courseBatch?.excludedDates?.sort((a, b) =>
    moment(a.date).diff(moment(b.date))
  );

  const returnTimeFormat = (date) => {
    return moment(date).format("hh:mm A");
  };

  const returnCustomizedFormats = (schedule) => {
    if (schedule) {
      return Object.keys(schedule).map((date) => {
        return (
          <AautiText
            key={date}
            size={"semi"}
            title={`${moment(date).format("DD MMM YYYY")}: ${returnTimeFormat(
              schedule[date]?.startTime
            )} - ${returnTimeFormat(schedule[date]?.endTime)}`}
          />
        );
      });
    }
  };
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"flex-start"}
      alignItems={"center"}
      width={"100%"}
    >
      <Box
        display={showRanges ? "flex" : "none"}
        flexDirection={"row"}
        justifyContent={"flex-start"}
        alignItems={"center"}
        width={"100%"}
        gap={1}
      >
        <AautiText title={"Date Ranges:"} textStyle={{ color: "#000" }} />
        <AautiText
          size={"semi"}
          title={`${moment(courseBatch?.startDate).format(
            "DD MMM YYYY"
          )} - ${moment(courseBatch?.endDate).format("DD MMM YYYY")}`}
        />
      </Box>
      <Box
        display={showRanges ? "flex" : "none"}
        flexDirection={"row"}
        justifyContent={"flex-start"}
        alignItems={"center"}
        width={"100%"}
        gap={1}
      >
        <AautiText title={"Times:"} textStyle={{ color: "#000" }} />
        <AautiText
          size={"semi"}
          title={`${moment(courseBatch?.startTime).format(
            "hh:mm A"
          )} - ${moment(courseBatch?.endTime).format("hh:mm A")}`}
        />
      </Box>
      <Box
        display={!isEmpty(courseBatch?.excludedDates) ? "flex" : "none"}
        flexDirection={"row"}
        justifyContent={"flex-start"}
        alignItems={"center"}
        width={"100%"}
        gap={1}
        flexWrap={"wrap"}
      >
        <AautiText title={"Excluded Dates:"} textStyle={{ color: "#000" }} />
        {formatedDates?.map((each, index) => {
          const excludeLength = formatedDates?.length;
          return (
            <AautiText
              size={"semi"}
              title={`${moment(each?.date).format("DD MMM")} ${
                excludeLength - 1 == index ? "" : ","
              }`}
            />
          );
        })}
      </Box>
      <Box
        display={!isEmpty(courseBatch?.customDates) ? "flex" : "none"}
        flexDirection={"column"}
        justifyContent={"flex-start"}
        alignItems={"flex-start"}
        width={"100%"}
        // gap={1}
        flexWrap={"wrap"}
      >
        <AautiText title={"Customizes Dates:"} textStyle={{ color: "#000" }} />
        {returnCustomizedFormats(courseBatch?.customDates)}
      </Box>
    </Box>
  );
};

export default CustomBatchDetails;
