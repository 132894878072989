import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
} from "@mui/material";
import React from "react";
import DeletionAlert from "../../assets/images/DeletionAlert.png";
import { useSelector } from "react-redux";

const ConfirmationDialogRaw = (props) => {
  const {
    onClose,
    open,
    onClick,
    dialogTitle = "Are you sure want to delete?",
  } = props;
  const { mobileDevices } = useSelector((state) => state.responsive);

  const handleCancel = (e) => {
    e.stopPropagation();
    onClose();
  };
  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": {
          width: mobileDevices ? "75%" : "50%",
          maxWidth: "320px",
          maxHeight: 435,
        },
      }}
      maxWidth="xs"
      open={open}
      onClose={handleCancel}
    >
      <DialogContent
        sx={{
          padding: "5px 10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          alt="delete"
          style={{
            height: "200px",
            width: "200px",
            // marginLeft: "20%",
            alignSelf: "center",
          }}
          src={DeletionAlert}
        />
      </DialogContent>
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogActions>
        <Button
          autoFocus
          variant="outlined"
          type="button"
          onClick={handleCancel}
          id="confirm-cancel"
        >
          Cancel
        </Button>
        <Button
          onClick={onClick}
          type="button"
          variant="contained"
          id="confirm-delete"
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialogRaw;
