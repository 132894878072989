import React, { useState } from "react";
import AautiText from "../../globalComponents/AautiText";
import moment from "moment";
import { isEmpty } from "lodash";
import { Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import { GoDotFill } from "react-icons/go";
import { options } from "./feed/poll/constants";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { MdFilePresent } from "react-icons/md";
import AautiDialog from "../../globalComponents/AautiDialog";
import CourseImages from "../../course/courseCreation/CourseImages";
import { Document, Page } from "react-pdf";

const AgendaCard = (props) => {
  const { cardDetails, UserRole, screen, handleClickOption } = props;
  const { loggedinUserId } = useSelector((state) => state.app);
  const [selectedOption, setSelectedOption] = useState("");
  const [topicIndex, setTopicIndex] = useState(1);
  const [agendaDialog, setAgendaDialog] = useState(false);
  const [saveAgendaApi, setSaveAgendaApi] = useState({});
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [MenuActiveIndex, setMenuActiveIndex] = useState(null);
  const [topicDialog, SetTopicDialog] = useState(false);
  const [file, setFile] = useState("");
  const [fileType, setFileType] = useState("");
  const [imagesList, setImagesLiist] = useState([]);
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);

  const renderValidations = (each) => {
    console.log(each, "skdlskd");
    if (UserRole.includes("serviceprovider")) {
      if (each?.serviceProviderId === loggedinUserId) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <>
      <div className="agenda-card" key={cardDetails?._id}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <AautiText
            textAlign={"left"}
            size={"small"}
            weight={"normal"}
            textStyle={{
              color: "#828282",
            }}
            title={`Created at ${moment(cardDetails?.createdAt).format(
              "MM/DD/YY"
            )}
              ${moment(cardDetails?.createdAt).format("hh:mm A")}`}
          />
          {screen === "feed" && (
            <AautiText
              size={"small"}
              weight={"normal"}
              textStyle={{
                color: "#828282",
              }}
              title={"Agenda"}
            />
          )}
        </div>

        <div
          style={{
            width: "100%",
            border: "1px solid #E0E0E0",
            borderRadius: "5px",
          }}
        >
          <div className="agenda-card-sub">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                marginTop: "5px",
              }}
            >
              <AautiText
                textAlign={"left"}
                size={"medium"}
                weight={"bold"}
                textStyle={{
                  color: "#1E1E1E",
                  width: "100%",
                }}
                title={`${cardDetails?.title || cardDetails?.question}ijiji`}
              />
              {renderValidations(cardDetails) && screen !== "feed" && (
                <>
                  <Tooltip
                    title={
                      <div style={{ padding: "5px" }}>
                        {options?.map((option) => (
                          <p
                            style={{
                              cursor: "pointer",
                              textAlign: "center",
                              padding: "2px",
                              marginLeft: "5px",
                              fontSize: "14px",
                            }}
                            key={option}
                            onClick={(e) => handleClickOption(e, cardDetails)}
                          >
                            {option}
                          </p>
                        ))}
                      </div>
                    }
                  >
                    <div>
                      <HiOutlineDotsVertical
                        key={cardDetails._id.$oid}
                        size={18}
                        onClick={() => {
                          setOpenMenu(!openMenu);
                          setMenuActiveIndex(cardDetails?._id);
                        }}
                        style={{
                          alignSelf: "center",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  </Tooltip>
                </>
              )}
            </div>
          </div>
          <div className="agenda-sub-sub">
            <div
              style={{
                backgroundColor: "#FFFFFF",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                width: "100%",
                padding: "10px",
                borderRadius: "5px",
              }}
            >
              <div style={{ width: "100%" }}>
                <div style={{ width: "100%" }}>
                  {!isEmpty(cardDetails?.topics) ? (
                    cardDetails?.topics?.map((each, index) => (
                      <div
                        style={{
                          borderBottom: "1px solid #E0E0E0",
                          borderRadius: "5px",
                          padding: "10px",
                          paddingLeft: "15px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "center",
                          }}
                        >
                          <GoDotFill color="black" />
                          <AautiText
                            // maxLength={100}
                            textAlign={"left"}
                            size={"normal"}
                            weight={"bold"}
                            textStyle={{
                              color: "black",

                              borderRadius: "5px",
                              width: "100%",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                            title={each?.topicDescription || "N/A"}
                          />
                        </div>

                        <div style={{ marginTop: "5px" }}>
                          {each?.uploadedFiles?.map((item) => (
                            <button
                              className="attachment-class-name"
                              onClick={() => {
                                SetTopicDialog(true);
                                setFile(item);
                                setFileType(
                                  item.substring(item.lastIndexOf(".") + 1)
                                );
                                setImagesLiist(
                                  each?.uploadedFiles.filter(
                                    (item) =>
                                      item.substring(
                                        item.lastIndexOf(".") + 1
                                      ) === "png" ||
                                      item.substring(
                                        item.lastIndexOf(".") + 1
                                      ) === "jpeg" ||
                                      item.substring(
                                        item.lastIndexOf(".") + 1
                                      ) === "jpg"
                                  )
                                );
                              }}
                            >
                              <MdFilePresent size={15} />

                              <AautiText
                                textAlign={"left"}
                                size={"semi"}
                                weight={"medium"}
                                textStyle={{
                                  color: "#1E1E1E",
                                  marginLeft: "2px",
                                }}
                                title={`${item?.substring(
                                  item?.lastIndexOf("/") + 1
                                )}`}
                              />
                            </button>
                          ))}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div>
                      <AautiText
                        textAlign={"left"}
                        size={"medium"}
                        weight={"bold"}
                        textStyle={{
                          color: "#1E1E1E",
                          width: "100%",
                        }}
                        title={`No Topics`}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AautiDialog
        onClose={(e, reason) =>
          reason === "backdropClick" || reason === "escapeKeyDown"
            ? null
            : SetTopicDialog(false)
        }
        open={topicDialog}
        dialogTitle={"Files"}
      >
        <div>
          <AautiText
            size="medium"
            title={file?.substring(file?.lastIndexOf("/") + 1)}
            weight="bold"
            textAlign="center"
            textStyle={{ marginBottom: "5px" }}
          />
          {fileType === "png" || fileType === "jpeg" || fileType === "jpg" ? (
            <CourseImages {...{ imagesList, showIcon: false }} />
          ) : fileType === "pdf" ? (
            <div>
              <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
                <Page pageNumber={pageNumber} />
              </Document>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <button
                  style={{ marginRight: 10 }}
                  onClick={() => {
                    if (pageNumber > 1) {
                      setPageNumber(pageNumber - 1);
                    }
                  }}
                >
                  {`<`}
                </button>
                <p>
                  Page {pageNumber} of {numPages}
                </p>
                <button
                  style={{ marginLeft: 10 }}
                  onClick={() => {
                    if (numPages > pageNumber) {
                      setPageNumber(pageNumber + 1);
                    }
                  }}
                >
                  {`>`}
                </button>
              </div>
            </div>
          ) : (
            <video
              src={file}
              alt={fileType}
              autoPlay
              controls
              style={{
                height: "100%",
                width: "100%",
              }}
            />
          )}
        </div>
      </AautiDialog>
    </>
  );
};

export default AgendaCard;
