import React from 'react'
// import HighlightText from '../HighlightedText'
import ParsedText from './ParsedText'
import HighlightText from '../../../components/highlighted_text'

const textStyle = {
    fontSize:13,
    flexWrap:'wrap',
    wordBreak:'break-all',
    padding:2,
  }

const styles = {
  left: {
    container: {
      backgroundColor: '#ffffff',
      borderRadius:10,
      borderTopLeftRadius: 0,
      padding:4,
    },
    text: {
      color: '#333333',
      ...textStyle,
    }
  },
  right: {
    container: {
      backgroundColor:'#cce1fc',
      borderRadius: 8,
      borderTopRightRadius: 0,
       padding:4,
    },
    text: {
      color: '#1e1e1e',
      ...textStyle,
    }
  },
}


export function MessageText({
  currentMessage = {},
  position = 'left',
  textProps={},
  reply = false,
  searchText,
  highlighted = false
}) {
  const onUrlPress = (url) => {}
  const onPhonePress = (phone) => {}
  const onEmailPress = (email) =>{}
  return (
    <div style={styles[position].container}>
      <ParsedText
        style={styles[position].text}
        parse={[
          { type: 'url', onPress: onUrlPress },
          { type: 'phone', onPress: onPhonePress },
          { type: 'email', onPress: onEmailPress },
        ]}
        childrenProps={{ ...textProps }}
      >
         <HighlightText
          {...{
            id: currentMessage._id,
            searchWords: [searchText],
            textToHighlight: currentMessage.text,
            highlightStyle:{
              backgroundColor: highlighted ?"#d6b85a":"#3083EF",
              color:'white'
            }
          }}
        />
      </ParsedText>
    </div>
  )
}
