import React from "react";
import AautiText from "../../globalComponents/AautiText";
import { chatConstants, ChatRoomAccess } from "../constants";

import { Dropdown } from "primereact/dropdown";

const ChatAccessCard = ({ id = "chat_room_access_card", access, onChangeAccess = () => {} }) => {
  const options = [
    { label: chatConstants.everyOne, type: ChatRoomAccess.EVERYONE },
    { label: chatConstants.onlyMe, type: ChatRoomAccess.ONLY_ME },
  ];

  const onChange =(option)=>onChangeAccess(option.value)

  return (
    <div
    className="chat_room_info_access_card_wrapper"
  >
      <AautiText
              size={'semi'}
              title={chatConstants?.whoCanSendMessages}
              weight={'normal'}
              textStyle={{marginBottom:10,color:'#333333',verticalAlign:'middle',paddingTop:8}}
      />

      <Dropdown
        {...{
          value:access,
          options,
          optionLabel:'label',
          optionValue:'type',
          onChange
        }}
      />
   </div>
  );
};

export default ChatAccessCard;
