import { useEffect, useState } from "react";
// import "./ScrollToTop";
import AautiText from "./AautiText";
import JoinButton from "./JoinButton";
import { Skeleton } from "@mui/material";
import AautiDialog from "./AautiDialog";
import { useSelector } from "react-redux";
import { AuthService } from "../serviceProviderDetails/service";
import { showToast } from "./Toast";
import AautiTextInput from "./AautiTextInput";
import "./styles.scss";

const AautiReport = ({
  reportType,
  serviceProviderId,
  endUserId,
  gigId,
  courseId,
  sessionId,
  classId,
  setDialogName,
  screenName,
  open,
  setOpen,
  SPdataAPI,
  getGigDetails,
  chatRoomId,
}) => {
  const [reportValue, SetReportValue] = useState("");
  const [reportID, selectReportID] = useState([]);
  const [reportSkeleton, setReportSkeleton] = useState("");
  const { loggedinUserId, userDetails } = useSelector((state) => state.app);
  const [reportReason, setReportReason] = useState(null);

  useEffect(() => {
    handleReport(screenName);
    setReportSkeleton(true);
  }, []);

  const complaintAPI = () => {
    if (screenName === "Report Chat") {
      const chatReportData = {
        userId: loggedinUserId,
        description: reportValue,
        roomId: chatRoomId,
      };
      return AuthService.reportInChatRoom(
        chatReportData,
        onSuccessReportChat,
        onFailure
      );
    }
    const complaintDataList = {
      description: reportValue,
      userName: userDetails?.displayName
        ? userDetails?.displayName
        : userDetails?.firstName,
      userId: loggedinUserId,
      courseId:
        screenName === "Report Course" || screenName === "Report Issue"
          ? courseId
          : null,
      gigId: screenName === "Report Gig" ? gigId : null,
      sessionId:
        screenName === "Report Issue" || screenName === "Report Issue"
          ? sessionId
          : null,
      serviceProviderId:
        screenName === "Report Service Provider" ||
          screenName === "Report Issue"
          ? serviceProviderId
          : null,
      endUserId: screenName === "Report User" ? endUserId : null,
      status: "reported",
      type: reportID,
    };

    AuthService.complaintSave(
      complaintDataList,
      onSuccessComplaintPost,
      onFailure
    );
  };

  const onSuccessComplaintPost = (response) => {
    if (response?.data?.status === "Success") {
      showToast("success", response?.data?.message);
      if (screenName === "Report Service Provider") {
        setDialogName("");
      } else if (screenName === "Report Gig") {
        getGigDetails();
      }
    } else {
      showToast("info", response?.message);
    }
  };
  const onSuccessReportChat = (response) => {
    if (response?.data.status === "success") {
      showToast("success", response?.data?.message);
    } else {
      showToast("error", response?.message);
    }
  };
  const addValue = (newValue) => {
    if (reportID?.includes(newValue)) {
      const r = reportID?.filter((each, index) => {
        return each !== newValue;
      });
      selectReportID(r);
    } else {
      selectReportID([...reportID, newValue]);
    }
  };
  const handleClose = () => {
    if (screenName === "Report Service Provider") {
      setDialogName("");
      setOpen(!open);
      selectReportID([]);
      SetReportValue("");
    } else {
      setOpen(!open);
      selectReportID([]);
      SetReportValue("");
    }
  };
  const getReportTypesAPI = (type) => {
    AuthService.getReports(type, onSuccessReport, onFailure);
  };

  function handleReport(screenName) {
    switch (screenName) {
      case "Report User":
        return getReportTypesAPI("End User");
      case "Report Service Provider":
        return getReportTypesAPI("Service Provider");
      case "Report Course":
        return getReportTypesAPI("Course");
      case "Report Issue":
        return getReportTypesAPI("Class EU");
      case "Report Gig":
        return getReportTypesAPI("Gig");
      case "Report Chat":
        return getReportTypesAPI("Report Chat");
      default:
        return null;
    }
  }
  function handleReportTitle(screenName) {
    switch (screenName) {
      case "Report User":
        return "Report End User";
      case "Report Service Provider":
        return "Report Service Provider";
      case "Report Course":
        return "Report Course";
      case "Report Issue":
        return "Report Issue";
      case "Report Gig":
        return "Report Gig";
      case "Report Chat":
        return "Report Chat"
      default:
        // Handle unexpected values of screenName
        return null; // or throw an error, or handle the case differently
    }
  }
  const onSuccessReport = (response) => {
    if (response?.status === "Success") {
      setReportSkeleton(false);
      setReportReason(response?.result);
    } else {
      showToast("error", response?.message)("Something went wrong....");
      setReportSkeleton(false);
    }
  };
  //
  const onFailure = (error) => {
    setReportSkeleton(false);
  };

  const disableBtn = () => {
    return reportValue?.length > 14 && reportID?.length > 0 ? false : true;
  };
  return (
    <AautiDialog
      titlePosition="left"
      dialogTitle={handleReportTitle(screenName)}
      open={open}
      onClose={() => handleClose()}
    >
      {reportSkeleton ? (
        <>
          <Skeleton
            variant="rounded"
            sx={{ width: "80%", height: 30, margin: "20px 0px" }}
          />
          <div className="aautiReport-div">
            {Array.from({ length: 20 }).map((each) => {
              return (
                <Skeleton variant="rounded" sx={{ width: 90, height: 28 }} />
              );
            })}
            <Skeleton
              variant="rounded"
              sx={{ width: "100%", height: 40, margin: "20px 0px" }}
            />
          </div>
        </>
      ) : (
        <div className="ReportDialogDivRR">
          <div style={{ flexDirection: "row", display: "flex", }}>
            <AautiText
              weight={"bold"}
              title={"We won’t let the person know who is reported them"}
              size={"medium"}
              textStyle={{ color: "#1e1e1e" }}
            />
            <AautiText
              weight={"bold"}
              title={"*"}
              size={"medium"}
              textStyle={{ color: "red" }}
            />
          </div>
          <div className="SPnewreportDiv">
            {reportReason?.map((each, index) => (
              <div
                className="SPnewReportSelect"
                key={index}
                style={{
                  border: !reportID?.includes(each) && "1px solid #dddddd",
                  backgroundColor: reportID?.includes(each)
                    ? "#078dda"
                    : "#ffffff",
                }}
                onClick={() => {
                  addValue(each);
                }}
              >
                <AautiText
                  title={each.name}
                  size={"normal"}
                  textStyle={{
                    color: reportID?.includes(each) ? "#ffffff" : "#1e1e1e",
                  }}
                />
              </div>
            ))}
          </div>
          <AautiTextInput
            style={{ width: "100%", margin: "0px 0px 20px 0px" }}
            text={"Reason"}
            multiline
            rows={3}
            value={reportValue}
            important
            maxLength={300}
            helperText={`${reportValue?.length}/300 (minimum 15 characters)`}
            onChange={(e) => {
              SetReportValue(e.target.value);
            }}
          />
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
            }}
          >
            <JoinButton
              disabled={
                screenName === "Report Chat"
                  ? reportValue?.length < 15
                  : disableBtn()
              }
              onClick={() => {
                handleClose();
                complaintAPI();
              }}
              buttonStyle={{ width: 100, height: 40 }}
              title={"Submit"}
            />
          </div>
        </div>
      )}
    </AautiDialog>
  );
};

export default AautiReport;
