import React, { useState } from "react";
import "./styles.scss";
import { Tooltip } from "@mui/material";
import AautiText from "../../../globalComponents/AautiText";
import { conversationTypes } from "../../constants";
import AautiAvatar from "../AautiAvatar";
import { StyledBadge } from "../../chat_messages/chat_profile/container";
import HighlightText from "../highlighted_text";

export default function ChatContact({
  id,
  onPress,
  bottomText,
  alt,
  src,
  searchText,
}) {
  const [showAvatarView, setShowAvatarView] = useState(false);

  return (
    <li className="item_container" onClick={onPress}>
      <div className="item_content_container">
        <div className={"item_left_wrapper"}>
          <AautiAvatar {...{ height: 45, width: 45, alt, src }} />
        </div>
        <div className={"item_right_wrapper"}>
        <div className={"item_right_top_wrapper"}>
          <HighlightText
            className="item_text"
            textToHighlight={alt}
            searchWords={[searchText]}
            textStyle={{width:'240px'}}
          />
           </div>
          <div className={"item_right_top_wrapper"}>
            <AautiText
              id={`contact-card-bottom-text-${id}`}
              title={bottomText}
              size={"small"}
              weight={"normal"}
              textAlign={"left"}
              className="item_text"
              textStyle={{
                color: "#575b5c",
                width: "100%",
                fontSize: 12,
              }}
            />
          </div>
        </div>
      </div>
      <hr
        style={{
          border: "solid 0.1px whitesmoke",
          marginTop: 4,
          marginBottom: 4,
        }}
      />
    </li>
  );
}
