import React, { memo, useEffect, useLayoutEffect, useState } from "react";
import AautiText from "../../globalComponents/AautiText";
import AautiTextInput from "../../globalComponents/AautiTextInput";
import NavButton from "./NavButton";
import { courseConstant } from "./courseConstant";
import { AautiStrings } from "../../globalComponents/AautiStrings";
import { useSelector } from "react-redux";
import { getRefundConditions } from "../courseDetails/Services";
import { Backdrop, CircularProgress } from "@mui/material";
let width = window.innerWidth;
const RefundConditions = ({
  refundConditions,
  setRefundConditions = () => {},
  handleSaveRefundDetails,
  type = "course",
}) => {
  const { mobileDevices } = useSelector((state) => state.responsive);
  const [conditions, setConditions] = useState(refundConditions ?? []);
  const [refundAvailable, setRefundAvailable] = useState(
    refundConditions?.some((each) => each?.isSelected) ?? false
  );
  const [apiConditions, setApiConditions] = useState([]);
  const [loading, setLoading] = useState(false);

  //getting refund conditions metadata
  const callRefundConditionsAPI = (type, conditions = false) => {
    setLoading(true);
    const onSuccess = (res) => {
      let result = res?.result?.map((each) => ({
        ...each,
        isSelected: false,
      }));
      setApiConditions(result);
      if (conditions) {
        setConditions(result ?? []);
        setRefundAvailable(false);
      }
      setLoading(false);
    };
    const onFail = (err) => {
      setLoading(false);
    };
    getRefundConditions({ type }, onSuccess, onFail);
  };

  useLayoutEffect(() => {
    callRefundConditionsAPI(type, false);
  }, [type]);

  useEffect(() => {
    if (refundConditions?.length > 0) {
      setConditions(refundConditions ?? []);
      setRefundAvailable(refundConditions?.some((each) => each?.isSelected));
    } else {
      callRefundConditionsAPI(type, true);
    }
  }, [type, refundConditions]);

  const handlePercentageChange = (key, value) => {
    const numericValue = Number(value);
    if (!isNaN(numericValue)) {
      const updatedDiscounts = conditions?.map((discount) => {
        if (discount.key === key) {
          const updatedValue = Math.min(numericValue, discount.maximum);
          return {
            ...discount,
            percentage: updatedValue === 0 ? "" : updatedValue.toString(),
          };
        }
        return discount;
      });
      setConditions(updatedDiscounts);
      setRefundConditions(updatedDiscounts);
    }
  };

  const checkDisabled = () => {
    let disabled;

    if (refundAvailable) {
      disabled =
        conditions?.some((discount) => discount.isSelected) &&
        conditions
          .filter((discount) => discount.key !== -2)
          .every((discount) => discount.percentage != 0);
    } else {
      disabled = true;
    }
    return disabled;
  };

  const handleUpdateSelected = (bool, key) => {
    const updatedKeyList = conditions?.map((each) => {
      if (each.key == key) {
        return { ...each, isSelected: !each?.isSelected };
      } else {
        return { ...each };
      }
    });
    setConditions(updatedKeyList);
    setRefundConditions(updatedKeyList);
  };

  const onChangeRefundAvailability = () => {
    setRefundAvailable(!refundAvailable);
    setConditions(apiConditions);
    setRefundConditions(apiConditions);
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
        }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {/* radio buttons */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 25,
          width: mobileDevices ? "60%" : "30%",
        }}
      >
        <AautiText title={"Refunds"} style={{ alignSelf: "flex-start" }} />
        <div style={{ display: "flex", alignItems: "center" }}>
          <input
            className="SPmarginRight5"
            type="radio"
            value={"Yes"}
            checked={refundAvailable}
            onChange={onChangeRefundAvailability}
          />
          <AautiText
            weight={"medium"}
            size={"semi"}
            title={"Yes"}
            textStyle={{ marginLeft: 10 }}
          />
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          <input
            className="SPmarginRight5"
            type="radio"
            value={"No"}
            checked={!refundAvailable}
            onChange={onChangeRefundAvailability}
            style={{ marginLeft: 10, marginRight: 10 }}
          />
          <AautiText weight={"medium"} size={"semi"} title={"No"} />
        </div>
      </div>
      {type === "course" && (
        <AautiText title={courseConstant?.afterThePurchaseOfCourse} />
      )}
      {conditions?.map((each, index) => (
        <div
          key={index}
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
            alignItems: "center",
            width: "100%",
            marginBottom: 10,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 15,
              width: each?.key == -2 ? "100%" : "80%",
              alignItems: "flex-start",
            }}
          >
            <input
              id={`checkbox-${each?.key}`}
              type="checkbox"
              checked={each?.isSelected}
              onChange={(e) => {
                handleUpdateSelected(e.target.checked, each?.key);
              }}
              style={{ height: "20px", width: "20px", cursor: "pointer" }}
              disabled={!refundAvailable}
            />
            <label htmlFor={`checkbox-${each?.key}`}>
              <AautiText title={each?.name} textStyle={{ color: "#000" }} />
            </label>
          </div>
          <div
            style={{ width: width * 0.12, display: each?.key == -2 && "none" }}
          >
            <AautiTextInput
              labelStyle={{ zIndex: 201 }}
              disable={!each?.isSelected}
              onChange={(e) => {
                handlePercentageChange(each?.key, e.target.value);
              }}
              style={{ width: "100%" }}
              text={"%"}
              value={each?.percentage}
            />
          </div>
        </div>
      ))}

      <NavButton
        disabled={!checkDisabled()}
        text={AautiStrings?.SaveString}
        handleClick={() => {
          setLoading(true);
          handleSaveRefundDetails(conditions);
          setTimeout(() => {
            setLoading(false);
          }, 2000);
        }}
      />
    </div>
  );
};

export default memo(RefundConditions);
