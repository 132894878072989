import React, { useState } from "react";
import { MdOutlineDesktopWindows } from "react-icons/md";
import AautiText from "../globalComponents/AautiText";
import moment from "moment";
import { RiGroupLine } from "react-icons/ri";
import { BsPerson } from "react-icons/bs";
import { ImLocation } from "react-icons/im";
import { IconButton, Tooltip } from "@mui/material";
import ClockIcon from "../../assets/images/Clock-Icon.png";
import DialyIcon from "../../assets/images/Daily-icon.png";
import CatIcons from "../../assets/images/categories.png";
import FlagIcon from "../../assets/images/flag.png";
import ScheduleIcon from "../../assets/images/schedule.png";
import "./Style.scss";
import { useDispatch } from "react-redux";
import { setAdhocEditObject } from "../../redux/reducer/appReducer";
import ConfirmationDialogRaw from "../globalComponents/ConfirmationDialogRaw";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { returnIcon } from "../CommonFunctions";
import { showToast } from "../globalComponents/Toast";
import WeekView from "../mySchedule/WeekView";

export const Icon = ({ height = "18px", width = "18px", icon }) => {
  return (
    <IconButton
      sx={{
        height: height,
        width: width,
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        p: 0,
      }}
    >
      <img
        src={icon}
        alt="share"
        style={{
          height: "100%",
          width: "100%",
          zIndex: 100,
        }}
      />
    </IconButton>
  );
};

const AdhocEventsCard = ({
  navigate,
  Capitalize,
  returnsubcategories,
  each,
  returnWeekTimeSlots,
  handleDelete,
  from,
  handleGetSessions,
  showActiveDisc,
  setShowActiveDisc,
  pathname,
}) => {
  const dispatch = useDispatch();
  const dailyStyle = {
    display: "flex",
    flexDirection: "row",
    gap: 8,
    alignItems: "center",
    justifyContent: "space-between",
  };
  const rowStyle = {
    display: "flex",
    flexDirection: "row",
    gap: 12,
    alignItems: "center",
    marginBottom: 8,
  };

  const [open, setOpen] = useState(false);
  const activeDiscountBatch = showActiveDisc == each?._id;
  const [moreText, setMoreText] = useState(false);

  return (
    <div
      className="events-card"
      onClick={() => {
        handleGetSessions(each);
      }}
      style={{
        cursor: "pointer",
      }}
    >
      <div className="card-head-dots">
        <Tooltip title={Capitalize(each?.title)}>
          <div>
            <AautiText
              title={Capitalize(each?.title) || "Title"}
              weight={"bold"}
              textStyle={{
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 1,
                overflow: "hidden",
                overflowWrap: "anywhere",
                width: "90%",
              }}
            />
          </div>
        </Tooltip>
        {returnIcon(each)}
      </div>
      <div className="events-card-data">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 8,
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: 8,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 12,
              alignItems: "center",
            }}
          >
            <Icon icon={DialyIcon} />
            <AautiText
              title={each?.recurrenceType === "daily" ? "Daily" : "Weekly"}
              textStyle={{ color: "#3083EF" }}
            />
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            {each?.courseType.includes("Individual") && (
              <BsPerson size={18} style={{ marginRight: 5 }} />
            )}
            {each?.courseType.includes("Group") && <RiGroupLine size={15} />}
          </div>
        </div>

        <div style={rowStyle}>
          <Icon icon={ScheduleIcon} />
          <AautiText
            textStyle={{ color: "#5C5B57" }}
            title={
              moment(each?.startDate).format("DD MMM YYYY") +
              " - " +
              moment(each?.endDate).format("DD MMM YYYY")
            }
          />
        </div>
        {each?.recurrenceType == "daily" ? (
          <div
            style={{
              gap: 12,
              marginBottom: 8,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Icon icon={ClockIcon} />
            <div className="textBox3">
              <AautiText
                textStyle={{
                  color: "#5C5B57",
                  // maxWidth: "350px",
                  // textWrap: "nowrap",
                  // overflow: "scroll",
                  // maxWidth: "400px",
                }}
                title={`${each?.dailyTimeSlots?.map((Item, index) => {
                  return (
                    " " +
                    moment(Item?.startTime).format("LT") +
                    " - " +
                    moment(Item?.endTime).format("LT")
                  );
                })}`}
                className={"span"}
              />
            </div>
          </div>
        ) : (
          each?.title && <WeekView data={each} />
        )}
        <div className="category-section">
          <Icon icon={CatIcons} />
          <div className="textBox">
            <AautiText
              title={returnsubcategories(each)}
              // textStyle={{
              //   overflow: "scroll",
              //   width: "97%",
              //   color: "#5C5B57",
              //   textWrap: "nowrap",
              // }}
              className={"span"}
            />
            {/* <span>{returnsubcategories(each)}</span> */}
          </div>
        </div>
        <div style={rowStyle}>
          <Icon icon={FlagIcon} />
          <AautiText
            title={each?.countries?.join(",")}
            textStyle={{
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 1,
              overflow: "hidden",
              width: "82%",
              color: "#5C5B57",
            }}
          />
        </div>
        {each?.languages?.length > 0 && (
          <div style={rowStyle}>
            <Icon icon={CatIcons} />
            <div className="textBox2">
              <AautiText
                title={each?.languages?.join(", ")}
                // textStyle={{
                //   // display: "-webkit-box",
                //   // WebkitBoxOrient: "vertical",
                //   // WebkitLineClamp: 1,
                //   // overflow: "hidden",
                //   // width: "82%",
                //   color: "#5C5B57",
                // }}
                className={"span"}
              />
            </div>
          </div>
        )}
        <div
          style={{ display: each?.allowMembers ? "flex" : "none" }}
          className="family-discount"
        >
          <button
            onClick={(e) => {
              e.stopPropagation();
              if (showActiveDisc != "") {
                setShowActiveDisc("");
              } else {
                setShowActiveDisc(each?._id);
              }
            }}
            className="family-btn"
          >
            <AautiText
              size={"semi"}
              title={"View discount for family members"}
            />
            {activeDiscountBatch ? (
              <IoIosArrowDown size={16} />
            ) : (
              <IoIosArrowForward size={16} />
            )}
          </button>
          <div className={`${activeDiscountBatch ? "expand" : "hide-expand"} `}>
            <AautiText
              title={
                "If one person subscribes, the total price will be applied."
              }
              textAlign={"left"}
              size={"small"}
            />

            {each?.membersDiscount?.map((each, index) => {
              return (
                <AautiText
                  key={index}
                  title={`For ${each?.noOfMembers || 0} ${
                    each?.noOfMembers == 1 ? "member" : "members"
                  } ${each?.percentage || 0}% discount will be applied.`}
                  textAlign={"left"}
                  size={"small"}
                />
              );
            })}
          </div>
        </div>
        <div
          style={{
            display: !from ? "flex" : "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
            width: "100%",
            marginTop: "auto",
          }}
        >
          <button
            onClick={(e) => {
              e.stopPropagation();
              if (each?.isPurchased) {
                showToast("info", "Users purchased can't be Deleted");
              } else {
                setOpen(true);
              }
            }}
            style={{
              background: "none",
              border: "none",
              padding: "8px 10px",
              cursor: "pointer",
            }}
          >
            <AautiText
              title={"Delete"}
              weight={"bold"}
              textStyle={{ color: "#3083EF" }}
            />
          </button>
          <button
            onClick={(e) => {
              e.stopPropagation();
              if (each?.isPurchased) {
                showToast("info", "Users purchased can't be edited");
              } else {
                dispatch(setAdhocEditObject(each));
                navigate("/AdhocEvent?edit=true", {
                  state: { screenName: pathname },
                });
              }
            }}
            style={{
              background: "none",
              border: "none",
              padding: "8px 10px",
              cursor: "pointer",
            }}
          >
            <AautiText
              title={"Edit"}
              weight={"bold"}
              textStyle={{ color: false ? "lightgrey" : "#3083EF" }}
            />
          </button>
        </div>
      </div>
      <ConfirmationDialogRaw
        id="ringtone-menu"
        keepMounted
        open={open}
        onClick={(e) => {
          e.stopPropagation();
          handleDelete(each?._id);
          setOpen(false);
        }}
        onClose={() => {
          setOpen(false);
        }}
      />
    </div>
  );
};

export default AdhocEventsCard;
