export const courseConstant = {
  levels: "Levels",
  category: "Category",
  courseType: "Course Type",
  coursePricing: "Course Pricing",
  location: "Location",
  age: "Age",
  searchTags: "Search Tags",
  selectALocation: "Select a location",
  addALocation: "Add a location",
  pickARadiusFromTheDesiredLocation: "Pick a radius from the desired location",
  editLocationAndFixRadius: "Edit Location & fix Radius",
  courseOverview: "Overview",
  courseTitle: "Title",
  allowsYouToTeachForVariousLevelsOfPeople:
    "Allows you to teach for various levels of People",
  categoryAndSubcategory: "Category and Subcategory",
  categoryAndSubCategoryRequired: "Category & subcategory required",
  courseTypeRequired: "Course Type required",
  selectGroupLanguage: "Select Group language",
  selectLanguages: "Select Languages",
  classType: "Class Type",
  PickAgeLimitsThatYouWouldLikeToTeach:
    "Pick Age limits that you would like to teach",
  courseTags: "Tags",
  addUptTo10KeywordsThatItWillHelpPeopleToDiscoverTheCourse:
    "add up to 10 keywords that it will help people to discover the course",
  upTo10Tags: "Up to 10 tags - (minimum 3 characters)",
  shortDescription: "Short description",
  courseSummary: "Course Summary",
  enterNoOfSessions: "No of Sessions",
  addAtHowManySessionsYourCourseIsGoingToEnd:
    "add at how many sessions your course is going to end",
  selectedDays: "Selected Days",
  selectedDates: "Selected Dates",
  step3Pricing: "Step:3 Pricing",
  selectBatchType: "Select Batch Type",
  conflicts: "Conflicts",
  allowAdditionalFamilyMembersToJoin: "Allow additional family members to join",
  discountForEachAdditionalMemeberBeyondTheFirst:
    "Discount for each additional memeber beyond the first",
  discountType: "Discount Type",
  selectDiscountValidDates: "Select Discount Dates",
  addToExceptionsList: "add to exceptions list",
  followandCreateFromTheBottomSectionswhereItEnablesTheCoursePreviewForTheStudents:
    "Follow & Create from the bottom sections where it enables the course preview for the Students...",
  previewCourse: "Preview Course",
  discard: "Discard",
  onceSubmittedItWillBeUnderReview: "Once Submitted it will be under review",
  afterThePurchaseOfCourse: "After the purchase of course",
  allTheOngoingAndFutureSessionsWillBeCancelledDoYouWantToProceedString:
    "All the Ongoing and Future sessions will be cancelled. Do you want to proceed?",
  deactivationOrDeletion: "Deactivate or Delete",
  createYourOwnLessonPlan: "Create Your Own Lesson Plan",
};
