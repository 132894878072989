import React, {
  createRef,
  memo,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  ChatRoomAccess,
  MIN_COMPOSER_HEIGHT,
  MAX_COMPOSER_HEIGHT,
  SocketEvents,
  conversationTypes,
  messageTypes,
  chatConstants,
} from "../constants";
import { showToast } from "../../globalComponents/Toast";
import InputToolBar from "../chat_messages/custom_messages/input_toolbar";
import MessagesContainer from "../chat_messages/custom_messages/messages_container";
import AautiText from "../../globalComponents/AautiText";
import { getMessageTypeByMime, idGenerator } from "../utils";
import { useDispatch, useSelector } from "react-redux";
import {
  setSocketHandler,
  setActiveRoom,
  chatRoomSelector,
  updateContacts,
  updateActiveChatRoomMembers,
  leaveRoom,
  updateBlockedUsers,
  updateAdminFreezeStatus,
  updateAdminsInRoom,
} from "../../../redux/reducer/chatSlice";
import AautiAvatar from "../components/AautiAvatar";
import ChatMenu from "../components/ChatMenu";
import { FaArrowLeft } from "react-icons/fa6";
// import { RxCrossCircled } from "react-icons/rx";
import AautiReport from "../../globalComponents/AautiReport";
// import SearchBar from "../components/searchBar";
import ChatRoomInfo from "../chat_room_info";
import AddUserDialog from "../components/chat_dialogs/AddUserDialog";
import EditChatGroupDialog from "../components/chat_dialogs/EditChatGroupDialog";
import { BootstrapDialog } from "../chat_user_list/container";
import DialogCloseButton from "../components/chat_dialogs/DialogCloseButton";
import { DialogContent } from "@mui/material";
import { Search } from "@mui/icons-material";
import { copyTextToClipboard } from "../../../utils/Helpers";
import EmptyMessages from "./EmptyMessages";
import { UploadBaseUrl } from "../../../service/api-constants";
import { apiServices } from "../../../service/apiService";
import moment from "moment";

function ChatRoom({
  showBackIcon,
  showArrowDownIcon,
  onCloseChat,
  screen,
  extraEventHandler,
  roomId,
}) {
  const id = `chat-room-${roomId}`;
  const { sender, activeRoom, isSocketOpen } = useSelector(chatRoomSelector);
  const {
    miniLapys,
    ipodDevices,
    aboveLargeMobileDevices,
    mobileDevices,
    desktop,
    lapy,
  } = useSelector((state) => state.responsive);

  const dispatch = useDispatch();
  const [state, setState] = useState({
    text: "",
    // searchInput: undefined,
    showSearchBar: false,
    messages: [],
    pageNo: 0,
    files: [],
    reply: null,
    isSending: false,
    deleteMsgIds: [],
    editingMsg: undefined,
    showAudioRecorder: false,
    composerHeight: MIN_COMPOSER_HEIGHT,
    showRoomInfo: false,
  });

  const [showEditDialog, setShowEditDialog] = useState(false);
  const [showAddUserDialog, setShowAddUserDialog] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);

  const {
    text,
    // searchInput,
    showSearchBar,
    messages,
    pageNo,
    files,
    reply,
    isSending,
    deleteMsgIds,
    editingMsg,
    composerHeight,
    showRoomInfo,
  } = state;

  const textInputRef = useRef();
  const messageContainerRef = useRef();
  const isOneToOne = activeRoom?.type === conversationTypes.ONE_TO_ONE;
  const hasExtraHandler = typeof extraEventHandler === "function";

  useLayoutEffect(() => {
    let timerId = setTimeout(() => {
      dispatch(setSocketHandler(handleSocketEvents));
      clearTimeout(timerId);
    }, 200);
  }, []);

  const getMessages = (roomId, pageNo = state.pageNo) => {
    dispatch({
      type: SocketEvents.room.getMessagesByRoom,
      roomId,
      pageNo,
    });
  };

  useEffect(() => {
    if (isSocketOpen) {
      getMessages(roomId, pageNo);
    }
  }, [pageNo, isSocketOpen, roomId]);

  function handleSocketEvents({ event, ...rest }) {
    if (rest?.batchId || rest?.sessionId) {
      return extraEventHandler?.({ event, ...rest });
    }
    if (
      [SocketEvents.getContacts, SocketEvents.getCourseSubscribers].includes(
        event
      )
    ) {
      return dispatch(updateContacts(rest?.data?.contacts ?? []));
    }
    if (
      event === SocketEvents.room.created &&
      rest.data?.user?._id === activeRoom.user?._id
    ) {
      if (hasExtraHandler) {
        return extraEventHandler?.({ event, ...rest });
      }
      return dispatch(setActiveRoom({ data: rest.data }));
    }
    const isInRoom = roomId === rest.data?._id || roomId === rest.data?.roomId;
    if (isInRoom) {
      return handleBasedOnEvent({ event, ...rest });
    }
    extraEventHandler?.({ event, ...rest });
  }

  function handleBasedOnEvent({ event, ...rest }) {
    if (rest.data?.text) {
      showToast("info", rest.data.text);
    }
    if (rest.data?.message && event !== SocketEvents.message.edit) {
      setState((prev) => ({
        ...prev,
        messages: [rest.data.message, ...prev.messages],
      }));
      if (hasExtraHandler) extraEventHandler({ event, ...rest });
    }
    if (rest.data?.message && event === SocketEvents.message.edit) {
      setState((prev) => ({
        ...prev,
        messages: prev.messages.map((message) => {
          if (message._id === rest.data.message._id) {
            message = rest.data.message;
          }
          return message;
        }),
      }));
    }
    switch (event) {
      case SocketEvents.room.clearConversation:
        extraEventHandler?.({ event, _id: rest.data._id });
        break;
      case SocketEvents.room.addUsers:
        onAddingUsersToRoom(rest.data?.message?.sender);
        break;
      case SocketEvents.room.getMembers:
        dispatch(updateActiveChatRoomMembers(rest.data?.members ?? []));
        break;
      case SocketEvents.room.getMessagesByRoom:
        {
          const messagesLength = rest?.data?.messages?.length;
          if (!messagesLength) return;
          setState((prev) => ({
            ...prev,
            messages:
              prev.pageNo > 0
                ? [...prev.messages, ...rest.data.messages]
                : rest.data.messages,
          }));
          if (activeRoom?.unreadCount) {
            extraEventHandler({
              event: SocketEvents.updateUnreadCount,
              _id: rest.data?._id,
            });
          }
        }
        break;

      case SocketEvents.message.delete:
        setState((prev) => ({
          ...prev,
          messages: prev.messages
            .filter((msg) => msg._id !== rest.data.messageId)
            .map((msg) => {
              if (
                msg.parentId === rest.data.messageId &&
                msg.reply &&
                msg.reply.type !== messageTypes.TEXT
              ) {
                msg.reply.deleted = true;
              }
              return msg;
            }),
        }));
        break;

      case SocketEvents.room.updateInfo:
        onUpdatedRoomInfo(rest.data);
        break;

      case SocketEvents.room.blockUser:
      case SocketEvents.room.unblockUser:
        dispatch(
          updateBlockedUsers({
            blockedBy: rest.data?.blockedBy,
            blockedUsers: rest?.data?.blockedUsers,
          })
        );
        break;

      case SocketEvents.room.makeAdmin:
      case SocketEvents.room.removeAdminAccess:
        dispatch(updateAdminsInRoom(rest.data.admins));
        break;

      case SocketEvents.room.freeze:
      case SocketEvents.room.unFreeze:
        dispatch(updateAdminFreezeStatus(rest.data.adminFreezed));
        extraEventHandler?.({ event, ...rest });
        break;

      case SocketEvents.room.deleteConversation:
        extraEventHandler?.({ event, ...rest });
        onCloseChat?.();
        break;

      case SocketEvents.room.leave:
      case SocketEvents.room.removeUser:
        onUserLeavesRoom(rest.data.userId);
        break;

      default:
        break;
    }

    if (event !== SocketEvents.room.deleteConversation) {
      dispatch({
        type: SocketEvents.room.updateLastSeen,
        payload: { roomId },
      });
    }
  }

  const onAddingUsersToRoom = (senderId) => {
    if (senderId === sender._id) toggleEditProfileDialog();
    getActiveRoomMembers();
  };

  const onUpdatedRoomInfo = ({ message, ...roomProps }) => {
    dispatch(setActiveRoom({ data: roomProps }));
    if (message.sender === sender._id) {
      toggleEditProfileDialog();
    }
  };

  const getActiveRoomMembers = () => {
    dispatch({ type: SocketEvents.room.getMembers, payload: { roomId } });
  };

  const onUserLeavesRoom = (userId) => {
    if (userId === sender._id)
      dispatch(leaveRoom({ roomId, userId: sender._id }));
    getActiveRoomMembers();
  };

  const updateSendingStatus = () => {
    setState((prev) => ({ ...prev, isSending: !prev.isSending }));
  };

  const uploadFiles = async () => {
    const data = new FormData();
    files?.forEach((each) => data.append("files", each));
    data.append("platform", UploadBaseUrl);
    try {
      const res = await apiServices.uploadMultipleFiles(data);
      return res.result;
    } catch (err) {
      showToast("info", "Something went wrong. Please try again.");
    }
  };

  const updateAndSendMessage = (newMessage, abortStateUpdate = false) => {
    if (!abortStateUpdate) {
      setState((prev) => {
        let messages = [...prev.messages];
        if (prev.editingMsg?._id) {
          messages = prev.messages.map((message, i) => {
            if (message._id === newMessage._id) {
              message.text = newMessage.text;
              message.files = newMessage?.files ? newMessage?.files : undefined;
            }
            return message;
          });
        } else {
          messages = [{ ...newMessage }, ...prev.messages];
        }
        return { ...prev, messages };
      });
    }
    let type = SocketEvents.message.send;
    let payload = {
      roomId,
      roomType: activeRoom.type,
    };
    if (newMessage.edited) {
      type = SocketEvents.message.edit;
      payload = { ...payload, message: newMessage };
    } else {
      payload = { ...payload, ...newMessage, sender: newMessage.sender._id };
    }
    dispatch({ type, payload });
    let data = { message: newMessage, _id: roomId };
    if (isOneToOne) data = { ...data, user: activeRoom.user };
    if (
      (!abortStateUpdate && !editingMsg?._id) ||
      editingMsg?._id === messages?.[0]?._id
    ) {
      extraEventHandler?.({ event: type, data });
    }
  };

  const generateAndSendMessages = async () => {
    const abortStateUpdate = isOneToOne && roomId === activeRoom?.user?._id;
    if (!!files?.length && !editingMsg?._id) {
      let uploadedFiles = await uploadFiles();
      let imagesCount = 0;
      uploadedFiles = uploadedFiles.map((file, i) => {
        if (files[i].type.includes("image")) imagesCount += 1;
        return {
          name: files[i].name,
          type: files[i].type,
          size: files[i].size,
          uri: file,
        };
      });
      const areAllImages = files.length === imagesCount;
      if (areAllImages) uploadedFiles = [uploadedFiles];

      uploadedFiles.forEach((file) => {
        const newMessage = {
          _id: idGenerator(),
          files: areAllImages ? file : [file],
          type: areAllImages
            ? messageTypes.IMAGE
            : getMessageTypeByMime(file.type),
          createdAt: new Date(),
          sender,
          receiver: isOneToOne ? activeRoom?.user?._id : roomId,
        };
        updateAndSendMessage(newMessage, abortStateUpdate);
      });
    }
    if (!!text?.trim()) {
      let message = editingMsg?._id
        ? { ...editingMsg, text, edited: true }
        : {
            text: text.trim(),
            type: messageTypes.TEXT,
            sender,
            receiver: isOneToOne ? activeRoom?.user?._id : roomId,
            _id: idGenerator(),
          };
      if (reply?._id) {
        message.type = messageTypes.REPLY;
        message.reply = reply;
        message.parentId = reply?._id;
      }
      if (!editingMsg?._id) message.createdAt = new Date();
      updateAndSendMessage(message, abortStateUpdate);
    }
  };

  const onSend = async () => {
    if (isSending) return;
    updateSendingStatus(true);
    await generateAndSendMessages();
    scrollToBottom();
    setState((prev) => ({
      ...prev,
      isSending: false,
      files: [],
      editingMsg: undefined,
      reply: null,
      text: "",
    }));
  };

  const scrollToBottom = () =>
    messageContainerRef.current?.scrollTo({
      top: -Infinity,
      behavior: "smooth",
    });

  const onInputSizeChanged = (size) => {
    const newComposerHeight = Math.max(
      MIN_COMPOSER_HEIGHT,
      Math.min(MAX_COMPOSER_HEIGHT, size.height)
    );

    setState({
      ...state,
      composerHeight: newComposerHeight,
    });
  };

  const onInputTextChanged = (text, emoji = "") => {
    if (state.isSending) return;
    setState((prev) => ({ ...prev, text: `${text}${emoji}` }));
  };

  function toggleSearchBarVisibility() {
    setState((prev) => ({
      ...prev,
      showSearchBar: !prev.showSearchBar,
    }));
  }

  const clearConversation = () => {
    const type = SocketEvents.room.clearConversation;
    dispatch({ type, payload: { roomId, clearChatDate: new Date() } });
    setState((prev) => ({ ...prev, messages: [] }));
    extraEventHandler?.({ event: type, _id: roomId });
  };

  const getBasicOptionDispatchType = (title) => {
    if (title === chatConstants.exitGroup) return SocketEvents.room.leave;
    if (title === chatConstants.freezeGroup) return SocketEvents.room.freeze;
    if (title === chatConstants.unfreezeGroup)
      return SocketEvents.room.unFreeze;
    if (title === chatConstants.deleteFromChat) {
      dispatch(setActiveRoom({ data: undefined }));
      return SocketEvents.room.deleteConversation;
    }
  };

  const blockAndUnblockUser = (title) => {
    const isBlockTitle = title === chatConstants.blockUser;
    const type = isBlockTitle
      ? SocketEvents.room.blockUser
      : SocketEvents.room.unblockUser;
    dispatch({
      type,
      payload: {
        roomId,
        userId: activeRoom.user._id,
      },
    });
  };

  const { disabledText, menuOptions } = useMemo(() => {
    let menuOptions = [];
    let disabledText = "";

    if (activeRoom?.user?.deactivateStatus)
      disabledText = chatConstants.userNotActive;
    if (!activeRoom || activeRoom?.deleted || !activeRoom?._id)
      return { menuOptions, disabledText };

    const {
      _id,
      isCancelled = false,
      type,
      user,
      blockedUsers,
      admins,
      freezed = false,
      adminFreezed = false,
      access,
      batch,
      session,
    } = activeRoom;

    if (user?.exitDate || user?.deleted) {
      disabledText = user?.deleted
        ? chatConstants.userNotInApp
        : chatConstants.userLeftRoom;

      return {
        disabledText,
        menuOptions: [chatConstants.deleteFromChat],
      };
    }
    menuOptions = [chatConstants.clearConversation];
    if (isCancelled) {
      return {
        disabledText: chatConstants.courseCancelFreeze,
        menuOptions,
      };
    }
    switch (type) {
      case conversationTypes.ONE_TO_ONE: {
        if (_id === user?._id) return { disabledText, menuOptions: [] };
        const isMyChat = user?._id === sender._id;
        const isBlockedByMe = user?.blockedBy === sender._id;
        const isBlocked = isBlockedByMe || blockedUsers?.includes(sender._id);

        if (!isMyChat) {
          if (!!_id) {
            menuOptions.push(
              isBlockedByMe
                ? chatConstants.unblockUser
                : chatConstants.blockUser
            );
          }
          menuOptions.push(chatConstants.reportUser);
        }
        if (isBlocked) {
          disabledText = `${
            isBlockedByMe ? "You" : user.displayName
          }  blocked ${isBlockedByMe ? user.displayName : "you"}`;
        }

        return { disabledText, menuOptions };
      }

      case conversationTypes.COURSE_ADHOC:
      case conversationTypes.GROUP:
      case conversationTypes.ADHOC:
      case conversationTypes.COURSE: {
        const isAdmin = admins?.includes(sender._id);
        const hasMoreAdmins =
          admins?.filter((id) => id !== sender._id)?.length > 0;
        if (freezed && !adminFreezed && type !== conversationTypes.GROUP) {
          let isAdhoc = !!session?._id;
          let { startDateTime, endDateTime } = batch ?? session;
          const today = moment().utc();
          startDateTime = moment(startDateTime).utc();
          endDateTime = moment(endDateTime).utc();
          const before15min = startDateTime.clone().subtract('15', 'min')
          if (
            today.isBetween(startDateTime, endDateTime) ||
            today.isBetween(
              before15min,
              startDateTime
            )
          ) {
            disabledText = "";
          }
          if(today.isBefore(before15min)) {
            disabledText = chatConstants.beforeClassStart;
          }
          if (today.diff(endDateTime, "m") >= 0) {
            disabledText = `The Room froze because the ${
              isAdhoc ? "adhoc" : "course"
            } was completed`;
          }
          menuOptions = [];
        }
        if (adminFreezed && !freezed) {
          disabledText = chatConstants.groupFreezeText;
        }
        if (isAdmin && !freezed) {
          if (adminFreezed) {
            menuOptions = [chatConstants.unfreezeGroup];
            return { disabledText, menuOptions };
          }
          menuOptions = [...menuOptions, chatConstants.freezeGroup];
          if (hasMoreAdmins && type === conversationTypes.GROUP) {
            menuOptions.push(chatConstants.exitGroup);
          }
          return { disabledText, menuOptions };
        }
        if (access && access === ChatRoomAccess.ONLY_ME && !isAdmin) {
          disabledText = chatConstants.onlyAdminSendMsg;
        }
        if (blockedUsers?.includes(user?._id) && !adminFreezed) {
          disabledText = chatConstants.adminBlockedYou;
        }
        menuOptions = [
          ...menuOptions,
          chatConstants.exitGroup,
          chatConstants.reportGroup,
        ];
        return { disabledText, menuOptions };
      }

      default:
        return { disabledText, menuOptions };
    }
  }, [
    activeRoom?._id,
    activeRoom?.user?.deleted,
    activeRoom?.user?.blockedBy,
    activeRoom?.user?.exitDate,
    activeRoom?.admins,
    activeRoom?.blockedUsers,
    activeRoom?.adminFreezed,
    activeRoom?.freezed,
    activeRoom?.access,
    activeRoom?.deleted,
    activeRoom?.isCancelled,
    activeRoom?.user?.deactivateStatus,
  ]);

  const onClickEdit = (msg) => {
    if (!!disabledText) return;
    setState((prev) => {
      const result = { ...prev };
      result.reply = undefined;
      result.editingMsg = msg;
      result.text = !!msg?.text?.length ? msg.text : "";
      result.files = msg?.files ?? [];
      return result;
    });
    textInputRef.current?.focus();
  };

  const onClickReply = (msg) => {
    if (!!disabledText) return;
    setState((prev) => ({
      ...prev,
      reply: msg,
      editingMsg: undefined,
      files: [],
      text: "",
    }));
    let timer = setTimeout(() => {
      textInputRef.current?.focus();
      clearTimeout(timer);
    }, 200);
  };

  const onPressMenuItem = (title, msg) => {
    switch (title) {
      case chatConstants.search:
        toggleSearchBarVisibility();
        break;

      case chatConstants.clearConversation:
        clearConversation();
        break;
      case chatConstants.exitGroup:
      case chatConstants.freezeGroup:
      case chatConstants.unfreezeGroup:
      case chatConstants.deleteFromChat:
        dispatch({
          type: getBasicOptionDispatchType(title),
          payload: { roomId, userId: activeRoom.user._id },
        });
        break;

      case chatConstants.blockUser:
      case chatConstants.unblockUser:
        blockAndUnblockUser(title);
        break;

      case chatConstants.reportUser:
      case chatConstants.reportGroup:
        setState((prev) => ({
          ...prev,
          showReportModal: !prev.showReportModal,
        }));
        break;
      case chatConstants.reply:
        onClickReply(msg);
        break;
      case chatConstants.copy:
        copyTextToClipboard(msg.text).then(() =>
          showToast("info", "text copied to clipboard")
        );
        break;
      case chatConstants.edit:
        onClickEdit(msg);
        break;

      case chatConstants.delete:
        setState((prev) => ({
          ...prev,
          messages: prev.messages
            .filter((message) => message._id !== msg._id)
            .map((message) => {
              if (
                message.parentId === msg._id &&
                message.type !== messageTypes.TEXT
              )
                message.reply.deleted = true;
              return message;
            }),
        }));
        dispatch({
          type: SocketEvents.message.delete,
          payload: { messageId: msg._id },
        });
        break;
      default:
        break;
    }
  };

  const updateFiles = (files) => {
    function removeDuplicateImages(filesList) {
      let uniqueSet = new Set();
      const uniqueFiles = filesList.reduce((files, file) => {
        if (uniqueSet.has(file.name)) return files;
        files.push(file);
        uniqueSet.add(file.name);
        return files;
      }, []);
      return uniqueFiles;
    }
    setState((prev) => ({
      ...prev,
      files: removeDuplicateImages([...prev.files, ...files]),
    }));
  };

  const deleteFile = (fileIndex) => {
    setState((prev) => ({
      ...prev,
      files: prev.files.filter((_, index) => index !== fileIndex),
    }));
  };

  const onDismissReply = () => {
    setState((prev) => ({ ...prev, reply: null }));
  };

  const toggleAddUserDialog = (status = false) => setShowAddUserDialog(status);
  const toggleEditProfileDialog = (status = false) => setShowEditDialog(status);
  const toggleReportDialog = (status) => setShowReportModal(status);

  const updatePageNo = () => {
    setState((prev) => ({
      ...prev,
      pageNo: prev.pageNo + 1,
    }));
  };

  const updateSelectedMsgs = (deleteMsgId, isSelected) => {
    if (isSelected) {
      setState((prev) => ({
        ...prev,
        deleteMsgIds: prev.deleteMsgIds.filter(
          (msgId) => msgId !== deleteMsgId
        ),
      }));
    } else {
      setState((prev) => ({
        ...prev,
        deleteMsgIds: [...prev.deleteMsgIds, deleteMsgId],
      }));
    }
  };

  const deleteSelectedMessages = () => {
    let { messages, deleteMsgIds } = state;
    dispatch({
      type: SocketEvents.message.messageDeleteForMe,
      payload: {
        roomId,
        messageIds: deleteMsgIds,
      },
    });
    messages = messages
      .filter(({ _id: msgId }) => !deleteMsgIds.includes(msgId))
      .map((msg) => {
        if (
          msg?.parentId &&
          deleteMsgIds.includes(msg.parentId) &&
          msg.reply.type !== messageTypes.TEXT
        ) {
          let deletedFor = msg.reply?.deletedFor ?? [];
          deletedFor.push(sender._id);
          msg.reply.deletedFor = deletedFor;
        }
        return msg;
      });
    setState((prev) => ({ ...prev, messages, deleteMsgIds: [] }));
  };

  const clearSelectedMessages = () => {
    setState((prev) => ({ ...prev, deleteMsgIds: [] }));
  };

  const onPressDeleteAll = () => {
    const wantsToDeleteAll = window.confirm(
      chatConstants.deleteSelectedMessages
    );
    if (wantsToDeleteAll) deleteSelectedMessages();
  };

  const cancelEditing = () =>
    setState((prev) => ({
      ...prev,
      editingMsg: undefined,
      files: [],
      text: "",
    }));

  const openRoomInfo = () =>
    !isOneToOne && setState((prev) => ({ ...prev, showRoomInfo: true }));

  const onCloseRoomInfo = () =>
    setState((prev) => ({ ...prev, showRoomInfo: false }));

  const renderInputToolbar = () => {
    if (disabledText) {
      return (
        <div className="disabled_input_toolbar">
          <AautiText title={disabledText} size={"normal"} weight={"medium"} />
        </div>
      );
    }
    if (showSearchBar) return null;
    return (
      <InputToolBar
        {...{
          id,
          text,
          composerHeight: Math.max(MIN_COMPOSER_HEIGHT, composerHeight),
          updateFiles,
          onSend,
          onInputSizeChanged,
          onTextChanged: onInputTextChanged,
          textInputProps: {
            ref: textInputRef,
          },
          files,
          reply,
          onDismissReply,
          deleteFile,
          editingMsg,
          cancelEditing,
          isSending,
        }}
      />
    );
  };

  const roomName = isOneToOne
    ? activeRoom?.user?.displayName
    : activeRoom?.name;
  const avatar = isOneToOne
    ? activeRoom?.user?.profileImage
    : activeRoom?.avatar;

  const headerBottomText = isOneToOne
    ? activeRoom?.user?.onlineStatus
      ? chatConstants.online
      : chatConstants.offline
    : chatConstants.groupInfo;

  const renderChatHeaderProfile = () => (
    <div className={"chat_room_header_profile"}>
      <AautiText
        id={`room-name-${roomId}`}
        title={roomName}
        size={"medium"}
        weight={""}
        textAlign={"left"}
        textStyle={{
          color: "#1e1e1e",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          width: "200px",
        }}
      />
      <AautiText
        id={`room-name-${roomId}`}
        title={headerBottomText}
        size={"small"}
        weight={""}
        textAlign={"left"}
        textStyle={{
          color: "#1e1e1e",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          width: "200px",
          cursor: isOneToOne ? "none" : "pointer",
        }}
        onClick={openRoomInfo}
      />
    </div>
  );

  return (
    <div className="chat_room_and_info_wrapper">
      <div className="chat_room_wrapper">
        <div className="chat_room_header">
          {showBackIcon && (
            <FaArrowLeft
              onClick={onCloseChat}
              className={"chat_header_icon_styles"}
              size={20}
            />
          )}
          <>
            <AautiAvatar
              alt={`${roomName ?? "N/A"}`}
              src={avatar}
              height={40}
              width={40}
            />
            {renderChatHeaderProfile()}
          </>
          <div className={"chat_room_header_right"}>
            {!showSearchBar && !!messages?.length && (
              <Search
                className={"header_button"}
                onClick={toggleSearchBarVisibility}
              />
            )}
            {!!menuOptions.length && (
              <ChatMenu
                key={`chat_room_header_menu`}
                {...{ options: menuOptions, onPressMenuItem }}
              />
            )}
            {showArrowDownIcon && (
              <KeyboardArrowDownIcon
                className="header_button"
                onClick={onCloseChat}
              />
            )}
          </div>
        </div>
        <div className="chat_room_bottom_wrapper">
          {!messages?.length && (
            <EmptyMessages text={chatConstants.noMessagesYet} />
          )}
          {!!messages?.length && (
            <MessagesContainer
              key={`${roomId}-messages-container`}
              {...{
                forwardRef: messageContainerRef,
                messages,
                updatePageNo,
                sender,
                renderUsernameOnMessage:
                  activeRoom?.type !== conversationTypes.ONE_TO_ONE,
                onPressMenuItem,
                deleteMsgIds,
                updateSelectedMsgs,
                showSearchBar,
                toggleSearchBarVisibility,
              }}
            />
          )}
          {renderInputToolbar()}
        </div>
      </div>

      {showRoomInfo && (desktop || lapy) && !screen && (
        <div style={{ height: "100%", width: "36%", flexShrink: 0 }}>
          <ChatRoomInfo
            key={`chat-room-info${roomId}`}
            {...{
              loggedInUserId: sender?._id,
              onCloseRoomInfo,
              toggleAddUserDialog,
              toggleEditProfileDialog,
              toggleReportDialog,
            }}
          />
        </div>
      )}
      <BootstrapDialog
        onClose={onCloseRoomInfo}
        aria-labelledby="chat-room-info-dialog"
        open={
          showRoomInfo &&
          (aboveLargeMobileDevices ||
            ipodDevices ||
            miniLapys ||
            mobileDevices ||
            !!screen)
        }
        sx={{ zIndex: 201 }}
      >
        <DialogCloseButton
          {...{
            id: "chat-room-info-dialog-close",
            onClick: onCloseRoomInfo,
          }}
        />
        <DialogContent>
          <ChatRoomInfo
            key={`chat-room-info${roomId}`}
            {...{
              loggedInUserId: sender?._id,
              onCloseRoomInfo,
              toggleAddUserDialog,
              toggleEditProfileDialog,
              toggleReportDialog,
              showBackIcon: false,
            }}
          />
        </DialogContent>
      </BootstrapDialog>
      <AautiReport
        {...{
          chatRoomId: roomId,
          screenName: "Report Chat",
          open: showReportModal,
          setOpen: setShowReportModal,
        }}
      />
      {!isOneToOne && (
        <EditChatGroupDialog
          {...{
            open: Boolean(showEditDialog),
            onClose: toggleEditProfileDialog,
            activeRoom: {
              name: roomName,
              avatar,
              description: activeRoom?.description,
              roomId,
            },
          }}
        />
      )}
      {!isOneToOne && (
        <AddUserDialog
          {...{
            open: Boolean(showAddUserDialog),
            onClose: toggleAddUserDialog,
            roomType: activeRoom?.type,
            roomId,
            courseId: activeRoom?.courseId,
            instituteId: activeRoom?.instituteId,
            batchId: activeRoom?.batch?._id,
          }}
        />
      )}
    </div>
  );
}

export default memo(ChatRoom);
