import React from "react";
import { useSelector } from "react-redux";

const AppContainer = ({ style, children, className }) => {
  const { mobileDevices } = useSelector((state) => state.responsive);
  return (
    <div
      style={{
        width: "100%",
        padding: mobileDevices ? "2% 4%" : "1% 2%",
        height: "90vh",
        ...style,
      }}
      className={className}
    >
      {children}
    </div>
  );
};

export default AppContainer;
