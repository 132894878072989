
import { useSelector } from "react-redux"
import { getActiveChatRoomId} from "../../../redux/reducer/chatSlice"
import MainChatRoom from './MainChatRoom'
import NoChatsImage from "../../../assets/images/no-chat-room-selected.png";
import { chatConstants } from "../constants";
import AautiText from "../../globalComponents/AautiText";
import { Button } from "@mui/material";
import './styles.scss'

function ChatRoom(props){
  const activeChatRoomId = useSelector(getActiveChatRoomId)

  if(props?.isInMobileView && !activeChatRoomId) return null;

  if(activeChatRoomId) return <MainChatRoom key={activeChatRoomId} {...{...props,roomId:activeChatRoomId}}/>
  
  const inactiveText = props.screen ? chatConstants.noChat : chatConstants.welcome
  return (
    <div className={"empty_div"}>
        <img
          src={NoChatsImage}
          alt="nochat"
          style={{ height: "100px", width: "130px" }}
        />
        <AautiText
          title={inactiveText}
          size={"medium"}
          textStyle={{
            color: "#404040",
            marginTop: "5px",
          }}
        />
        {!!props?.screen && <Button
          type="primary"
          onClick={props.onCloseChat}
        >Go Back</Button>}
    </div>
  )
}

export default ChatRoom


