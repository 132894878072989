import React from "react";
import { GoDotFill } from "react-icons/go";
import AautiText from "../../globalComponents/AautiText";
import "./index.scss";
import { AautiStrings } from "../../globalComponents/AautiStrings";
import { IoInformationCircleOutline } from "react-icons/io5";
import { CapitalizeFirstLetter } from "../../CommonFunctions";

const HelperText = ({
  title,
  handleClickGenerateAI,
  error,
  aiGeneration = false,
  info = false,
}) => {
  return (
    <div
      className="flex-container"
      style={{
        marginTop: 2,
        gap: 15,
      }}
    >
      <div className={`flex-container ${info && "alignFlex"}`}>
        {info ? (
          <IoInformationCircleOutline color="#5C5B57" size={20} />
        ) : (
          <GoDotFill color="#5C5B57" size={12} />
        )}
        <AautiText
          title={CapitalizeFirstLetter(title)}
          size={"small"}
          textStyle={{ color: error ? "red" : "#5C5B57" }}
        />
      </div>
      <AautiText
        title={AautiStrings?.GenerateUsingAIString}
        size={"small"}
        weight={"bold"}
        onClick={handleClickGenerateAI}
        textStyle={{ color: "#2076E5", display: !aiGeneration && "none" }}
      />
    </div>
  );
};

export default HelperText;
