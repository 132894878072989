import React, { memo } from "react";
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import "./Style.scss";
import AautiText from "../globalComponents/AautiText";
import WifiProtectedSetupIcon from "@mui/icons-material/WifiProtectedSetup";
import PublicIcon from "@mui/icons-material/Public";
import { HeaderStrings } from "./constants";
import { MdOutlineSupportAgent } from "react-icons/md";
import {
  HeaderChatInActive,
  HeaderSettingsIcon,
} from "../homeScreen/header/pageHeader/pageHeaderUtils";
import DashboardIcon from "./assets/Dashboard.svg";
import MyActivity from "./assets/activity.svg";
import HelpSupportIcon from "./assets/Help & Support.svg";
import LogOutIcon from "./assets/Logout.svg";
import MenuSettingsIcon from "./assets/Settings.svg";
import MenuChatIcon from "./assets/MenuChat.svg";
import RightArrow from "./assets/Arrow 2.svg";
import RoleToggle from "./assets/roleToggle.svg";
import { useSelector } from "react-redux";

const MenuIcon = memo(({ url, style, height = 22, width = 22 }) => (
  <img
    src={url}
    style={{ ...style, height: height, width: width }}
    alt="menu-icon"
  />
));

const MenuItemComponent = memo(
  ({ onClick, iconUrl, title, rightIcon = true, chatCount }) => (
    <MenuItem sx={{ minHeight: "auto !important", p: 1 }} onClick={onClick}>
      <ListItemIcon>
        <MenuIcon url={iconUrl} />
      </ListItemIcon>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        width="90%"
        alignItems="center"
      >
        <Box display="flex" alignItems={"center"}>
          <AautiText size="normal" weight="medium" title={title} />

          <Box
            height={"20px"}
            width={"20px"}
            backgroundColor={"#ed6c02"}
            borderRadius={"50%"}
            display={chatCount ? "flex" : "none"}
            flexDirection={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            ml={0.5}
          >
            <AautiText
              size="small"
              weight="medium"
              textStyle={{
                color: "white",
                textAlign: "center",
              }}
              title={`${chatCount || 0}`}
            />
          </Box>
        </Box>
        {rightIcon && <MenuIcon url={RightArrow} />}
      </Box>
    </MenuItem>
  )
);

const ProfileMenu = ({
  handleMenuClose,
  menuOpen,
  userDetails,
  FirstName,
  handleUserRole,
  navigation,
  handleOpenConfirmation,
  mobileDevices,
  countryObject,
  setToggleCurrency,
  chatCount,
  onClickChat,
}) => {
  const { UserRole } = useSelector((state) => state.app);
  const isEndUser =
    userDetails?.role?.length === 1 && userDetails?.role?.includes("endUser");

  const menuItems = [
    {
      title: HeaderStrings?.myDashboard,
      iconUrl: DashboardIcon,
      onClick: () => navigation("/calendar"),
    },
    {
      title: HeaderStrings?.myAccount,
      iconUrl: MyActivity,
      onClick: () => navigation("/my-account"),
    },
    {
      title: "Chat",
      iconUrl: MenuChatIcon,
      chatCount,
      onClick: () => navigation("/chat"),
    },
    {
      title: HeaderStrings?.profileSettings,
      iconUrl: MyActivity,
      onClick: () => navigation("/profile"),
    },
    {
      title: HeaderStrings?.instituteInfo,
      iconUrl: MenuSettingsIcon,
      onClick: () => navigation("/Institute-Information"),
      condition: userDetails?.role?.includes(HeaderStrings?.instituteAdmin),
    },
    {
      title: `${countryObject?.countryCode || countryObject?.name} - ${
        countryObject?.countryCurrencySymbol ||
        countryObject?.countryCurrency ||
        "NA"
      }`,
      iconUrl: countryObject?.flag,
      onClick: () => {
        setToggleCurrency(true);
        handleMenuClose();
      },
      condition: mobileDevices,
    },
    {
      title: "Settings",
      iconUrl: MenuSettingsIcon,
      onClick: () => navigation("/account-settings"),
      condition: mobileDevices,
    },
    {
      title: HeaderStrings?.HelpSupport,
      iconUrl: HelpSupportIcon,
      onClick: () => navigation("/help-support"),
    },
    {
      title: HeaderStrings?.logoutCap,
      iconUrl: LogOutIcon,
      onClick: () => handleOpenConfirmation(HeaderStrings?.logout),
    },
  ];

  return (
    <Menu
      anchorEl={menuOpen}
      id="account-menu"
      open={menuOpen}
      onClose={handleMenuClose}
      PaperProps={{
        elevation: 0,
        className: "ProfileMenu-menu1",
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
        },
      }}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      sx={{
        height: "auto !important",
        pt: "0px !important",
        pb: "0px !important",
      }}
    >
      <Box
        mb={1}
        sx={{
          background: "#ffffff !important",
          borderRadius: 2,
          p: 3,
        }}
      >
        <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
          <Avatar
            onClick={() => {
              navigation("/profile");
              handleMenuClose();
            }}
            src={userDetails?.profileImage}
            className="ProfileMenu-div1"
            sx={{ cursor: "pointer" }}
          />
          <Box
            justifyContent="space-between"
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            width={"100%"}
          >
            <Box>
              <AautiText
                weight={"bold"}
                textStyle={{
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  WebkitLineClamp: 1,
                  textOverflow: "ellipsis",
                  maxWidth: "100%",
                }}
                title={
                  userDetails?.displayName || FirstName || HeaderStrings?.user
                }
                textAlign="left"
              />
              <AautiText
                title={`${
                  userDetails?.role?.includes(HeaderStrings?.instituteAdmin)
                    ? HeaderStrings?.instituteAdminCap
                    : UserRole === HeaderStrings.endUser2
                    ? HeaderStrings.endUser
                    : HeaderStrings.serviceProvider
                }`}
                size={"small"}
              />
            </Box>
            <Tooltip title="Switch Role">
              <IconButton
                style={{ display: isEndUser && "none" }}
                onClick={handleUserRole}
              >
                <MenuIcon height={30} width={30} url={RoleToggle} />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
        {isEndUser && (
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            width={"100%"}
            mt={1}
          >
            <button
              onClick={() => handleUserRole(isEndUser)}
              style={{
                background: "none",
                marginTop: 10,
                cursor: "pointer",
                color: "#3083EF",
                border: "1px solid #3083EF",
                padding: 6,
                borderRadius: 3,
              }}
            >
              <AautiText
                textStyle={{}}
                title="Become a Service Provider"
                size="semi"
              />
            </button>
          </Box>
        )}
      </Box>

      <Box sx={{ background: "#fff", padding: 1, borderRadius: 2 }}>
        {menuItems
          .filter((item) => item.condition !== false)
          .map((item, index) => (
            <MenuItemComponent
              rightIcon={item?.title == "Logout" ? false : true}
              key={index}
              title={item?.title}
              iconUrl={item?.iconUrl}
              onClick={() => {
                handleMenuClose();
                item?.onClick();
                item?.title === "Chat" && onClickChat?.();
              }}
              chatCount={item?.chatCount}
            />
          ))}
      </Box>
    </Menu>
  );
};

export default ProfileMenu;
