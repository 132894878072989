import React from "react";
import AppContainer from "../AppContainer";
import AccountTabs from "../globalComponents/AccountTabs";
import "./Styles.scss";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import AautiText from "../globalComponents/AautiText";
import AautiTextInput from "../globalComponents/AautiTextInput";
import Avatar from "@mui/material/Avatar";
import { useSelector } from "react-redux";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SpContainer from "./Container";
import { formatPercentage, pluralOrSingular } from "../../utils/Helpers";
import { PriceConversions } from "../CommonFunctions";
import EmptyData from "../globalComponents/EmptyData";
import StarIcon from "@mui/icons-material/Star";
import Rating from "@mui/material/Rating";
import AautiDialog from "../globalComponents/AautiDialog";
import { DashBoardInfoConstants } from "./Constants";
import StudentProfile from "../../components/students/StudentProfile";

const DashBoardInfo = ({ type, id }) => {
  const {
    onHandleTitleClick,
    activeTab,
    headerCount,
    renderData,
    expanded,
    handleChange,
    searchText,
    setSearchText,
    selectedData,
    setSelectedData,
    currentScreen,
    loading,
    displayPopUp,
    setDisplayPopUp,
    dashBoardTabTitle,
    studentId,
    setStudentId,
    open,
    setOpen,
    setExpanded,
    onClickEachItem,
    firstItem,
    selectedRole,
    onSwitchRoleTab,
  } = SpContainer({ type, id });

  const { mobileDevices } = useSelector((state) => state.responsive);
  const { UserRole, userDetails } = useSelector((state) => state.app);
  const { dashBoardRevenueconvertToMillionBillions } = PriceConversions();
  const currencySymbol = userDetails?.transactionCountrySymbol;

  const SPTitles = ({ titles, onHandleTitleClick }) => {
    return (
      <div className="sp-titles-div">
        {titles?.map((title, index) => (
          <>
            <AautiText
              weight={"bold"}
              title={title}
              className="sp-titles-text"
              textStyle={{
                color: activeTab === title ? "#078dda" : "#000000",
                border: `1px solid ${
                  activeTab === title ? "#078dda" : "#dedede"
                }`,
                display:
                  mobileDevices &&
                  currentScreen === "reviews-ratings" &&
                  "inline-block",
                whiteSpace:
                  mobileDevices &&
                  currentScreen === "reviews-ratings" &&
                  "nowrap",
                flex: mobileDevices && 1,
                width: !mobileDevices && "150px",
              }}
              key={index}
              onClick={() => activeTab !== title && onHandleTitleClick(title)}
            />
          </>
        ))}
      </div>
    );
  };
  const ActiveRoleTab = ({ titles, onHandleTitleClick }) => {
    return (
      <div className="sp-attendance-titles">
        {titles?.map((title, index) => (
          <>
            <AautiText
              weight={"bold"}
              title={title}
              className="sp-attendance-text"
              textStyle={{
                color: selectedRole === title ? "#078dda" : "#000000",
                border: `1px solid ${
                  selectedRole === title ? "#078dda" : "#dedede"
                }`,
              }}
              key={index}
              onClick={() => selectedRole !== title && onHandleTitleClick(title)}
            />
          </>
        ))}
      </div>
    );
  };

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    if (!name) return {};

    const nameParts = name?.split(" ");
    const initials = nameParts
      ?.slice(0, 2)
      .map((part) => part[0]?.toUpperCase())
      .join("");

    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: initials,
    };
  }

  const cardHeaderImage = (each) => {
    switch (activeTab) {
      case "Courses":
        return each?.courseImage;
      case "Gigs":
        return each?.gigImage;
      case "Adhocs":
        return each?.adhocImage;
      case "My Reviews":
        return each?.adhocImage;
      default:
        break;
    }
  };

  const cardHeaderName = (each) => {
    switch (currentScreen) {
      case "students":
        if (activeTab === "Courses") {
          return each?.courseName;
        } else if (activeTab === "Gigs") {
          return each?.gigName;
        } else {
          return each?.adhocName;
        }
      case "my-revenue":
        if (activeTab === "Courses") {
          return each?.courseName;
        } else if (activeTab === "Gigs") {
          return each?.gigName;
        } else {
          return each?.adhocName;
        }
      case "attendance-details":
        if (activeTab === "Courses") {
          return each?.courseName;
        } else if (activeTab === "Gigs") {
          return each?.gigName;
        } else {
          return each?.adhocName;
        }
      case "reviews-ratings":
        if (activeTab === "Courses") {
          return each?.courseName;
        } else if (activeTab === "Gigs") {
          return each?.gigName;
        } else if (activeTab === "Adhocs") {
          return each?.adhocName || each?.displayName;
        } else if (activeTab === "My Reviews") {
          return each?.displayName;
        }
      default:
        break;
    }
  };
  const batchesCountData = (each) => {
    const formatPercentage = (value) => {
      return value == null ? "N/A" : value % 1 === 0 ? value : value.toFixed(2);
    };
    switch (currentScreen) {
      case "students":
        if (activeTab === "Courses") {
          return `${each?.batchesCount} ${pluralOrSingular(
            each?.batchesCount,
            "Batch",
            true
          )} | ${each?.totalSubscribedCount} ${pluralOrSingular(
            each?.totalSubscribedCount,
            "Student"
          )}`;
        } else if (activeTab === "Gigs") {
          return `${
            each?.subscribedCount || each?.totalSubscribedCount
          } ${pluralOrSingular(
            each?.subscribedCount || each?.totalSubscribedCount,
            "Student"
          )}`;
        } else {
          return `${each?.totalSubscribersCount} ${pluralOrSingular(
            each?.totalSubscribersCount,
            "Student"
          )}`;
        }
      case "my-revenue":
        return (
          <span>
            Revenue :{" "}
            <span style={{ fontFamily: "sans-serif" }}>{currencySymbol}</span>
            {dashBoardRevenueconvertToMillionBillions(each?.revenue, 2)}
          </span>
        );
      case "attendance-details":
        switch (UserRole) {
          case "serviceprovider":
            if (activeTab === "Courses") {
              return `${pluralOrSingular(each?.sessionsCount, "Session")} ${
                each.sessionsCount
              }  | 
            ${formatPercentage(each?.totalPercentage)}% Attendance
           `;
            } else if (activeTab === "Adhocs") {
              return `${pluralOrSingular(each?.sessionsCount, "Session")} ${
                each?.sessionsCount
              } | 
            ${formatPercentage(each?.totalPercentage)}% Attendance
           `;
            }
            break;
          case "endUser":
            if (activeTab === "Courses") {
              return `${pluralOrSingular(each?.sessionsCount, "Session")} ${
                each?.sessionsCount
              } | 
            ${formatPercentage(each?.totalPercentage)}% Attendance
           `;
            } else if (activeTab === "Adhocs") {
              return `${pluralOrSingular(each?.sessionsCount, "Session")} ${
                each?.sessionsCount
              } | 
            ${formatPercentage(each?.totalPercentage)}% Attendance
           `;
            }
            break;
          default:
            break;
        }
        break;
      case "reviews-ratings":
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Rating
              sx={{ color: "#078dda" }}
              color="#078dda"
              name="text-feedback"
              value={each?.averageRating || each?.rating}
              readOnly
              precision={0.5}
              emptyIcon={
                <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
              }
            />
            <Box sx={{ ml: 1 }}>
              {(each?.averageRating || each?.rating)?.toFixed(1)}
            </Box>
          </div>
        );
      default:
        break;
    }
  };

  const SPCard = () => {
    return (filteredBySearch?.length > 0 ? filteredBySearch : renderData)?.map(
      (each, index) => (
        <div
          className="sp-card-div"
          style={{
            backgroundColor:
              (selectedData?._id ||
                selectedData?.courseId ||
                selectedData?.gigId ||
                selectedData?.adhocId || selectedData?.schedulerId) ===
              (each._id || each?.courseId || each?.gigId || each?.adhocId || each?.schedulerId)
                ? "#e9f2fd"
                : "#ffffff",
          }}
          key={index}
          onClick={() => onClickEachItem(each)}
        >
          <Avatar
            alt={cardHeaderName(each)}
            src={cardHeaderImage(each)}
            {...stringAvatar(cardHeaderName(each))}
          />
          <div className="course-coutn-map" key={index}>
            <AautiText
              size={"semi"}
              title={cardHeaderName(each)}
              weight={"bold"}
              className="sp-card-title"
            />
            <div className="bactches-students-div">
              <AautiText title={batchesCountData(each)} />
            </div>
          </div>
        </div>
      )
    );
  };

  const selectedBatchData =
    selectedData?.batches || selectedData?.sessions || selectedData?.users || selectedData?.reviews;

  const batchUsersList = (person, index) => {
    return (
      <div
        style={{
          width:mobileDevices? "100%": currentScreen === "attendance-details" ? "100%" : null,
        }}
      >
        {currentScreen !== "attendance-details" && currentScreen !== "reviews-ratings" && (
          <div
            className="students-div"
            key={index}
            onClick={() => {
                setStudentId(person?._id);
                setOpen(true);
           }}
          >
            {(currentScreen === "my-revenue" ||
              currentScreen === "students") && (
              <Avatar
                alt="Remy Sharp"
                src={person?.profileImage}
                {...stringAvatar(person?.displayName)}
              />
            )}
            <div className="batch-details-div">
              <AautiText
                weight={"bold"}
                size={"semi"}
                title={person?.displayName}
              />
              {currentScreen !== "students" && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <AautiText
                    title={
                      <>
                        Revenue :{" "}
                        <span style={{ fontFamily: "sans-serif" }}>
                          {currencySymbol}
                        </span>
                        {dashBoardRevenueconvertToMillionBillions(
                          person?.purchasedPrice,
                          2
                        )}
                      </>
                    }
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  };

  const batchMembers = (each, index) => {
    const getBatchMembers = each?.batches || each?.sessions || each?.users;

    return (
      <>
        {activeTab === "Courses" && currentScreen !== "reviews-ratings" && (
          <div className="courses-batches-students-div" key={index}>
            <Accordion
              expanded={
                expanded === (each?._id || each?.batchId || each?.courseId || index)
              }
              onChange={handleChange(
                each?._id || each?.batchId || each?.courseId || index
              )}
              className="accordian-styles"
              sx={{
                boxShadow: "1px",
              }}
            >
              <AccordionSummary
                expandIcon={
                  !expanded ? <ChevronRightIcon /> : <ExpandMoreIcon />
                }
                sx={{
                  "& .MuiAccordionSummary-content": {
                    width: "100%",
                  },
                }}
              >
                <AautiText
                  weight={"bold"}
                  textStyle={{
                    width: "100%",
                  }}
                  title={batchName(each)}
                  className="sp-card-title"
                />
              </AccordionSummary>
              <AccordionDetails>
                <div
                  style={{
                    width: currentScreen === "attendance-details" && "100%",
                  }}
                >
                  {currentScreen === "attendance-details" && (
                    <TableContainer
                      component={Paper}
                      sx={{
                        width: "100%",
                        boxShadow: "none",
                      }}
                    >
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell
                              align="center"
                              sx={{ fontWeight: "bold" }}
                            >
                              Sessions
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{ fontWeight: "bold" }}
                            >
                              Attendance
                            </TableCell>
                           {selectedRole === "End User" &&
                            <TableCell
                              align="center"
                              sx={{ fontWeight: "bold" }}
                            >
                              Percentage
                            </TableCell>}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {getBatchMembers?.map((person, index) => (
                            <TableRow key={index}>
                              <TableCell align="center">
                                {`Session ${index + 1}`}
                              </TableCell>
                           {selectedRole === "Service Provider"?
                            <TableCell
                                  align="center"
                                  sx={{
                                    color: person?.upcoming
                                      ? "#ffba49"
                                      : person?.attended
                                      ? "#3acc6c"
                                      : "#f77777",
                                  }}
                                >
                                  {person?.upcoming
                                    ? "Upcoming"
                                    : person?.attended
                                    ? "Attended"
                                    : "Not Attended"}
                                </TableCell>
                                 : <TableCell align="center" sx={{color:person?.upcoming ? '#ffba49' : null}}>
                                {person?.upcoming ? 'Upcoming' : `${person?.attendedCount} / ${person?.totalCount}`}
                                 </TableCell> }
                              {selectedRole === "End User" &&
                                <TableCell align="center">
                                  {
                                    person?.upcoming ? `--` : `${formatPercentage(person?.sessionPercentage)} %`
                                  }
                                </TableCell>}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </div>

                <div className="accordian-details" style={{gap:currentScreen !== "attendance-details" && "10px"}}>
                  {getBatchMembers?.map((person, index) => {
                    return batchUsersList(person, index);
                  })}
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        )}
        <div className="accordian-details-main-div" style={{width:mobileDevices && "100%"}}>
          {activeTab === "Gigs" && (
            <div className="accordian-details"  style={{gap:currentScreen !== "attendance-details" && "10px"}}>
              {batchUsersList(each, index)}
            </div>
          )}
          {activeTab === "Adhocs" && currentScreen === "my-revenue" && (
            <div className="accordian-details" style={{gap:currentScreen !== "attendance-details" && "10px"}}>
              {batchUsersList(each, index)}
            </div>
          )}
          {activeTab === "Adhocs" && currentScreen === "students" && (
            <div className="accordian-details"  style={{gap:currentScreen !== "attendance-details" && "10px"}}>
              {each?.users?.map((person, index) => {
                return batchUsersList(person, index);
              })}
            </div>
          )}
        </div>
        {currentScreen === "reviews-ratings" && (
             <div
             className="students-div"
             key={index}
             onClick={() => {
                 setStudentId(each?.endUserId);
                 setOpen(true);
               }}
               style={{width: mobileDevices && "100%"}}
               >
               <Avatar
                 alt="Remy Sharp"
                 src={each?.profileImage}
                 {...stringAvatar(each?.displayName)}
               />
               <div className="batch-details-div">
                <AautiText
                  weight={"bold"}
                  size={"semi"}
                  title={each?.displayName}
                />
                <div style={{ display: "flex", alignItems: "center" }}>
                   <Rating
                     sx={{ color: "#078dda" }}
                     color="#078dda"
                     name="text-feedback"
                     value={each?.averageRating || each?.rating}
                     readOnly
                     precision={0.5}
                     emptyIcon={
                       <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                     }
                   />
                   <Box sx={{ ml: 1, fontFamily:"Poppins", fontWeight:500}}>
                     {(each?.averageRating || each?.rating)?.toFixed(1)}
                   </Box>
                 </div>
             </div>
           </div>
          )
        }
      </>
    );
  };
  const eachbatchesCountData = (each) => {
    const formatPercentage = (value) => {
      return value == null
        ? "N/A"
        : value % 1 === 0
        ? value
        : value?.toFixed(2);
    };
    switch (currentScreen) {
      case "students":
        if (activeTab === "Courses") {
          return `${each?.batchesCount} ${pluralOrSingular(
            each?.batchesCount,
            "Batch",
            true
          )} | ${each?.totalSubscribedCount} ${pluralOrSingular(
            each?.totalSubscribedCount,
            "Student"
          )}`;
        } else if (activeTab === "Gigs") {
          return `${
            each?.subscribedCount || each?.totalSubscribedCount
          } ${pluralOrSingular(
            each?.subscribedCount || each?.totalSubscribedCount,
            "Student"
          )}`;
        } else {
          return `${each?.totalSubscribedCount} ${pluralOrSingular(
            each?.totalSubscribedCount,
            "Student"
          )}`;
        }
      case "my-revenue":
        if (activeTab === "Courses") {
          return (
            <span>
              Revenue :{" "}
              <span style={{ fontFamily: "sans-serif" }}>{currencySymbol}</span>
              {dashBoardRevenueconvertToMillionBillions(each?.courseRevenue, 2)}
            </span>
          );
        } else if (activeTab === "Gigs") {
          return (
            <span>
              Revenue :{" "}
              <span style={{ fontFamily: "sans-serif" }}>{currencySymbol}</span>
              {dashBoardRevenueconvertToMillionBillions(each?.gigRevenue, 2)}
            </span>
          );
        } else if (activeTab === "Adhocs") {
          return (
            <span>
              Revenue :{" "}
              <span style={{ fontFamily: "sans-serif" }}>{currencySymbol}</span>
              {dashBoardRevenueconvertToMillionBillions(each?.adhocRevenue, 2)}
            </span>
          );
        }
        break;
      case "attendance-details":
        switch (UserRole) {
          case "serviceprovider":
            if (activeTab === "Courses") {
              return `${pluralOrSingular(each?.sessionsCount, "Session")} ${
                each?.sessionsCount
              }  | 
              ${formatPercentage(each?.totalPercentage)}% Attendance
             `;
            } else if (activeTab === "Adhocs") {
              return `${pluralOrSingular(each?.sessionsCount, "Session")} ${
                each?.sessionsCount
              } | 
            ${formatPercentage(each?.totalPercentage)}% Attendance
           `;
            }
            break;
          case "endUser":
            if (activeTab === "Courses") {
              return `${pluralOrSingular(each?.sessionsCount, "Session")} ${
                each?.sessionsCount
              } | 
            ${formatPercentage(each?.totalPercentage)}% Attendance
           `;
            } else if (activeTab === "Adhocs") {
              return `${pluralOrSingular(each?.sessionsCount, "Session")} ${
                each?.sessionsCount
              } | 
            ${formatPercentage(each?.totalPercentage)}% Attendance
           `;
            }
            break;
          default:
            break;
        }
        break;
      case "reviews-ratings":
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Rating
              sx={{ color: "#078dda" }}
              color="#078dda"
              name="text-feedback"
              value={each?.averageRating || each?.rating}
              readOnly
              precision={0.5}
              emptyIcon={
                <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
              }
            />
            <Box sx={{ ml: 1 }}>
              {(each?.averageRating || each?.rating)?.toFixed(1)}
            </Box>
          </div>
        );
      default:
        break;
    }
  };
  const SPCardItem = () => {
    return renderData?.length > 0 && (
      firstItem && (
        <>
          {filteredBySearch?.length > 0 && (
            <div className="sp-card-item-div">
              <div className="sp-header-item-div">
                <Avatar
                  alt={cardHeaderName(selectedData)}
                  src={cardHeaderImage(selectedData)}
                  {...stringAvatar(cardHeaderName(selectedData))}
                />
                <div className="course-coutn-map">
                  <AautiText
                    size={"semi"}
                    title={cardHeaderName(selectedData)}
                    weight={"bold"}
                    className="sp-card-title"
                  />
                  <div className="bactches-students-div">
                    <AautiText title={eachbatchesCountData(selectedData)} />
                  </div>
                </div>
              </div>
              <div
                className="batches-students-div"
                style={{
                  padding: currentScreen === "reviews-ratings" ? "10px" : activeTab !== "courses" && "10px",
                }}
              >
                {currentScreen === "attendance-details" &&
                  activeTab === "Adhocs" && (
                    <TableContainer
                      component={Paper}
                      sx={{
                        boxShadow: "none",
                        padding: "0px",
                        width: "100%",
                      }}
                    >
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell align="center">Sessions</TableCell>
                            <TableCell align="center">Attendance</TableCell>
                            {selectedRole === "End User" &&
                            <TableCell align="center">Percentage</TableCell>
                            }
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {selectedBatchData?.map((each, index) => (
                            <TableRow key={index}>
                              <TableCell align="center">
                                {`Session ${index + 1}`}
                              </TableCell>
                             {selectedRole === "Service Provider"? <TableCell
                                  align="center"
                                  sx={{
                                    color: each?.upcoming
                                      ? "#ffba49"
                                      : each?.attended
                                      ? "#3acc6c"
                                      : "#f77777",
                                  }}
                                >
                                  {each?.upcoming
                                    ? "Upcoming"
                                    : each?.attended
                                    ? "Attended"
                                    : "Not Attended"}
                                </TableCell>:
                               <TableCell align="center" sx={{color:each?.upcoming ? '#ffba49' : null}}>
                                {each?.upcoming ? 'Upcoming' : `${each?.attendedCount} / ${each?.totalCount}`}
                                 </TableCell>}
                              {selectedRole === "End User" &&
                                 <TableCell align="center">
                                  {
                                    each?.upcoming ? `--` : `${formatPercentage(each?.sessionPercentage)} %`
                                  }
                                </TableCell>}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}

                {selectedBatchData?.map((each, index) => {
                  return batchMembers(each, index);
                })}
                <AautiDialog
                  open={open}
                  onClose={() => {
                    setOpen(false);
                  }}
                  dialogTitle={"Student Details"}
                >
                  <StudentProfile
                    studentId={studentId}
                    screenName={"spStudents"}
                  />
                </AautiDialog>
              </div>
            </div>
          ) }
        </>
      )
    ) 
  };

  const returnTypeTabs = () => {
    const ratingData = () => {
      switch (activeTab) {
        case "Courses":
          return headerCount?.coursesValue;
        case "Gigs":
          return headerCount?.gigsValue;
        case "Adhocs":
          return headerCount?.adhocValue;
        case "My Reviews":
          return headerCount?.averageRating;
        default:
          return undefined;
      }
    };
    const getCourseAdhocGigTitle = () => {
      switch (activeTab) {
        case "Courses":
          return "Course";
        case "Gigs":
          return "Gig";
        case "Adhocs":
          return "Adhoc";
        case "My Reviews":
          return "User";
        default:
          return null;
      }
    };
    const averageRating = () => {
      const ratingValue = ratingData();
      if (ratingValue === undefined || ratingValue === null) {
        return null;
      }
      return (
        <div className="average-ratings-div">
          {`${renderData?.length} ${pluralOrSingular(
            renderData?.length,
            getCourseAdhocGigTitle()
          )} | `}
          <Rating
            sx={{ color: "#078dda" }}
            name="text-feedback"
            value={ratingValue}
            readOnly
            precision={0.5}
            emptyIcon={
              <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
            }
          />
          <Box sx={{ ml: 1 }}>{ratingValue?.toFixed(1)}</Box>
        </div>
      );
    };

    const formatPercentage = (value) => {
      return value % 1 === 0 ? value : value?.toFixed(2);
    };

    switch (currentScreen) {
      case "students":
        if (activeTab === "Courses") {
          return (
            <div style={{ display: "flex", marginLeft: "10px" }}>
              <AautiText
                title={`${renderData?.length} ${pluralOrSingular(
                  renderData?.length,
                  "Course"
                )} | ${headerCount?.coursesValue} ${pluralOrSingular(
                  headerCount?.coursesValue,
                  "Student"
                )}`}
              />
            </div>
          );
        } else if (activeTab === "Gigs") {
          return (
            <div style={{ display: "flex", marginLeft: "10px" }}>
              <AautiText
                title={`${renderData?.length} ${pluralOrSingular(
                  renderData?.length,
                  "Gig"
                )} | ${headerCount?.gigsValue} ${pluralOrSingular(
                  headerCount?.gigsValue,
                  "Student"
                )}`}
              />
            </div>
          );
        } else {
          return (
            <div style={{ display: "flex", marginLeft: "10px" }}>
              <AautiText
                title={`${renderData?.length} ${pluralOrSingular(
                  renderData?.length,
                  "Adhoc"
                )} | ${headerCount?.adhocValue} ${pluralOrSingular(
                  headerCount?.adhocValue,
                  "Student"
                )}`}
              />
            </div>
          );
        }
      case "my-revenue":
        if (activeTab === "Courses") {
          return (
            <div
              style={{
                display: "flex",
                marginLeft: "10px",
                alignItems: "center",
              }}
            >
              <AautiText
                title={
                  <>
                    {renderData?.length}{" "}
                    {pluralOrSingular(renderData?.length, "Course")} | Revenue :{" "}
                    <span style={{ fontFamily: "sans-serif" }}>
                      {currencySymbol}
                    </span>
                    {dashBoardRevenueconvertToMillionBillions(
                      headerCount?.coursesValue,
                      2
                    )}
                  </>
                }
              />
            </div>
          );
        } else if (activeTab === "Gigs") {
          return (
            <div
              style={{
                display: "flex",
                marginLeft: "10px",
                alignItems: "center",
              }}
            >
              <AautiText
                title={
                  <>
                    {renderData?.length}{" "}
                    {pluralOrSingular(renderData?.length, "Gig")} | Revenue :{" "}
                    <span style={{ fontFamily: "sans-serif" }}>
                      {currencySymbol}
                    </span>
                    {dashBoardRevenueconvertToMillionBillions(
                      headerCount?.gigsValue,
                      2
                    )}
                  </>
                }
              />
            </div>
          );
        } else {
          return (
            <div
              style={{
                display: "flex",
                marginLeft: "10px",
                alignItems: "center",
              }}
            >
              <AautiText
                title={
                  <>
                    {renderData?.length}{" "}
                    {pluralOrSingular(renderData?.length, "Adhoc")} | Revenue :{" "}
                    <span style={{ fontFamily: "sans-serif" }}>
                      {currencySymbol}
                    </span>
                    {dashBoardRevenueconvertToMillionBillions(
                      headerCount?.adhocValue,
                      2
                    )}
                  </>
                }
              />
            </div>
          );
        }
      case "attendance-details":
        if (activeTab === "Courses") {
          return (
            <div style={{ display: "flex", marginLeft: "10px" }}>
              <AautiText
                title={`${renderData?.length} ${pluralOrSingular(
                  renderData?.length,
                  "Course"
                )} | ${formatPercentage(
                  headerCount?.coursesValue
                )}% Attendance`}
              />
            </div>
          );
        } else if (activeTab === "Adhocs") {
          return (
            <div style={{ display: "flex", marginLeft: "10px" }}>
              <AautiText
                title={`${renderData?.length} ${pluralOrSingular(
                  renderData?.length,
                  "Adhoc"
                )} | ${formatPercentage(headerCount?.adhocValue)}% Attendance`}
              />
            </div>
          );
        }
        break;
      case "reviews-ratings":
        return (
          renderData?.length > 0 && (
            <div style={{ display: "flex", marginLeft: "10px" }}>
              <AautiText title={averageRating()} />
            </div>
          )
        );
      default:
        return null;
    }
  };
  const batchName = (each) => {
    const isCourseScreen = activeTab === "Courses";
    if (isCourseScreen) {
      return each?.batchName;
    }
    return null;
  };

  const filteredBySearch = renderData?.filter((each) => {
    const name =
      activeTab === "Courses"
        ? each.courseName
        : activeTab === "Gigs"
        ? each.gigName
        : activeTab === "Adhocs"
        ? each.adhocName
        : each.displayName;
    return name?.toLowerCase().includes(searchText.toLowerCase());
  });
  
  let role = [];
  switch (UserRole) {
    case "serviceprovider":
    if(currentScreen === "attendance-details") 
       role = ["Service Provider", "End User"];
    break;
    default:
      break;
  }

  let titles = [];
  switch (currentScreen) {
    case "attendance-details":
      titles = ["Courses", "Adhocs"];
      break;
    case "reviews-ratings":
      titles = ["Courses", "Gigs", "Adhocs", "My Reviews"];
      break;
    default:
      titles = ["Courses", "Gigs", "Adhocs"];
      break;
  }

  let navigationTitles;
  switch (currentScreen) {
    case "students":
      navigationTitles = "Students";
      break;
    case "my-revenue":
      navigationTitles = "My Revenue";
      break;
    case "attendance-details":
      navigationTitles = "Attendance";
      break;
    case "reviews-ratings":
      navigationTitles = "Reviews & Ratings";
      break;
    default:
      break;
  }

  let navigationPaths;
  switch (currentScreen) {
    case "students":
      navigationPaths = "/students";
      break;
    case "my-revenue":
      navigationPaths = "/my-revenue";
      break;
    case "attendance-details":
      navigationPaths = "/attendance-details";
      break;
    case "reviews-ratings":
      navigationPaths = "/reviews-ratings";
      break;
    default:
      break;
  }

  const emptyData = () => {
    switch (currentScreen) {
      case "students":
        return DashBoardInfoConstants.studentsEmpty;
      case "my-revenue":
        return DashBoardInfoConstants.revenueEmpty;
      case "attendance-details":
        return DashBoardInfoConstants.attendanceEmpty;
      case "reviews-ratings":
        return DashBoardInfoConstants.ratingsEmpty;
      default:
        break;
    }
  };

  return (
    <AppContainer
      style={{
        padding: mobileDevices ? "2% 4%" : type == undefined && "1% 2%",
      }}
    >
      {type == undefined && (
        <AccountTabs
          tabObj={{
            label: navigationTitles,
            navigation: navigationPaths,
            active: true,
            navigationLabel: dashBoardTabTitle,
          }}
        />
      )}
      <div className="head">
        {loading ? (
          <Box className="TRloadingdiv1">
            <Box className="TRloadingdiv2">
              <Skeleton variant="rounded" width={"100%"} height={40} />
              <Skeleton
                variant="rounded"
                width={"100%"}
                height={40}
                sx={{ m: "8px 0px" }}
              />
              <Skeleton
                variant="rounded"
                width={"100%"}
                height={mobileDevices ? 200 : 400}
              />
            </Box>
            <Box className="TRloadingdiv3">
              <Skeleton variant="rounded" width={"100%"} height={30} />
              <Skeleton
                variant="rounded"
                width={"100%"}
                height={40}
                sx={{ m: "8px 0px" }}
              />
              <Skeleton variant="rounded" width={"100%"} height={60} />
            </Box>
          </Box>
        ) : (
          <div
            className="box-styles"
            style={{
              flexDirection: mobileDevices ? "column" : "row",
            }}
          >
            <div
              className="return-type-tabs"
              styles={{
                width: mobileDevices ? "100%" : "30%",
                borderBottom: mobileDevices && "1px #dedede solid",
              }}
            >
               <ActiveRoleTab
                titles={role}
                onHandleTitleClick={onSwitchRoleTab}
              />
              <SPTitles
                titles={titles}
                onHandleTitleClick={onHandleTitleClick}
              />
              {renderData?.length > 0 && returnTypeTabs()}
              {renderData?.length > 0 && (
                <AautiTextInput
                  text="Search"
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                  className="search-styles"
                  size="small"
                  style={{
                    width: "98%",
                    marginLeft: mobileDevices ? "3px" : "4px",
                  }}
                />
              )}
              {renderData?.length > 0 ? (
                <>
                  {filteredBySearch?.length > 0 ? (
                    <Box
                      style={{
                        height: !mobileDevices && "65vh",
                      }}
                      className="sp-card-main-div"
                    >
                      <SPCard />
                    </Box>
                  ) : (
                    <EmptyData title="No search results" />
                  )}
                </>
              ) : (
                <EmptyData title={emptyData()} />
              )}
            </div>

            {!mobileDevices && (
              <div
                className="sp-header-div"
                style={{
                  width: mobileDevices ? "100%" : "70%",
                }}
              >
                <SPCardItem />
              </div>
            )}
          </div>
        )}
      </div>
      {selectedBatchData?.length > 0 && (
        <AautiDialog
          open={displayPopUp}
          onClose={() => {
            setDisplayPopUp(false);
          }}
        >
          <SPCardItem />
        </AautiDialog>
      )}
      <AautiDialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        dialogTitle={"Student Details"}
      >
        <StudentProfile studentId={studentId} screenName={"spStudents"} />
      </AautiDialog>
    </AppContainer>
  );
};

export default DashBoardInfo;
