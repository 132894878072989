import React, { useState } from "react";
import { Dialog } from "@mui/material";
import { useSelector } from "react-redux";
import { Typography } from "@mui/material";
import Dropdown from "react-multilevel-dropdown";
import JoinButton from "../../globalComponents/JoinButton";
import AautiText from "../../globalComponents/AautiText";
import { AautiStrings } from "../../globalComponents/AautiStrings";
import NavButton from "../courseCreation/NavButton";
import AautiDialog from "../../globalComponents/AautiDialog";

const CatDropdown = (props) => {
  const {
    CreateCourseObj,
    visible2,
    mobileDevices,
    setVisible2,
    ipodDevices,
    categories,
    changeCreateObj,
    setRefresh,
    subCategories,
    refresh,
    setCreateCourseObj,
    setSubCategories,
    allCategories,
    setCourseOverviewErrors,
    courseOverviewErrors,
    resetObject,
  } = props;
  const { desktop, lapy, miniLapys, aboveLargeMobileDevices } = useSelector(
    (state) => state.responsive
  );
  const [categorySubCat, setCategorySubCat] = useState({
    category: CreateCourseObj?.category,
    subCategory: CreateCourseObj?.subCategory,
  });

  const onChageDrodown = (e) => {
    setCategorySubCat((prev) => ({
      ...prev,
      category: e,
      subCategory: "",
    }));
    const newList = [];
    const b = allCategories.filter((each, index) => {
      return e === each?.categoryName;
    });
    b[0]?.subCategories?.map((each, index) =>
      newList.push(each.subCategoryName)
    );
    setSubCategories(newList);
  };

  return (
    <Dialog
      open={visible2}
      // onClose={() => setVisible2(false)}
      draggable={false}
      fullWidth
      position="bottom"
      PaperProps={{
        sx: {
          mt: mobileDevices ? "0px" : "100px",
          verticalAlign: "bottom",
        },
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: 5,
        }}
      >
        <AautiText title={"Categories and SubCategories"} required />

        {/* <AautiTextInput
          size={"small"}
          weight={"medium"}
          // rows={2}
          multiline
          important
          text={"Categories and SubCategories"}
          style={{
            width: mobileDevices ? "85%" : "90%",
            margin: 1,
            backgroundColor: "#fff",
            cursor: "pointer",
          }}
        /> */}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          backgroundColor: "#f2f2f2",
          width: mobileDevices && "auto",
          // height: "70vh",
          // overflow: "scroll",
        }}
      >
        <div
          style={{
            width: mobileDevices ? "45%" : "50%",
            height: mobileDevices ? "50vh" : ipodDevices ? "43vh" : "60vh",
            overflow: "scroll",
          }}
        >
          {categories?.map((each, index) => {
            return (
              <Dropdown.Item
                key={index}
                onClick={(e) => {
                  onChageDrodown(each);

                  // changeCreateObj("", "subCategory");
                  setCategorySubCat((prev) => ({
                    ...prev,
                    subCategory: "",
                  }));
                }}
                style={{
                  backgroundColor:
                    categorySubCat?.category === each ? "#f2f2f2" : "#ffffff",
                  borderTop: "0.2px solid lightgray",
                }}
              >
                <AautiText
                  title={each}
                  id={`${each}-category`}
                  textStyle={{
                    color: categorySubCat?.category === each && "#078dda",
                  }}
                />
              </Dropdown.Item>
            );
          })}
        </div>
        <div
          style={{
            width: mobileDevices ? "59%" : "50%",
            backgroundColor: "#f2f2f2",
            height: mobileDevices ? "50vh" : ipodDevices ? "43vh" : "60vh",
            overflow: "scroll",
          }}
        >
          {subCategories.map((item) => {
            return (
              <Dropdown.Item
                onClick={(e) => {
                  // changeCreateObj(item, "subCategory");
                  setCategorySubCat((prev) => ({
                    ...prev,
                    subCategory: item,
                  }));
                  setRefresh(!refresh);
                }}
                style={{
                  backgroundColor:
                    categorySubCat?.subCategory === item ? "#f2f2f2" : "#fff",
                  border: "1px solid #dddddd",
                }}
              >
                <AautiText
                  title={item}
                  id={`${item}-subcategory`}
                  textStyle={{
                    color: categorySubCat?.subCategory === item && "#078dda",
                  }}
                />
              </Dropdown.Item>
            );
          })}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          width: "99%",
          alignItems: "center",
          marginBottom: 8,
        }}
      >
        <button
          className="previewbutt"
          onClick={() => {
            setVisible2(false);
          }}
          style={{
            width: mobileDevices ? "20%" : "14%",
            height: "40px",
            marginLeft: "10px",
            marginTop: "8px",
            borderRadius: "5px",
            padding: mobileDevices ? "5px" : "10px",
          }}
        >
          <AautiText
            size={"semi"}
            title={AautiStrings?.cancelString}
            weight={"normal"}
            textStyle={{ color: "#000000" }}
          />
        </button>
        <JoinButton
          buttonStyle={{
            width: mobileDevices ? "20%" : "14%",
            height: "40px",
            marginRight: "10px",
            marginLeft: "10px",
            marginTop: "8px",
            alignSelf: "flex-end",
          }}
          title={AautiStrings?.DoneString}
          disabled={
            categorySubCat?.category === "" || categorySubCat.subCategory === ""
          }
          onClick={() => {
            if (
              CreateCourseObj.subCategory !== "" &&
              CreateCourseObj.subCategory !== categorySubCat?.subCategory
            ) {
              let text =
                "If you are updating category the prefilled data will be cleared";
              if (window.confirm(text) === true) {
                setCreateCourseObj({
                  ...resetObject,
                  category: categorySubCat?.category,
                  subCategory: categorySubCat.subCategory,
                  searchTags: [
                    categorySubCat?.category,
                    categorySubCat.subCategory,
                  ],
                });
                setCourseOverviewErrors((prev) => ({
                  ...prev,
                  category: "",
                  subCategory: "",
                }));

                setVisible2(false);
              }
            } else {
              setCreateCourseObj((prev) => ({
                ...prev,
                category: categorySubCat?.category,
                subCategory: categorySubCat.subCategory,
                searchTags: [
                  categorySubCat?.category,
                  categorySubCat.subCategory,
                ],
              }));
              setCourseOverviewErrors((prev) => ({
                ...prev,
                category: "",
                subCategory: "",
              }));

              setVisible2(false);
            }
          }}
        />
      </div>
    </Dialog>
  );
};

export default CatDropdown;
