import React from "react";
import defaultImage from "../../assets/images/default_img.png";

const CourseImage = ({ imgUrl, height = "200px", className = "" }) => {
  const encodedUrl = imgUrl;
  return (
    <div
      style={{
        height: height,
        width: "100%",
        // backgroundImage: `url(${encodedUrl})`,
        backgroundSize: "cover",
        // position: "relative",
        borderRadius: 6,
        // borderTopLeftRadius: 6,
        // borderTopRightRadius: 6,
        // borderBottomLeftRadius: 0,
        // borderBottomRightRadius: 0,
      }}
    >
      {/* <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backdropFilter: "blur(10px)",
          borderRadius: 6,
        }}
      ></div> */}
      <img
        className={className}
        alt="related-imgs"
        width="100%"
        src={imgUrl || defaultImage}
        style={{
          width: "100%",
          height: "100%",

          // objectFit: "contain",
          // position: "absolute",
          // top: "50%",
          // left: "50%",
          // transform: "translate(-50%, -50%)",
          borderRadius: "6px",
          borderTopLeftRadius: 6,
          borderTopRightRadius: 6,
          // borderBottomLeftRadius: "0px",
          // borderBottomRightRadius: "0px",
          backgroundSize: "contain",
        }}
      />
    </div>
  );
};

export default CourseImage;
