import React, { memo, useMemo, useState } from "react";
import { ServiceProviderSlider, VectorUnderLineIcon } from "../container";
import { Box, Button } from "@mui/material";
import constants from "../constants.json";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const ServiceproviderSection = () => {
  const [activeSpCategory, setActiveSpCategory] = useState(
    constants?.teacherCategories[0].id
  );
  const navigate = useNavigate();
  const { topServiceProviders, status } = useSelector(
    (state) => state.homePageReducer
  );

  const handleSelectCategory = (id) => {
    setActiveSpCategory(id);
  };

  return (
    <section id="DOSE_OF_INSPIRATION">
      <Box className="dose_of_inspiration_frame">
        <p className="dose_of_inspiration_label">
          Explore the Best <br />
          <span className="dose_of_inspiration_span">Service providers</span>
        </p>
        <Box className="text_underline text_underline2">
          <VectorUnderLineIcon />
        </Box>
      </Box>
      <Box className="filter_courses_container">
        {constants?.teacherCategories.map((section, index) => (
          <Box
            key={index}
            className="filter_course_section"
            sx={{
              backgroundColor:
                activeSpCategory === section?.id ? "#3083EF" : "#ffffff",
              color: activeSpCategory === section?.id ? "#ffffff" : "#000000",
              cursor: "pointer",
            }}
            onClick={() => handleSelectCategory(section?.id)}
          >
            <p className="filter_course_section_label">{section.text}</p>
          </Box>
        ))}
      </Box>
      <Box className="courses_container">
        <p className="courses_container_label">
          Total {topServiceProviders?.length}{" "}
          {topServiceProviders?.length == 1 ? "Finding" : "Findings"}
        </p>
      </Box>
      <Box className="simple_slider_container">
        <ServiceProviderSlider
          activeSpCategory={activeSpCategory}
          {...{ topServiceProviders, status }}
        />
      </Box>
      <Box
        className="view_more_container"
        style={{ display: topServiceProviders?.length <= 6 && "none" }}
      >
        <Button
          variant="contained"
          className="view_more_button"
          onClick={() => {
            navigate("/more-serviceproviders", {
              state: {
                title: "More Service Providers",
                activeSpCategory: activeSpCategory,
              },
            });
          }}
        >
          View More
        </Button>
      </Box>
    </section>
  );
};

export default memo(ServiceproviderSection);
