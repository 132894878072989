import { ApiMethods, ApiPaths } from "../../../service/api-constants";
import { ApiRequest } from "../../../service/api-request";

function deleteNotificationById(id, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.DELETE_NOTIFICATION_BY_ID + "id=" + id,
    method: ApiMethods.DELETE,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

function getSessionsByCourseIdBatchId(
  courseId,
  batchId,
  endUserId,
  onSuccess,
  onFailure
) {
  ApiRequest({
    url:
      ApiPaths.GET_SESSIONBY_COURSEID_BATCHID +
      "courseId=" +
      courseId +
      "&batchId=" +
      batchId +
      "&endUserId=" +
      endUserId,

    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure();
    }
  );
}

function deleteMultipleNotifications(data, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.DELETE_MULTIPLE_NOTIFICATIONS,
    method: ApiMethods.DELETE,
    data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

function deleteAllNotificationsByUserId(userId, onSuccess, onFailure) {
  ApiRequest({
    url: "notification/deleteAllNotificationsByUser",
    method: ApiMethods.DELETE,
    params: { userId },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

function clearNotification(data, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.CLEAR_NOTIFICATION,
    method: ApiMethods.PUT,
    data,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure();
    }
  );
}

function clearAllNotificationsByUser(userId, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.CLEAR_ALL_NOTIFICATIONS + userId,
    method: ApiMethods.PUT,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

function getAllNotificationsByUserId(
  userId,
  offSet,
  count,
  onSuccess,
  onFailure
) {
  ApiRequest({
    url: ApiPaths.GET_ALL_NOTIFICATIONS_USER,
    method: ApiMethods.GET,
    params: { userId, offSet, count },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

function getAllSubscriptionsByUserID(userId, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.GET_ALL_SUBSCRIPTIONS_BY_USER_ID + userId,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

function getCourseDetails(loggedIn, id, endUserId, onSuccess, onFailure) {
  ApiRequest({
    url: "myCourse/getCompleteCourseDetails",
    method: ApiMethods.GET,
    params: loggedIn ? { courseId: id, endUserId } : { courseId: id },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}

function getInviteStatus(data, onSuccess, onFailure) {
  ApiRequest({
    url: "notification/updateUserStatus",
    method: ApiMethods.PUT,
    params: data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}

function getInviteCourse(data, onSuccess, onFailure) {
  ApiRequest({
    url: "invite/updateInviteStatus",
    method: ApiMethods.PUT,
    data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}
function batchReschedule(data, onSuccess, onFailure) {
  ApiRequest({
    url: "rescheduleBatch/acceptOrRejectRescheduleBatch",
    method: ApiMethods.PUT,
    data: data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}
function saveCart(cartData, onSuccess, onFailure) {
  ApiRequest({
    url: "cart/saveCart",
    method: ApiMethods.POST,
    data: cartData,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
const getBatchDetailsByBatchId = (data, onSuccess, onFailure) => {
  const { courseId, batchId } = data;
  ApiRequest({
    url: "reScheduleBatch/getAcceptAndRejectedUsersByBatchId",
    method: ApiMethods.GET,
    params: { courseId, batchId },
  }).then(
    (response) => {
      onSuccess && onSuccess(response?.data);
    },
    (error) => {
      onFailure && onFailure(error);
    }
  );
};
const changeBatchInReject = (data, onSuccess, onFailure) => {
  ApiRequest({
    url: "reScheduleBatch/changeBatch",
    method: ApiMethods.GET,
    params: data,
  }).then(
    (response) => {
      onSuccess && onSuccess(response?.data);
    },
    (error) => {
      onFailure && onFailure(error);
    }
  );
};
const getBatchesByCourseId = (data, onSuccess, onFailure) => {
  ApiRequest({
    url: "myCourse/getBatchesByCourseId",
    method: ApiMethods.GET,
    params: data,
  }).then(
    (response) => {
      onSuccess && onSuccess(response?.data);
    },
    (error) => {
      onFailure && onFailure(error);
    }
  );
};
const getRescheduleRefundAmount = (data, onSuccess, onFailure) => {
  ApiRequest({
    url: "reScheduleBatch/reScheduleRefundAmount",
    method: ApiMethods.GET,
    params: data,
  }).then(
    (response) => {
      onSuccess && onSuccess(response?.data);
    },
    (error) => {
      onFailure && onFailure(error);
    }
  );
};
function refundRequest(refundData, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.REFUND_REQUEST,
    method: ApiMethods.POST,
    data: refundData,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure();
    }
  );
}
function getSessionDetailsById(sessionId, onSuccess, onFailure) {
  ApiRequest({
    url: "session/getSessionDetailsById?sessionId=" + sessionId,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}

function getAdhocClassDetails(id, onSuccess, onFailure) {
  ApiRequest({
    url: "mySchedule/getAdhocClassDetails?id=" + id,
    method: ApiMethods.GET,
  }).then(
    (responce) => {
      onSuccess(responce?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}

function childTransferUpdateStatus(data, onSuccess, onFailure) {
  ApiRequest({
    url: "user/updateChildTransferStatus",
    method: ApiMethods.PUT,
    data: data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}

function moderatorUpdateStatus(data, onSuccess, onFailure) {
  ApiRequest({
    url: "notification/moderatorStatusUpdate?",
    method: ApiMethods.PUT,
    data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      console.log(error, "error");
      onFailure(error);
    }
  );
}

function updateUserInviteStatus(data, onSuccess, onFailure) {
  
  ApiRequest({
    url: "invite/updateUserInviteStatus",
    method: ApiMethods.PUT,
    data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      console.log(error, "error");
      onFailure(error);
    }
  );
}
function getBuyNowData(params, onSuccess, onFailure) {
  ApiRequest({
    url: "cart/getCartsByCourseIdandEndUserId?",
    method: ApiMethods.GET,
    params,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error, "");
    }
  );
}
function getRecentSessionData(data, onSuccess, onFailure) {
  console.log(data);
  ApiRequest({
    url: "session/getRecentSession?",
    method: ApiMethods.GET,
    params: data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}
function updateRejectData(params, onSuccess, onFailure) {
  
  ApiRequest({
    url: "invite/updateCourseInviteStatus",
    method: ApiMethods.PUT,
    params,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}
export const AuthService = {
  deleteNotificationById,
  deleteMultipleNotifications,
  deleteAllNotificationsByUserId,
  clearNotification,
  clearAllNotificationsByUser,
  getAllNotificationsByUserId,
  getAllSubscriptionsByUserID,
  getCourseDetails,
  getInviteStatus,
  getInviteCourse,
  batchReschedule,
  saveCart,
  getBatchDetailsByBatchId,
  changeBatchInReject,
  getBatchesByCourseId,
  getRescheduleRefundAmount,
  refundRequest,
  getSessionDetailsById,
  getSessionsByCourseIdBatchId,
  getAdhocClassDetails,
  updateUserInviteStatus,
  moderatorUpdateStatus,
  getRecentSessionData,
  childTransferUpdateStatus,
  getBuyNowData,
  updateRejectData,
};
