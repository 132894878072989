import React, { useState } from "react";
import AautiTextInput from "../../../globalComponents/AautiTextInput";
import AautiText from "../../../globalComponents/AautiText";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
// import { TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { PriceConversions } from "../../../CommonFunctions";
import { AautiStrings } from "../../../globalComponents/AautiStrings";
import { createCourseStrings } from "../Constants";
import STRINGS from "../../../globalComponents/Strings";
import HelperText from "../../courseCreation/HelperText";
import { showToast } from "../../../globalComponents/Toast";
import { checkNull } from "../../../../utils/Helpers";

const GigPricing = ({
  handleDate,
  gigObject,
  handleChangePricing,
  convertINRtoUSD,
  editGig,
  returnRelatedPricing,
}) => {
  const { convertPriceWithCurrency, onlyPriceConvert, onlyPriceWithCurrency } =
    PriceConversions();
  const {
    discountStartDate,
    discountEndDate,
    gigPrice,
    discountValue,
    localGigPrice,
    discountType,
    localGigDiscountValue,
  } = gigObject;
  const { pricingRates } = useSelector((state) => state.app);

  const convertedPrice = onlyPriceConvert(5).toFixed();

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
      }}
    >
      <div style={{ width: "100%" }}>
        <AautiTextInput
          disable={!isEmpty(editGig?.endUserList)}
          text={createCourseStrings?.price}
          value={localGigPrice}
          onChange={(e) => {
            let value = Number(
              e.target.value.trimStart().replace(/\s+/g, " ") || 0
            );
            handleChangePricing(value || 0, "localGigPrice");
            handleChangePricing(convertINRtoUSD(value) || 0, "gigPrice");
          }}
          style={{ width: "100%" }}
          maxLength={STRINGS.maxPriceConstant}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            marginTop: 5,
          }}
        >
          <HelperText
            title={`minimum price should be ${onlyPriceWithCurrency(
              convertedPrice
            )}`}
            size={"small"}
          />
          <AautiText
            title={`ASP - ${onlyPriceWithCurrency(
              onlyPriceConvert(
                returnRelatedPricing("individualPrice")
              ).toFixed()
            )}`}
            size={"small"}
          />
        </div>
      </div>
      <div style={{ marginTop: 10, width: "100%" }}>
        <AautiText title={createCourseStrings?.discount} weight={"medium"} />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 15,
            marginBottom: 15,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 4,
            }}
          >
            <input
              type="radio"
              checked={discountType === "percentage"}
              id="percentage"
              onChange={() => {
                handleChangePricing(0, "gigDiscountValue");
                handleChangePricing(0, "localGigDiscountValue");
                handleChangePricing("percentage", "discountType");
              }}
            />
            <label htmlFor="percentage" style={{ cursor: "pointer" }}>
              Percentage
            </label>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 4,
            }}
          >
            <input
              type="radio"
              id="amount"
              checked={discountType === "amount"}
              onChange={() => {
                handleChangePricing(0, "gigDiscountValue");
                handleChangePricing(0, "localGigDiscountValue");
                handleChangePricing("amount", "discountType");
              }}
            />
            <label htmlFor="amount" style={{ cursor: "pointer" }}>
              Amount
            </label>
          </div>
        </div>

        <div style={{ width: "100%" }}>
          <AautiTextInput
            text={`Discount ${
              discountType == "percentage" ? "Percentage" : "Amount"
            }`}
            maxLength={discountType == "percentage" && 2}
            style={{ width: "100%" }}
            value={localGigDiscountValue}
            onChange={(e) => {
              let value = Number(
                e.target.value.trimStart().replace(/\s+/g, " ") || 0
              );
              const minValue = localGigPrice;

              const discountedPrice = (minValue * value) / 100;
              const actPriceWithDic = minValue - discountedPrice;

              if (discountType == "percentage") {
                if (actPriceWithDic >= convertedPrice) {
                  handleChangePricing(value || 0, "gigDiscountValue");
                  handleChangePricing(value || 0, "localGigDiscountValue");
                } else {
                  showToast(
                    "info",
                    "Discount price cannot be less than the minimum price"
                  );
                }
              } else {
                if (minValue - value >= Number(convertedPrice)) {
                  handleChangePricing(
                    convertINRtoUSD(value || 0),
                    "gigDiscountValue"
                  );
                  handleChangePricing(value || 0, "localGigDiscountValue");
                } else {
                  let num = (value - (value % 10)) / 10;
                  handleChangePricing(
                    convertINRtoUSD(num || 0),
                    "gigDiscountValue"
                  );
                  handleChangePricing(num || 0, "localGigDiscountValue");
                }
              }
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            gap: 5,
          }}
        >
          <div style={{ width: "100%" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DateField"]}>
                <DemoItem label={AautiStrings?.StartDateString}>
                  <DatePicker
                    defaultValue={dayjs(discountStartDate)}
                    size="small"
                    value={dayjs(discountStartDate)}
                    onChange={(e) => {
                      handleDate(e, "discountStartDate");
                    }}
                    minDate={dayjs(new Date())}
                    disabled={checkNull(localGigDiscountValue)}
                    slotProps={{ field: { readOnly: true } }}
                  />
                </DemoItem>
              </DemoContainer>
            </LocalizationProvider>
          </div>
          <div style={{ width: "100%" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DateField"]}>
                <DemoItem label={AautiStrings?.EndDateString}>
                  <DatePicker
                    defaultValue={dayjs(discountEndDate)}
                    size="small"
                    value={dayjs(discountEndDate)}
                    onChange={(e) => {
                      handleDate(e, "discountEndDate");
                    }}
                    minDate={dayjs(discountStartDate)}
                    disabled={checkNull(localGigDiscountValue)}
                    slotProps={{ field: { readOnly: true } }}
                  />
                </DemoItem>
              </DemoContainer>
            </LocalizationProvider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GigPricing;
