import React, { useEffect, useState } from "react";
import AppContainer from "../../AppContainer";
import AccountTabs from "../../globalComponents/AccountTabs";
import AautiText from "../../globalComponents/AautiText";
import "../Style.scss";
import { AuthService } from "../service";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import EmptyData from "../../globalComponents/EmptyData";
import MembersLoader from "../../members/MembersLoader";
import moment from "moment";
import { noImage } from "../../calendar/constants";
import { Skeleton, Tooltip } from "@mui/material";
import { IoIosInformationCircleOutline } from "react-icons/io";

const tabs = [
  { id: 1, name: "Credits", value: "credits" },
  { id: 2, name: "Refunds", value: "netBanking" },
];
const Refunds = () => {
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("credits");
  const { loggedinUserId, userDetails } = useSelector((state) => state.app);
  const [refundsList, setRefundsList] = useState([]);
  const { mobileDevices } = useSelector((state) => state.responsive);
  useEffect(() => {
    setLoading(true);
    const params = { endUserId: loggedinUserId, refundMethod: activeTab };
    AuthService.getAllRefundRequests(
      params,
      (response) => {
        if (response?.result) {
          setRefundsList(response?.result?.refundList);
        } else {
          setRefundsList([]);
        }
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        console.log(error, "error");
      }
    );
  }, [activeTab]);

  return (
    <AppContainer>
      <AccountTabs
        {...{
          tabObj: {
            label: "Refunds",
            navigation: "/refunds",
            active: true,
          },
        }}
      />
      <div className="tabs-flex">
        {tabs?.map((each, index) => (
          <button
            className={`tab-refund ${
              activeTab == each?.value && "active-class"
            }`}
            key={index}
            onClick={() => {
              setActiveTab(each?.value);
            }}
          >
            <AautiText title={each?.name} />
          </button>
        ))}
      </div>
      <div className="list-main">
        {loading ? (
          <div className="tabs-flex ">
            {[1, 2, 3, 4, 5, 6]?.map((each, index) => (
              <Skeleton
                key={index}
                variant="rounded"
                width={mobileDevices ? "100%" : 350}
                height={mobileDevices ? 80 : 100}
              />
            ))}
          </div>
        ) : isEmpty(refundsList) ? (
          <EmptyData
            showExplore={false}
            text={
              activeTab === "credits" ? "No Credits Yet..." : "No Refunds Yet.."
            }
          />
        ) : (
          <>
            {activeTab === "credits" &&
              userDetails?.transactionCountrySymbol && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <IoIosInformationCircleOutline size={16} />
                  <AautiText
                    size={"semi"}
                    title={`1 Credit equals to ${userDetails?.transactionCountrySymbol}1`}
                    textStyle={{ margin: "4px 0px 4px 2px" }}
                  />
                </div>
              )}
            <AautiText
              weight={"bold"}
              title={`Transaction History`}
              textStyle={{ margin: "5px 0px 5px 0px" }}
            />
            <div className="refund-main">
              {refundsList?.map((each, index) => (
                <div className="refund-card" key={index}>
                  <img
                    src={
                      each?.courseImage ||
                      each?.gigImage ||
                      each?.adhocName ||
                      noImage
                    }
                    className="refund-img2"
                    alt="refund-img"
                  />
                  <div className="refund-right-div ">
                    <div className="price-cont-New">
                      <Tooltip
                        title={each?.courseName || each?.adhocName || "N/A"}
                      >
                        <AautiText
                          title={
                            each?.courseName ||
                            each?.gigName ||
                            each?.adhocName ||
                            "N/A"
                          }
                          textStyle={{
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            WebkitLineClamp: 1,
                            textOverflow: "ellipsis",
                            maxWidth: "80%",
                          }}
                        />
                      </Tooltip>
                      <AautiText
                        title={`${
                          (activeTab === "credits"
                            ? userDetails?.transactionCountrySymbol
                            : each?.currencySymbol) +
                          (activeTab === "credits"
                            ? each?.EUTransactionAmount?.toFixed(2)
                            : each?.localPurchasedPrice?.toFixed(2) || 0)
                        }`}
                        weight={"medium"}
                        size={"semi"}
                      />
                    </div>
                    <AautiText
                      textStyle={{ marginTop: 5 }}
                      size={"semi"}
                      title={`Ref ID: ${each?.orderId}`}
                    />
                    <div className="price-cont-New">
                      <AautiText
                        size={"semi"}
                        title={moment(each?.createdAt).format(
                          "DD MMM YYYY hh:mm A"
                        )}
                      />
                      <AautiText
                        title={
                          (each?.refundAmount < 0 ? "-" : "+") +
                          (activeTab === "credits"
                            ? userDetails?.transactionCountrySymbol
                            : each?.currencySymbol) +
                          (Math.abs(each?.refundAmount).toFixed(2) || 0)
                        }
                        weight={"medium"}
                        size={"semi"}
                        textStyle={{
                          color: each?.refundAmount < 0 ? "#dc3545" : "#3acc6c",
                        }}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </AppContainer>
  );
};

export default Refunds;
