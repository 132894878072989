import React from "react";
import {
  getImageType,
  isSameUser,
  isSameDay,
  isTextMessage,
  getMessageOptions,
} from "../../../utils";
import { MessageText } from "./MessageText";
import { Avatar } from "@mui/material";
import AautiText from "../../../../globalComponents/AautiText";
import { messageTypes } from "../../../constants";
import { Time } from "./Time";
import MessageOptionsMenu from "./MessageOptions";
import MessageImage from "../../../components/messages/image";
import MessageVideo from "../../../components/messages/video";
import MessageAudio from "../../../components/messages/audio";
import { MessageReply } from "../../../components/messages/reply";
import PollCard from "../../../../calendar/join_class/pollCard";
import AgendaCard from "../../../../calendar/join_class/agendaCard";
import AssignmentCard from "../../../../calendar/join_class/assignmentCard";
import AskCard from "../../../../calendar/join_class/askCard";
import MessageFile from "../../../components/messages/file";
import QuizCard from "../../../../calendar/join_class/quizCard";
import TaskCard from "../../../../calendar/join_class/taskCard";

const style = {
  left: {
    wrapper: {
      display: "flex",
      backgroundColor: "#ffffff",
      // marginRight: 60,
      minHeight: 20,
      justifyContent: "flex-start",
      borderRadius: 5,
      boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
      border: "solid 1px #e6ebff",
      overflow: "hidden",
      padding:2,
    },
    containerToNext: {
      borderTopLeftRadius: 0,
      marginBottom: 20,
    },
    containerToPrevious: {
      borderTopLeftRadius: 0,
      marginBottom: 20,
    },
  },
  right: {
    wrapper: {
      display: "flex",
      backgroundColor: "#cce1fc",
      minHeight: 20,
      // marginLeft: 60,
      justifyContent: "flex-start",
      borderRadius: 5,
      overflow: "hidden",
      padding:2,
    },
    containerToNext: {
      borderTopRightRadius: 0,
      marginBottom: 10,
    },
    containerToPrevious: {
      borderTopRightRadius: 0,
      marginBottom: 10,
    },
  },
  content: {
    username: {
      top: -3,
      left: 0,
      fontSize: 12,
      backgroundColor: "transparent",
      color: "#aaa",
    },
    usernameView: {
      flexDirection: "row",
    },
  },
};
const DEFAULT_OPTION_TITLES = ["Copy Text", "Cancel"];

class Bubble extends React.Component {
  constructor() {
    super(...arguments);

    this.state = {
      visible: false,
      submitted: false,
    };

    const { currentMessage, position } = this.props;
    this.feedComponent = this.renderFeedCards(currentMessage);

    // if (!this.feedComponent) {
    const isTextMsg = isTextMessage(currentMessage);
    this.menuOptions = getMessageOptions(position, isTextMsg);
  }

  toggleMessageOptions = (title = undefined) => {
    this.setState((prev) => ({ visible: !prev.visible }));
    if (!!title) this.props?.onPressMenuItem(title, this.props.currentMessage);
  };

  updateFileUrl = (file) => {
    let timerId;
    timerId = setTimeout(() => {
      //   this.props.dispatch(updateFileProps(file))
      clearTimeout(timerId);
    }, 400);
  };

  // previewFile = () => {
  //   const files = this.props.currentMessage.files;
  //   switch (this.props.currentMessage.type) {
  //     case messageTypes.IMAGE:
  //       this.updateFileUrl(files);
  //       break;
  //     case messageTypes.DOCUMENT:
  //       const fileExt = getFileExtension(files[0]);
  //       const isPDFFile = fileExt === fileExtensions.pdf;
  //       if (isPDFFile) {
  //         this.updateFileUrl(files[0]);
  //       } else {
  //         showToast("info", "Unable to preview file. Please try again");
  //       }
  //       break;

  //     default:
  //       this.updateFileUrl(files[0]);
  //       break;
  //   }
  // };

  styledBubbleToNext() {
    const { currentMessage, nextMessage, position } = this.props;
    if (
      currentMessage &&
      nextMessage &&
      position &&
      isSameUser(currentMessage, nextMessage) &&
      isSameDay(currentMessage, nextMessage)
    ) {
      return style[position].containerToNext;
    }
    return null;
  }
  styledBubbleToPrevious() {
    const { currentMessage, previousMessage, position } = this.props;
    if (
      currentMessage &&
      previousMessage &&
      position &&
      isSameUser(currentMessage, previousMessage) &&
      isSameDay(currentMessage, previousMessage)
    ) {
      return style[position].containerToPrevious;
    }
    return null;
  }

  renderMessageText() {
    if (this.props.currentMessage && this.props.currentMessage.text) {
      return (
        <MessageText
          {...{
            ...this.props,
          }}
        />
      );
    }
    return null;
  }

  renderMessageImage() {
    if (this.props.currentMessage?.files?.length) {
      return <MessageImage {...this.props} />;
    }
    return null;
  }
  renderMessageVideo() {
    if (this.props.currentMessage?.files?.length) {
      return <MessageVideo {...this.props} />;
    }
    return null;
  }

  renderMessageAudio() {
    if (this.props.currentMessage?.files?.length) {
      return <MessageAudio {...this.props} />;
    }
    return null;
  }

  renderMessageMedia() {
    if (this.props.currentMessage?.files?.length) {
      return <MessageFile {...this.props} />;
    }
    return null;
  }

  renderMessageReply() {
    return <MessageReply {...this.props} />;
  }

  renderTime() {
    if (this.props.currentMessage && this.props.currentMessage.createdAt) {
      return <Time {...this.props.currentMessage} />;
    }
    return null;
  }

  renderUsername() {
    const { currentMessage, sender } = this.props;
    if (sender && currentMessage.sender._id === sender._id) {
      return null;
    }
    return (
      <AautiText
        size={"small"}
        title={`${currentMessage.sender.displayName}`}
      />
    );
  }

  renderAvatar = () => {
    const { currentMessage, sender, position } = this.props;
    if (sender && currentMessage.sender._id === sender._id) {
      return null;
    }

    return (
      <div
        style={position === "left" ? { marginRight: 10 } : { marginLeft: 10 }}
      >
        <Avatar
          alt={currentMessage?.sender?.displayName ?? ""}
          src={currentMessage?.sender?.profileImage ?? ""}
          sx={{ width: 25, height: 25 }}
        />
      </div>
    );
  };

  renderMessageAgenda() {
    const { currentMessage, UserRole } = this.props;
    return (
      <AgendaCard
        {...{
          cardDetails: {
            ...currentMessage,
            displayName: currentMessage?.sender?.displayName,
          },
          UserRole,
          screen: "feed",
        }}
      />
    );
  }

  renderMessageAsk() {
    const { currentMessage, UserRole } = this.props;
    return (
      <AskCard
        {...{
          cardDetails: {
            ...currentMessage,
            displayName: currentMessage?.sender?.displayName,
          },
          UserRole,
          screen: "feed",
        }}
      />
    );
  }
  renderMessageAssignment() {
    const { currentMessage, callBackFun, isEndUser, UserRole } = this.props;
    return (
      <AssignmentCard
        {...{
          cardDetails: {
            ...currentMessage,
            displayName: currentMessage?.sender?.displayName,
          },
          UserRole,
          screen: "feed",
        }}
      />
    );
  }

  renderMessageTask() {
    const { currentMessage, callBackFun, isEndUser, UserRole } = this.props;

    return (
      <TaskCard
        {...{
          cardDetails: {
            ...currentMessage,
            displayName: currentMessage?.sender?.displayName,
          },
          UserRole,
          screen: "feed",
        }}
      />
    );
  }

  renderMessageQuiz() {
    const { currentMessage, callBackFun, isEndUser, UserRole } = this.props;
    return (
      <QuizCard
        {...{
          cardDetails: {
            ...currentMessage,
            displayName: currentMessage?.sender?.displayName,
          },
          UserRole,
          screen: "feed",
        }}
      />
    );
  }

  renderMessagePOll() {
    const { currentMessage, UserRole } = this.props;
    return (
      <PollCard
        {...{
          cardDetails: {
            ...currentMessage,
            displayName: currentMessage?.sender?.displayName,
          },
          UserRole,
          screen: "feed",
        }}
      />
    );
  }

  renderBubbleContent(message) {
    if (!message?.type) return null;
    switch (message?.type) {
      case messageTypes.IMAGE:
        return this.renderMessageImage();
      case messageTypes.AUDIO:
        return this.renderMessageAudio();
      case messageTypes.VIDEO:
        return this.renderMessageVideo();
      case messageTypes.REPLY:
        return this.renderMessageReply();
      case messageTypes.TEXT:
        return this.renderMessageText();
      case messageTypes.OTHER:
      case messageTypes.DOCUMENT:
        return this.renderMessageMedia();
      default:
        return null;
    }
  }

  renderFeedCards(message) {
    if (!message?.type) return null;
    switch (message?.type?.toUpperCase()) {
      case messageTypes.AGENDA:
        return this.renderMessageAgenda();
      case messageTypes.ASK:
        return this.renderMessageAsk();
      case messageTypes.TASK:
        return this.renderMessageTask();
      case messageTypes.ASSIGNMENT:
        return this.renderMessageAssignment();
      case messageTypes.POLL:
        return this.renderMessagePOll();
      case messageTypes.QUIZ:
        return this.renderMessageQuiz();
      default:
        return null;
    }
  }

  renderMessageOptions(id, position) {
    return (
      <div className={`message_options ${position}`}>
        {this.state.visible && (
          <MessageOptionsMenu
            id={`${id}-messages-options-menu`}
            {...{
              options: this.menuOptions,
              onPressMenuItem: this.toggleMessageOptions,
            }}
          />
        )}
      </div>
    );
  }

  render() {
    const { position, currentMessage } = this.props;

    if (!currentMessage?.type) return null;

    const justifyContentStyle = {
      justifyContent: position === "right" ? "flex-end" : "flex-start",
    };

    return this.feedComponent ? (
      <div style={{ width: "100%" }}>{this.feedComponent}</div>
    ) : (
      <div
        className="bubble_container"
        style={{...justifyContentStyle,alignItems: position==="right"?"flex-end": "flex-start"}}
        onMouseEnter={this.toggleMessageOptions}
        onMouseLeave={this.toggleMessageOptions}
      >
        {position === "left" &&
          this.renderMessageOptions(currentMessage._id, position)}
        {position === "left" &&
          this.props.renderUsernameOnMessage &&
          this.renderAvatar()}
        <div className="bubble_content">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              ...justifyContentStyle,
              alignItems: "baseline",
              columnGap: 5,
            }}
          >
            {this.props.renderUsernameOnMessage && this.renderUsername()}
            {this.renderTime()}
          </div>

          <div style={{ display: "flex", flexDirection: "row",...justifyContentStyle, }}>
            <div
              style={{
                ...style[position].wrapper,
                ...justifyContentStyle,
                ...this.styledBubbleToNext(),
                ...this.styledBubbleToPrevious(),
              }}
            >
              {this.renderBubbleContent(this.props.currentMessage)}
            </div>
          </div>
        </div>
        {position === "right" &&
          this.renderMessageOptions(currentMessage._id, position)}
      </div>
    );
  }
}

export default Bubble;
