import AppContainer from "../../AppContainer";
import AautiText from "../../globalComponents/AautiText";
import AccountTabs from "../../globalComponents/AccountTabs";
import { useDispatch } from "react-redux";
import { useCourseAdhocCont } from "./container";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import CourseAdhocCard from "./CourseAdhocCard";
import { Skeleton } from "@mui/material";

const CourseAdhocEvent = () => {
  const { courseList, onPressDelete, loadingData } = useCourseAdhocCont();

  return (
    <AppContainer>
      <AccountTabs
        {...{
          tabObj: {
            label: "Course Adhoc Events",
            navigation: "/course-adhoc-events",
            active: true,
          },
        }}
      />
      {/* <AautiText
        title={"Course Adhoc Events"}
        weight={"bold"}
        textStyle={{ marginBottom: "15px" }}
        size={"medium"}
      /> */}
      <div
        style={{
          // height: "85vh",
          border: "1px solid #E0E0E0",
          borderRadius: 4,
          padding: 10,
          width: "100%",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            gap: 12,
            flexWrap: "wrap",
            overflow: "scroll",
            // height: "100%",
          }}
        >
          {loadingData ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                flexWrap: "wrap",
              }}
            >
              {[1, 2, 3, 4, 5].map((each) => (
                <div>
                  <Skeleton
                    variant="rectangular"
                    width={350}
                    height={200}
                    sx={{ borderRadius: 5, margin: 2 }}
                  />
                </div>
              ))}
            </div>
          ) : courseList.length > 0 ? (
            courseList.map((each, index) => (
              <CourseAdhocCard
                each={each}
                onPressDelete={onPressDelete}
                key={"courseAdhocCard" + index}
              />
            ))
          ) : (
            <div
              style={{
                height: "75vh",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <AautiText title={"No Course Adhocs Yet"} />
            </div>
          )}
        </div>
      </div>
    </AppContainer>
  );
};

export default CourseAdhocEvent;
