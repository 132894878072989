import React, { } from "react";
import { MessageText } from "../../../chat_messages/custom_messages/messages_container/MessageText";
import {  Box, Button, IconButton, Slider } from "@mui/material";
import { CloudDownload, Pause, PlayArrow } from "@mui/icons-material";
import { useAudioController } from "./audioController";
import FileInfo from '../../file_info'
import { StyledMsgContainer } from "../../styled_components";
function MessageAudio({ currentMessage, position, reply = false, searchText }) {
const { files, ...rest } = currentMessage;
const {_id:fileId,type,name,uri} = files[0]
const { audioRef, isPlaying, togglePlay,secondsElapsed } = useAudioController({
  mimeType:type,
});

  return (
    <StyledMsgContainer >
        <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between',width:'100%'}}>
        <audio ref={audioRef}
          style={{display:'none'}}
           preload='metadata'
          >
          <source {...{ src: uri, type}}/>
        </audio>
        <IconButton onClick={togglePlay} sx={{width:40}}>
          {isPlaying?<Pause/>:<PlayArrow/>}
        </IconButton>
        <div style={{flexGrow:1,margin:2}}>
        <Slider size="small" value={secondsElapsed} max={audioRef?.current?.duration} aria-label="Default" valueLabelDisplay="auto"
          
        />
         </div>
      </div>
      <FileInfo
        key={fileId}
        file={files[0]}
      />
      {currentMessage.text && (<MessageText
        {...{
          currentMessage: rest,
          position,
          reply,
          searchText,
        }}
      />)}
     </StyledMsgContainer>
   );
}

export default MessageAudio;
