import React, { useEffect, useState } from "react";
import { AiFillStar } from "react-icons/ai";
import JoinButton from "../../globalComponents/JoinButton";
import "./Style.scss";
import AautiText from "../../globalComponents/AautiText";
import { AuthService } from "../Service";
import { showToast } from "../../globalComponents/Toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import WishIcon from "../../../assets/images/wishlist.svg";
import { Divider, IconButton, Tooltip } from "@mui/material";
import ShareIcon from "../../globalComponents/icons/ShareIcon";
import { PackageStrings } from "../Constants";
import { isEmpty } from "lodash";
import { useDispatch } from "react-redux";
import { toggleSignInPop } from "../../../redux/reducer/appReducer";
import { CapitalizeFirstLetter } from "../../CommonFunctions";

import default_user_avatar from "../../homeScreen/images/default_user_avatar.png";
const TopProvidersCard = ({
  each,
  index,
  wishlistScreen,
  getSPdata,
  cardwidth = "269px",
}) => {
  const dispatch = useDispatch();
  const { mobileDevices, ipodDevices, aboveLargeMobileDevices, miniLapys } =
    useSelector((state) => state.responsive);
  const navigate = useNavigate();
  const { loggedinUserId, isUserLoggedIn, userDetails } = useSelector(
    (state) => state.app
  );
  const [isHovered, setIsHovered] = useState(false);
  const [cardItem, setCardItem] = useState(each);
  const { innerWidth: windowInnerWidth } = window;
  const path = window.location;
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    setCardItem(each);
  }, [each]);

  const onFailure = (error) => {
    showToast(PackageStrings?.error, PackageStrings?.apiAlert);
  };
  const onSuccessDeleteList = (response) => {
    if (response.status === PackageStrings?.success) {
      // showToast(PackageStrings?.successSmall, PackageStrings?.unFollowed);
      if (wishlistScreen === PackageStrings?.favourite) {
        getSPdata();
      }
      setCardItem((prevs) => ({
        ...prevs,
        isWishlisted: !cardItem?.isWishlisted,
      }));
    } else {
      showToast(PackageStrings?.error, response?.message);
    }
  };
  const deleteListAPI = (each) => {
    const wishListData = {
      serviceProviderId: each?._id,
      name: each?.displayName,
      description: PackageStrings?.nodesc,
      endUserId: loggedinUserId,
    };
    AuthService.deleteWishlistedServiceProvider(
      wishListData,
      onSuccessDeleteList,
      onFailure
    );
  };
  const onSuccessWishList = (response) => {
    if (response.status === PackageStrings?.success) {
      // showToast(PackageStrings?.successSmall, PackageStrings?.following);
      setCardItem((prevs) => ({
        ...prevs,
        isWishlisted: !cardItem?.isWishlisted,
      }));
    } else {
      showToast(PackageStrings?.error, response?.message);
    }
  };
  const wishListAPI = (each) => {
    const wishListData = {
      serviceProviderId: each?._id,
      name: each?.displayName,
      description: PackageStrings?.wishListSmall,
      endUserId: loggedinUserId,
    };
    AuthService.wishlistServiceProvider(
      wishListData,
      onSuccessWishList,
      onFailure
    );
  };

  const defaultWidth = cardwidth;
  const shareUrl = window.location.origin + `/sp-profile/${each?._id}`;

  return (
    <>
      {wishlistScreen === PackageStrings?.aautiPool ? (
        <div
          id="home-topServiceprovider"
          key={index}
          style={{
            width: mobileDevices ? "98%" : cardwidth,
          }}
          className="TSPmainCard"
          onClick={() => {
            if (mobileDevices) {
              navigate(`/sp-profile/${cardItem?._id}`);
            }
          }}
        >
          <img
            className="cardmobileStyle"
            style={{ objectFit: "cover" }}
            alt={PackageStrings?.altText}
            src={
              cardItem?.profileImage
                ? cardItem?.profileImage
                : PackageStrings?.altImage2
            }
          />
          <div className="cardsecondDiv">
            {mobileDevices ? (
              <div className="TOPnewstyles">
                <div className="TOPnewstylesdiv2">
                  <div className="topProvider-div2">
                    <div className="TOPnewstylesdiv3">
                      <img
                        alt={PackageStrings?.altText}
                        src={cardItem?.countryFlag}
                        className="TOPimagediv"
                      />
                      <AautiText
                        size={"normal"}
                        weight={"extraBold"}
                        className={"TPdispynameText"}
                        title={CapitalizeFirstLetter(cardItem?.displayName)}
                      />
                    </div>
                    <AautiText
                      size={"semi"}
                      weight={"normal"}
                      className={"TPskillname"}
                      title={
                        cardItem?.skillsetName
                          ? cardItem?.skillsetName
                          : PackageStrings?.notAvailable
                      }
                    />
                  </div>
                  <div className="TPshareDiv">
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      className="TSPheartDiv"
                    >
                      <ShareIcon
                        height="25px"
                        width="25px"
                        shareUrl={shareUrl}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      />
                    </div>
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        if (isEmpty(userDetails)) {
                          // dispatch(toggleSignInPop(true));
                          navigate("/login");
                        }
                      }}
                      className="TSPheartDiv"
                    >
                      <img
                        src={WishIcon}
                        alt={PackageStrings?.altText}
                        className="TSimageNewStyle"
                      />
                    </div>
                  </div>
                </div>
                <div className="TSPhoverdiv1">
                  <div className="TSPhoverdiv">
                    <div className="TSPhoverdiv3">
                      <AautiText
                        size={"semi"}
                        weight={"extraBold"}
                        title={cardItem?.coursesCount || 0}
                      />
                      <AautiText
                        size={"semi"}
                        weight={"normal"}
                        className={"Tsp-text3"}
                        title={
                          cardItem?.coursesCount == 1
                            ? "Course"
                            : PackageStrings?.Courses
                        }
                      />
                    </div>
                    <div className="TSPhoverdiv4">
                      <AautiText
                        size={"semi"}
                        weight={"extraBold"}
                        title={
                          cardItem?.subscribersCount ||
                          cardItem?.subscriptionsCount ||
                          0
                        }
                      />
                      <AautiText
                        size={"semi"}
                        weight={"normal"}
                        className={"Tsp-text3"}
                        title={
                          (cardItem?.subscribersCount ||
                            cardItem?.subscriptionsCount) == 1
                            ? "Student"
                            : PackageStrings?.students
                        }
                      />
                    </div>
                    <div className="TSPhoverdiv3">
                      <div className="TSAiFillStar">
                        <AiFillStar
                          size={mobileDevices ? 17 : 20}
                          className="Tsp-icon1"
                        />
                        <AautiText
                          size={"semi"}
                          weight={"extraBold"}
                          title={`${cardItem?.averageRating.toFixed(
                            1
                          )} (${Math.abs(cardItem?.ratingCount)})`}
                        />
                      </div>
                      <AautiText
                        size={"semi"}
                        weight={"normal"}
                        className={"Tsp-text1"}
                        title={PackageStrings?.ratings}
                      />
                    </div>
                  </div>
                  {!mobileDevices && (
                    <JoinButton
                      onClick={() => {
                        navigate(`/sp-profile/${cardItem?._id}`);
                      }}
                      title={PackageStrings?.knowMore}
                      className="Tsp-button1"
                    />
                  )}
                </div>
              </div>
            ) : (
              <div className="TPshareDiv2">
                <IconButton
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  className="TSPheartDiv"
                  sx={{
                    backgroundColor: "#fff",
                    p: 1,
                    "&:hover": { background: "#fff" },
                  }}
                >
                  <ShareIcon
                    shareUrl={shareUrl}
                    // onClick={(e) => {
                    //   e.stopPropagation();
                    // }}
                  />
                </IconButton>
                <Tooltip title={PackageStrings?.wishListSmall}>
                  <IconButton
                    onClick={() => {
                      if (isEmpty(userDetails)) {
                        navigate("/login");
                      }
                    }}
                    className="TSPheartDiv"
                    sx={{
                      backgroundColor: "#fff",
                      p: 1,
                      "&:hover": { background: "#fff" },
                    }}
                  >
                    <img
                      src={WishIcon}
                      alt={PackageStrings?.altText}
                      className="TSimageNewStyle2"
                    />
                  </IconButton>
                </Tooltip>
              </div>
            )}
            <div
              className="TSPsecondDiv"
              onMouseEnter={() => handleMouseEnter()}
              onMouseLeave={() => handleMouseLeave()}
            >
              <div className="Tsp-div2">
                <div className="TOPnewstylesdiv3">
                  <img
                    alt={PackageStrings?.altText}
                    src={cardItem?.countryFlag}
                    className="TOPimagediv2"
                  />
                  <AautiText
                    size={"normal"}
                    weight={"extraBold"}
                    className={"Tsp-text2"}
                    title={cardItem?.displayName}
                  />
                </div>
                <AautiText
                  size={"semi"}
                  weight={"normal"}
                  className={"TOPtextstyle"}
                  title={
                    cardItem?.skillsetName
                      ? cardItem?.skillsetName
                      : PackageStrings?.notAvailable
                  }
                />
              </div>
              {isHovered && (
                <div className="TSPhoverdiv1">
                  <div className="TSPhoverdiv">
                    <div className="TSPhoverdiv3">
                      <AautiText
                        size={"semi"}
                        weight={"extraBold"}
                        title={cardItem?.coursesCount || 0}
                      />
                      <AautiText
                        size={"semi"}
                        weight={"normal"}
                        className={"Tsp-text1"}
                        title={
                          cardItem?.coursesCount == 1
                            ? "Course"
                            : PackageStrings?.Courses
                        }
                      />
                    </div>
                    <div className="TSPhoverdiv4">
                      <AautiText
                        size={"semi"}
                        weight={"extraBold"}
                        title={
                          cardItem?.subscribersCount ||
                          cardItem?.subscriptionsCount ||
                          0
                        }
                      />
                      <AautiText
                        size={"semi"}
                        weight={"normal"}
                        className={"Tsp-text1"}
                        title={
                          (cardItem?.subscribersCount ||
                            cardItem?.subscriptionsCount) == 1
                            ? "Student"
                            : PackageStrings?.students
                        }
                      />
                    </div>
                    <div className="TSPhoverdiv3">
                      <div className="TSAiFillStarNew">
                        <AiFillStar
                          size={mobileDevices ? 17 : 20}
                          className="Tsp-icon1"
                          style={{ marginRight: 5 }}
                        />
                        <AautiText
                          size={"semi"}
                          weight={"extraBold"}
                          title={`${cardItem?.averageRating.toFixed(
                            1
                          )} (${Math.abs(cardItem?.ratingCount)})`}
                        />
                      </div>
                      <AautiText
                        size={"semi"}
                        weight={"normal"}
                        className={"Tsp-text1"}
                        title={PackageStrings?.ratings}
                      />
                    </div>
                  </div>
                  {!mobileDevices && (
                    <JoinButton
                      onClick={() => {
                        navigate(`/sp-profile/${cardItem?._id}`);
                      }}
                      title={PackageStrings?.knowMore}
                      className="Tsp-button1"
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div
          key={index}
          style={{
            width: defaultWidth,
            minWidth: defaultWidth,
            maxWidth: defaultWidth,
            maxHeight: "250px",
          }}
          className="TSPmainCardNew"
          id="sp-card"
          onClick={() => {
            navigate(`/sp-profile/${cardItem?._id}`);
          }}
        >
          <div className="TPnewcardDiv">
            <img
              style={{ objectFit: "cover" }}
              className={
                cardItem?.profileImage
                  ? "cardmobileStyleNew"
                  : "default-image-view"
              }
              alt={PackageStrings?.altText}
              src={
                cardItem?.profileImage
                  ? cardItem?.profileImage
                  : default_user_avatar
              }
            />
            <div className="Tsp-div3">
              {/* {each?.averageRating ? ( */}
              <div className="Tsp-div4">
                <AiFillStar
                  className="Tsp-icon2"
                  size={mobileDevices ? 12 : 13}
                />
                <AautiText
                  size={"small"}
                  weight={"normal"}
                  className={"Tsp-text4"}
                  title={
                    each?.averageRating
                      ? `${
                          cardItem?.averageRating?.toFixed(1) +
                          " " +
                          PackageStrings?.ratings
                        }`
                      : PackageStrings?.new
                  }
                />
              </div>
              {/* ) : (
                <div className="Tsp-div4">
                  <AiFillStar
                    className="Tsp-icon2"
                    size={mobileDevices ? 12 : 13}
                  />
                  <AautiText
                    size={"small"}
                    weight={"normal"}
                    className={"Tsp-text4"}
                    title={PackageStrings?.new}
                  />
                </div>
              )} */}
            </div>
            <div className="cardsecondDivNew">
              <AautiText
                size={"semi"}
                weight={"extraBold"}
                className={"TPdispynameText"}
                title={cardItem?.displayName}
              />
              <AautiText
                size={"small"}
                weight={"normal"}
                className={"TPskillname"}
                title={
                  cardItem?.skillsetName
                    ? cardItem?.skillsetName
                    : PackageStrings?.notAvailable
                }
              />
              <div className="TSPhoverdiv">
                <div className="TSPhoverdiv2">
                  <AautiText
                    size={"semi"}
                    weight={"extraBold"}
                    className={"Tsp-text5"}
                    title={cardItem?.coursesCount || 0}
                  />
                  <AautiText
                    size={"small"}
                    weight={"normal"}
                    className={"Tsp-text1"}
                    title={
                      cardItem?.coursesCount == 1
                        ? "Course"
                        : PackageStrings?.Courses
                    }
                  />
                </div>
                <Divider
                  variant="middle"
                  orientation="vertical"
                  sx={{
                    marginLeft: "5px",
                    marginRight: "8px",
                    color: "black",
                    borderWidth: "1px",
                  }}
                />
                <div className="TSPhoverdiv2">
                  <AautiText
                    size={"semi"}
                    weight={"extraBold"}
                    title={
                      cardItem?.subscribersCount ||
                      cardItem?.subscriptionsCount ||
                      0
                    }
                  />
                  <AautiText
                    size={"small"}
                    weight={"normal"}
                    className={"Tsp-text6"}
                    title={
                      (cardItem?.subscribersCount ||
                        cardItem?.subscriptionsCount) == 1
                        ? "Student"
                        : PackageStrings?.students
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className="TPhighDiv"
            style={{
              justifyContent: !isEmpty(cardItem?.tags)
                ? "space-between"
                : "flex-end",
            }}
          >
            {!isEmpty(cardItem?.tags) && (
              <div className="TPhighTextdiv">
                <AautiText size={"semi"} title={cardItem?.tags?.[0]} />
              </div>
            )}
            <div className="TPgapDiv">
              {wishlistScreen === PackageStrings?.favourite ? (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteListAPI(cardItem);
                    getSPdata();
                  }}
                  title={PackageStrings?.following}
                  className="Tsp-button2"
                >
                  <AautiText
                    size={"semi"}
                    title={PackageStrings?.following}
                    className={"Tsp-text4"}
                  />
                </button>
              ) : (
                <>
                  {cardItem?.isWishlisted ? (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        if (isUserLoggedIn) {
                          deleteListAPI(cardItem);
                        } else {
                          if (isEmpty(userDetails)) {
                            navigate("/login");
                          }
                        }
                      }}
                      className="TPFollowTextDiv"
                    >
                      <AautiText
                        size={"semi"}
                        title={PackageStrings?.following}
                        className={"Tsp-text4"}
                      />
                    </button>
                  ) : (
                    <button
                      // disabled={loggedinUserId === cardItem?._id}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (isUserLoggedIn) {
                          wishListAPI(cardItem);
                        } else if (!isUserLoggedIn) {
                          if (isEmpty(userDetails)) {
                            navigate("/login");
                          }
                        }
                      }}
                      className="TPFollowTextDiv"
                    >
                      <AautiText
                        size={"semi"}
                        title={PackageStrings?.follow}
                        className={"Tsp-text4"}
                      />
                    </button>
                  )}
                </>
              )}
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <ShareIcon
                  shareUrl={shareUrl}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TopProvidersCard;
