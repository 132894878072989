import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import timeSvg from "./assets/time.svg";
import createCourseSvg from "./assets/create_course.svg";
import createLessonSvg from "./assets/create_lessonp.svg";
import createAdhocSvg from "./assets/create_adhoc.svg";
import myOrdersSvg from "./assets/my_orders.png";
import profileSvg from "./assets/profile.svg";
import AautiText from "../../globalComponents/AautiText";
import VerificationPng from "./assets/verification_png.svg";
import "./styles.scss";
import moment from "moment";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  CloneCourseObj,
  editCourseObject,
  editGigObject,
  setJitsiFullScreenMode,
  setJwtToken,
  setRoomName,
} from "../../../redux/reducer/appReducer";
import { AuthService } from "../../../service";
import { showToast } from "../../globalComponents/Toast";
import { checkNull, pluralOrSingular } from "../../../utils/Helpers";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    minWidth: 300,
  },
  // "& .MuiDialogActions-root": {
  //   padding: theme.spacing(1),
  // },
}));

const TodoListDialog = ({
  toggleOpenTodoDialog,
  openTodoDialog,
  navigateToTodoOptions,
  todayEvents,
  toDoList,
}) => {
  const {
    myLessonPlansCount = 0,
    gigsCount = 0,
    draftGigsCount = 0,
    coursesCount = 0,
    draftCoursesCount = 0,
    adhocClassesCount = 0,
  } = toDoList ?? {};

  const { UserRole, profilePercentage, loggedinUserId, userDetails } =
    useSelector((state) => state.app);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { mobileDevices } = useSelector((state) => state.responsive);

  const renderEvents = (item, index) => {
    const checkButtonStatus = () => {
      const startDateTime = moment(item?.startTime);
      const endDateTime = moment(item?.endTime);
      if (!startDateTime.isValid() || !endDateTime.isValid()) {
        return false;
      }
      const startWindow = startDateTime.clone().subtract(15, "minutes");
      if (
        moment().isBetween(startWindow, endDateTime) &&
        !isEmpty(item?.usersList)
      ) {
        return true;
      } else {
        return false;
      }
    };

    const handleAttendanceService = () => {
      const isEndUser =
        userDetails.role.length === 1 && userDetails.role[0] === "endUser";
      const data = {
        serviceProviderId: item?.serviceProviderId,
        sessionId: item?._id,
        courseId: item?.courseId,
      };
      if (item?.instituteId) {
        data.instituteId = item?.instituteId;
      }
      if (isEndUser) {
        data.endUserId = loggedinUserId;
      }
      AuthService.saveAttendance(data, onSuccessAttendance, OnFailure);
    };

    const onSuccessAttendance = (response) => {
      if (response.status === "Success" && response.result !== null) {
        showToast("success", "Attendance saved successfully");
      }
    };

    const OnFailure = (error) => {
      showToast("error", error?.message);
    };

    const handleJoin = () => {
      handleAttendanceService();
      const room = `${
        item.courseName.replace(/ +/g, "") ?? item?.scheduleType ?? ""
      }-${item?._id}`;

      let isModerator = UserRole?.includes("serviceprovider");
      const data = {
        room: room,
        userId: loggedinUserId,
        isSp: isModerator && loggedinUserId === item?.serviceProviderId,
      };

      AuthService.generateJWT(
        data,
        (response) => {
          if (response?.result) {
            dispatch(setJwtToken(response?.result));
            localStorage.setItem("jwtToken", response?.result);
            localStorage.setItem("room", room);
            const path = window.location.origin;
            const strWindowFeatures = "location=yes,scrollbars=yes,status=yes";

            const joinClassPath = `${path}/join_class/aautimeet`;
            window.open(joinClassPath, "_blank", strWindowFeatures);
            // dispatch(setJitsiFullScreenMode(true));
            dispatch(setRoomName(room));
          }
        },
        (error) => {
          console.log(error, "error");
        }
      );
    };
    return (
      <div
        className="todo-option-item"
        key={index}
        onClick={() => {
          item?.scheduleType != "offTime" &&
            navigate("/join_class", { state: item });
          toggleOpenTodoDialog();
        }}
      >
        <div className="todo-option-item-first">
          <img src={createCourseSvg} alt="course" className="todo-item-image" />
          <div className="todo-option-item-text-con">
            <AautiText title={item?.courseName} className="todo-label-text" />
            <AautiText
              title={`Batch : ${item?.batchName}`}
              size={"semi"}
              textStyle={{
                display:
                  (item?.scheduleType == "offTime" ||
                    item?.scheduleType == "adhoc") &&
                  "none",
              }}
            />
            <AautiText
              title={
                moment(item?.startTime).format("hh:mm A") +
                " to " +
                moment(item?.endTime).format("hh:mm A")
              }
              className="todo-sublabel-text"
            />
          </div>
        </div>
        <button
          className="todo-btn-course"
          onClick={(e) => {
            e.stopPropagation();
            // navigateToTodoOptions("/my-schedule");
            handleJoin();
            toggleOpenTodoDialog();
          }}
          disabled={!checkButtonStatus()}
          style={{
            backgroundColor: !checkButtonStatus() && "lightGrey",
            cursor: checkButtonStatus() && "pointer",
          }}
        >
          Join
        </button>
      </div>
    );
  };
  const userApproved = userDetails?.approvalStatus == "approved";
  return (
    <BootstrapDialog
      onClose={toggleOpenTodoDialog}
      aria-labelledby="customized-dialog-title"
      open={openTodoDialog}
      className="todo-dialog"
    >
      <AautiText title={" To-Do List"} className={"todo-list-head1"} />
      <IconButton
        aria-label="close"
        onClick={toggleOpenTodoDialog}
        sx={(theme) => ({
          position: "absolute",
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <div className="todo-option-items-container">
          {UserRole !== "endUser" ? (
            <>
              <div className="todo-option-item">
                <div className="todo-option-item-first">
                  <img
                    src={VerificationPng}
                    alt="time"
                    className="todo-item-image"
                  />
                  <div className="todo-option-item-text-con">
                    <AautiText
                      title={"Verification Status"}
                      className="todo-label-text"
                    />
                    {/* <AautiText
                      title={"Your verification is in pending"}
                      className="todo-sublabel-text"
                    /> */}
                  </div>
                </div>
                <AautiText
                  title={userApproved ? "Approved" : "Pending"}
                  textStyle={{ color: userApproved ? "green" : "#FFA551" }}
                />
              </div>
              <div className="todo-option-item">
                <div className="todo-option-item-first">
                  <img src={timeSvg} alt="time" className="todo-item-image" />
                  <div className="todo-option-item-text-con">
                    <AautiText
                      title={"Set your Availability"}
                      className="todo-label-text"
                    />
                    <AautiText
                      title={"6 AM - 10 PM (Default)"}
                      className="todo-sublabel-text"
                    />
                  </div>
                </div>
                <button
                  className="todo-btn"
                  onClick={() => navigateToTodoOptions("/my-schedule")}
                >
                  Set now
                </button>
              </div>

              <div className="todo-option-item">
                <div className="todo-option-item-first">
                  <img
                    src={createCourseSvg}
                    alt="course"
                    className="todo-item-image"
                  />
                  <div className="todo-option-item-text-con">
                    <AautiText title={"Courses"} className="todo-label-text" />
                    <AautiText
                      title={`${
                        checkNull(coursesCount?.toString()) ? 0 : coursesCount
                      } ${pluralOrSingular(coursesCount, "Course")} ${
                        !checkNull(draftCoursesCount?.toString())
                          ? `| ${draftCoursesCount} ${pluralOrSingular(
                              draftCoursesCount,
                              "Draft"
                            )}`
                          : ""
                      }`}
                      className="todo-sublabel-text"
                    />
                  </div>
                </div>
                <button
                  className="todo-btn"
                  onClick={() => navigateToTodoOptions("/course-creation")}
                >
                  Create
                </button>
              </div>

              <div className="todo-option-item">
                <div className="todo-option-item-first">
                  <img
                    src={createCourseSvg}
                    alt="course"
                    className="todo-item-image"
                  />
                  <div className="todo-option-item-text-con">
                    <AautiText title={"Gigs"} className="todo-label-text" />
                    <AautiText
                      title={`${
                        checkNull(gigsCount?.toString()) ? 0 : gigsCount
                      } ${pluralOrSingular(gigsCount, "Gig")} 
                        ${
                          !checkNull(draftGigsCount?.toString())
                            ? `| ${draftGigsCount} ${pluralOrSingular(
                                draftGigsCount,
                                "Draft"
                              )}`
                            : ""
                        }`}
                      className="todo-sublabel-text"
                    />
                  </div>
                </div>
                <button
                  className="todo-btn"
                  onClick={() => {
                    navigate("/course-creation ", {
                      replace: true,
                      state: "Gig",
                    });
                    dispatch(editCourseObject(null));
                    dispatch(CloneCourseObj(null));
                    dispatch(editGigObject(null));
                  }}
                >
                  Create
                </button>
              </div>

              <div className="todo-option-item">
                <div className="todo-option-item-first">
                  <img
                    src={createLessonSvg}
                    alt="lesson"
                    className="todo-item-image"
                  />
                  <div className="todo-option-item-text-con">
                    <AautiText
                      title={"Lesson Plan"}
                      className="todo-label-text"
                    />
                    <AautiText
                      title={`${
                        checkNull(myLessonPlansCount?.toString())
                          ? 0
                          : myLessonPlansCount
                      } ${pluralOrSingular(myLessonPlansCount, "Lesson Plan")}`}
                      className="todo-sublabel-text"
                    />
                  </div>
                </div>
                <button
                  className="todo-btn"
                  onClick={() => navigateToTodoOptions("/create-lesson-plan")}
                >
                  Create
                </button>
              </div>

              <div className="todo-option-item">
                <div className="todo-option-item-first">
                  <img
                    src={createAdhocSvg}
                    alt="adhoc"
                    className="todo-item-image"
                  />
                  <div className="todo-option-item-text-con">
                    <AautiText
                      title={"Adhoc Class"}
                      className="todo-label-text"
                    />
                    <AautiText
                      title={`${
                        checkNull(adhocClassesCount?.toString())
                          ? 0
                          : adhocClassesCount
                      } ${pluralOrSingular(adhocClassesCount, "Class", true)}`}
                      className="todo-sublabel-text"
                    />
                  </div>
                </div>
                <button
                  className="todo-btn"
                  onClick={() => navigateToTodoOptions("/AdhocEvent")}
                >
                  Create
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="todo-option-item">
                <div className="todo-option-item-first">
                  <img
                    src={createCourseSvg}
                    alt="time"
                    className="todo-item-image"
                  />
                  <div className="todo-option-item-text-con">
                    <AautiText
                      title={"My Learnings"}
                      className="todo-label-text"
                    />
                    {/* <AautiText
                      title={"6 AM - 10 PM (Default)"}
                      className="todo-sublabel-text"
                    /> */}
                  </div>
                </div>
                <button
                  className="todo-btn"
                  onClick={() => navigateToTodoOptions("/my-learnings")}
                >
                  View
                </button>
              </div>
              <div className="todo-option-item">
                <div className="todo-option-item-first">
                  <img
                    src={myOrdersSvg}
                    alt="time"
                    className="todo-item-image"
                  />
                  <div className="todo-option-item-text-con">
                    <AautiText
                      title={"My Orders"}
                      className="todo-label-text"
                    />
                    {/* <AautiText
                      title={"6 AM - 10 PM (Default)"}
                      className="todo-sublabel-text"
                    /> */}
                  </div>
                </div>
                <button
                  className="todo-btn"
                  onClick={() => navigateToTodoOptions("/orders")}
                >
                  View
                </button>
              </div>
            </>
          )}

          <div className="todo-option-item">
            <div className="todo-option-item-first">
              <img src={profileSvg} alt="time" className="todo-item-image" />
              <div
                className="todo-option-item-text-con"
                style={{ maxWidth: mobileDevices && "150px" }}
              >
                <AautiText
                  title={"Complete Your Profile"}
                  style={{ maxWidth: mobileDevices && "150px !important" }}
                  className="todo-label-text"
                />
                <AautiText
                  title={`${profilePercentage || 0}% Completed`}
                  className="todo-sublabel-text"
                />
              </div>
            </div>
            <button
              className="todo-btn"
              onClick={() => navigateToTodoOptions("/profile")}
            >
              Finish
            </button>
          </div>
        </div>
        {todayEvents?.length > 0 && (
          <div className="todo-courses-container">
            <AautiText
              title={"Classes to Join"}
              className={"todo-list-head2"}
            />
            <div className="classes-join-section">
              {todayEvents?.map((item, index) => renderEvents(item, index))}
            </div>
          </div>
        )}
      </DialogContent>
    </BootstrapDialog>
  );
};

export default TodoListDialog;
