import React, { useRef, memo, useState, useCallback, useEffect } from "react";
import { ArrowDropDown, Cancel } from "@mui/icons-material";
import "./styles.scss";
import Message from "./Message";
import { Divider } from "@mui/material";
import SearchBar from "../../../components/searchBar";
import { messageTypes } from "../../../constants";
import { CiCircleChevDown, CiCircleChevUp } from "react-icons/ci";

function MessagesContainer({
  forwardRef,
  messages,
  showSearchBar,
  toggleSearchBarVisibility,
  sender,
  updatePageNo,
  deleteMsgIds,
  ...rest
}) {
  const [showScrollButton, setShowScrollButton] = useState(false);
  const listRef = useRef([]);
  const [highlightedIndex, setHigHlightedIndex] = useState(0);
  const [searchInput, setSearchInput] = useState('');
  const highlightedIndices =  messages.reduce((result, msg, i) => {
    if (searchInput.length>1 &&
      [messageTypes.TEXT, messageTypes.REPLY].includes(msg.type) &&
      msg?.text?.toLowerCase().includes(searchInput?.toLowerCase())
    ) {
      result.push(i);
    }
    return result;
  }, []);


  const handleSearchScroll = (direction) => {
    setHigHlightedIndex((prevIndex) => {
      const newIndex = prevIndex + direction;
      if (newIndex < 0 || newIndex >= highlightedIndices.length)
        return prevIndex;
      return newIndex;
    });
  };

  useEffect(() => {
    if (highlightedIndices.length && listRef.current[highlightedIndices[highlightedIndex]]) {
      listRef.current[highlightedIndices[highlightedIndex]]?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [highlightedIndex, highlightedIndices]);

  const onCloseSearchBar = ()=>{
    setSearchInput('')
    setHigHlightedIndex(0);
    toggleSearchBarVisibility()
  }

  const onChangeSearchInput = (e)=>{
    setSearchInput(e.target.value)
  }

  const scrollToBottom = () =>
    forwardRef.current.scrollTo({ top: -Infinity, behavior: "smooth" });

  function renderScrollToBottomWrapper() {
    return (
      <button className={"scroll_btn"} onClick={scrollToBottom}>
        <ArrowDropDown />
      </button>
    );
  }

  const handleScroll = () => {
    const offset = 20;
    const container = forwardRef.current;
    const height =
      container.scrollHeight - container.clientHeight + container.scrollTop;
    if (height < offset) updatePageNo();
    if (container.scrollTop === 0) setShowScrollButton(false);
    if (
      container.scrollTop !== 0 &&
      container.scrollTop + (container.clientHeight - offset) <= 0
    ) {
      setShowScrollButton(true);
    } else {
      setShowScrollButton(false);
    }
  };
  const renderMessage = useCallback((message, index) => {
    if (!message.sender) {
      message.sender = { _id: 0 };
    }
    if (messages && sender) {
      const previousMessage = messages[index + 1] || {};
      const nextMessage = messages[index - 1] || {};
      const isSelected = deleteMsgIds?.includes(message._id);
      const messageProps = {
        ...rest,
        sender,
        currentMessage: { ...message },
        previousMessage,
        nextMessage,
        position: message.sender._id === sender._id ? "right" : "left",
        isSelected,
        searchText: searchInput?.length > 0?searchInput:undefined,
        highlighted:(highlightedIndices?.[highlightedIndex] === index)||((highlightedIndices.length === 1 || highlightedIndex === 0)&& index === 0)
      };
      return (
        <Message
          ref={(ele) => (listRef.current[index] = ele)}
          key={message._id}
          {...messageProps}
        />
      );
    }
    return null;
  },[highlightedIndex,highlightedIndices]);
  return (
    <div className="messages_wrapper">
      {showSearchBar && (
        <div className="div_wrapper searchbar">
          <SearchBar
            {...{
              value: searchInput,
              showBackIcon: false,
              onChangeSearchInput,
              onPressClear: () => setSearchInput(""),
            }}
          />
           <Divider orientation="vertical" variant="middle" flexItem />
           <div style={{width:80,display:'flex',alignItems:'center',justifyContent:'center'}}>
            <span>
              {highlightedIndex+(highlightedIndices.length?1:0)} / {highlightedIndices.length}
            </span>
          </div>
          <Divider orientation="vertical" variant="middle" flexItem />
          <div className="div_wrapper">
              <CiCircleChevUp size={24} onClick={() => handleSearchScroll(1)}/>
              <CiCircleChevDown size={24} onClick={() => handleSearchScroll(-1)}/>
          </div>
          <Divider orientation="vertical" variant="middle" flexItem />
          <Cancel onClick={onCloseSearchBar} />
        </div>
      )}
      <div
        ref={forwardRef}
        className="messages_container column_reverse_div"
        onScroll={handleScroll}
      >
        {showScrollButton && renderScrollToBottomWrapper()}
        <ul className="column_reverse_div">{messages.map(renderMessage)}</ul>
      </div>
    </div>
  );
}

export default memo(MessagesContainer);
