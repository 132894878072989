import React from "react";
import { useSelector } from "react-redux";
import "@fontsource/poppins";

const AautiText = ({
  size,
  textStyle,
  title,
  weight,
  textAlign,
  onClick,
  className,
  Latin,
  textId,
  required = false,
  id = title,
}) => {
  const {
    desktop,
    lapy,
    miniLapys,
    ipodDevices,
    aboveLargeMobileDevices,
    mobileDevices,
  } = useSelector((state) => state.responsive);

  const sizes = {
    jumbo: desktop
      ? 55
      : lapy
      ? 50
      : miniLapys
      ? 45
      : ipodDevices
      ? 30
      : aboveLargeMobileDevices
      ? 24
      : mobileDevices
      ? 20
      : 20,
    normaljumbo: desktop
      ? 45
      : lapy
      ? 40
      : miniLapys
      ? 36
      : ipodDevices
      ? 28
      : aboveLargeMobileDevices
      ? 24
      : mobileDevices
      ? 29
      : 19,
    largeSize: desktop
      ? 40
      : lapy
      ? 36
      : miniLapys
      ? 28
      : ipodDevices
      ? 24
      : aboveLargeMobileDevices
      ? 22
      : mobileDevices
      ? 20
      : 20,
    bigSize: desktop
      ? 32
      : lapy
      ? 28
      : miniLapys
      ? 24
      : ipodDevices
      ? 22
      : aboveLargeMobileDevices
      ? 18
      : mobileDevices
      ? 18
      : 18,
    big: desktop
      ? 26
      : lapy
      ? 26
      : miniLapys
      ? 25
      : ipodDevices
      ? 22
      : aboveLargeMobileDevices
      ? 20
      : mobileDevices
      ? 17
      : 17,
    large: desktop
      ? 22
      : lapy
      ? 22
      : miniLapys
      ? 21
      : ipodDevices
      ? 19
      : aboveLargeMobileDevices
      ? 18
      : mobileDevices
      ? 16
      : 16,
    extraMedium: desktop
      ? 20
      : lapy
      ? 20
      : miniLapys
      ? 20
      : ipodDevices
      ? 18
      : aboveLargeMobileDevices
      ? 18
      : mobileDevices
      ? 15
      : 15,
    medium: desktop
      ? 18
      : lapy
      ? 18
      : miniLapys
      ? 18
      : ipodDevices
      ? 17
      : aboveLargeMobileDevices
      ? 16
      : mobileDevices
      ? 14
      : 14,
    normal: desktop
      ? 16
      : lapy
      ? 16
      : miniLapys
      ? 16
      : ipodDevices
      ? 15
      : aboveLargeMobileDevices
      ? 15
      : mobileDevices
      ? 14
      : 14,
    semi: desktop
      ? 14
      : lapy
      ? 14
      : miniLapys
      ? 12
      : ipodDevices
      ? 12
      : aboveLargeMobileDevices
      ? 12
      : mobileDevices
      ? 12
      : 14,
    small: desktop
      ? 12
      : lapy
      ? 12
      : miniLapys
      ? 12
      : ipodDevices
      ? 12
      : aboveLargeMobileDevices
      ? 11
      : mobileDevices
      ? 10
      : 11,
  };

  const boldText = {
    extraBold: 700,
    bold: 600,
    medium: 500,
    normal: 400,
    auto: 300,
  };

  const defaultStyle = {
    fontSize: sizes[size] || sizes.normal,
    fontWeight: boldText[weight] || boldText.medium,
    textAlign: textAlign,
    fontFamily: Latin ? "roboto" : "Poppins",
    wordWrap: "break-word",

    ...textStyle,
  };
  return (
    <p style={defaultStyle} onClick={onClick} className={className} id={id}>
      {title}
      {required && <span style={{ color: "red" }}>*</span>}
    </p>
  );
};

export default AautiText;
