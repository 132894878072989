import moment from 'moment'
import * as React from 'react'
import {  isSameDay, isSamePublishedDay } from '../../../utils'
import AautiText from '../../../../globalComponents/AautiText'
const styles = {
  container: {
    display:'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 5,
    marginBottom: 10,
  },
  text: {
    color: '#000',
    fontSize:10,
    fontWeight: 400,
  },
}
export function Day({
  dateFormat = 'll',
  currentMessage,
  previousMessage,
}) {
  if (currentMessage == null || isSameDay(currentMessage, previousMessage)) {
    return null
  }

  if (
    currentMessage == null ||
    isSamePublishedDay(currentMessage, previousMessage)
  ) {
    return null
  }
  const isToday = moment().isSame(moment(currentMessage.createdAt), 'date')
  return (
    <div style={styles.container}>
        <p style={styles.text}>
          {isToday
            ? 'Today'
            : moment(currentMessage.createdAt).format(dateFormat)}
        </p>
    </div>
  )
}
