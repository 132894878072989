import React from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Style.scss";
import AautiText from "../globalComponents/AautiText";
import AnimateButton from "../../utils/AnimateButton";
import { IoMdTime } from "react-icons/io";
import moment from "moment";
import Avatar from "@mui/material/Avatar";
import { useNavigate } from "react-router-dom";
import {
  setJitsiFullScreenMode,
  setJwtToken,
  setRoomName,
  // setShowTodaysEvents,
} from "../../redux/reducer/appReducer";
import { AuthService } from "../../service";
import { showToast } from "../globalComponents/Toast";
import { isEmpty } from "lodash";
import { cardTitles } from "./Constants";

const EventCard = ({ event, key, userDetails, UserRole, loggedinUserId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleAttendanceService = () => {
    const isEndUser =
      userDetails.role.length === 1 && userDetails.role[0] === "endUser";
    const data = {
      serviceProviderId: event?.serviceProviderId,
      sessionId: event?._id,
      courseId: event?.courseId,
    };
    if (event?.instituteId) {
      data.instituteId = event?.instituteId;
    }
    if (isEndUser) {
      data.endUserId = loggedinUserId;
    }
    AuthService.saveAttendance(data, onSuccessAttendance, OnFailure);
  };

  const onSuccessAttendance = (response) => {
    if (response.status === "Success" && response.result !== null) {
      showToast("success", "Attendance saved successfully");
    }
  };

  const OnFailure = (error) => {
    showToast("error", error?.message);
  };

  const handleJoin = () => {
    handleAttendanceService();
    const room = `${
      event.courseName.replace(/ +/g, "") ?? event?.scheduleType ?? ""
    }-${event?._id}`;

    let isModerator = UserRole?.includes("serviceprovider");
    const data = {
      room: room,
      userId: loggedinUserId,
      isSp: isModerator && loggedinUserId === event?.serviceProviderId,
    };

    AuthService.generateJWT(
      data,
      (response) => {
        if (response?.result) {
          dispatch(setJwtToken(response?.result));
          localStorage.setItem("jwtToken", response?.result);
          localStorage.setItem("room", room);
          const path = window.location.origin;
          const strWindowFeatures = "location=yes,scrollbars=yes,status=yes";

          const joinClassPath = `${path}/join_class/aautimeet`;
          window.open(joinClassPath, "_blank", strWindowFeatures);
          // dispatch(setJitsiFullScreenMode(true));
          dispatch(setRoomName(room));
        }
      },
      (error) => {
        console.log(error, "error");
      }
    );
  };

  const checkButtonStatus = () => {
    const startDateTime = moment(event?.startTime);
    const endDateTime = moment(event?.endTime);
    if (!startDateTime.isValid() || !endDateTime.isValid()) {
      return false;
    }
    const startWindow = startDateTime.clone().subtract(15, "minutes");
    if (
      moment().isBetween(startWindow, endDateTime) &&
      !isEmpty(event?.usersList)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const btnValidation = !checkButtonStatus();

  return (
    <div
      key={key}
      className="today-event-card"
      onClick={() => {
        event?.scheduleType != "offTime" &&
          navigate("/join_class", { state: event });
      }}
    >
      <Avatar src={event?.courseImage} />

      <div style={{ marginLeft: 10 }}>
        <AautiText title={event?.courseName} />
        <AautiText
          title={`Batch : ${event?.batchName}`}
          size={"semi"}
          textStyle={{
            display:
              (event?.scheduleType == "offTime" ||
                event?.scheduleType == "adhoc") &&
              "none",
          }}
        />
        <div className="time-container">
          <IoMdTime style={{ marginRight: 7, color: "#000000" }} />
          <AautiText
            size={"small"}
            title={
              moment(event?.startTime).format("hh:mm A") +
              " to " +
              moment(event?.endTime).format("hh:mm A")
            }
          />
        </div>
        {event?.scheduleType == "offTime" ? (
          <AautiText
            title="Offtime"
            size={"semi"}
            textStyle={{ color: "#3083EF" }}
          />
        ) : (
          <AnimateButton>
            <button
              onClick={(e) => {
                e.stopPropagation();
                // dispatch(setShowTodaysEvents(false));
                handleJoin();
              }}
              disabled={btnValidation}
              className="course_button"
              style={{
                backgroundColor: btnValidation && "lightGrey",
                cursor: !btnValidation && "pointer",
              }}
            >
              Join
            </button>
          </AnimateButton>
        )}
      </div>
    </div>
  );
};

const TodaysEvents = () => {
  const { todayEvents, userDetails, UserRole, loggedinUserId } = useSelector(
    (state) => state.app
  );

  return (
    <div className="day-events-cards">
      <AautiText
        title={
          todayEvents?.length == 1
            ? cardTitles?.upcomingSession
            : cardTitles?.upcomingSessions
        }
      />
      {todayEvents?.map((each, index) => {
        return (
          <EventCard
            event={each}
            key={index}
            {...{ userDetails, loggedinUserId, UserRole }}
          />
        );
      })}
    </div>
  );
};

export default TodaysEvents;
