import React from "react";
import AautiText from "../../globalComponents/AautiText";
import { emptyMessagesList } from "../animations";
import { chatConstants, lottiePlayerStyles } from "../constants";
import Lottie from "react-lottie-player";

function EmptyMessages({ id = "empty_messages_list", text }) {
  const textStyle = {
    color: "#1E1E1E",
    textAlign: "center",
  }
  return (
    <div
      id={id}
      className="empty_div"
    >
      <div className="empty_div">
      <Lottie
        loop
        play
        animationData={emptyMessagesList}
        style={lottiePlayerStyles}
      />
      <AautiText
        weight={"medium"}
        title={text}
        size={"normal"}
        textAlign={"center"}
        textStyle={textStyle}
      />
      <AautiText
        weight={"medium"}
        title={chatConstants.startNewConversation}
        size={"normal"}
        textStyle={textStyle}
      />
      </div>
    </div>
  );
}

export default EmptyMessages;
