import { Drawer, IconButton, Tooltip } from "@mui/material";
import React from "react";
import { RxCrossCircled } from "react-icons/rx";
import { useSelector } from "react-redux";
import AautiText from "./AautiText";

const BottomSheet = ({ onClose, open, children, dialogTitle = "" }) => {
  const { mobileDevices, aboveLargeMobileDevices, ipodDevices } = useSelector(
    (state) => state.responsive
  );
  return (
    <Drawer
      anchor={
        mobileDevices || aboveLargeMobileDevices || ipodDevices
          ? "bottom"
          : "right"
      }
      sx={{
        zIndex: 502,
        position: "relative",
        "& .MuiDrawer-paper": {
          borderRadius:
            mobileDevices || aboveLargeMobileDevices || ipodDevices
              ? "10px 10px 0px 0px"
              : "10px 0px 0px 10px",
          minHeight:
            mobileDevices || aboveLargeMobileDevices || ipodDevices
              ? "80vh"
              : "100vh",
          maxHeight:
            mobileDevices || aboveLargeMobileDevices || ipodDevices
              ? "80vh"
              : "100vh",
          minWidth:
            mobileDevices || aboveLargeMobileDevices || ipodDevices
              ? "100vw"
              : "40vw",
          maxWidth:
            mobileDevices || aboveLargeMobileDevices || ipodDevices
              ? "100vw"
              : "40vw",
          padding: 2,
          paddingTop: 3,
        },
      }}
      open={open}
      onClose={(e) => {
        // e.stopPropagation();
        onClose(e);
      }}
    >
      <Tooltip title={"Close"}>
        <IconButton
          sx={{ position: "absolute", top: -2, right: 0 }}
          onClick={() => {
            onClose();
          }}
        >
          <RxCrossCircled />
        </IconButton>
      </Tooltip>
      {/* <AautiText title={dialogTitle} size={"normal"} /> */}
      {children}
    </Drawer>
  );
};

export default BottomSheet;
