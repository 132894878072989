// import { ApiMethods, ApiPaths } from "../../service/api-constants";
import { ApiRequest } from "../../service";
import { ApiMethods, ApiPaths } from "../../service/api-constants";

/** login authentication
 * @param username, password
 */

const SAVE_FEEDBACK = "feedback/saveFeedback";

export function getAllStudentsBySPId(serviceProviderId, onSuccess, onFailure) {
  ApiRequest({
    url: "attendance/getServiceproviderStudentsList",
    method: ApiMethods.GET,
    params: { serviceProviderId },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}
function complaintSave(complaintData, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.POST_COMPLAINT_SAVE,
    method: ApiMethods.POST,
    data: complaintData,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure();
    }
  );
}
function getReports(type, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.GET_REPORTS + `?type=${type}`,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function saveFeedback(feedbackData, onSuccess, onFailure) {
  ApiRequest({
    url: SAVE_FEEDBACK,
    method: ApiMethods.POST,
    data: feedbackData,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure();
    }
  );
}
function GetuserDetailsById(id, onSuccess, onFailure) {
  ApiRequest({
    url: "user/getUserById",
    method: ApiMethods.GET,
    params: { id },
  }).then(
    (response) => {
      onSuccess(response.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export const getSpRatingsRatings = (
  url,
  serviceProviderId,
  onSuccess,
  onFailure
) => {
  ApiRequest({
    url: url,
    method: ApiMethods.GET,
    params: { serviceProviderId },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
};

export const AuthService = {
  getReports,
  complaintSave,
  saveFeedback,
  GetuserDetailsById,
};
