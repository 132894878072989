import moment from "moment";
import {
  acceptedImageTypes,
  chatConstants,
  ChatRoomAccess,
  DocumentMimeTypes,
  messageTypes,
} from "./constants";
import { LuReply } from "react-icons/lu";
import { FaRegTrashAlt } from "react-icons/fa";
import { ContentCopy, CreateOutlined } from "@mui/icons-material";
export function isSameDay(currentMessage, diffMessage) {
  if (!diffMessage || !diffMessage.createdAt) {
    return false;
  }
  const currentCreatedAt = moment(currentMessage.createdAt);
  const diffCreatedAt = moment(diffMessage.createdAt);
  if (!currentCreatedAt.isValid() || !diffCreatedAt.isValid()) {
    return false;
  }
  return currentCreatedAt.isSame(diffCreatedAt, "day");
}

export function isSamePublishedDay(currentMessage, diffMessage) {
  if (!diffMessage || !diffMessage.startDate) {
    return false;
  }
  const currentCreatedAt = currentMessage?.startDate
    ? moment(currentMessage.startDate)
    : moment(currentMessage.createdAt);
  const diffCreatedAt = diffMessage?.startDate
    ? moment(diffMessage.startDate)
    : moment(diffMessage.createdAt);
  if (!currentCreatedAt.isValid() || !diffCreatedAt.isValid()) {
    return false;
  }
  return currentCreatedAt.isSame(diffCreatedAt, "day");
}

export function isSameUser(currentMessage, diffMessage) {
  return !!(
    diffMessage &&
    diffMessage.sender &&
    currentMessage.sender &&
    (diffMessage.sender._id === currentMessage.sender._id ||
      currentMessage.sender === diffMessage.sender)
  );
}

export function isTextMessage(currentMessage) {
  return !!(
    currentMessage.type === messageTypes.TEXT ||
    currentMessage.type === messageTypes.REPLY
  );
}

export const idGenerator = () => {
  return "xxxxxxxxxxxx4xxxyxxxxxxx".replace(/[xy]/g, (c) => {
    let r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const getFileExtension = (fileURL) => {
  if (fileURL) {
    return null;
  } else {
    fileURL?.uri?.split(".")?.pop()?.toLowerCase();
  }
};
export const getImageType = (file) => {
  const urlExtension = getUrlExtension(file);
  if (acceptedImageTypes.includes(urlExtension)) {
    return "image";
  } else if (urlExtension === "mp4" || urlExtension === "MP4") {
    return "video";
  } else if (urlExtension === "pdf") {
    return "pdf";
  } else {
    return "document";
  }
};

export const getUrlExtension = (url) => {
  return url?.split?.(/[#?]/)[0].split(".").pop().trim();
};

export function getFeedsAccessString(feedsAccess) {
  return feedsAccess === ChatRoomAccess.ONLY_ME ||
    feedsAccess === chatConstants.onlyMe
    ? chatConstants.onlyMe
    : chatConstants.everyOne;
}

export const getMessageTypeByMime = (mimeType) => {
  if (mimeType.includes("audio")) return messageTypes.AUDIO;
  if (mimeType.includes("video")) return messageTypes.VIDEO;
  if (mimeType.includes("image")) return messageTypes.IMAGE;
  if (DocumentMimeTypes.includes(mimeType)) return messageTypes.DOCUMENT;
  return messageTypes.OTHER;
};

export const getMessageOptions = (position, isTextMsg) => {
  let menuOptions = [
    {
      icon: <LuReply size={20} />,
      label: chatConstants.reply,
    },
  ];
  if (isTextMsg) {
    menuOptions.push({
      icon: <ContentCopy fontSize="small" />,
      label: chatConstants.copy,
    });
  }
  if (position === "right") {
    menuOptions = [
      {
        icon: <CreateOutlined fontSize="small" />,
        label: chatConstants.edit,
      },
      ...menuOptions,
      {
        icon: <FaRegTrashAlt fontSize="small" />,
        label: chatConstants.delete,
      },
    ];
  }
  return menuOptions;
};

export const convertBase64ToFile = (base64,fileName)=>{
  const byteString = atob(base64.split(',')[1]);
  const mimeString = base64.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new File([ab],fileName, { type: mimeString });
}