import { IconButton, Tooltip } from "@mui/material";
import React from "react";

import Icon from "../../../assets/images/Share.svg";
import { RWebShare } from "react-web-share";

const ShareIcon = ({
  height = "20px",
  width = "20px",
  shareUrl,
  icon = Icon,
  id = "share-icon",
}) => {
  const url = shareUrl || window.location.origin;
  return (
    <div>
      <RWebShare
        onClick={(e) => {
          e.stopPropagation();
        }}
        data={{
          text: "Aauti Marketplace",
          url: url,
          title: "Aauti",
        }}
      >
        <Tooltip title="Share">
          <IconButton
            // onClick={(e) => {
            //   e.stopPropagation();
            // }}
            id={id}
            sx={{
              height: height,
              width: width,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "row",
              //   backgroundColor: "#fff",
              p: 0,
              //   "&:hover": { background: "#fff" },
            }}
          >
            <img
              src={icon}
              alt="share"
              style={{
                height: "100%",
                width: "100%",
                zIndex: 100,
              }}
            />
          </IconButton>
        </Tooltip>
      </RWebShare>
    </div>
  );
};

export default ShareIcon;
