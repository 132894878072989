import React, { useState, useMemo, useCallback, useEffect, memo } from "react";
import AautiText from "../../globalComponents/AautiText";
import { FaArrowLeft } from "react-icons/fa6";
import AautiAvatar from "../components/AautiAvatar";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  addAdmin,
  addModerator,
  blockUser,
  getActiveChatRoom,
  getActiveChatRoomMembers,
  leaveRoom,
  removeAdmin,
  removeModerator,
  setActiveRoom,
  unblockUser,
} from "../../../redux/reducer/chatSlice";
import { Button, List, ListItem, Tooltip } from "@mui/material";
import ChatAccessCard from "../components/ChatAccess";
import SearchBar from "../components/searchBar";
import { chatConstants, conversationTypes, SocketEvents } from "../constants";
import { FiPlus } from "react-icons/fi";
import { SearchRounded } from "@mui/icons-material";
import ChatMenu from "../components/ChatMenu";
import { showToast } from "../../globalComponents/Toast";
import EmptyData from "../../globalComponents/EmptyData";

function ChatRoomInfo({
  id = "",
  loggedInUserId,
  onCloseRoomInfo,
  toggleAddUserDialog = () => {},
  toggleEditProfileDialog = () => {},
  toggleReportDialog = () => {},
  showBackIcon = true,
}) {
  const activeRoom = useSelector(getActiveChatRoom);
  const members = useSelector(getActiveChatRoomMembers);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [searchInput, setSearchInput] = useState(undefined);

  const {
    user,
    type: roomType,
    name = "",
    avatar = "",
    description,
    _id: roomId = "",
    access,
    isCancelled,
    moderators = [],
    admins = [],
    blockedUsers = [],
  } = activeRoom;

  const isGroupAdmin = admins?.includes(loggedInUserId);
  const userLeftChat = user?.exitDate ? true : false;
  const btnTitle = userLeftChat ? "Delete from chat" : "Exit group";
  const showFooterBtns = !isGroupAdmin || admins?.length > 1;

  const dispatch = useDispatch();

  const onChangeAccess = (access) => {
    dispatch({
      type: SocketEvents.room.updateInfo,
      payload: { access, roomId },
    });
  };

  useEffect(() => {
    dispatch({ type: SocketEvents.room.getMembers, payload: { roomId } });
  }, []);

  const exitGroup = () => {
    dispatch({ type: SocketEvents.room.leave, payload: { roomId } });
    dispatch(leaveRoom({ roomId, userId: loggedInUserId }));
  };

  const onPressExitOrDeleteGroup = () => {
    if (userLeftChat) {
      dispatch({
        type: SocketEvents.room.deleteConversation,
        payload: { roomId },
      });
      dispatch(setActiveRoom({ data: undefined }));
    } else {
      const isUserWantsToLeave = window.confirm(
        chatConstants.doYouWantToLeaveRoom
      );
      if (isUserWantsToLeave) {
        exitGroup();
      }
    }
  };

  function getUserMenuOptions({
    roomType,
    isAdmin,
    isModerator = false,
    isBlocked,
  }) {
    if (roomType === conversationTypes.GROUP) {
      if (isAdmin) return [chatConstants.removeAsAdmin];
      if (isBlocked) return [chatConstants.unblock, chatConstants.remove];
      return [
        chatConstants.makeAsAdmin,
        chatConstants.block,
        chatConstants.remove,
      ];
    }
    if (
      [
        conversationTypes.COURSE,
        conversationTypes.COURSE_ADHOC,
        conversationTypes.ADHOC,
      ].includes(roomType)
    ) {
      if (isModerator) return [chatConstants.removeAsModerator];
      if (isBlocked) return [chatConstants.unblock];
      return [chatConstants.makeAsModerator, chatConstants.block];
    }
  }

  const onPressSearch = () => setShowSearchBar(true);
  const onChangeSearchInput = (event) => setSearchInput(event.target.value);

  const onPressBackOrClear = (action) => {
    if (!action) return;
    if (action === "BACK") {
      setShowSearchBar(!showSearchBar);
      setSearchInput(undefined);
    }
    if (action === "CLEAR") {
      setSearchInput(undefined);
    }
  };

  const handleRemovingAdmin = (userId) => {
    const availableAdmins = admins?.filter((eachAdmin) => eachAdmin !== userId);
    if (availableAdmins?.length === 0) {
      showToast("info", "Make anyone an admin to remove your access.");
    } else {
      dispatch(removeAdmin(userId));
      dispatch({
        type: SocketEvents.room.removeAdminAccess,
        payload: { userId, roomId },
      });
    }
  };

  const onPressMenuItem = (selectedItem, selectedUserId) => {
    let type;
    switch (selectedItem) {
      case chatConstants.removeAsAdmin:
        handleRemovingAdmin(selectedUserId);
        break;
      case chatConstants.block:
        type = SocketEvents.room.blockUser;
        dispatch(
          blockUser({ blockedBy: loggedInUserId, userId: selectedUserId })
        );
        break;
      case chatConstants.unblock:
        dispatch(unblockUser(selectedUserId));
        type = SocketEvents.room.unblockUser;
        break;
      case chatConstants.makeAsAdmin:
        dispatch(addAdmin(selectedUserId));
        type = SocketEvents.room.makeAdmin;
        break;
      case chatConstants.makeAsModerator:
        dispatch(addModerator(selectedUserId));
        type = SocketEvents.room.makeModerator;
        break;
      case chatConstants.removeAsModerator:
        dispatch(removeModerator(selectedUserId));
        type = SocketEvents.room.removeModerator;
        break;
      case chatConstants.remove:
        type = SocketEvents.room.removeUser;
        break;
      default:
        break;
    }
    if (type) dispatch({ type, payload: { userId: selectedUserId, roomId } });
  };

  const filteredMembers = useMemo(() => {
    if (showSearchBar && searchInput?.length >= 1) {
      return members.filter((member) =>
        member.displayName.toLowerCase().includes(searchInput?.toLowerCase())
      );
    }
    return members;
  }, [members, searchInput, showSearchBar]);

  const renderListHeader = () => {
    const value = `Participant${members?.length > 1 ? "s" : ""}`;
    return (
      <div
        style={{
          height: 40,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {showSearchBar ? (
          <SearchBar
            {...{
              id: `${id}-search-bar`,
              value: searchInput,
              onChangeSearchInput,
              onPressBack: () => onPressBackOrClear("BACK"),
              onPressClear: () => onPressBackOrClear("CLEAR"),
              textInputProps: { autoFocus: true },
            }}
          />
        ) : (
          <>
            <AautiText
              title={`${members?.length ?? 0} ${value}`}
              size={"semi"}
              textStyle={{ color: "#333333" }}
            />
            <div
              style={{ display: "flex", flexDirection: "row", columnGap: 10 }}
            >
              {isGroupAdmin && !isCancelled && (
                <FiPlus
                  size={25}
                  color="#3083EF"
                  style={{ cursor: "pointer" }}
                  onClick={() => toggleAddUserDialog(true)}
                />
              )}
              <SearchRounded onClick={onPressSearch} />
            </div>
          </>
        )}
      </div>
    );
  };

  const renderUser = useCallback(
    (value) => {
      let displayName = value?.displayName;
      const isMe = value?._id === loggedInUserId;
      const isAdmin = admins?.includes(value?._id);
      const isModerator = moderators?.includes(value?._id);
      const isBlocked = blockedUsers?.includes(value?._id);
      const menuOptions = isGroupAdmin
        ? (isAdmin && roomType === conversationTypes.COURSE) || isCancelled
          ? []
          : getUserMenuOptions({
              roomType,
              isAdmin,
              isModerator,
              isBlocked,
            })
        : [];
      displayName = isMe ? "You" : displayName;
      let endTags = [];
      if (isAdmin) endTags.push("Admin");
      if (isModerator) endTags.push("Moderator");
      if (isBlocked) endTags = ["Blocked"];
      displayName = `${displayName}${
        endTags.length ? ` (${endTags.join(", ")})` : ""
      }`;
      return (
        <ListItem
          key={value._id}
          secondaryAction={
            isGroupAdmin &&
            !isMe && (
              <ChatMenu
                {...{
                  id: "chat_room_info_user_item_menu",
                  options: menuOptions,
                  onPressMenuItem: (title) =>
                    onPressMenuItem(title, value?._id),
                }}
              />
            )
          }
          disablePadding
          disableRipple
          disableTouchRipple
          className={"list_item_add_group"}
          style={{ height: 60, columnGap: 10 }}
          sx={{ p: 0 }}
        >
          <AautiAvatar
            alt={value?.displayName}
            src={value?.profileImage}
            width={35}
            height={35}
          />
          <AautiText
            title={displayName}
            size={"semi"}
            textStyle={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
            weight={"normal"}
          />
        </ListItem>
      );
    },
    [blockedUsers, admins, isGroupAdmin, moderators]
  );

  const wrapperInLineStyle = showBackIcon
    ? { borderLeft: "solid 1px #d1e0e7" }
    : null;

  return (
    <div className="chat_room_info_container" style={wrapperInLineStyle}>
      <div className="chat_room_info_header">
        {showBackIcon && (
          <FaArrowLeft
            onClick={onCloseRoomInfo}
            className={"chat_header_icon_styles"}
            size={20}
          />
        )}
        {isGroupAdmin && !isCancelled && (
          <AautiText
            title={"Edit"}
            onClick={() => toggleEditProfileDialog(true)}
            textStyle={{ color: "#3166ba", textDecoration: "underline" }}
          />
        )}
      </div>
      <div>
        <div className="chat_room_info_body">
          <div className="chat_room_info_profile">
            <AautiAvatar
              {...{ src: avatar, alt: name, height: 80, width: 80 }}
            />
            <Tooltip title={name} arrow>
              <div>
                <AautiText
                  title={name}
                  size={"medium"}
                  textAlign={"center"}
                  textStyle={{
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    maxWidth: 320,
                  }}
                />
              </div>
            </Tooltip>
            <Tooltip title={description ?? "N/A"} arrow>
              <div>
                <AautiText
                  size={"semi"}
                  title={description ?? "N/A"}
                  weight={"auto"}
                  textAlign={"center"}
                  textStyle={{
                    color: "#333333",
                    paddingLeft: 10,
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    maxWidth: 300,
                  }}
                />
              </div>
            </Tooltip>
          </div>
          {isGroupAdmin && !isCancelled && (
            <ChatAccessCard {...{ access, onChangeAccess }} />
          )}
          {renderListHeader()}
          <div
            style={{
              border: "solid 1px rgba(221, 221, 221, 0.7)",
              borderRadius: 4,
              height: "60%",
            }}
          >
            {!filteredMembers?.length && (
              <EmptyData
                {...{
                  text: chatConstants.noResults,
                  showExplore: false,
                  height: "100%",
                }}
              />
            )}
            <List
              sx={{ width: "100%", bgcolor: "background.paper", margin: 0 }}
              style={{ paddingLeft: 10, paddingRight: 10 }}
            >
              {filteredMembers?.map(renderUser)}
            </List>
          </div>
        </div>
      </div>
      <div className="chat_room_info_footer">
        {!userLeftChat && (
          <Button
            id={`${id}-${btnTitle}-btn1`}
            onClick={() => toggleReportDialog(true)}
            className="chat_room_info_action_btn"
          >
            {chatConstants.reportGroup}
          </Button>
        )}
        {showFooterBtns && (
          <Button
            id={`${id}-${btnTitle}-btn2`}
            onClick={onPressExitOrDeleteGroup}
            className="chat_room_info_action_btn"
          >
            {btnTitle}
          </Button>
        )}
      </div>
    </div>
  );
}

export default memo(ChatRoomInfo);
