import React, { useEffect, useRef, useState } from "react";
import AautiText from "../globalComponents/AautiText";
import { Avatar, CircularProgress, IconButton, Tooltip } from "@mui/material";
import JoinButton from "../globalComponents/JoinButton";
import { BsChat, BsVolumeMute, BsVolumeUp } from "react-icons/bs";
import { AiOutlineHeart } from "react-icons/ai";
import _ from "lodash";
import { FaHeart } from "react-icons/fa";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { MdDeleteOutline } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { AuthService } from "../../service";
import {
  editCourseObject,
  editGigObject,
  setActiveGigObject,
  setBuyNowdata,
  setCartCount,
  toggleSignInPop,
} from "../../redux/reducer/appReducer";
import { useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import { AuthServices } from "../gig/Services";
import { IoPauseCircleOutline, IoPlayCircleOutline } from "react-icons/io5";
import {
  setBuyNowDataAfterRefresh,
  setInLocalBeforeLogin,
} from "../CommonFunctions";
import CourseImage from "../globalComponents/CourseImage";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ShareIcon from "../globalComponents/icons/ShareIcon";
import { showToast } from "../globalComponents/Toast";
import maximize from "../../assets/images/maximize.svg";
import { isDraft } from "@reduxjs/toolkit";
// import AautiDialog from "../globalComponents/AautiDialog";
// import GigComments from "../gig/gigComments/GigComments";

const GigCard = ({
  each,
  cardWidth = "269px",
  height = 360,
  selectCourse,
  AddPackage,
  getGigsList,
  isDarft,
  FromExpand,
  hideBtns,
  isViewAll = false,
  wishlisted = false,
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const [item, setItem] = useState(undefined);
  const [expandWish, setExpandWish] = useState(wishlisted);

  useEffect(() => {
    setItem(each);
    setExpandWish(wishlisted);
  }, [each]);

  const path = window?.origin + "/gig-preview/" + item?._id;

  const iconsList = [
    { name: "like", icon: <AiOutlineHeart size={16} color="#000" />, id: 1 },
    {
      name: "comment",
      icon: <BsChat size={16} color="#000" />,
      id: 2,
    },
    {
      name: "share",
      icon: <ShareIcon height="18px" width="18px" shareUrl={path} />,
      id: 3,
    },

    {
      name: "edit",
      icon: <MdOutlineModeEditOutline color="#000" size={16} />,
      id: 4,
    },
    {
      name: "delete",
      icon: <MdDeleteOutline color="#000" size={16} />,
      id: 5,
    },
  ];

  const iconList2 = [
    {
      name: "edit",
      icon: <MdOutlineModeEditOutline color="#000" size={16} />,
      id: 4,
    },
    {
      name: "delete",
      icon: <MdDeleteOutline color="#000" size={16} />,
      id: 2,
    },
  ];

  const [selected, setSelected] = useState(false);
  const [videoPlaying, setVideoPlaying] = useState(false);

  const [muted, setMuted] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const {
    loggedinUserId,
    userDetails,
    isUserLoggedIn,
    cartsDataBeforeLogin,
    UserRole,
    cartCount,
  } = useSelector((state) => state.app);
  const { mobileDevices } = useSelector((state) => state.responsive);

  const [hoverId, setHoveredId] = useState(FromExpand ? item?._id : null);
  const handleMouseEnter = (item) => {
    setHoveredId(item?._id);
    // setIsLoading(true);
  };
  const [progress, setProgress] = useState(0);
  const handleMouseLeave = () => {
    if (!FromExpand) {
      setHoveredId(null);
      // setIsLoading(false);
    }
  };

  const handleMuteVideo = (bool) => {
    setMuted(bool);
  };

  const OnPressCard = (item) => {
    !AddPackage
      ? navigate(`/gig-preview/${item?._id}`, {})
      : selectCourse(item, "gig");
  };
  const onSuccessDelete = (response) => {
    if (response?.status === "Success") {
      // getGigsList();
      showToast("success", response?.message);
      dispatch(setCartCount(cartCount - 1));
      setItem({ ...item, addedToCart: !item?.addedToCart });
    }
  };

  const onFailure = (err) => {
    console.log(err, "eror");
  };

  const buyNow = (gig) => {
    const user = [
      {
        displayName: item?.serviceProviderDisplayName,
        firstName: item?.serviceProviderName,
        lastName: "",
        isAautiCertified: true,
      },
    ];

    const data = {
      type: "gig",
      endUserId: loggedinUserId,
      gigId: gig?._id,
      name: gig?.gigName,
      serviceProviderId: gig?.serviceProviderId,
      parentId: userDetails?.parentId ? userDetails?.parentId : loggedinUserId,
      childDetails: each?.isPurchased == true ? [] : [{ _id: loggedinUserId }],
    };
    const buyNowData = [
      {
        type: "gig",
        serviceProvider: user,
        serviceProviderId: item?.serviceProviderId,
        serviceProviderName: item?.serviceProviderDisplayName,
        transactionCurrencyCode: item?.transactionCurrencyCode,
        yearsOfExperience: gig?.yearsOfExperience,
        subscribersCount:
          item?.endUserList?.length > 0 ? item?.endUserList?.length : 0,
        averageRating: item?.averageRating,
        ratingCount: item?.ratingCount,
        gig: item,
        averageRating: {
          averageRating: item?.averageRating,
          ratingCount: item?.ratingCount,
        },
        childDetails: [
          {
            endUserId: loggedinUserId,
          },
        ],
      },
    ];
    AuthService.saveCart(
      data,
      (response) => {
        if (response.status == "Success") {
          if (response.message === "Already Purchased") {
            showToast("info", response?.message);
          } else {
            showToast("success", response?.message);
            setItem({ ...item, addedToCart: !item?.addedToCart });
            dispatch(setCartCount(cartCount + 1));
            dispatch(setBuyNowdata(buyNowData));
            setBuyNowDataAfterRefresh(buyNowData, dispatch, "gig");
            navigate(`/buyNow`, {
              state: "buyNow",
            });
          }
        } else {
          showToast("error", response?.message);
        }
      },
      (error) => {
        console.log(error, "error save cart");
      }
    );
  };

  const handleCart = (gig) => {
    const data = {
      type: "gig",
      endUserId: loggedinUserId,
      gigId: gig?._id,
      name: gig?.gigName,
      serviceProviderId: gig?.serviceProviderId,
      parentId: userDetails?.parentId ? userDetails?.parentId : loggedinUserId,
      childDetails: each?.isPurchased == true ? [] : [{ _id: loggedinUserId }],
    };

    if (gig?.addedToCart) {
      AuthService.deleteCart(data, onSuccessDelete, onFailure);
    } else {
      AuthService.saveCart(
        data,
        (response) => {
          if (response.status == "Success") {
            if (response.message === "Already Purchased") {
              showToast("info", response?.message);
            } else {
              showToast("success", response?.message);
              setItem({ ...item, addedToCart: !item?.addedToCart });
              dispatch(setCartCount(cartCount + 1));
            }
          } else {
            showToast("error", response?.message);
          }
        },
        (error) => {
          console.log(error, "error save cart");
        }
      );
    }
  };

  const onSuccess = (response) => {
    if (response.status === "Success") {
      if (response.message === "Already wishlisted.") {
        showToast("info", response?.message);
      } else {
        if (!FromExpand) {
          setItem({ ...item, isWishlisted: !item?.isWishlisted });
          getGigsList();
        } else {
          setExpandWish(!expandWish);
        }
      }
    }
  };

  const onSuccessRemove = (response) => {
    if (response.status === "Success") {
      if (!FromExpand) {
        setItem({ ...item, isWishlisted: !item?.isWishlisted });
        getGigsList();
      } else {
        setExpandWish(!expandWish);
      }
    }
  };

  const handleTimeUpdate = () => {
    const video = videoRef?.current;
    const progress = (video?.currentTime / video?.duration) * 100;
    setProgress(progress);
  };

  const handleDeleteGig = () => {
    isDarft
      ? AuthService.deleteDraftedGig(
          item?._id,
          (response) => {
            if (response?.status == "Success") {
              // showToast("success", response?.message);
              getGigsList();
            }
          },
          (error) => {
            console.log(error, "error deleting gig");
          }
        )
      : AuthService.deleteUserGigById(
          item?._id,
          (response) => {
            let res = response?.data;
            if (res?.status == "Success") {
              if (res?.message == "Already Purchased. You can not delete now") {
                showToast("info", res?.message);
              } else if (res?.result !== null) {
                // showToast("success", res?.message);
                getGigsList();
              }
            }
          },
          (error) => {
            console.log(error, "error deleting gig");
          }
        );
  };

  const handleWishlist = () => {
    const obj = {
      type: "gig",
      courseId: null,
      gigId: item?._id,
      serviceProviderId: item?.serviceProviderId,
      name: item?.gigName,
      description: item?.gigDescription,
      endUserId: loggedinUserId,
      schedulerId: null,
      parentId: userDetails?.parentId ?? loggedinUserId,
    };

    if (isWishlisted) {
      AuthService.deleteWishList(obj, onSuccessRemove, onFailure);
    } else {
      AuthService.saveWishList(obj, onSuccess, onFailure);
    }
  };

  const videoRef = useRef();
  const storeGig = () => {
    let data = { id: item?._id, endUserId: loggedinUserId };
    AuthServices.getGigById(
      data,
      (res) => {
        if (res?.result) {
          dispatch(editGigObject(res?.result[0]?.gig));
          navigate("/course-creation", {
            replace: true,
            state: "Gig",
          });
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const returnBtn = () => {
    const isIdAvailable = _.some(cartsDataBeforeLogin, { gigId: item?._id });
    return isIdAvailable;
  };

  const isWishlisted = FromExpand ? expandWish : item?.isWishlisted;
  const isChild = userDetails?.parentId;
  return (
    <div
      id="gig-card"
      onMouseEnter={() => {
        handleMouseEnter(item);
      }}
      onMouseLeave={() => {
        if (!FromExpand) {
          handleMouseLeave();
          setMuted(true);
          setProgress(0);
        }
      }}
      style={{
        width: cardWidth,
        maxWidth: cardWidth,
        padding: 0,
        height: height,
        cursor: "pointer",
        border: selected === true ? "2px solid blue" : "1px solid lightgrey",
        borderRadius: "6px",
      }}
      onClick={() => {
        if (isDarft) {
        } else if (!AddPackage) {
          OnPressCard(item);
        }
      }}
    >
      <div style={{ width: "100%", height: "100%", position: "relative" }}>
        {isLoading && (
          <div
            style={{
              position: "absolute",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignSelf: "center",
              width: "100%",
              top: "35%",
              left: "40%",
            }}
          >
            <CircularProgress />
          </div>
        )}
        {hoverId === item?._id ? (
          item?.shortVideoUrl ? (
            <video
              style={{
                height: "100%",
                width: "100%",
                objectFit: "cover",
                borderRadius: 5,
              }}
              autoPlay
              ref={videoRef}
              onTimeUpdate={handleTimeUpdate}
              onPlay={() => {
                setVideoPlaying(true);
                // setIsLoading(false);
              }}
              muted={muted}
              controls={false}
              preload="metadata"
              loop
              // onLoadStart={() => {
              //   setIsLoading(true);
              // }}
              onCanPlayThrough={() => {
                setIsLoading(false);
              }}
            >
              <source src={item?.shortVideoUrl} type="video/mp4"></source>
            </video>
          ) : (
            !FromExpand && (
              <div
                style={{
                  height: height,
                  width: "100%",
                  alignContent: "center",
                }}
              >
                <AautiText
                  textAlign={"center"}
                  title="No video to play"
                  size={"normal"}
                  weight={"normal"}
                  textStyle={{ color: "#000000" }}
                />
              </div>
            )
          )
        ) : (
          <CourseImage
            imgUrl={
              item?.gigImage ||
              "https://svbk-lms.s3.ap-south-1.amazonaws.com/aautie/No_Image_Available.jpg"
            }
            height="100%"
          />
        )}

        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            background: "transparent",
            top: 0,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              padding: 10,
              height: "10%",
            }}
          >
            {hoverId === item?._id && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Tooltip title={videoPlaying ? "pause" : "play"}>
                  <IconButton
                    disableTouchRipple
                    sx={{
                      bgcolor: "#FBFCFF",
                      p: 0.8,
                      "&:hover": {
                        background: "#FBFCFF",
                      },
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (videoPlaying) {
                        videoRef?.current?.pause();
                        setVideoPlaying(false);
                      } else {
                        videoRef?.current?.play();
                        setVideoPlaying(true);
                      }
                    }}
                  >
                    {videoPlaying ? (
                      <IoPauseCircleOutline size={18} color="#000" />
                    ) : (
                      <IoPlayCircleOutline size={18} color="#000" />
                    )}
                  </IconButton>
                </Tooltip>
                <Tooltip title={muted ? "Unmute" : "Mute"}>
                  <IconButton
                    disableTouchRipple
                    sx={{
                      bgcolor: "#FBFCFF",
                      p: 0.8,
                      "&:hover": {
                        background: "#FBFCFF",
                      },
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setMuted(!muted);
                    }}
                  >
                    {muted ? (
                      <BsVolumeMute size={18} color="#000" />
                    ) : (
                      <BsVolumeUp size={18} color="#000" />
                    )}
                  </IconButton>
                </Tooltip>
              </div>
            )}
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              height: "60%",
              justifyContent: "space-between",
              position: "relative",
            }}
          >
            {/* <div
              style={{
                width: "100%",
                height: "100%",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {!item?.shortVideoUrl && (
                <AautiText
                  title="No video to play"
                  size={"normal"}
                  weight={"normal"}
                  textStyle={{ color: "#000000" }}
                />
              )}
            </div> */}
            <div
              style={{
                // width: "100%",
                position: "absolute",
                right: 0,
                top: 0,
                display: hoverId === item?._id ? "flex" : "none",
                // display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 5,
                padding: 10,
                height: "100%",
                justifyContent: "flex-end",
              }}
            >
              {!FromExpand && !isDarft && (
                <Tooltip title={"Preview"} id="Expand-gig">
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(`/expand-gig/${item?._id}`, {
                        state: isViewAll ? true : false,
                      });
                    }}
                    saria-label={"Preview"}
                    sx={{
                      backgroundColor: "#FBFCFF",
                      p: 0.8,
                      "&:hover": {
                        background: "#FBFCFF",
                      },
                    }}
                  >
                    {
                      <img
                        alt="view"
                        src={maximize}
                        style={{ color: "#000", height: 16, width: 16 }}
                      />
                    }
                  </IconButton>
                </Tooltip>
              )}
              {(isDarft ? iconList2 : iconsList)
                ?.slice(
                  0,
                  pathname === "/view-gigs" && UserRole !== "endUser" ? 6 : 3
                )
                ?.map((item1, index) => {
                  return item1?.name === "like" ? (
                    <Tooltip
                      key={index}
                      title={
                        isWishlisted ? "remove wishlist" : "add to wishlist"
                      }
                    >
                      <IconButton
                        id={
                          isWishlisted
                            ? "gig-card-wishlisted"
                            : "gig-card-wishlist"
                        }
                        onClick={(e) => {
                          e.stopPropagation();
                          if (isEmpty(userDetails)) {
                            navigate("/login");
                          } else {
                            handleWishlist();
                          }
                        }}
                        key={item1.id}
                        aria-label={item1.name}
                        sx={{
                          backgroundColor: "#FBFCFF",
                          p: 0.8,
                          "&:hover": {
                            background: "#FBFCFF",
                          },
                        }}
                      >
                        {isWishlisted ? (
                          <FaHeart color="#3997eadb" size={16} />
                        ) : (
                          <AiOutlineHeart size={18} color="#000" />
                        )}
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Tooltip title={item1?.name} key={index}>
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          if (item1.name === "view") {
                            navigate(`/course-details/${item?._id}`);
                          } else if (item1.name === "like") {
                            handleWishlist(item1);
                          } else if (item1.name === "delete") {
                            handleDeleteGig();
                          } else if (item1?.name === "edit") {
                            if (isDarft) {
                              dispatch(editCourseObject(null));
                              dispatch(editGigObject(item));
                              navigate("/course-creation", {
                                replace: true,
                                state: "Gig",
                              });
                            } else {
                              storeGig();
                            }
                          } else if (item1?.name === "Expand") {
                            navigate(`/expand-gig/${item?._id}`);
                          } else if (item1?.name === "comment") {
                            if (isUserLoggedIn) {
                              dispatch(setActiveGigObject(each));
                              setHoveredId(null);
                            } else {
                              navigate("/login");
                            }
                          }
                        }}
                        key={item1.id}
                        aria-label={item1.name}
                        sx={{
                          backgroundColor: "#FBFCFF",
                          p: 0.8,
                          "&:hover": {
                            background: "#FBFCFF",
                          },
                        }}
                      >
                        {item1.icon}
                      </IconButton>
                    </Tooltip>
                  );
                })}
            </div>
          </div>
          <div
            style={{
              paddingTop: 15,
              padding: 10,
              width: mobileDevices ? "100%" : "100%",
              borderBottomLeftRadius: 5,
              borderBottomRightRadius: 5,
              backgroundImage:
                "linear-gradient(-180deg, rgba(201, 201, 201, 0) 3.070175438596502%, rgba(69, 69, 69, 0.65) 12.1885964912281%, #000000 98.24561403508774%)",
            }}
          >
            <Tooltip title={item?.gigName} id="gig-card-title">
              <div style={{ width: "100%" }}>
                <AautiText
                  title={item?.gigName}
                  id={"gig-title"}
                  size={"normal"}
                  weight={"medium"}
                  textStyle={{
                    color: "#ffffff",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    WebkitLineClamp: 1,
                    textOverflow: "ellipsis",
                    maxWidth: "100%",
                    textShadow: "0px 1px 2px 4px",
                  }}
                  textAlign={"left"}
                />
              </div>
            </Tooltip>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: 5,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 6,
                  alignItems: "center",
                }}
              >
                <Avatar
                  variant="circular"
                  src={item?.profileImage}
                  size="small"
                  sx={{ height: 24, width: 24 }}
                />
                <AautiText
                  id={"Sp-name"}
                  title={item?.serviceProviderDisplayName || "N/A"}
                  size={"small"}
                  textStyle={{ color: "#ffffff" }}
                  textAlign={"left"}
                />
              </div>
              <div
                style={{
                  display: isDarft ? "none" : "flex",
                  flexDirection: "row",
                  gap: 4,
                  alignItems: "center",
                }}
              >
                <VisibilityIcon fontSize="small" sx={{ color: "#fff" }} />
                <AautiText
                  title={`${item?.viewCount || 0}`}
                  size={"small"}
                  textStyle={{ color: "#ffffff" }}
                />
              </div>
            </div>

            {pathname !== "/view-gigs" && !hideBtns && (
              <div
                style={{
                  display: hoverId === item?._id ? "flex" : "none",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  marginTop: 10,
                  gap: 10,
                }}
              >
                <JoinButton
                  textStyle={{
                    fontSize: isUserLoggedIn
                      ? item?.addedToCart
                        ? mobileDevices
                          ? 10
                          : "12px"
                        : mobileDevices
                        ? 12
                        : "14px"
                      : returnBtn()
                      ? mobileDevices
                        ? 10
                        : "12px"
                      : mobileDevices
                      ? 12
                      : "14px",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (isEmpty(userDetails)) {
                      setInLocalBeforeLogin(
                        {
                          gigId: item?._id,
                        },
                        dispatch,
                        "gig"
                      );
                    } else {
                      if (item?.isPurchased) {
                        showToast("info", "Already Purchased");
                      } else {
                        handleCart(item);
                      }
                    }
                  }}
                  color="#ffffff"
                  width={isChild ? "100%" : "55%"}
                  height="40px"
                  title={
                    isUserLoggedIn
                      ? item?.addedToCart
                        ? "Remove from Cart"
                        : "Add to Cart"
                      : returnBtn()
                      ? "Remove from  Cart"
                      : "Add to Cart"
                  }
                  outlined
                  buttonStyle={{ color: "#ffffff", fontSize: 10 }}
                />

                {!isChild && (
                  <JoinButton
                    id={"Gig-buyNow"}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (isEmpty(userDetails)) {
                        navigate("/login");
                      } else {
                        if (item?.isPurchased) {
                          showToast("info", "Already Purchased");
                        } else {
                          buyNow(item);
                        }
                      }
                    }}
                    height="40px"
                    title={"Buy Now"}
                    width="45%"
                    color="#ffffff"
                    outlined
                    textStyle={{ fontSize: 14 }}
                  />
                )}
              </div>
            )}
            <div
              className="progress-bar"
              onClick={(e) => {
                e.stopPropagation();
              }}
              style={{
                width: "100%",
                height: "3px",
                background: "#fff",
                position: "relative",
                marginTop: 10,
                borderRadius: 4,
                display: hoverId !== item?._id && "none",
              }}
            >
              <div
                className="progress"
                style={{
                  width: `${progress}%`,
                  height: "100%",
                  background: "#2076E5",
                  position: "absolute",
                  borderRadius: 4,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GigCard;
