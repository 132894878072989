import { Dialog, styled } from "@mui/material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: 15,
      minWidth: 350,
    },
    "& .MuiDialogActions-root": {
      padding: 15,
    },
    [theme.breakpoints.up("md")]: {
        "& .MuiDialogContent-root": {
        padding: 15,
        maxWidth: 430,
      },
    }
  }));

  export default BootstrapDialog