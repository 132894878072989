import React from "react";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import SearchIcon from "@mui/icons-material/Search";
import AautiText from "../../globalComponents/AautiText";
import RescheduleBatchCard from "./RescheduleBatchCard";
import { isEmpty } from "lodash";

const BatchesListing = ({
  classTypes,
  arrayOfBatches,
  classTypeState,
  setClassTypeState,
  selectedSlotDate,
  setSelectedSlotData,
}) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        border: "1px solid #E0E0E0",
        borderRadius: 8,
        padding: 10,
        height: "90vh",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              width: "50%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {classTypes?.map((each, index) => (
              <AautiText
                onClick={() => {
                  setClassTypeState(each);
                }}
                key={index}
                title={each}
                textStyle={{
                  color: each === classTypeState ? "#078dda" : "#1e1e1e",
                  borderBottom:
                    each === classTypeState
                      ? "3px solid #078dda"
                      : "1px solid #dedede",
                  paddingBottom: 10,
                  width: "50%",
                  textAlign: "center",
                  cursor: "pointer",
                }}
              />
            ))}
          </div>
          {/* <FormControl
            sx={{ m: 1, width: "25ch", margin: 0 }}
            size="small"
            variant="outlined"
          >
            <InputLabel htmlFor="outlined-adornment-password">
              Search
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={"text"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
              label="Search"
            />
          </FormControl> */}
        </div>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          gap: 10,
          margin: "20px 0px",
          overflow: "scroll",
          flexWrap: "wrap",
        }}
      >
        {arrayOfBatches?.map((batch, index) => {
          return (
            <div
              key={index}
              style={{
                display: isEmpty(batch?.arrayOfBatches) ? "none" : "flex",
                flexDirection: "row",
                gap: 5,
              }}
            >
              {batch?.arrayOfBatches?.map((each, index) => (
                <div key={index} style={{ width: "400px" }}>
                  <RescheduleBatchCard
                    key={index}
                    {...{
                      each,
                      title: batch?.title,
                      selectedSlotDate,
                      setSelectedSlotData,
                    }}
                  />
                </div>
              ))}
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default BatchesListing;
