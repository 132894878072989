import React from 'react'
import { MessageText } from '../../../chat_messages/custom_messages/messages_container/MessageText'
import AbstractReply from '../abstract_reply'

const styles = {
    left: {
      container: {
        backgroundColor: '#ffffff',
        borderRadius:5,
      },
    },
    right: {
      container: {
        backgroundColor: '#cce1fc',
        borderRadius: 5,
      },
    },
  }
  
  export function MessageReply({
    currentMessage = {},
    position = 'left',
    searchText,
  }) {
    const onClickReply = (e)=>{
      const parentMsg = document.getElementById(currentMessage?.parentId);
      parentMsg?.scrollIntoView({ behavior: 'smooth' });
    }
    return (
      <div style={styles[position].container}
        onClick={onClickReply}
      >
        <div style={{ padding: 4 }}>
          <AbstractReply
            {...{
              message: currentMessage.reply,
              position: position === 'left' ? 'right' : 'left',
              searchText,
            }}
          />
        </div>
        <MessageText
          {...{
            currentMessage,
            position,
            searchText,
          }}
        />
      </div>
    )
  }
  