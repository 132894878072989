import styled from "styled-components";

export const StyledMsgContainer = styled.div`
    display: flex;
    flex-direction:column;
    padding: 4px;
    rowGap: 4px;
    width:100%;
    @media(min-width:768px){
        width:250px;
    }
`