import React, { forwardRef } from "react";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import "./styles.scss";
import { chatConstants } from "../../constants";

function searchBar(
  {
    onPressBack = () => {},
    onPressClear = () => {},
    onChangeSearchInput = () => {},
    value = "",
    showBackIcon = true,
    textInputProps = {},
  },
  ref
) {
  return (
    <div className={"text_input_clear_btn_wrapper"}>
      {showBackIcon && (
        <ChevronLeftIcon
          className={"search_box_btn"}
          onClick={onPressBack}
        />
      )}
      <input
        {...{
            ref,
            value,
            className:"search_box_input",
            placeholder:chatConstants.search,
            onChange:onChangeSearchInput,
            ...textInputProps
        }}
      />
      {value?.length > 0 ? (
        <button
          className={"search_box_btn"}
          onClick={onPressClear}
        >
          <CloseSharpIcon />
        </button>
      ) : (
        <button className={"search_box_btn"}>
          <SearchRoundedIcon />
        </button>
      )}
    </div>
  );
}

export default forwardRef(searchBar);
