import { chatUrl } from "../service/api-constants.js";
import {
  MaxChatResultsCount,
  SocketEvents,
} from "../components/chat/constants";
import {
  connectionEstablished,
  disconnectSocket,
  setActiveRoom,
  setActiveUser,
  updateUnreadCount,
} from "../redux/reducer/chatSlice.js";

const socketMiddleware = (store) => {
  let socket = null;
  let timerId;
  let isConnected = false;
  let queuedEvents = {}

  function closeSocket() {
    timerId && clearInterval(timerId);
    socket?.close && socket.close();
    socket = null;
    isConnected = false;
  }

  function onOpenSocket() {
    timerId && clearInterval(timerId);
    getUnreadCount();
    isConnected && Object.values(queuedEvents).forEach(send)
  }

  function onMessage(msgEvent) {
    const { event, ...rest } = JSON.parse(msgEvent.data);
    if (!isConnected && socket.readyState !== WebSocket.CONNECTING) {
      store.dispatch(connectionEstablished());
      isConnected = true;
    }
    if (queuedEvents.hasOwnProperty(event)) delete queuedEvents[event]
    switch (event) {
      case SocketEvents.ping:
        send({ event: SocketEvents.pong });
        break;

      case SocketEvents.connected:
        onOpenSocket();
        break;

      case SocketEvents.users.getUnreadCount:
        store.dispatch(updateUnreadCount(rest.unreadCount));
        break;

      case SocketEvents.users.status:
        store.dispatch(setActiveUser(rest.data));
        break;

      case SocketEvents.users.getSupport:
      case SocketEvents.room.getRoom:
        store.dispatch(setActiveRoom({ data: rest.data }));
        break;

      default: {
        const eventHandler = getChatPropFromStore("eventHandler");
        if (typeof eventHandler === "function") {
          eventHandler({ event, ...rest });
        } else {
          handleEventsInMiddleWare({ event, ...rest });
        }
        break;
      }
    }
  }

  const connectSocket = (userId) => {
    if (isConnected || socket?.readyState === WebSocket.CONNECTING || !userId)
      return;
    socket = new WebSocket(`${chatUrl}${userId}`);
    socket.onopen = onOpenSocket;

    socket.onmessage = onMessage;
    socket.onerror = reconnectSocket;
    socket.onclose = closeSocket;
  };

  function reconnectSocket(e) {
    if (socket?.close) closeSocket();
    const userId = localStorage.getItem("USER_ID");
    if (
      !socket ||
      (socket?.readyState && socket.readyState === WebSocket.CLOSED && !!userId)
    ) {
      clearInterval(timerId);
      isConnected = false;
      timerId = setInterval(() => connectSocket(userId), 10000);
    }
  }

  function getChatPropFromStore(prop) {
    const chatState = store.getState().chat;
    return chatState?.[prop];
  }

  function handleEventsInMiddleWare(props) {
    const unreadCount = getChatPropFromStore("unreadCount");
    if (props?.data?.hasOwnProperty("message")) {
      store.dispatch(updateUnreadCount(unreadCount + 1));
    }
  }

  function getUnreadCount() {
    send({
      event: SocketEvents.users.getUnreadCount,
    });
  }

  function send(data) {
    if (isConnected) {
      const stringifiedData = JSON.stringify(data);
      socket?.send(stringifiedData);
    }
    if(!isConnected && [WebSocket.CLOSED,WebSocket.CLOSING].includes(socket?.readyState)) {
      reconnectSocket();
      queuedEvents[data.event] = data
    }
  }

  return (next) => (action) => {
    switch (action?.type) {
      case SocketEvents.connect:
        if (!isConnected) {
          connectSocket(action.payload);
        }
        break;

      case disconnectSocket.type:
        closeSocket();
        break;

      case SocketEvents.room.getConversations:
        getUnreadCount();
      case SocketEvents.getContacts:
      case SocketEvents.getCourseSubscribers:
      case SocketEvents.room.getRoom:
        send({
          event: action.type,
          ...action.payload,
          limit: MaxChatResultsCount,
        });
        break;

      case SocketEvents.users.getUnreadCount:
        getUnreadCount();
        break;

      case SocketEvents.room.getMessagesByRoom:
        send({
          event: action.type,
          roomId: action.roomId,
          pageNo: action.pageNo,
          limit: MaxChatResultsCount,
        });
        break;

      case SocketEvents.users.status:
        send({ event: action.type, userId: action.payload });
        break;

      case SocketEvents.room.updateInfo:
      case SocketEvents.room.deleteConversation:
      case SocketEvents.room.freeze:
      case SocketEvents.room.unFreeze:
      case SocketEvents.room.updateLastSeen:
      case SocketEvents.room.clearConversation:
      case SocketEvents.room.getMembers:
      case SocketEvents.room.makeAdmin:
      case SocketEvents.room.makeModerator:
      case SocketEvents.room.removeModerator:
      case SocketEvents.room.removeAdminAccess:
      case SocketEvents.room.removeUser:
      case SocketEvents.room.leave:
      case SocketEvents.room.blockUser:
      case SocketEvents.room.unblockUser:
      case SocketEvents.room.updateTimeFreeze:
      case SocketEvents.room.createRoom:
      case SocketEvents.message.send:
      case SocketEvents.message.edit:
      case SocketEvents.message.delete:
      case SocketEvents.message.messageDeleteForMe:
      case SocketEvents.aautiFeed.getFeedsAccess:
      case SocketEvents.aautiFeed.updateFeedsAccess:
      case SocketEvents.aautiFeed.recentFeed:
      case SocketEvents.users.getSupport:
      case SocketEvents.room.addUsers:
        send({
          event: action.type,
          ...(action?.payload ?? {}),
        });
        break;
    }
    next(action);
  };
};

export default socketMiddleware;
