import React from "react";
import RefundConfigurationCont from "./Container";
import RefundConditions from "../course/courseCreation/RefundConditions";
import AccountTabs from "../globalComponents/AccountTabs";
import AppContainer from "../AppContainer";
import Chips from "../Chips";
let height = window.innerHeight;

const RefundConfiguration = (props) => {
  const { activeTab, setActiveTab, tabs, conditions, onSaveRefundConditions } =
    RefundConfigurationCont(props);

  return (
    <AppContainer style={{ width: "100%", height: "90vh" }}>
      <div style={{ width: "100%" }}>
        <AccountTabs
          tabObj={{
            label: "Refund Configuration",
            navigation: "/refund-configuration",
            active: true,
          }}
        />
      </div>

      <Chips
        data={tabs}
        onClick={() => setActiveTab(activeTab === 0 ? 1 : 0)}
        containerStyle={{ marginBottom: "15px" }}
      />
      <div
        style={{
          justifyContent: "center",
          // alignItems: "center",
          display: "flex",
          height: "68vh",
          overflowY: "scroll",
        }}
      >
        <RefundConditions
          type={activeTab === 0 ? "course" : "adhoc"}
          refundConditions={conditions}
          handleSaveRefundDetails={(conditions) =>
            onSaveRefundConditions(conditions)
          }
        />
      </div>
    </AppContainer>
  );
};

export default RefundConfiguration;
