import React from "react";
import AautiText from "../../globalComponents/AautiText";
import { CapitalizeFirstLetter } from "../../CommonFunctions";
import { isEmpty } from "lodash";
import { Divider, IconButton, Tooltip } from "@mui/material";
import { MdOutlineEdit } from "react-icons/md";
import CourseDetailsStrings from "./CourseDeatilsStrings";
import "./index.scss";

const RefundSectionView = ({
  completeDetails,
  creation = false,
  isPackage = false,
  setRefundPolicyState,
  onClickEdit,
  onClickRefund,
}) => {
  return (
    <div style={{ width: "100%" }}>
      <div className="cancelationPolicy-con">
        <AautiText title={CourseDetailsStrings?.cancelPolicy} weight={"bold"} />
        <AautiText
          title={CourseDetailsStrings?.refundPolicy}
          size={"semi"}
          weight={"bold"}
          onClick={(e) => {
            e.stopPropagation();
            onClickRefund(setRefundPolicyState, completeDetails);
          }}
          className={"detailsLeft-text9"}
        />
      </div>

      {completeDetails?.isRefundAvailable &&
      !isEmpty(completeDetails?.refundConditions) ? (
        <div
          style={{
            display:
              completeDetails?.isRefundAvailable &&
              !isEmpty(completeDetails?.refundConditions)
                ? "block"
                : "none",
            border: "1px solid #f2f2f2",
            // height: "200px",
            width: "100%",
            marginBottom: 10,
            padding: 6,
            borderRadius: 4,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              paddingTop: "5px",
              paddingBottom: "5px",
              alignItems: "center",
            }}
          >
            <AautiText
              title={"Duration after Purchase"}
              textStyle={{ marginBottom: 5 }}
            />
            <AautiText
              title={"% of Refund"}
              textStyle={{ display: creation && "none" }}
            />
            <Tooltip title="Edit" sx={{ display: !creation && "none" }}>
              <IconButton onClick={onClickEdit}>
                <MdOutlineEdit size={18} />
              </IconButton>
            </Tooltip>
          </div>
          <Divider />
          <div style={{ padding: "5px" }}>
            {completeDetails?.refundConditions?.map((conditions) => {
              return (
                <div
                  style={{
                    display: conditions?.isSelected ? "flex" : "none",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 4,
                  }}
                >
                  <AautiText
                    title={CapitalizeFirstLetter(conditions?.name)}
                    textStyle={{ color: "#212529e6" }}
                    size={"semi"}
                  />
                  <AautiText
                    textStyle={{
                      display: conditions?.key == -2 && "none",
                      color: "#212529e6",
                    }}
                    title={`${conditions?.percentage || 0}%`}
                    size={"semi"}
                  />
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div
          className="doesnot-have-refund"
          style={{ display: isPackage && "none" }}
        >
          <AautiText
            textAlign={"left"}
            title={"This course does not have any refund"}
            size={"semi"}
            textStyle={{ padding: 5 }}
          />
        </div>
      )}
    </div>
  );
};

export default RefundSectionView;
