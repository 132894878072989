export const globalColors = {
  AAUTI_THEME_COLOR: "#3083EF",
  BORDER_COLOR: "#f2f2f6",
  RED_COLOR: "red",

  BORDER_GREY: "#dedede",

  //Avatar colors
  avatarOrange: "#e67e22",
  avatarGreen: "#2ecc71",
  avatarPrimary: "#3498db",
  avatarPink: "#8e44ad",
  avatarRed: "#e74c3c",
  avatarInfo: "#1abc9c",
  avatarDefault: "#2c3e50",
  darkGrey: "#9A9A9A",
  HEADING_COLOR: "#1E1E1E",
  PARAGRAPH_COLOR: "#626972",
  TAG_BACKGROUND: "#F9EF99",
};
