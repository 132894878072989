import React, { useEffect, useState } from "react";
import AautiText from "../globalComponents/AautiText";
import { Box, Grid, Skeleton } from "@mui/material";
import "./style.scss";
import { useSelector } from "react-redux";
import { AuthService } from "./Service";
import { showToast } from "../globalComponents/Toast";
import { convertToMillionBillionsPrice, PriceConversions } from "../CommonFunctions";
import AautiDialog from "../globalComponents/AautiDialog";
import CreateRequest from "./CreateRequest";
import AppContainer from "../AppContainer";
import AccountTabs from "../globalComponents/AccountTabs";
import EmptyData from "../globalComponents/EmptyData";
import moment from "moment";
import { isEmpty } from "lodash";
import { HiPlus } from "react-icons/hi";
import AautiSearch from "../globalComponents/AautiSearch";

const PayoutRequest = () => {
  const { mobileDevices } = useSelector(
    (state) => state.responsive
  );
  const { convertPriceWithCurrency, onlyPriceWithCurrency } = PriceConversions();
  const [count, setCount] = useState(10);
  const [offSet, setOffSet] = useState(0);
  const [banksList, setBanksList] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [spCharges, setSpcharges] = useState({});
  const { loggedinUserId, userDetails } = useSelector((state) => state.app);
  const [selectedBank, setSelectedBank] = useState("");
  const [selectedAmount, setSelectedAmount] = useState("");
  const [payoutList, setPayoutList] = useState([]);

  const tax = (selectedAmount * spCharges?.tax) / 100;
  const serviceCharges = (selectedAmount * spCharges?.serviceCharge) / 100;
  const netPay = selectedAmount - (tax + serviceCharges);

  const chargesInfo = [
    { chargeType: "Withdraw Amount", charges: selectedAmount },
    { chargeType: "Service Charge", charges: serviceCharges },
    { chargeType: "Tax", charges: tax },
    { chargeType: "Net Pay", charges: netPay },
  ];
  const payoutData = {
    serviceProviderId: loggedinUserId,
    countryName: selectedBank?.countryName,
    bankName: selectedBank?.bankName,
    accountHolderName: selectedBank?.accountHolderName,
    bankDocument: selectedBank?.accountHolderName,
    accountNumber: selectedBank?.accountNumber,
    ssnNumber: selectedBank?.ssnNumber,
    ifscCode: selectedBank?.ifscCode,
    dateOfBirth: selectedBank?.dateOfBirth,
    payoutStatus: "inProgress",
    withdrawAmount: selectedAmount,
    serviceCharge: serviceCharges,
    tax: tax,
    netPay: netPay,
  };

  const onCloseHandle = () => {
    setOpenDialog(false);
    setSelectedBank([]);
    setSelectedAmount("");
  };

  useEffect(() => {
    setLoading(true);
    getPayoutRequestsApi();
    bankListCall();
    getTotalUserCreditsCall();
  }, [count, loggedinUserId, offSet]);

  const getPayoutRequestsApi = () => {
    AuthService.getPayoutRequests(
      loggedinUserId,
      count,
      offSet,
      onSuccessGetPayouts,
      onFailure
    );
  };
  const bankListCall = () => {
    AuthService.GetBanksList(loggedinUserId, onSuccessBankList, onFailure);
  };
  const getTotalUserCreditsCall = () => {
    AuthService.getTotalUserCredits(
      loggedinUserId,
      onSuccessCharges,
      onFailure
    );
  };
  const onSuccessCharges = (response) => {
    setSpcharges(response.result);
  };
  const onSuccessBankList = (response) => {
    if (response?.status === "Success") {
      setLoading(false);
      setBanksList(response?.result);
    } else {
      setLoading(false);
      showToast("response", response.message);
    }
  };

  const onSuccessGetPayouts = (response) => {
    if (response.status === "Success") {
      setLoading(false);
      setPayoutList(response?.result?.[0]);
    } else {
      setLoading(false);
      showToast("response", response.message);
    }
  };

  const onFailure = (error) => {
    setLoading(false);
    showToast("response", error.message);
  };

  const payoutSaveApi = () => {
    AuthService.payoutSave(payoutData, onSuccessSave, onFailure);
  };
  const onSuccessSave = (response) => {
    if (response.status === "Success") {
      showToast("success", response.message);
      getPayoutRequestsApi();
    }
  };
  const filtered = payoutList?.payOutList?.filter(
    (each) =>
      each?.status?.toLowerCase().includes(search.toLowerCase()) ||
      each?.bankName?.toLowerCase().includes(search.toLowerCase())
  );
  return (
    <AppContainer style={{ width: "100%", height: "90vh" }}>
      <AccountTabs
        {...{
          tabObj: {
            label: "Payout Requests",
            navigation: "/payout-requests",
            active: true,
          },
        }}
      />
      <Grid className="payOut-grid-height ">
        {loading ? (
          <>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                padding: 15,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: mobileDevices ? "column" : "row",
                  justifyContent: "space-between",
                  marginBottom: 20,
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    columnGap: 15,
                    rowGap: mobileDevices ? 10 : 0,
                    marginBottom: mobileDevices ? 10 : 0,
                    width: "100%",
                  }}
                >
                  <Skeleton
                    variant="rounded"
                    width={mobileDevices ? "48%" : 300}
                    height={mobileDevices ? 50 : 100}
                  />
                  <Skeleton
                    variant="rounded"
                    width={mobileDevices ? "48%" : 300}
                    height={mobileDevices ? 50 : 100}
                  />
                </div>
                <Skeleton
                  variant="rounded"
                  width={mobileDevices ? "100%" : 222}
                  height={mobileDevices ? 25 : 40}
                />
              </div>
              <div className="tabs-flex ">
                {[1, 2, 3, 4, 5, 6]?.map((each, index) => (
                  <Skeleton
                    variant="rounded"
                    width={mobileDevices ? "100%" : 333}
                    height={100}
                  />
                ))}
              </div>
            </div>
          </>
        ) : (
          <Box className={"payOut-main-box"}>
            <div className="payOut-child-div1">
              <div className="payOut-child-div2">
                <div className="payOut-funds-div">
                  <AautiText
                    title={onlyPriceWithCurrency(payoutList?.availableFunds > 0 ?
                      (convertToMillionBillionsPrice(payoutList?.availableFunds)) : 0)}
                    textStyle={{ color: "#000000" }}
                    weight={"bold"}
                    size={"medium"}
                  />
                  <AautiText
                    title={"Available Funds"}
                    textStyle={{ color: "#5C5B57" }}
                    weight={"medium"}
                    size={"semi"}
                  />
                </div>
                <div className="payOut-funds-div">
                  <AautiText
                    title={onlyPriceWithCurrency(payoutList?.pipelineFunds > 0 ? (convertToMillionBillionsPrice(
                      payoutList?.pipelineFunds)) : 0)}
                    textStyle={{ color: "#000000" }}
                    weight={"bold"}
                    size={"medium"}
                  />
                  <AautiText
                    title={"Pipeline Funds"}
                    textStyle={{ color: "#5C5B57" }}
                    weight={"medium"}
                    size={"semi"}
                  />
                </div>
              </div>
              <div className="payOut-search-div">
                <AautiSearch
                  addStyle={{ width: mobileDevices && "90%" }}
                  searchValue={search}
                  handleSearchChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
                <HiPlus
                  id="create-course-button"
                  style={{
                    width: "38px",
                    height: "38px",
                    borderRadius: "4px",
                    backgroundColor: "#078dda",
                    color: "#fff",
                    cursor: "pointer",
                  }} onClick={() => {
                    if (isEmpty(banksList)) {
                      showToast('info', 'Please add bank details to continue..')
                    } else if (payoutList?.availableFunds < 1) {
                      showToast('info', 'No Available Funds')
                    } else {
                      setOpenDialog(true)
                    }
                  }}
                />
              </div>
            </div>

            {isEmpty(filtered) ?
              <EmptyData showExplore={false} text={"No Data Found"} /> :
              <div className="payOut-map-wrap" >
                {filtered?.map((each, index) =>
                  <div key={index} className="payOut-map-div">
                    <div className="price-cont-New">
                      <AautiText
                        weight={"bold"}
                        title={
                          each?.bankName +
                          "-" +
                          (each?.accountNumber
                            ? each.accountNumber.toString().slice(-4)
                            : null)
                        }
                        textStyle={{
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          WebkitLineClamp: 1,
                          textOverflow: "ellipsis",
                          maxWidth: "80%",
                        }}
                      />
                      <AautiText weight={'bold'}
                        title={onlyPriceWithCurrency(convertToMillionBillionsPrice(each?.netPay ? each?.netPay : 0))} size={"semi"}
                      />
                    </div>
                    <div className="price-cont-New" style={{ marginTop: 5 }}>
                      <AautiText
                        textStyle={{
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          WebkitLineClamp: 1,
                          textOverflow: "ellipsis",
                          maxWidth: "100%",
                        }}
                        size={"semi"}
                        title={`Ref ID: ${each?._id}`}
                      />
                      <AautiText
                        weight={"bold"}
                        textStyle={{
                          color:
                            each?.status === "inProgress"
                              ? "orange"
                              : each?.status === "success"
                                ? "#3acc6c"
                                : "#dc3545",
                        }}
                        size={"semi"}
                        title={
                          each?.status?.charAt(0).toUpperCase() +
                          each.status.slice(1)
                        }
                      />
                    </div>
                    <div className="price-cont-New">
                      <AautiText
                        size={"semi"}
                        title={moment(each?.createdAt).format(
                          "DD MMM YYYY hh:mm A"
                        )}
                      />
                    </div>
                  </div>
                )}
              </div>
            }
          </Box>
        )}
      </Grid>
      <AautiDialog
        open={openDialog}
        onClose={() => {
          onCloseHandle();
        }}
        dialogTitle={"Request Payout"}
      >
        <CreateRequest
          {...{
            payoutList,
            convertPriceWithCurrency,
            banksList,
            selectedBank,
            setSelectedBank,
            userDetails,
            setSelectedAmount,
            selectedAmount,
            chargesInfo,
            payoutSaveApi,
            onCloseHandle,
            convertToMillionBillionsPrice,
            onlyPriceWithCurrency
          }}
        />
      </AautiDialog>
    </AppContainer>
  );
};

export default PayoutRequest;
