import { findAll } from 'highlight-words-core'
import React from 'react'

export default function HighlightText({
  id,
  autoEscape = false,
  caseSensitive = false,
  sanitize = undefined,
  searchWords = [],
  textToHighlight = undefined,
  highlightStyle = {
    backgroundColor: '#3083EF',
    color:'white'
  },
  textStyle={},
  ...props
}) {
  const chunks = findAll({
    autoEscape,
    caseSensitive,
    sanitize,
    searchWords,
    textToHighlight,
  })

  return (
    <p id={`${id}-highlight-text`} {...props} style={textStyle}>
      {chunks.map((chunk, index) => {
        const text = textToHighlight.substr(
          chunk.start,
          chunk.end - chunk.start
        )

        return chunk.highlight ? (
          <span
            {...props}
            id={`${id}-highlight-text-chunk-${index}`}
            key={`${id}-chunk-${index}`}
            style={highlightStyle}
          >
            {text}
          </span>
        ) : (
          text
        )
      })}
    </p>
  )
}
