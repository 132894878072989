import React, { useState } from "react";
import AautiText from "../../globalComponents/AautiText";
import moment from "moment";
import { setAskClicked, setAskObject } from "../../../redux/reducer/appReducer";
import { useDispatch, useSelector } from "react-redux";
import { FaChevronRight } from "react-icons/fa6";
import { Tooltip } from "@mui/material";
import { options } from "./feed/poll/constants";
import { AuthService } from "../join_class/feed/ask/service";
import { showToast } from "../../globalComponents/Toast";
import { HiOutlineDotsVertical } from "react-icons/hi";

const AskCard = (props) => {
  const dispatch = useDispatch();
  const { cardDetails, UserRole, screen, switchChange } = props;
  const { loggedinUserId } = useSelector((state) => state.app);
  const [selectedOption, setSelectedOption] = useState("");
  const [askopen, setAskOpen] = useState(false);
  const [saveAskObj, setSaveAskObj] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [anchorEl, setAnchorEl] = useState(false);
  const { miniLapys, ipodDevices, mobileDevices } = useSelector(
    (state) => state.responsive
  );
  const [MenuActiveIndex, setMenuActiveIndex] = useState(null);

  const renderValidations = (each) => {
    if (UserRole.includes("serviceprovider")) {
      if (each.serviceProviderId === loggedinUserId) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const handleClickOption = (e, each, index) => {
    setSelectedOption(e.target.innerText);

    if (e.target.innerText === "Edit") {
      setAskOpen(true);
      setSaveAskObj(each);
    } else {
      if (switchChange) {
        AuthService.deleteDraftAsk(each?._id, onSuccessDelete, onFailure);
      } else {
        AuthService.deleteAsk(each?._id, onSuccessDelete, onFailure);
      }
    }
  };

  const handleClick = (each, index) => {
    setAnchorEl(!anchorEl);
    setMenuActiveIndex(index);
  };
  const onFailure = (error) => {
    setAskOpen(false);
    setSubmitted(false);
  };
  const onSuccessDelete = (response) => {
    if (response?.data?.status === "Success") {
      setSubmitted(true);
      showToast("success", response?.data?.message);
    } else {
      setSubmitted(true);
    }
  };
  return (
    <>
      <div style={{ marginTop: "10px" }} key={cardDetails?._id}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <AautiText
            textAlign={"left"}
            size={"small"}
            weight={"normal"}
            textStyle={{
              color: "#828282",
            }}
            title={`Created at ${moment(cardDetails?.createdAt).format(
              "MM/DD/YY"
            )}
              ${moment(cardDetails?.createdAt).format("hh:mm A")}`}
          />
          {screen === "feed" && (
            <AautiText
              size={"small"}
              weight={"normal"}
              textStyle={{
                color: "#828282",
              }}
              title={"Ask"}
            />
          )}
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            padding: 12,
            borderRadius: 4,
            border: "1px #E0E0E0 solid",
            backgroundColor: "#F7F7FA",
            justifyContent: "space-between",
            alignSelf: "center",
          }}
        >
          <div
            onClick={() => {
              UserRole === "endUser" && dispatch(setAskClicked(true));
              dispatch(setAskObject(cardDetails));
            }}
            style={{
              width: UserRole === "endUser" ? "100%" : "97%",
              alignItems: "center",
              display: "flex",
              flexDirection: "row",
              padding: 10,
              justifyContent: "space-between",
              borderRadius: 4,
              border: "1px #E0E0E0 solid",
              backgroundColor: "#ffffff",
              cursor: "pointer",
            }}
          >
            <AautiText
              title={cardDetails?.title}
              size={"normal"}
              textStyle={{ color: "#5C5B57" }}
            />

            <FaChevronRight size={18} />
          </div>
          {renderValidations(cardDetails) && screen !== "feed" && (
            <>
              <Tooltip
                title={
                  <>
                    <div style={{ padding: "4px" }}>
                      {options?.map((option) => (
                        <p
                          style={{
                            cursor: "pointer",
                            textAlign: "center",
                            padding: "2px",
                            fontSize: 15,
                          }}
                          key={option}
                          onClick={(e) =>
                            handleClickOption(e, cardDetails, cardDetails?._id)
                          }
                        >
                          {option}
                        </p>
                      ))}
                    </div>
                  </>
                }
              >
                <div>
                  <HiOutlineDotsVertical
                    key={cardDetails?._id.$oid}
                    size={25}
                    onClick={() => handleClick(cardDetails, cardDetails?._id)}
                    style={{
                      alignSelf: "center",
                      cursor: "pointer",
                      marginLeft: mobileDevices ? "0px" : "10px",
                      marginTop: "8px",
                    }}
                  />
                </div>
              </Tooltip>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default AskCard;
