import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ApiRequest } from "../../../service";
import { ApiMethods } from "../../../service/api-constants";

export function deleteCourseAdhoc(id, onSuccess, onFailure) {
  ApiRequest({
    method: ApiMethods.DELETE,
    url: "mySchedule/deleteMySchedule?",
    params: { id },
  }).then(
    (response) => {
      onSuccess && onSuccess(response.data);
    },
    (error) => {
      onFailure && onFailure(error);
    }
  );
}

export const useCourseAdhocCont = () => {
  const [courseList, setCourseAdhoc] = useState(null);
  const [sheetData, setSheetData] = useState({ open: false, batches: [] });
  const { loggedinUserId } = useSelector((state) => state.app);
  const [loadingData, setLoadingData] = useState(true);

  const getAdhocs = useCallback(() => {
    ApiRequest({
      url: "/mySchedule/getMySchedulesByUserId?",
      params: { userId: loggedinUserId, type: "course adhoc", count: 1000 },
    })
      .then((response) => {
        if (response?.data?.result) {
          setCourseAdhoc(response.data.result);
        } else {
          setCourseAdhoc([]);
        }
        setLoadingData(false);
      })
      .catch((e) => {
        setCourseAdhoc([]);
        setLoadingData(false);
      });
  }, []);

  useEffect(() => {
    getAdhocs();
  }, []);

  const onPressDelete = (id) => {
    deleteCourseAdhoc(id, onDeleteSucess, (e) => {});
  };

  const onDeleteSucess = () => {
    // setLoadingData(true)
    getAdhocs();
  };

  return { courseList, onPressDelete, setSheetData, loadingData };
};
