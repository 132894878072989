import { Component, forwardRef } from "react";
import { messageTypes } from "../../../constants";
import { isSameUser } from "../../../utils";
import { Day } from "./Day";
import { SystemMessage } from "./SystemMessage";
import Bubble from "./Bubble";
const styles = {
  left: {
    container: {
      flexDirection: "row",
      alignItems: "flex-end",
      justifyContent: "flex-start",
    },
  },
  right: {
    container: {
      flexDirection: "row",
      alignItems: "flex-end",
      justifyContent: "flex-end",
    },
  },
};
class Message extends Component {
  shouldComponentUpdate(nextProps) {
    const next = nextProps.currentMessage;
    const current = this.props.currentMessage;
    const { previousMessage, nextMessage, isSelected, searchText,highlighted } = this.props;
    const nextPropsMessage = nextProps.nextMessage;
    const nextPropsPreviousMessage = nextProps.previousMessage;

    return (
      next.createdAt !== current.createdAt ||
      next.text !== current.text ||
      next.files?.length !== current.files?.length ||
      next.type !== current.type ||
      previousMessage !== nextPropsPreviousMessage ||
      nextMessage !== nextPropsMessage ||
      isSelected !== nextProps.isSelected ||
      searchText !== nextProps.searchText || highlighted !== nextProps.highlighted
    );
  }

  renderDay() {
    if (this.props.currentMessage && this.props.currentMessage.createdAt) {
      return <Day {...this.props} />;
    }
    return null;
  }
  renderBubble() {
    return <Bubble key={`bubble-${this.props.currentMessage?._id}`} {...this.props}/>;
  }
  renderSystemMessage() {
    return <SystemMessage {...this.props} />;
  }

  render() {
    const {innerRef, currentMessage, nextMessage, position, isSelected } = this.props;
    if (currentMessage) {
      const sameUser = isSameUser(currentMessage, nextMessage);
      return (
        <li ref={innerRef} id={currentMessage._id}>
          {this.renderDay()}
          {currentMessage.type === messageTypes.SYSTEM ? (
            this.renderSystemMessage()
          ) : (
            <div
              style={{
                ...styles[position].container,
                marginBottom: sameUser ? 2 : 8,
                backgroundColor: isSelected ? "#77A4E0" : "transparent",
              }}
            >
              {this.renderBubble()}
            </div>
          )}
        </li>
      );
    }
    return null;
  }
}
Message.defaultProps = {
  position: "left",
  currentMessage: {},
  nextMessage: {},
  previousMessage: {},
  user: {},
  showUserAvatar: false,
  inverted: true,
  shouldUpdateMessage: undefined,
};

export default forwardRef((props, ref) => <Message innerRef={ref} {...props} />);
