import { useState, useRef, useEffect } from "react";
import { HiBuildingOffice } from "react-icons/hi2";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import Slider from "@mui/material/Slider";
import JoinButton from "../../globalComponents/JoinButton";
import { useDispatch, useSelector } from "react-redux";
import { BiSolidHome } from "react-icons/bi";
import MenuItem from "@mui/material/MenuItem";
import { v4 } from "uuid";
import AautiText from "../../globalComponents/AautiText";
import AautiTextInput from "../../globalComponents/AautiTextInput";
import { FormControl, IconButton, Select, Tooltip } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import { AuthService } from "../../profile/Service";
import { showToast } from "../../globalComponents/Toast";
import MapsComponent from "./MapsComponent";
import GoogleMaps from "./GoogleAutocomplete";
import { ImFlag } from "react-icons/im";
import _, { isEmpty } from "lodash";
import { Edit } from "@mui/icons-material";
import TrashIcon from "../../globalComponents/icons/TrashIcon";
import { useLocation } from "react-router-dom";
import { CommonFunctions } from "../../CommonFunctions";
import { AautiStrings } from "../../globalComponents/AautiStrings";
import { createCourseStrings } from "./Constants";

const Address = [
  { id: 0, categoryName: "Home" },
  { id: 1, categoryName: "Office" },
  { id: 2, categoryName: "Other" },
];

const Location1 = (props) => {
  const {
    edit,
    handleSaveEditAddress,
    add,
    setActiveIndex,
    coordinates,
    setCoordinates,
    setCurrentLocClicked,
    currentLocClicked,
    setAddresses,
    getUserLocation,
    errorMessage,
  } = props;
  const header = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginBottom: "20px",
          alignItems: "center",
          width: "100%",
          marginTop: 6,
        }}
      >
        <AautiText
          weight={"bold"}
          title={AautiStrings?.chooseYourLocationString}
        />
        <div style={{ width: "60%", position: "relative" }}>
          <GoogleMaps {...{ setCoordinates, getLocationByLatLan }} />
        </div>
      </div>
    );
  };
  const adressTypeList = add
    ?.map((each) => each.addressType)
    .filter((each) => each !== "Other");
  const handleGetCoor = async () => {
    getUserLocation()
      .then((location) => {
        props?.handleSetAddress(location);
        const obj = { lat: location?.latitude, lng: location?.longitude };
        setCoordinates(obj);
        setCurrentLocClicked(true);
      })
      .catch((error) => {
        console.error("Error getting user location:", error);
      });
  };
  const getLocationByLatLan = (coordinates) => {
    const { lat, lng, newValue } = coordinates;

    AuthService.getUserLocationByLatLang(
      lat,
      lng,
      (response) => {
        if (response?.result !== null) {
          let location = response?.result;
          if (newValue) {
            location.formattedAddress = newValue?.description;
          }
          props?.handleSetAddress(location);
          const obj = { lat, lng };
          setCoordinates(obj);
        } else {
          showToast("info", response?.message);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const disableSave =
    props.addresses.addressType === "" ||
    props.addresses.address === "" ||
    (props?.addresses?.addressType === "Other" &&
      props.addresses?.name === "") ||
    errorMessage;
  return (
    <div style={{ height: "97vh", overflow: "hidden", overflowY: "scroll" }}>
      {header()}
      <div style={{ background: "#fff" }}>
        <MapsComponent
          {...{ getLocationByLatLan, coordinates, setCoordinates }}
        />
      </div>
      <div style={{ width: "100%", position: "relative" }}>
        {/* <AautiText
          onClick={() => {
            handleGetCoor();
          }}
          title={AautiStrings?.useCurrentLocationString}
          textAlign={"right"}
          weight={"bold"}
          textStyle={{
            color: "#000",
            cursor: "pointer",
            marginTop: 15,
            zIndex: 20000000,
          }}
        /> */}
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            marginBottom: "10px",
            borderRadius: "8px",
          }}
        >
          <AautiText
            textStyle={{
              textAlign: "left",
              fontSize: "18px",
              marginBottom: "10px",
            }}
            title={createCourseStrings?.saveAddressAs}
          />
          <div
            style={{
              width: "98%",
              display: "flex",
              flexDirection: "row",
              marginLeft: "5px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                marginBottom: 5,
              }}
            >
              {Address.map((each, id) => {
                const disableButton = adressTypeList?.includes(
                  each?.categoryName
                );
                return (
                  <button
                    disabled={disableButton}
                    style={{
                      width: "45%",
                      height: 35,
                      padding: "2px 2px 2px 2px",
                      borderRadius: 5,
                      display: "flex",
                      border:
                        props.addresses.addressType === each.categoryName
                          ? "1px solid #3166ba"
                          : "1px solid #dddddd",
                      backgroundColor: disableButton
                        ? "lightgrey"
                        : props.addresses.addressType === each.categoryName
                        ? "#3166ba"
                        : "#ffffff",
                      boxSizing: "border-box",
                      justifyContent: "center",
                      alignItems: "center",
                      marginRight: "12px",
                    }}
                    onClick={() =>
                      props.changeAddress(each.categoryName, "addressType")
                    }
                    key={id}
                  >
                    <AautiText
                      textStyle={{
                        fontWeight: 500,
                        color:
                          props.addresses?.addressType === each.categoryName
                            ? "#ffffff"
                            : "#1e1e1e",
                        fontSize: 14,
                      }}
                      title={each.categoryName}
                    />
                  </button>
                );
              })}
            </div>
          </div>
          {props.addresses?.addressType === "Other" && (
            <div
              style={{
                width: "100%",
                display: "flex",
                marginBottom: "5px",
                //   alignItems: "center",
                justifyContent: "center",
              }}
            >
              <AautiTextInput
                important={true}
                style={{
                  width: "100%",
                }}
                value={props?.addresses?.name || ""}
                onChange={(e) => {
                  const value = e.target.value.trimStart().replace(/\s+/g, " ");
                  props.changeAddress(value, "name");
                }}
                text={createCourseStrings?.addressName}
              />
            </div>
          )}
          {props.addresses.address !== "" && (
            <div
              style={{
                width: "100%",
                display: "flex",
                //   alignItems: "center",
                justifyContent: "center",
              }}
            >
              <AautiTextInput
                labelStyle={{ zIndex: 201 }}
                important={true}
                style={{
                  width: "100%",
                }}
                readOnly={true}
                value={props.addresses.address}
                onChange={(e) => {
                  const value = e.target.value.trimStart().replace(/\s+/g, " ");
                  props.changeAddress(value, "address");
                }}
                text={createCourseStrings?.completeAddress}
              />
            </div>
          )}

          <div
            style={{
              width: "100%",
              display: "flex",
              //   alignItems: "center",
              justifyContent: "center",
            }}
          >
            <AautiTextInput
              style={{
                width: "100%",
                // marginBottom: 10,
              }}
              value={props.addresses?.floor}
              onChange={(e) => {
                const value = e.target.value.trimStart().replace(/\s+/g, " ");
                props.changeAddress(value, "floor");
              }}
              text={createCourseStrings?.floorOptional}
            />
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <AautiTextInput
              style={{
                width: "100%",
              }}
              value={props.addresses?.landMark}
              onChange={(e) => {
                const value = e.target.value.trimStart().replace(/\s+/g, " ");
                props.changeAddress(value, "landMark");
              }}
              text={createCourseStrings?.nearByLandMarkOptional}
            />
          </div>
          {errorMessage && (
            <AautiText
              title={errorMessage}
              size={"small"}
              textAlign={"center"}
              textStyle={{ color: "red", marginTop: 5 }}
            />
          )}
          <button
            onClick={() => {
              edit === true
                ? handleSaveEditAddress()
                : props.handleSaveaddress(props.addresses);
            }}
            disabled={disableSave}
            style={{
              width: "100%",
              padding: "10px",
              borderRadius: "4px",
              marginTop: 10,
              backgroundColor: disableSave ? "lightgrey" : "#3166ba",
              boxSizing: "border-box",
              border: "0px",
              color: "white",
              fontSize: "16px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <AautiText
              textStyle={{ color: "white", fontSize: "16px" }}
              title={createCourseStrings?.saveAddress}
            />
          </button>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <AautiText
              onClick={() => {
                setActiveIndex(0);
                setAddresses({
                  id: v4(),
                  addressType: "",
                  address: "",
                  floor: "",
                  landMark: "",
                  name: "",
                  isDefault: false,
                });
                setCurrentLocClicked(false);
              }}
              title={AautiStrings?.backString}
              // textAlign={"right"}
              size={"small"}
              textStyle={{
                marginTop: "10px",
                cursor: "pointer",
                textDecoration: "underline",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const CourseInPerson = (props) => {
  const { clientLocation = true } = props;
  const [isVisible, setMapVisible] = useState(false);
  const [add, setadd] = useState([]);
  const [edit, setEdit] = useState(false);
  const { userDetails, userLocation, loggedinUserId } = useSelector(
    (state) => state.app
  );

  const { CreateCourseObj = {} } = props;

  const { fetchUserDetails, getUserLocation } = CommonFunctions();
  const { mobileDevices } = useSelector((state) => state.responsive);
  const [currentLocClicked, setCurrentLocClicked] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [locationIn, setLocationIn] = useState(
    CreateCourseObj?.isClientLocation ? CreateCourseObj?.radiusType : "Km"
  );
  const [coordinates, setCoordinates] = useState();
  const { pathname } = useLocation();
  const [errorMessage, setErrorMessage] = useState("");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const [index, setActiveIndex] = useState(0);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const setDefaultFields = (userDetails) => {
    setadd(userDetails?.addressList);
    let data = userDetails?.addressList?.find(
      (each) => each.isDefault === true
    );
    props.handleSelectAddress(data);
    const coords = userDetails?.addressList?.[0]?.latLong;
    if (coords) {
      const initialCoords = {
        lat: coords?.latitude,
        lng: coords?.longitude,
      };
      setCoordinates(initialCoords);
    }
  };
  useEffect(() => {
    setDefaultFields(userDetails);
  }, [userDetails]);

  const defautlFields = {
    id: v4(),
    addressType: "",
    address: "",
    floor: "",
    landMark: "",
    name: "",
    isDefault: false,
  };
  const [addresses, setAddresses] = useState(defautlFields);
  const handleDeleteAddress = (item, i) => {
    const newlist = [...add];
    const filtered = _.reject(newlist, { id: item.id });
    const updateUser = { ...userDetails, addressList: filtered };
    handleUpdateAddress(updateUser);
    handleClose();
    // newlist?.splice(i, 1);
    // setadd(newlist);
  };

  const isAddressAvailable = add?.length > 0;
  const handleDefaultAddress = (item) => {
    const newList = [...add];
    newList.map((each) => {
      if (each.id == item?.id) {
        return { ...each, isDefault: true };
      } else return { ...each, isDefault: false };
    });
    const updateUser = { ...userDetails, addressList: newList };
    handleUpdateAddress(updateUser);
    handleClose();
  };
  const handleSaveEditAddress = () => {
    const newList = add.map((each) => {
      if (each?.id === addresses?.id) {
        let newAddress = { ...addresses };
        if (newAddress?.name === "") {
          newAddress.name = addresses.addressType;
        }
        return newAddress;
      } else {
        return each;
      }
    });

    setadd(newList);
    setEdit(false);
    setMapVisible(false);
    setActiveIndex(0);
    const updateUser = { ...userDetails, addressList: newList };
    handleUpdateAddress(updateUser);
    setAddresses(defautlFields);
  };

  const handleUpdateAddress = (updateUser) => {
    AuthService.updateUserDetails(
      updateUser,
      (res) => {
        fetchUserDetails(loggedinUserId);
        setDefaultFields(res.result);
        setMapVisible(false);
        setActiveIndex(0);
        setAddresses({
          id: v4(),
          addressType: "",
          address: "",
          floor: "",
          landMark: "",
          name: "",
        });
      },
      (error) => {
        console.log(error, "error-updating address");
      }
    );
  };

  const handleSaveaddress = (obj) => {
    let addObjec = obj;
    if (isEmpty(add)) {
      addObjec.isDefault = true;
    }
    const addresList = add ? [...add, addObjec] : [addObjec];
    const updateUser = { ...userDetails, addressList: addresList };
    handleUpdateAddress(updateUser);
  };

  const menu1 = useRef(null);
  const menu2 = useRef(null);
  const defaulAddTypes = ["Home", "Office", "Other"];
  const changeAddress = (value, field) => {
    if (field == "name") {
      if (defaulAddTypes.includes(value)) {
        setErrorMessage(`${value} name not accepted`);
      } else {
        setErrorMessage("");
        setAddresses((prevState) => ({
          ...prevState,
          [field]: value,
        }));
      }
    } else {
      setAddresses((prevState) => ({
        ...prevState,
        [field]: value,
      }));
    }
    setAddresses((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };
  const handleMapVisible = (val) => {
    setActiveIndex(1);
  };
  const dispatch = useDispatch();
  const handleSetAddress = (addre) => {
    const { formattedAddress, latitude, longitude } = addre;
    setAddresses((prevState) => ({
      ...prevState,
      address: formattedAddress,
      latLong: {
        latitude,
        longitude,
      },
    }));
  };
  const returnIcon = (each) => {
    if (each?.addressType === "Office") {
      return (
        <HiBuildingOffice style={{ fontSize: "15px", color: "#2980B9" }} />
      );
    } else if (each?.addressType === "Home") {
      return <BiSolidHome style={{ fontSize: "15px", color: "#2980B9" }} />;
    } else {
      return <ImFlag style={{ fontSize: "15px", color: "#2980B9" }} />;
    }
  };
  const handleClientLocation = (check) => {
    const { changeCreateObj } = props;
    changeCreateObj(check, props?.isClientLocation);
  };

  const updateDefaultAddress = (each) => {
    let adresses = [...userDetails?.addressList];
    const newAarray = adresses?.map((add) => {
      if (add.id == each?.id) {
        return { ...add, isDefault: true };
      } else {
        return { ...add, isDefault: false };
      }
    });
    handleUpdateAddress({ ...userDetails, addressList: newAarray });
  };

  return (
    <div
      style={{
        backgroundColor: "#fff",
        width: "100%",
        padding: "3%",
        height: "100%",
      }}
    >
      <Carousel
        duration={200}
        swipe={false}
        indicators={false}
        autoPlay={false}
        animation="slide"
        index={index}
        stopAutoPlayOnHover={false}
        navButtonsAlwaysInvisible={undefined}
        // className="nextbutton"
      >
        <div
          style={{
            width: "100%",
            height: mobileDevices ? "70vh" : "93vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            // overflow: "scroll",
          }}
        >
          <div
            style={{
              width: "100%",
            }}
          >
            <div
              style={{
                borderRadius: "6px",
              }}
            >
              <JoinButton
                buttonStyle={{
                  width: "97%",
                  marginTop: 10,
                  marginRight: 10,
                  marginLeft: 10,
                }}
                outlined
                title={createCourseStrings?.addNewAddress}
                onClick={() => handleMapVisible(true)}
                icon={<AddCircleOutlineOutlinedIcon />}
              />

              {isAddressAvailable && (
                <AautiText
                  title={createCourseStrings?.myAddress}
                  textStyle={{
                    fontSize: "16px",
                    marginBottom: 10,
                    marginTop: 10,
                  }}
                />
              )}
              {isAddressAvailable && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    width: "100%",
                    gap: 10,
                  }}
                >
                  {add?.map((each, index) => {
                    return (
                      <button
                        key={index}
                        onClick={() => {
                          props.handleSelectAddress(each);
                          updateDefaultAddress(each);
                        }}
                        style={{
                          width: "100%",
                          padding: "10px",
                          borderRadius: "4px",
                          border: each?.isDefault
                            ? "2px solid #1e98d7"
                            : "1px solid #dddddd",
                          backgroundColor: "#ffffff",
                          boxSizing: "border-box",
                          cursor: "pointer",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: "row",
                            width: "100%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              gap: 6,
                              alignItems: "center",
                            }}
                          >
                            {returnIcon(each)}
                            <AautiText
                              textStyle={{
                                color: "#2980B9",
                              }}
                              title={
                                each?.addressType == "Other"
                                  ? each?.name
                                  : each?.addressType
                              }
                              size={"semi"}
                            />
                          </div>
                          <div
                            style={{
                              position: "relative",
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <IconButton
                              sx={{ p: 0.5 }}
                              onClick={(e) => {
                                e.stopPropagation();
                                setEdit(true);
                                setAddresses(each);
                                handleMapVisible(true);
                                handleClose();
                              }}
                            >
                              <Tooltip title="Edit">
                                <Edit fontSize="small" />
                              </Tooltip>
                            </IconButton>

                            {add.length > 1 &&
                              addresses.addressType !== each.categoryName && (
                                <TrashIcon
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    // handleClose();
                                    if (each?.isDefault) {
                                      showToast(
                                        "info",
                                        "Default address cannot be deleted"
                                      );
                                    } else {
                                      handleDeleteAddress(each, index);
                                    }
                                  }}
                                />
                              )}
                          </div>
                        </div>
                        <AautiText
                          title={each?.address}
                          size={"semi"}
                          textAlign={"left"}
                          textStyle={{ marginTop: 6, color: "#000" }}
                        />
                        <AautiText
                          title={`Floor: ${each?.floor}`}
                          size={"semi"}
                          textAlign={"left"}
                          textStyle={{
                            display: !each?.floor && "none",
                            marginTop: 6,
                            color: "#000",
                          }}
                        />
                        <AautiText
                          title={`Landmark: ${each?.landMark}`}
                          size={"semi"}
                          textAlign={"left"}
                          textStyle={{
                            display: !each?.landMark && "none",
                            marginTop: 6,
                            color: "#000",
                          }}
                        />
                      </button>
                    );
                  })}
                </div>
              )}
            </div>
            {isAddressAvailable && (
              <div
                style={{
                  display: !clientLocation && "none",
                  width: "100%",
                  backgroundColor: "#ffffff",
                  marginLeft: "5",
                  marginTop: 15,
                  //   border: "1px solid #dddddd",
                  borderRadius: "5px",
                }}
              >
                {/* {false && ( */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 6,
                      marginBottom: 8,
                    }}
                  >
                    <input
                      type="checkbox"
                      checked={
                        props?.CreateCourseObj?.[props?.isClientLocation] ||
                        false
                      }
                      id="client-location"
                      style={{ height: "16px", width: "16px" }}
                      onChange={(e) => {
                        handleClientLocation(e.target.checked);
                      }}
                    />
                    <label
                      htmlFor="client-location"
                      style={{ cursor: "pointer" }}
                    >
                      <AautiText
                        title={createCourseStrings?.clientLocation}
                        textStyle={{ fontSize: "16px", userSelect: "none" }}
                      />
                    </label>
                  </div>
                  {props?.CreateCourseObj?.[props.isClientLocation] && (
                    <div style={{ width: "20%" }}>
                      <FormControl fullWidth size="small">
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={locationIn}
                          onChange={(e) => {
                            setLocationIn(e.target.value);
                          }}
                        >
                          <MenuItem value={"Km"}>Km</MenuItem>
                          <MenuItem value={"Mile"}>Mile</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  )}
                </div>
                {/* )} */}
                {props?.CreateCourseObj?.[props?.isClientLocation] && (
                  <div
                    style={{
                      width: "100%",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      paddingHorizontal: 20,
                      marginTop: "10px",
                      display: "flex",
                    }}
                  >
                    <Slider
                      max={50}
                      sx={{ width: "96%", marginLeft: "11px" }}
                      aria-label="Default"
                      valueLabelDisplay="auto"
                      value={props?.CreateCourseObj?.radius || 0}
                      onChange={(e) => {
                        props.changeCreateObj(e.target.value, "radius");
                      }}
                    />
                    <AautiText
                      textAlign={"left"}
                      title={`Radius: ${
                        props?.CreateCourseObj?.radius || 0
                      }${locationIn}s`}
                      textStyle={{ marginTop: "10" }}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
          {isAddressAvailable && (
            <>
              {
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <JoinButton
                    disabled={
                      props?.CreateCourseObj?.[props?.isClientLocation] &&
                      props?.CreateCourseObj?.radius == ""
                    }
                    buttonStyle={{
                      marginTop: 20,
                    }}
                    title={AautiStrings?.continueString}
                    onClick={() => {
                      if (
                        props?.CreateCourseObj?.[props?.isClientLocation] &&
                        props?.CreateCourseObj?.radius == ""
                      ) {
                        alert("radius cannot be 0");
                      } else {
                        props?.handleSetSelectAddress(locationIn);
                      }
                    }}
                    width="50%"
                  />
                </div>
              }
            </>
          )}
        </div>
        <Location1
          {...{
            isVisible,
            add,
            menu2,
            menu1,
            edit,
            setEdit,
            currentLocClicked,
            setCurrentLocClicked,
            handleSaveEditAddress,
            handleMapVisible,
            changeAddress,
            addresses,
            handleSaveaddress,
            dispatch,
            userLocation,
            handleSetAddress,
            setActiveIndex,
            coordinates,
            setCoordinates,
            errorMessage,
            setAddresses,
            getUserLocation,
          }}
          selectedAddress={props?.CreateCourseObj?.address}
          handleSelectAddress={props.handleSelectAddress}
        />
      </Carousel>
    </div>
  );
};

export default CourseInPerson;
