import React, { useEffect, useState } from "react";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { weekName } from "../createCourse/Constants";
import moment from "moment";
import AautiText from "../../globalComponents/AautiText";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import _, { isEmpty } from "lodash";
import { Divider, IconButton, Popover } from "@mui/material";
import AautiCheckbox from "../../globalComponents/AautiCheckbox";
import { AautiStrings } from "../../globalComponents/AautiStrings";
import NavButton from "./NavButton";
import TrashIcon from "../../globalComponents/icons/TrashIcon";

const WeeklySlotSelection = ({
  courseBatch,
  editItem,
  nextTimeSlot,
  setCourseBatch,
  setDialogOpen,
  setEditItem,
}) => {
  const [activeWeek, setActiveWeek] = useState("");
  const [recurringObject, setRecurringObject] = useState({});
  const { mobileDevices, ipodDevices, aboveLargeMobileDevices } = useSelector(
    (state) => state.responsive
  );
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    const WeekArray = Object.keys(courseBatch?.recurringDays)?.filter(
      (day) => courseBatch?.recurringDays?.[day]?.length > 0
    );

    if (!isEmpty(WeekArray)) {
      // Find the first key with data and update activeWeek
      const firstDayWithData = Object.keys(courseBatch?.recurringDays)?.find(
        (day) => courseBatch?.recurringDays?.[day]?.length > 0
      );
      setActiveWeek(firstDayWithData); // Set the activeWeek state to the first key with data
    }
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    if (courseBatch?.recurringDays) {
      setRecurringObject(courseBatch?.recurringDays);
    } else {
      const createWeekObject = (days) => {
        const week = {};
        days.forEach((day) => {
          week[day] = [];
        });
        return week;
      };

      const weekObject = createWeekObject(weekName);
      setRecurringObject(weekObject);
    }
  }, [courseBatch]);

  const endTime = courseBatch?.recurringDays[activeWeek]
    ? moment(courseBatch?.recurringDays[activeWeek]?.[0]?.startTime)
        .add(1, "hour")
        ?.format("YYYY-MM-DDTHH:mm:ss")
    : moment(nextTimeSlot)?.add(1, "hour")?.format("YYYY-MM-DDTHH:mm:ss");
  const editEndTime = moment(
    editItem?.each?.recurringDays?.[activeWeek]?.[0]?.startTime
  )
    ?.add(1, "hour")
    ?.format("YYYY-MM-DDTHH:mm:ss");

  const addWeekSlotEndTime = (val) => {
    const timeFromat = moment(val?.$d).format("YYYY-MM-DDTHH:mm:00");
    const slot = {
      startTime: courseBatch.recurringDays[activeWeek]?.startTime,
      endTime: timeFromat,
    };
    const recurringDays = courseBatch?.recurringDays;
    const activeDay = courseBatch.recurringDays[activeWeek];
    if (isEmpty(activeDay)) {
      activeDay.push({ endTime: timeFromat });
    } else {
      activeDay[0].endTime = timeFromat;
    }
    recurringDays[activeWeek] = activeDay;

    setCourseBatch((prev) => ({
      ...prev,
      recurringDays: recurringDays,
    }));
  };
  const addWeekSlot = (val) => {
    const timeFromat = moment(val?.$d).format("YYYY-MM-DDTHH:mm:00");

    const slot = {
      startTime: timeFromat,
      endTime: "",
    };

    const recurringDays = courseBatch?.recurringDays;
    const activeDay = courseBatch.recurringDays[activeWeek];
    if (isEmpty(activeDay)) {
      activeDay.push({
        startTime: timeFromat,
        endTime: moment(timeFromat)
          ?.add(1, "hour")
          ?.format("YYYY-MM-DDTHH:mm:ss"),
      });
    } else {
      activeDay[0].startTime = timeFromat;
      activeDay[0].endTime = moment(timeFromat)
        ?.add(1, "hour")
        ?.format("YYYY-MM-DDTHH:mm:ss");
    }
    recurringDays[activeWeek] = activeDay;
    setCourseBatch((prev) => ({
      ...prev,
      recurringDays: recurringDays,
    }));
  };

  const handleUpdateSlotWeek = (week, bool, obj) => {
    let recurringDays = courseBatch?.recurringDays;
    if (bool) {
      recurringDays[week] = [obj];
      setCourseBatch((prev) => ({
        ...prev,
        recurringDays: recurringDays,
      }));
    } else {
      recurringDays[week] = [];
      setCourseBatch((prev) => ({
        ...prev,
        recurringDays: recurringDays,
      }));
    }
  };

  function compareSlots(slot1, slot2) {
    const bool = _.isEqual(slot1, slot2);
    return bool;
  }

  const handleAllWeek = (actWeek, bool, obj) => {
    const schedule = courseBatch?.recurringDays;
    let updatedSchedule;
    if (bool) {
      updatedSchedule = Object.keys(schedule).reduce((acc, day) => {
        acc[day] = [obj]; // Add the default time slot
        return acc;
      }, {});
    } else {
      updatedSchedule = Object.keys(schedule).reduce((acc, day) => {
        if (day === actWeek) {
          acc[day] = schedule[day]; // Keep existing time slots for the specified day
        } else {
          acc[day] = []; // Clear other days
        }
        return acc;
      }, {});
    }

    setCourseBatch((prev) => ({
      ...prev,
      recurringDays: updatedSchedule,
    }));
  };
  const hasValues = Object.values(courseBatch?.recurringDays).some(
    (timeSlots) => timeSlots.length > 0
  );

  const handleDeleteSlot = () => {
    const activeDay = courseBatch.recurringDays[activeWeek];
    let recurringDays = courseBatch?.recurringDays;

    recurringDays[activeWeek] = [];
    setCourseBatch((prev) => ({
      ...prev,
      recurringDays: recurringDays,
    }));
  };

  return (
    <>
      <div className="weeknames-map">
        {Object.keys(recurringObject)?.map((each) => {
          const hasData = recurringObject[each]?.length;
          return (
            <div
              onClick={() => {
                setActiveWeek(each);
              }}
              className="weekNae"
              style={{
                backgroundColor:
                  activeWeek === each ? "rgba(49, 102, 186, 1)" : "transparent",
                borderColor:
                  hasData || activeWeek === each ? "#2e5da6d6" : "#d2d2d2",
                color: activeWeek === each && "white",
              }}
            >
              <AautiText
                title={each?.slice(0, mobileDevices ? 1 : 3)}
                size={"semi"}
                weight={"bold"}
              />
            </div>
          );
        })}
      </div>
      {activeWeek !== "" && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <AautiText
              title={activeWeek?.slice(0, 3)}
              textStyle={{ marginTop: 12, color: "#000" }}
            />
            {!isEmpty(recurringObject?.[activeWeek]) && (
              <IconButton
                disabled={isEmpty(recurringObject[activeWeek])}
                aria-describedby={id}
                variant="contained"
                onClick={handleClick}
              >
                <ContentCopyOutlinedIcon />
              </IconButton>
            )}
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <div style={{ padding: 10 }}>
                <AautiCheckbox
                  onChange={(e) => {
                    const defaultObj = recurringObject[activeWeek]?.[0];
                    const value = e.target.checked;
                    handleAllWeek(activeWeek, value, defaultObj);
                    // handleUpdateSlotWeek(week, value, defaultObj);
                  }}
                  // checked={compareSlots(activeWeekObj,)}
                  key={"selectAl"}
                  inputId={"selectAll"}
                  inputLabel={"Select All"}
                />
                <Divider />
                {weekName?.map((week, index) => {
                  const hasData = recurringObject[week]?.length;
                  const activeWeekObj = recurringObject[activeWeek]?.[0];
                  const weekObject = recurringObject[week]?.[0];
                  return (
                    <AautiCheckbox
                      // disable={activeWeek === week}
                      onChange={(e) => {
                        if (activeWeek == week) {
                          handleClose();
                        }
                        const defaultObj = recurringObject[activeWeek]?.[0];
                        const value = e.target.checked;
                        handleUpdateSlotWeek(week, value, defaultObj);
                      }}
                      checked={compareSlots(activeWeekObj, weekObject)}
                      key={index}
                      inputId={week}
                      inputLabel={week}
                    />
                  );
                })}
              </div>
            </Popover>
          </div>
          <div
            className="start-end-time"
            style={{
              width: "100%",
              border: "none",
              gap: 5,
              padding: 0,
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "50%",
              }}
              className="eachDateStart"
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["Time Picker"]}>
                  <DemoItem label={AautiStrings?.StartTimeString}>
                    <TimePicker
                      timeSteps={{ minutes: 30 }}
                      ampm={false}
                      // minTime={
                      //   moment(dailyObj?.startDate).format("YYYY-MM-DD") ===
                      //   moment(new Date()).format("YYYY-MM-DD")
                      //     ? dayjs(nextTimeSlot)
                      //     : null
                      // }
                      // disablePast={
                      //   moment(courseBatch?.startDate).format("YYYY-MM-DD") ===
                      //   moment(new Date()).format("YYYY-MM-DD")
                      // }
                      value={
                        recurringObject?.[activeWeek][0]?.startTime
                          ? dayjs(recurringObject?.[activeWeek][0]?.startTime)
                          : null
                      }
                      onChange={(e) => addWeekSlot(e, "startTime")}
                      slotProps={{ field: { readOnly: true } }}
                      minutesStep={30}
                    />
                  </DemoItem>
                </DemoContainer>
              </LocalizationProvider>
            </div>
            <div
              className="eachDateStart"
              style={{
                width: "50%",
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["TimePicker"]}>
                  <DemoItem label={AautiStrings?.EndTimeString}>
                    <TimePicker
                      ampm={false}
                      timeSteps={{ minutes: 30 }}
                      minTime={editItem ? dayjs(editEndTime) : dayjs(endTime)}
                      value={
                        recurringObject?.[activeWeek][0]?.endTime
                          ? dayjs(recurringObject?.[activeWeek][0]?.endTime)
                          : null
                      }
                      onChange={(e) => {
                        addWeekSlotEndTime(e, "endTime");
                      }}
                      slotProps={{ field: { readOnly: true } }}
                      minutesStep={30}
                    />
                  </DemoItem>
                </DemoContainer>
              </LocalizationProvider>
            </div>
            {!isEmpty(courseBatch?.recurringDays[activeWeek]) && (
              <TrashIcon
                buttonStyle={{ marginTop: 5 }}
                onClick={() => {
                  console.log(recurringObject[activeWeek]?.length, "hey buddy");
                  handleDeleteSlot();
                }}
                height="30px"
                width="30px"
              />
            )}
          </div>
        </div>
      )}
      <NavButton
        text={"Done"}
        testId={"week-done-button"}
        disabled={!hasValues}
        handleClick={() => {
          setDialogOpen("");
        }}
      />
    </>
  );
};

export default WeeklySlotSelection;
