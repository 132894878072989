import React, { useCallback, useState } from "react";
import AautiText from "../../globalComponents/AautiText";
import moment from "moment";
import {
  Avatar,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextField,
  Tooltip,
} from "@mui/material";
import { options } from "./feed/poll/constants";
import { HiOutlineDotsVertical } from "react-icons/hi";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { CapitalizeFirstLetter } from "../../CommonFunctions";
import { CalenderStrings } from "../CalenderStrings";
import JoinButton from "../../globalComponents/JoinButton";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { AuthService } from "./feed/task/service";
import { showToast } from "../../globalComponents/Toast";
import { FaChevronRight } from "react-icons/fa6";
import { GoDotFill, GoTrash } from "react-icons/go";
import AautiDialog from "../../globalComponents/AautiDialog";
import SearchIcon from "@mui/icons-material/Search";
import { isEmpty } from "lodash";
import { Dropdown } from "primereact/dropdown";
import AautiTextInput from "../../globalComponents/AautiTextInput";
import {
  IoIosCloseCircle,
  IoIosCloseCircleOutline,
  IoMdClose,
  IoMdCloseCircle,
} from "react-icons/io";
import { FiPlus, FiPlusCircle } from "react-icons/fi";
import styled from "styled-components";
import { ApiPaths } from "../../../service/api-constants";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { IoCamera } from "react-icons/io5";
import { ChatGptFunc } from "../../course/createCourse/Services";

const AssignmentCard = (props) => {
  const {
    setSwitchChange,
    setSubmitClicked,
    scheduleDetails,
    cardDetails,
    UserRole,
    screen,
    handleOnClickEditMenu,
    switchChange,
    selectAssignSession,
    classDetails,
    AllSessions,
    sessionDetails,
    setCountPopUp,
    countPopUp,
    handleConfirmCountDialogClose,
  } = props;

  const [data, setData] = useState(cardDetails);
  const QuestionsType = ["Open Ended", "MCQ"];
  const [doneClicked, setDoneClicked] = useState(false);
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(0);
  const [aiLoad, setAiLoad] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [studentsList, setStudentsList] = useState([]);
  const [correctionAns, setCorrectionAns] = useState();
  const [anchorQuestion, setAnchorQuestion] = useState(null);
  const [MenuActiveIndex, setMenuActiveIndex] = useState(null);
  const [answers, setAnswers] = useState([]);
  const [camIndex, setCamIndex] = useState();
  const regex = /^[0-9]*$/;
  const studentSelection = ["EveryOne", "Selected"];
  const [selectedOption, setSelectedOption] = useState(null);
  const [questionsType, setQuestionsTypeState] = useState("");
  const [aiCount, setAiCount] = useState(1);
  const [aiTotalPoints, setAiTotalPoints] = useState(10);
  const [submitCorrectionObj, setSubmitCorrectionObj] = useState({
    _id: "",
    answers: correctionAns,
    endUserId: "",
    assignmentRemarks: "",
  });

  const [askopenName, setAskOpenName] = useState(
    "Task Correction(Session 1) - 25 May 2024"
  );
  const [uploadLoad, setUploadLoad] = useState(false);
  const [studentsSearchValue, setStudentsSearchValue] = useState("");
  const [askopen, setAskOpen] = useState(false);
  const [popupName, setPopupName] = useState("");
  const [clickedUser, setClickedUser] = useState({});
  const [eachLoad, setEachLoad] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState(
    classDetails?.subscribers || []
  );
  const [eachAssign, setEachAssign] = useState({});
  const [saveAssignment, setSaveAssignment] = useState({});
  const [dataLoad, setDataLoad] = useState(false);
  const [studentSelectionState, setStudentSelectionState] =
    useState("EveryOne");
  const [saveEditDelClicked, setSaveEditDelClicked] = useState(false);
  const [assignDataLoad, setAssignDataLoad] = useState(false);
  const [submissionDialog, setSubmissionDialog] = useState(false);
  const [enablePoints, setEnablePoints] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [resultId, setResultId] = useState("");
  const [questionsArray, setQuestionsArrray] = useState([]);
  const [points, setPoints] = useState("");
  const [dropObj, setDropObj] = useState(selectAssignSession);
  const [activeIndex, setActiveIndex] = useState(0);
  const [detailsDialog, setDetailsDialog] = useState(false);
  const { miniLapys, mobileDevices, ipodDevices } = useSelector(
    (state) => state.responsive
  );
  const today = new Date(scheduleDetails?.startTime);
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;
  const { loggedinUserId } = useSelector((state) => state.app);
  const [submitAssign, setSubmitAssign] = useState({
    _id: eachAssign?._id,
    endUserId: loggedinUserId,
    answers: [], // Initial empty array, later populated
    type: "assignment",
  });
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  function formatDate(dateString) {
    const startDate = moment(dateString?.startDate);
    const endDate = moment(dateString?.endDate);
    const startFormatted = startDate.format("ddd, DD MMM YYYY");
    const endFormatted = endDate.format("ddd, DD MMM YYYY");
    const range = `${startFormatted} - ${endFormatted}`;
    return range;
  }

  const handleOptionTextChange = (index, optIndex, newText) => {
    const updatedQuestionsArray = questionsArray.map((q, i) =>
      i === index
        ? {
            ...q,
            options: q.options.map((opt, j) =>
              j === optIndex ? { ...opt, text: newText } : opt
            ),
          }
        : q
    );

    setQuestionsArrray(updatedQuestionsArray);
    setSaveAssignment((prev) => ({
      ...prev,
      questions: updatedQuestionsArray,
    }));
  };

  const handleDeleteOption = (optIndex, index) => {
    const updatedQuestionsArray = questionsArray.map((q, i) =>
      i === index
        ? {
            ...q,
            options: q.options.filter((_, j) => j !== optIndex),
          }
        : q
    );

    setQuestionsArrray(updatedQuestionsArray);
    setSaveAssignment((prev) => ({
      ...prev,
      questions: updatedQuestionsArray,
    }));
  };

  const handleViewDraft = (each) => {
    setAskOpenName("Assignment Details");
    setAskOpen(true);
    setPopupName("View");
    setEachLoad(true);

    AuthService.getDraftAssignmentCreationById(
      each?._id,
      onSuccessEachAssign,
      onFailure
    );
  };

  const isEnabled = questionsArray?.some((quest) => quest.enablePoints);

  const onSuccessEachAssign = (response) => {
    if (response && response?.data?.status === "Success") {
      if (switchChange) {
        setEachAssign(response?.data?.result);
      } else {
        setEachAssign(response?.data?.result[0]);
      }

      setEachLoad(false);
    } else {
      setEachLoad(false);
    }
  };
  const onFailure = (error) => {
    setEachLoad(false);
    setDataLoad(false);
    setSaveEditDelClicked(false);
    setAssignDataLoad(false);
  };

  const renderViewTime = (each) => {
    const StartDateTime = moment(each?.startDate);
    const today = moment().startOf("day");
    if (StartDateTime?.isAfter(today, "day")) {
      return true;
    } else {
      return false;
    }
  };

  const handleAssignmentEachDetail = (each) => {
    if (UserRole === "serviceprovider") {
      setAskOpenName("Assignment Details");
      setAskOpen(true);
      setPopupName("View");
      setEachLoad(true);
      if (switchChange) {
        AuthService.getDraftAssignmentCreationById(
          each?._id,
          onSuccessEachAssign,
          onFailure
        );
      } else {
        AuthService.getAssignmentDetailsBySpId(
          each?._id,
          "assignment",
          loggedinUserId,
          onSuccessEachAssign,
          onFailure
        );
      }
    } else {
      const givenDate = moment.utc(each?.endDate);
      const today = moment.utc().startOf("day");
      const isBefore = givenDate.isBefore(today, "day");
      if (isBefore) {
        showToast("error", CalenderStrings?.subMissionDateExp);
      } else {
        setSubmissionDialog(true);
        if (screen === "feed") {
          if (each?.iscorrected) {
            setPopupName("View");
            setAskOpenName("Assignment Details");
          } else {
            setPopupName("Submission");
            setAskOpenName("Assignment Submission");
          }
        } else {
          setPopupName("Submission");
          setAskOpenName("Assignment Submission");
        }

        AuthService.getAssignmentDetailsByEuId(
          loggedinUserId,
          each?._id,
          "assignment",
          onSuccessEachAssign,
          onFailure
        );
      }
    }
  };

  const handleOnClickResults = (each) => {
    setAskOpenName("Assignment Correction");
    setAskOpen(true);
    setPopupName("Results");
    AuthService.getAssignmentUserListById(
      each._id,
      "assignment",
      onSuccessUserList,
      onFailure
    );
  };

  const onSuccessUserList = (response) => {
    if (response?.data.status === "Success") {
      setUsersList(response?.data?.result);
    } else {
      console.log(response?.data?.message);
    }
  };

  const handleCompleteAssignmentDetails = (each) => {
    setDetailsDialog(true);
    setAskOpenName("Assignment Details");
    AuthService.getCompleteAssignmentDetailsByEUId(
      each?._id,
      loggedinUserId,
      "assignment",
      onSuccessEUComplete,
      onFailure
    );
  };

  const onSuccessEUComplete = (response) => {
    if (response?.data?.status === "Success") {
      setEachAssign(response?.data?.result?.[0]);
    } else {
      // showToast("error", response?.data?.message);
    }
  };

  const handleStudentsSearch = (e) => {
    const searchValue = e.target.value.trim().toLowerCase();
    setStudentsSearchValue(searchValue);
    const filteredStudentsList = searchValue
      ? classDetails?.subscribers?.filter((each) =>
          each.displayName.toLowerCase().startsWith(searchValue)
        )
      : classDetails?.subscribers;

    setStudentsList(filteredStudentsList);
  };

  const extractSessionNumber = (sessionName) => {
    const match = sessionName?.match(/Session (\d+)/);
    return match ? match[1] : null;
  };

  const handleUserClick = (item) => {
    const isSelected = studentsList?.some((user) => user?._id === item?._id);

    if (isSelected) {
      setStudentsList(studentsList.filter((user) => user?._id !== item?._id));
    } else {
      setStudentsList([...studentsList, item]);
    }
  };

  const handleSelectSession = (e) => {
    if (e.value) {
      const objectIdVal = e.value;
      setDropObj(e.value);

      setSaveAssignment({
        ...saveAssignment,
        sessionId: objectIdVal?._id,
      });
    }
  };

  const handleRemoveQuestion = (each, index) => {
    setQuestionsArrray(questionsArray.filter((_, ind) => ind !== index));
    setQuestionsTypeState("");
    setActiveQuestionIndex(0);
  };

  const handleClickQuestion = (event) => {
    setAnchorQuestion(event.currentTarget);
  };

  const handleQuestionsArray = (each) => {
    const newQuestion =
      each === "Open Ended"
        ? {
            type: "Open Ended",
            description: "",
            uploadedFiles: [],
            points: 0,
            enablePoints: false,
          }
        : {
            type: "MCQ",
            description: "",
            uploadedFiles: [],
            points: 0,
            options: [
              { option: "1", text: "", image: "" },
              { option: "2", text: "", image: "" },
              { option: "3", text: "", image: "" },
              { option: "4", text: "", image: "" },
            ],
            correctAnswer: "",
            enablePoints: false,
          };

    const newUpdatedArray = [...questionsArray, newQuestion];

    setQuestionsArrray([...questionsArray, newQuestion]);
    setSaveAssignment({ ...saveAssignment, questions: newUpdatedArray });
  };

  const handleDescriptionChange = (index, value) => {
    const updatedQuestionsArray = questionsArray.map((question, i) =>
      i === index ? { ...question, description: value } : question
    );
    setQuestionsArrray(updatedQuestionsArray);
    setSaveAssignment({ ...saveAssignment, questions: updatedQuestionsArray });
  };

  const handleChangeFile = async (e, index, optIndex) => {
    const file = e.target.files[0];

    if (file) {
      const formData = new FormData();
      setUploadLoad(true);
      setCamIndex(index);
      formData.append("file", file);

      try {
        let res = await fetch(ApiPaths.BASE_URL + "file/upload", {
          method: "POST",
          body: formData,
        });

        let responseJson = await res.json();

        if (res.ok && responseJson.status === "Success") {
          const imageUrl = responseJson.result;

          // Reset cam index after successful upload
          setCamIndex();

          // Update the questions array with the new image for the selected option
          const updatedQuestionsArray = questionsArray.map((q, i) => {
            if (i === index) {
              return {
                ...q,
                options: q.options.map((opt, j) => {
                  if (j === optIndex) {
                    return { ...opt, image: imageUrl }; // Add the uploaded image to the correct option
                  }
                  return opt;
                }),
              };
            }
            return q;
          });

          // Update the state for both questionsArray and saveAssignment
          setQuestionsArrray(updatedQuestionsArray);
          setSaveAssignment((prev) => ({
            ...prev,
            questions: updatedQuestionsArray,
          }));
        } else {
          console.error("Failed to upload image:", responseJson.message);
        }
      } catch (error) {
        console.error("Error uploading file:", error);
      } finally {
        setUploadLoad(false);

        // Reset the file input so the same file can be uploaded again
        e.target.value = null;
      }
    }
  };

  const deleteImage = (index, optIndex) => {
    const updatedQuestionsArray = questionsArray.map((q, i) => {
      if (i === index) {
        return {
          ...q,
          options: q.options.map((opt, j) => {
            if (j === optIndex) {
              return { ...opt, image: "" }; // Set the image URL to an empty string to "delete" the image
            }
            return opt;
          }),
        };
      }
      return q;
    });

    setQuestionsArrray(updatedQuestionsArray);
    setSaveAssignment((prev) => ({
      ...prev,
      questions: updatedQuestionsArray,
    }));
  };

  const handleClick4 = (index) => {
    const updatedQuestionsArray = questionsArray.map((q, i) =>
      i === index
        ? {
            ...q,
            options: [
              ...q.options,
              {
                option: (q.options.length + 1).toString(),
                text: "",
                image: "",
              },
            ],
          }
        : q
    );
    setQuestionsArrray(updatedQuestionsArray);
  };
  const handleChangeAddFiles = async (e, index) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    try {
      let res = await fetch(ApiPaths.BASE_URL + "file/upload", {
        method: "POST",
        body: formData,
      });

      let responseJson = await res.json();

      if (res.ok && responseJson.status === "Success") {
        // const imageUrl = responseJson.result;

        const updatedQuestionsArray = questionsArray.map((q, i) => {
          if (i === index) {
            return {
              ...q,
              uploadedFiles: [...q.uploadedFiles, responseJson.result],
            };
          }
          return q;
        });

        setQuestionsArrray(updatedQuestionsArray);
        setSaveAssignment((prev) => ({
          ...prev,
          questions: updatedQuestionsArray,
        }));
      } else {
        console.error("Failed to upload image:", responseJson.message);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    } finally {
      setUploadLoad(false);
    }
  };
  const handleAnswerChange = useCallback(
    (each, index, value) => {
      const numericValue = parseInt(value);
      const numberOfOptions = each.options.length;

      if (value === "") {
        const updatedQuestionsArray = saveAssignment?.questions.map((q, i) =>
          i === index ? { ...q, correctAnswer: "" } : q
        );
        setQuestionsArrray(updatedQuestionsArray);
        setSaveAssignment((prevQuiz) => ({
          ...prevQuiz,
          questions: updatedQuestionsArray,
        }));
      } else if (!isNaN(numericValue) && numericValue <= numberOfOptions) {
        const updatedQuestionsArray = saveAssignment?.questions.map((q, i) =>
          i === index ? { ...q, correctAnswer: numericValue.toString() } : q
        );

        setQuestionsArrray(updatedQuestionsArray);
        setSaveAssignment((prevQuiz) => ({
          ...prevQuiz,
          questions: updatedQuestionsArray,
        }));
      } else {
        // setError(true);
      }
    },
    [saveAssignment?.questions]
  );

  const handleDeleteImage = (questionIndex, imageIndex) => {
    setQuestionsArrray((prevQuestionsArray) => {
      const updatedQuestions = [...prevQuestionsArray];

      // Safely check if uploadedFiles exists for the selected question
      if (updatedQuestions[questionIndex]?.uploadedFiles) {
        const updatedFiles = updatedQuestions[
          questionIndex
        ].uploadedFiles.filter((file, index) => index !== imageIndex);

        // Set the updated files back into the question
        updatedQuestions[questionIndex].uploadedFiles = updatedFiles;
      } else {
        console.error("uploadedFiles is undefined for this question");
      }

      return updatedQuestions;
    });
  };

  const handleEnablePointsChange = useCallback(
    (e, each, index, type) => {
      const updatedQuestionsArray = questionsArray.map((q, i) =>
        i === index
          ? {
              ...q,
              enablePoints: !q.enablePoints,
              points: parseInt(each.points),
            }
          : q
      );

      setQuestionsArrray(updatedQuestionsArray);
      setEnablePoints(!enablePoints);
      const totalPoints = updatedQuestionsArray.reduce(
        (total, question) =>
          total + (question.enablePoints ? question.points : 0),
        0
      );

      setSaveAssignment({
        ...saveAssignment,
        questions: updatedQuestionsArray,
        totalPoints: totalPoints,
      });
    },
    [questionsArray, saveAssignment]
  );

  const handlePointsChange = useCallback(
    (each, index, value) => {
      setPoints(value.substring(1));
      const updatedQuestionsArray = questionsArray.map((q, i) =>
        i === index ? { ...q, points: q.enablePoints ? parseInt(value) : 0 } : q
      );
      setQuestionsArrray(updatedQuestionsArray);
      const totalPoints = updatedQuestionsArray.reduce(
        (total, question) =>
          total + (question.enablePoints ? question.points : 0),
        0
      );

      setSaveAssignment({
        ...saveAssignment,
        questions: updatedQuestionsArray,
        totalPoints: totalPoints,
      });
    },
    [questionsArray, saveAssignment]
  );

  const disable = !(aiCount >= 1 && aiCount <= 10);
  const disblePublish =
    popupName !== "Edit"
      ? isEmpty(saveAssignment?.title) || // Disable if title is empty
        isEmpty(questionsArray) ||
        questionsArray.some(
          (question) =>
            question.description === "" ||
            (question.type === "MCQ" &&
              (question.options.some((option) => option.text === "") ||
                question.correctAnswer === ""))
        ) ||
        (enablePoints && points === "") ||
        isEmpty(classDetails?.subscribers) ||
        (studentSelectionState === "Selected" && isEmpty(selectedUsers))
      : false ||
        parseInt(extractSessionNumber(dropObj?.sessionName)) >
          parseInt(extractSessionNumber(selectAssignSession?.sessionName)) ||
        isEmpty(classDetails?.subscribers) ||
        (studentSelectionState === "Selected" && isEmpty(selectedUsers));

  const onSuccessDeleteAssignment = (response) => {
    if (response?.data?.status === "Success") {
      setSaveEditDelClicked(false);
      showToast("success", response?.data?.message);
    } else {
      setSaveEditDelClicked(false);
    }
  };

  const disabeDraft =
    popupName !== "Edit" ? saveAssignment?.title === "" : false;

  const handleCreateAssignment = () => {
    setSaveEditDelClicked(true);
    setAskOpen(false);
    setQuestionsTypeState("");

    if (switchChange) {
      const newSave = { ...saveAssignment, questions: questionsArray };
      AuthService.CreateAssignment(
        newSave,
        (response) => onSuccessSaveAssignment(response, "publish"),
        onFailure
      );
      AuthService.DeleteDraftAssignment(
        saveAssignment?._id,
        onSuccessDeleteAssignment,
        onFailure
      );
    } else {
      const newSave = { ...saveAssignment, questions: questionsArray };

      AuthService.CreateAssignment(
        newSave,
        (response) => onSuccessSaveAssignment(response, "publish"),
        onFailure
      );
    }
  };

  const handleDraftAssignment = () => {
    setAskOpen(false);
    setQuestionsTypeState("");

    const newSave = { ...saveAssignment, questions: questionsArray };

    if (newSave.hasOwnProperty("_id")) {
      delete newSave?._id;

      AuthService.SaveDraftAssignment(
        newSave,
        (response) => onSuccessSaveAssignment(response, "draft"),
        onFailure
      );
    } else {
      AuthService.SaveDraftAssignment(
        newSave,
        (response) => onSuccessSaveAssignment(response, "draft"),
        onFailure
      );
    }
  };

  const handleUpdateAssignment = () => {
    setSaveEditDelClicked(true);
    setAskOpen(false);
    setQuestionsTypeState("");

    const newSave = { ...saveAssignment, questions: questionsArray };
    AuthService.UpdateAssignment(
      newSave,
      (response) => onSuccessSaveAssignment(response, "publish"),
      onFailure
    );
  };

  const onSuccessSaveAssignment = (response, text) => {
    if (response?.data?.status === "Success") {
      setSaveEditDelClicked(false);
      setAskOpen(false);
      setSubmitClicked(false);
      showToast("success", response?.data?.message);
      if (text === "draft") {
        setSwitchChange(true);
      } else {
        setSwitchChange(false);
      }
    } else {
      setSaveEditDelClicked(false);
      setAskOpen(false);
      setSubmitClicked(false);
    }
  };

  const handleUpdateDraft = () => {
    setSaveEditDelClicked(true);
    setAskOpen(false);
    const newSave = { ...saveAssignment, questions: questionsArray };
    AuthService.UpdateDraftAssignment(
      newSave,
      (response) => onSuccessSaveAssignment(response, "draft"),
      onFailure
    );
  };

  const handleDescription = (titleContent) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = titleContent;
    const textContent = tempElement.textContent;
    return textContent;
  };

  const handleRemarksChange = (e, questionId) => {
    const newRemark = e.target.value;
    // Use a functional update to avoid state overwrite issues
    setSubmitCorrectionObj((prevState) => {
      // Update the remarks for the specific question in the answers array
      const updatedAnswers = prevState.answers?.map((answer) =>
        answer.questionId === questionId
          ? { ...answer, remarks: newRemark } // Update remarks with the latest value
          : answer
      );

      return {
        ...prevState, // Maintain other parts of the object
        answers: updatedAnswers, // Set the updated answers
      };
    });
  };

  const handlePointsCorrectionChange = (e, questionId) => {
    const newRemark =
      e.target.value >
      eachAssign?.questions?.find((each) => each._id === questionId)?.points
        ? 0
        : e.target.value;
    // Use a functional update to avoid state overwrite issues
    setSubmitCorrectionObj((prevState) => {
      // Update the remarks for the specific question in the answers array
      const updatedAnswers = prevState.answers?.map((answer) =>
        answer.questionId === questionId
          ? { ...answer, correctedPoints: parseInt(newRemark) } // Update remarks with the latest value
          : answer
      );

      // Log the updatedAnswers for debugging

      return {
        ...prevState, // Maintain other parts of the object
        answers: updatedAnswers, // Set the updated answers
      };
    });
  };

  const handleSubmitAssignmentCorrection = () => {
    const Nothing = submitCorrectionObj.answers;
    const newObj = {
      ...submitCorrectionObj,
      _id: clickedUser._id,
      endUserId: clickedUser.endUserId,
    };

    setSubmissionDialog(false);
    AuthService.UpdateAssignmentCorrection(
      newObj,
      onSuccessAssignmentCorrection,
      onFailure
    );
  };

  const onSuccessAssignmentCorrection = (response) => {
    if (response?.data?.status === "SUccess") {
      showToast("Success", response?.data?.message);
      setData({ ...data, iscorrected: true });
    } else {
      // showToast("error", response?.data?.message);
      setData(data);
    }
  };

  const handleChangeYourAnswer = (e, streak) => {
    const answerText = e.target.value;

    const existingAnswerIndex = submitAssign?.answers?.findIndex(
      (each) => each.questionId === streak._id
    );

    let updatedAnswers;

    if (existingAnswerIndex !== -1) {
      // If the answer exists, update it
      updatedAnswers = submitAssign?.answers?.map((each, index) =>
        index === existingAnswerIndex
          ? {
              ...each,
              description: answerText,
              type: "Open Ended",
              uploadedFiles: [],
              remarks: "",
            }
          : each
      );
    } else {
      // If the answer doesn't exist, add it to the array
      updatedAnswers = [
        ...submitAssign.answers,
        { questionId: streak._id, description: answerText },
      ];
    }

    // Update the submitAssign state without clearing previous answers
    setSubmitAssign({
      ...submitAssign,
      answers: updatedAnswers,
    });
  };

  const isSubmitButtonDisabled = () => {
    // Check if there are no questions
    if (isEmpty(eachAssign?.questions)) return true;

    // Check if each question has an answer
    return eachAssign.questions.some((question) => {
      const answer = submitAssign?.answers?.find(
        (ans) => ans.questionId === question._id
      );

      if (question.type === "Open Ended") {
        return !answer || answer.description.trim() === ""; // Check if description is empty
      } else if (question.type === "MCQ") {
        return !answer || answer.description.trim() === ""; // Check if MCQ answer is selected
      }
      return true;
    });
  };

  const handleSubmitAssignment = () => {
    // Set the _id dynamically before submitting
    const updatedSubmitAssign = {
      ...submitAssign,
      _id: eachAssign?._id, // Ensure eachAssign._id is populated here
    };

    setSubmissionDialog(false);
    screen === "assignment" && setSubmitClicked(true);

    AuthService.endUserAssignmentSubmission(
      updatedSubmitAssign,
      onSuccessSubmission,
      onFailure
    );
  };

  const onSuccessSubmission = (response) => {
    if (response.data?.status === "Success") {
      if (screen === "feed") {
        setData({ ...data, isSubmitted: true });
      } else {
        setSubmitClicked(false);
      }
    } else {
      if (screen === "feed") {
        setData(data);
      } else {
        setSubmitClicked(false);
      }
    }
  };

  const generateQUestions = () => {
    setCountPopUp(false);
    setAiLoad(true);
    const prompt =
      questionsType === "Open Ended"
        ? `Create ${aiCount} different open ended questions and points on ${sessionDetails?.category} category and ${sessionDetails?.subCategory} subCategory for ${sessionDetails?.level[0]} level with points total of ${aiTotalPoints} in this format [{"question": "","points":number}]`
        : `Create ${aiCount} different multiple choice questions and answers on ${sessionDetails?.category} category and ${sessionDetails?.subCategory} subCategory for ${sessionDetails?.level[0]} level with points total of ${aiTotalPoints} in this format [{"question": "",options:[{ "option": number, "text": "" }], "points":number, "correctAnswer":number}]`;
    ChatGptFunc(
      prompt,
      (response) => {
        if (response) {
          setAiLoad(false);
          const newGptResponse = {
            time: moment().format("hh:mm A"),
            myQuestion: prompt,
            gptAnswer: response,
          };

          const responseJson = JSON.parse(newGptResponse?.gptAnswer);
          if (saveAssignment?.questions && responseJson) {
            let totalPoints = 0; // Initialize total points

            if (questionsType === "Open Ended") {
              const newQuestions = responseJson?.map((each) => {
                totalPoints += each?.points || 0; // Accumulate points

                return {
                  type: questionsType,
                  description: each?.question,
                  points: each.points,
                  enablePoints: true,
                };
              });

              setQuestionsArrray((prev) => [...prev, ...newQuestions]);
              setSaveAssignment((prevState) => ({
                ...prevState, // Spread the existing state
                questions: [...prevState.questions, ...newQuestions], // Append new questions to the existing array
                totalPoints: prevState.totalPoints + totalPoints, // Update the total points
              }));
            } else {
              const newQuestions = responseJson?.map((each) => {
                totalPoints += each?.points || 0; // Accumulate points

                return {
                  type: questionsType || "MCQ",
                  description: each?.question || "",
                  uploadedFiles: [],
                  points: each?.points || 0,
                  options: each?.options?.map((option) => ({
                    option: option.option?.toString(), // ensuring it's a string
                    text: option?.text || "",
                    image: "",
                  })) || [
                    { option: "1", text: "", image: "" },
                    { option: "2", text: "", image: "" },
                    { option: "3", text: "", image: "" },
                    { option: "4", text: "", image: "" },
                  ],
                  correctAnswer: each?.correctAnswer?.toString() || "",
                  enablePoints: true,
                };
              });

              setQuestionsArrray((prev) => [...prev, ...newQuestions]);
              setSaveAssignment((prevState) => ({
                ...prevState, // Spread the existing state
                questions: [...prevState.questions, ...newQuestions], // Append new questions to the existing array
                totalPoints: prevState.totalPoints + totalPoints, // Update the total points
              }));
            }
          }
        } else {
          setAiLoad(false);
          setAiCount(1);
        }
        setAiCount(1);
      },
      (error) => {
        setAiLoad(false);
        setAiCount(1);
      }
    );
  };

  // const handleConfirmCountDialogClose = () => {
  //   setCountPopUp(false);
  //   setAiCount(1);
  //   setAiTotalPoints(10);
  // };

  const handleOnClickStudent = (each) => {
    setClickedUser(each);
    if (each.status === "Pending") {
      showToast("error", CalenderStrings?.haveNotSubmittedAsst);
    } else {
      setAskOpen(false);
      setSubmissionDialog(true);
      setPopupName("Correction");
      setAskOpenName("Assignment Correction");
      AuthService.getAssignmentDetailsAnswersForEU(
        each?._id,
        each?.endUserId,
        "assignment",
        onSuccessEUAnswers,
        onFailure
      );
    }
  };

  const onSuccessEUAnswers = (response) => {
    if (response && response?.data?.status === "Success") {
      setEachAssign(response?.data?.result[0]);

      const newAnswers = response?.data?.result?.[0]?.questions?.map(
        (each) => ({
          correctedPoints: each.type === "MCQ" ? each.points : 0,
          questionId: each?._id,
          totalPoints: each?.points,
          remarks: "",
        })
      );

      setSubmitCorrectionObj({
        ...submitCorrectionObj,
        answers: newAnswers,
      });
    } else {
      console.log(response?.data?.message);
    }
  };

  const handleChangeRadio = (event, streak, option) => {
    const updatedAnswers = submitAssign.answers.map((answer) => {
      if (answer.questionId === streak._id) {
        return {
          ...answer,
          description: event.target.value, // Update the description with selected option
        };
      }
      return answer;
    });

    // Check if the answer for the current question already exists
    const answerExists = submitAssign.answers.some(
      (answer) => answer.questionId === streak._id
    );

    // If the answer doesn't exist, add a new one
    if (!answerExists) {
      updatedAnswers.push({
        type: streak.type,
        questionId: streak._id,
        description: event.target.value,
        remarks: "",
        uploadedFiles: [],
      });
    }

    setSubmitAssign({
      ...submitAssign,
      answers: updatedAnswers,
    });
  };

  return (
    <>
      <div
        key={data?._id}
        style={{
          width: "100%",
          display: "flex",
          overflow: "scroll",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginBottom: 15,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <AautiText
              textAlign={"left"}
              size={"small"}
              weight={"normal"}
              textStyle={{
                color: "#828282",
              }}
              title={`Created at ${moment(data?.createdAt).format("MM/DD/YY")}
          ${moment(data?.createdAt).format("hh:mm A")}`}
            />{" "}
            {screen === "feed" && (
              <AautiText
                size={"small"}
                weight={"normal"}
                textStyle={{
                  color: "#828282",
                }}
                title={"Assignment"}
              />
            )}
          </div>

          <div
            style={{
              display: "flex",
              backgroundColor: "#F7F7FA",
              border: "1px solid #E0E0E0",
              borderRadius: 10,
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: "2px solid #E0E0E0",
                padding: 10,
                width: "100%",
              }}
            >
              <AautiText
                weight={"bold"}
                title={data?.title}
                size={"medium"}
                textStyle={{
                  paddingLeft: "10px",
                  color: "#1E1E1E",
                }}
              />
              {UserRole === "serviceprovider" && screen !== "feed" && (
                <div>
                  <Tooltip
                    title={
                      <div style={{ padding: "8px" }}>
                        {options?.map((option) => (
                          <p
                            style={{
                              cursor: "pointer",
                              textAlign: "center",
                              padding: "2px",
                              fontSize: "14px",
                            }}
                            key={option}
                            onClick={(e) =>
                              handleOnClickEditMenu(option, data, data?._id)
                            }
                          >
                            {option}
                          </p>
                        ))}
                      </div>
                    }
                  >
                    <div>
                      <HiOutlineDotsVertical
                        key={data._id.$oid}
                        size={18}
                        onClick={() => {
                          setOpenMenu(true);
                          setMenuActiveIndex(data?._id);
                        }}
                        style={{ alignSelf: "center", cursor: "pointer" }}
                      />
                    </div>
                  </Tooltip>

                  {/* )} */}
                </div>
              )}
            </div>
            {UserRole === "serviceprovider" && screen !== "feed" && (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: mobileDevices ? "column" : "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: 10,
                    width: mobileDevices ? "90%" : "98%",
                    border: "1px solid #E0E0E0",
                    backgroundColor: "white",
                    margin: "10px",
                    borderRadius: "5px",
                  }}
                >
                  <AautiText
                    weight={"medium"}
                    title={CapitalizeFirstLetter(data?.sessionName)}
                    size={"normal"}
                    textStyle={{ paddingLeft: "10px" }}
                  />
                  <AautiText
                    weight={"medium"}
                    title={formatDate(data)}
                    size={mobileDevices ? "small" : "normal"}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 10,
                    flexWrap: "wrap",
                    padding: "10px 15px",
                  }}
                >
                  {data?.assignedUsers?.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        border: "1px solid #E0E0E0",
                        padding: "10px 15px",
                        backgroundColor: "#ffffff",
                        borderRadius: 10,

                        width: mobileDevices
                          ? "45%"
                          : ipodDevices
                          ? "30%"
                          : "15%",
                      }}
                    >
                      <Avatar
                        alt="profileImage"
                        src={item?.profileImage}
                        sx={{ width: 30, height: 30, mr: 1.5 }}
                      />
                      <AautiText
                        title={item?.displayName}
                        size={mobileDevices ? "small" : "normal"}
                        textStyle={{
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          WebkitLineClamp: 1,
                          textOverflow: "ellipsis",
                          maxWidth: "100px",
                        }}
                      />
                    </div>
                  ))}
                </div>
              </>
            )}
            {(UserRole === "endUser" || screen === "feed") && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  margin: "15px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    backgroundColor: "white",
                    padding: "15px",
                    borderRadius: "5px",
                    marginRight: "15px",
                  }}
                >
                  <AautiText
                    title={CalenderStrings?.startDate}
                    size={"normal"}
                    weight={"bold"}
                  />
                  <AautiText
                    weight={"normal"}
                    title={moment(data?.startDate).format("ddd, DD MMM YYYY")}
                    size={"normal"}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    backgroundColor: "white",
                    padding: "15px",
                    borderRadius: "5px",
                  }}
                >
                  <AautiText
                    title={CalenderStrings?.endDate}
                    size={"normal"}
                    weight={"bold"}
                  />
                  <AautiText
                    weight={"normal"}
                    title={moment(data?.endDate).format("ddd, DD MMM YYYY")}
                    size={"normal"}
                  />
                </div>
              </div>
            )}

            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-start",
                padding: "10px 15px",
              }}
            >
              {UserRole === "serviceprovider" ? (
                <JoinButton
                  onClick={() =>
                    switchChange
                      ? handleViewDraft(data)
                      : renderViewTime(data)
                      ? handleAssignmentEachDetail(data)
                      : handleOnClickResults(data)
                  }
                  buttonStyle={{ width: 100, height: 40 }}
                  title={
                    switchChange
                      ? "View"
                      : renderViewTime(data)
                      ? "View"
                      : "Results"
                  }
                />
              ) : (
                <JoinButton
                  onClick={() => {
                    data?.isSubmitted
                      ? handleCompleteAssignmentDetails(data)
                      : data?.iscorrected
                      ? handleAssignmentEachDetail(data)
                      : handleAssignmentEachDetail(data);
                  }}
                  buttonStyle={{ width: 100, height: 40 }}
                  title={
                    data?.isSubmitted
                      ? CalenderStrings?.results
                      : data?.iscorrected
                      ? CalenderStrings?.results
                      : "View"
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <AautiDialog
        titlePosition="left"
        dialogTitle={askopenName}
        open={askopen}
        showCloseIcons={askopenName === "Select Users" && true}
        onClose={() => {
          setAskOpen(false);
          setAskOpenName("");
          setResultId("");
          setSaveAssignment([]);
          setQuestionsArrray([]);
          setAnswers([]);
          setSelectedOption(null);
          // setSubmitAssign({});
          setEnablePoints(false);
          setQuestionsTypeState("");
          setDropObj(selectAssignSession);
          setStudentsSearchValue("");
          setStudentsList(classDetails?.subscribers);
          setStudentSelectionState("EveryOne");
          setActiveIndex(0);
          setSelectedUsers(classDetails?.subscribers || []);
        }}
      >
        {popupName === "Create" || popupName === "Edit" ? (
          dataLoad || aiLoad ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "30vh",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <>
              {askopenName === "Select Users" && doneClicked ? (
                <div>
                  <FormControl
                    size="small"
                    sx={{ m: 1, width: "100%", mb: 1.5 }}
                    variant="outlined"
                  >
                    <OutlinedInput
                      id="outlined-adornment-password"
                      onChange={(e) => handleStudentsSearch(e)}
                      value={studentsSearchValue}
                      type={"text"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            edge="end"
                          >
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                      label={CalenderStrings?.search}
                    />
                    <InputLabel htmlFor="outlined-adornment-password">
                      {CalenderStrings?.search}
                    </InputLabel>
                  </FormControl>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 10,
                      flexWrap: "wrap",
                      padding: 10,
                    }}
                  >
                    {!isEmpty(studentsList) || studentsSearchValue === "" ? (
                      classDetails?.subscribers?.map((item, index) => (
                        <div
                          onClick={() => handleUserClick(item)}
                          key={index}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            border: "1px solid #E0E0E0",
                            padding: "10px 15px",
                            backgroundColor: studentsList.some(
                              (each) => each._id === item._id
                            )
                              ? "#3083EF"
                              : "#ffffff",
                            borderRadius: 10,
                          }}
                        >
                          <Avatar
                            alt="profileImage"
                            src={item?.profileImage}
                            sx={{ width: 30, height: 30, mr: 1.5 }}
                          />
                          <AautiText
                            textStyle={{
                              color: studentsList.some(
                                (each) => each._id === item._id
                              )
                                ? "#ffffff"
                                : null,
                            }}
                            title={item?.displayName}
                            size={"normal"}
                          />
                        </div>
                      ))
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "30px",
                          width: "100%",
                        }}
                      >
                        <AautiText
                          weight={"medium"}
                          title={"No users found"}
                          textStyle={{
                            color: "grey",
                            textAlign: "end",
                            cursor: "pointer",
                            marginRight: "3px",
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "flex-end",
                      marginTop: 20,
                      gap: 15,
                    }}
                  >
                    <JoinButton
                      outlined
                      onClick={() => {
                        setPopupName("Create");
                        setAskOpenName("Create Assignment");
                        setStudentsSearchValue("");
                        setStudentSelectionState(studentSelectionState);
                        setDoneClicked(false);
                        setSelectedUsers(selectedUsers);
                      }}
                      textStyle={{ color: "#3083EF" }}
                      buttonStyle={{
                        width: 100,
                        height: 40,
                      }}
                      title={CalenderStrings?.cancel}
                    />
                    <JoinButton
                      disabled={studentsList?.length === 0}
                      onClick={() => {
                        if (popupName === "Edit") {
                          setPopupName("Edit");
                        } else {
                          setPopupName("Create");
                        }
                        setStudentsSearchValue("");
                        setDoneClicked(false);
                        if (
                          selectedUsers.length ===
                          classDetails?.subscribers.length
                        ) {
                          setStudentSelectionState("EveryOne");
                        }
                        setSelectedUsers(studentsList);
                      }}
                      buttonStyle={{ width: 100, height: 40 }}
                      title={CalenderStrings?.done}
                    />
                  </div>
                </div>
              ) : (
                <div>
                  <Dropdown
                    className="centered-dropdown"
                    disabled={
                      dropObj?.scheduleType === "adhoc" ||
                      dropObj?.scheduleType === "course adhoc"
                    }
                    value={dropObj}
                    onChange={(e) => handleSelectSession(e)}
                    options={AllSessions}
                    optionLabel="sessionName"
                    placeholder={CalenderStrings?.selectSession}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignSelf: "flex-start",
                      width: "100%",
                      padding: "8px",
                      borderRadius: "8px",
                      border:
                        parseInt(extractSessionNumber(dropObj?.sessionName)) <
                          parseInt(
                            extractSessionNumber(
                              selectAssignSession?.sessionName
                            )
                          ) && "1px solid red",
                    }}
                    itemTemplate={(option) => (
                      <div
                        style={{
                          color: option.sessionName.includes("Ongoing")
                            ? "#066cb5"
                            : "black",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {option.sessionName}
                      </div>
                    )}
                  />

                  <AautiTextInput
                    style={{ width: "100%", margin: "15px 0px" }}
                    text={"Title"}
                    maxLength={30}
                    value={saveAssignment?.title}
                    helperText={`${saveAssignment?.title?.length}/30 (minimum 5 characters)`}
                    onChange={(e) =>
                      setSaveAssignment({
                        ...saveAssignment,
                        title: e.target.value,
                      })
                    }
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    {!isEmpty(questionsArray) ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            width: "100%",
                            overflow: "scroll",
                          }}
                        >
                          {questionsArray.map((each, index) => (
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  onClick={() => {
                                    setQuestionsTypeState(each.type);
                                    setActiveQuestionIndex(index);
                                  }}
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    marginRight: 8,
                                    borderRadius: 5,
                                    padding: 10,
                                    backgroundColor:
                                      activeQuestionIndex === index
                                        ? "#3083EF"
                                        : "white",
                                    border:
                                      activeQuestionIndex === index
                                        ? "1px solid white"
                                        : "1px solid #3083EF",
                                    cursor: "pointer",
                                    width:
                                      each.type === "Open Ended"
                                        ? "170px"
                                        : "100px",
                                  }}
                                >
                                  <AautiText
                                    title={`${index + 1}.${each?.type}`}
                                    textStyle={{
                                      color:
                                        activeQuestionIndex === index
                                          ? "white"
                                          : "#3083EF",
                                      marginRight: 5,
                                    }}
                                  />
                                  {questionsArray.length > 1 && (
                                    <IoIosCloseCircleOutline
                                      id={`assignment_close_${index}`}
                                      color={
                                        activeQuestionIndex === index
                                          ? "white"
                                          : "#3083EF"
                                      }
                                      size={20}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveQuestion(each, index);
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                        {saveAssignment?.questions?.length < 10 && (
                          <div id="basic-menu" onClick={handleClickQuestion}>
                            <FiPlusCircle
                              style={{ cursor: "pointer" }}
                              color="#3083EF"
                              size={25}
                            />
                          </div>
                        )}

                        <Menu
                          id="basic-menu1"
                          anchorEl={anchorQuestion}
                          open={Boolean(anchorQuestion)}
                          onClose={() => setAnchorQuestion(null)}
                          MenuListProps={{ "aria-labelledby": "basic-button" }}
                        >
                          {QuestionsType.map((each, index) => (
                            <MenuItem
                              key={index}
                              onClick={() => {
                                setQuestionsTypeState(each);
                                setAnchorQuestion(null);
                                handleQuestionsArray(each);
                              }}
                            >
                              {each}
                            </MenuItem>
                          ))}
                        </Menu>
                      </div>
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        <div
                          onClick={handleClickQuestion}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            alignSelf: "flex-end",
                            cursor: "pointer",
                          }}
                        >
                          <AautiText
                            weight={"bold"}
                            title={CalenderStrings?.addQuestions}
                            textStyle={{
                              color: "#078dda",
                              textAlign: "end",
                              cursor: "pointer",
                              marginRight: "3px",
                            }}
                          />
                          <FiPlusCircle color="#078dda" size={18} />
                        </div>

                        <div>
                          <Menu
                            id="basic-menu"
                            anchorEl={anchorQuestion}
                            open={Boolean(anchorQuestion)}
                            onClose={() => setAnchorQuestion(null)}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                          >
                            {QuestionsType.map((each, index) => (
                              <MenuItem
                                key={index}
                                onClick={() => {
                                  setQuestionsTypeState(each);
                                  setAnchorQuestion(null);
                                  handleQuestionsArray(each);
                                }}
                              >
                                {each}
                              </MenuItem>
                            ))}
                          </Menu>
                        </div>
                      </div>
                    )}
                  </div>
                  {questionsType !== "" && (
                    <div className="button-ai-generate-cont">
                      <button
                        className="generate-button-ali"
                        onClick={() => {
                          setCountPopUp(true);
                          setQuestionsTypeState(questionsType);
                        }}
                      >
                        <AautiText
                          weight={"bold"}
                          title={CalenderStrings?.generateUsingAi}
                          size={"semi"}
                          textStyle={{
                            color: "#078dda",
                            textAlign: "end",
                            cursor: "pointer",
                            marginRight: "3px",
                          }}
                        />
                      </button>
                    </div>
                  )}

                  <div>
                    {!isEmpty(questionsArray) &&
                      questionsArray.map((each, index) => (
                        <div key={index}>
                          {questionsType !== "" &&
                            activeQuestionIndex === index && (
                              <>
                                <AautiTextInput
                                  value={questionsArray?.[index]?.description}
                                  style={{ width: "100%", margin: "5px 0px" }}
                                  multiline
                                  rows={3}
                                  maxLength={50}
                                  important
                                  text={CalenderStrings?.descrioption}
                                  onChange={(e) =>
                                    handleDescriptionChange(
                                      index,
                                      e.target.value
                                    )
                                  }
                                />
                                {each.type === "MCQ" && (
                                  <>
                                    {each?.options.map((option, optIndex) => (
                                      <div
                                        key={optIndex}
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          width: "100%",
                                          justifyContent:
                                            each.options.length >= 2
                                              ? "flex-start"
                                              : "space-between",
                                          margin: "5px 0px",
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            width: 50,
                                            height: 50,
                                            justifyContent: "center",
                                            borderRadius: 5,
                                            marginRight: "25px",
                                          }}
                                        >
                                          {option.image &&
                                          option?.image !== "" ? (
                                            <div
                                              style={{
                                                width: "80px",
                                                marginTop: "-12px",
                                                // marginRight: "10px",
                                              }}
                                            >
                                              <IoMdCloseCircle
                                                onClick={() =>
                                                  deleteImage(index, optIndex)
                                                }
                                                color="red"
                                                style={{
                                                  position: "relative",
                                                  top: 18,
                                                  left: 48,
                                                  cursor: "pointer",
                                                }}
                                              />
                                              <img
                                                src={option.image}
                                                alt={option.text}
                                                style={{
                                                  height: "55px",
                                                  width: "60px",
                                                  borderRadius: "5px",
                                                }}
                                              />
                                            </div>
                                          ) : (
                                            <Button
                                              key={optIndex}
                                              component="label"
                                              variant="contained"
                                              sx={{
                                                height: 60,
                                                backgroundColor: "white",
                                                mr: 1,
                                                border: "1px solid #F2F2F6",
                                                borderRadius: "8px",
                                                boxShadow: "none",
                                                "&:hover": {
                                                  backgroundColor:
                                                    "transparent",
                                                },
                                              }}
                                              startIcon={
                                                uploadLoad &&
                                                optIndex === camIndex ? (
                                                  <CircularProgress size={20} />
                                                ) : (
                                                  <PhotoCameraIcon
                                                    size={20}
                                                    sx={{ color: "#B0B0B0" }}
                                                  />
                                                )
                                              }
                                            >
                                              <VisuallyHiddenInput
                                                id={`file-upload-${index}-${optIndex}`}
                                                type="file"
                                                accept=".jpeg,.jpg,.png"
                                                onChange={(e) =>
                                                  handleChangeFile(
                                                    e,
                                                    index,
                                                    optIndex
                                                  )
                                                }
                                              />
                                            </Button>
                                          )}
                                        </div>
                                        <TextField
                                          id="outlined-basic"
                                          label={`Option ${optIndex + 1}`}
                                          variant="outlined"
                                          sx={{
                                            width: "80% !important",
                                            margin: "15px 0px",
                                            marginRight: "25px",
                                          }}
                                          value={option.text}
                                          onChange={(e) =>
                                            handleOptionTextChange(
                                              index,
                                              optIndex,
                                              e.target.value
                                            )
                                          }
                                        />
                                        {each.options.length > 2 && (
                                          <GoTrash
                                            onClick={() =>
                                              handleDeleteOption(
                                                optIndex,
                                                index
                                              )
                                            }
                                            color="black"
                                            size={20}
                                          />
                                        )}
                                      </div>
                                    ))}
                                    {each.options.length < 5 && (
                                      <AautiText
                                        onClick={() => handleClick4(index)}
                                        weight={"bold"}
                                        title={CalenderStrings?.addOptions}
                                        textStyle={{
                                          color: "#078dda",
                                          textAlign: "end",
                                          cursor: "pointer",
                                          marginBottom: 10,
                                        }}
                                      />
                                    )}

                                    <AautiTextInput
                                      value={
                                        each.correctAnswer > 0
                                          ? each.correctAnswer.toString()
                                          : ""
                                      }
                                      maxLength={1}
                                      onChange={(e) => {
                                        if (e.target.value !== 0) {
                                          handleAnswerChange(
                                            each,
                                            index,
                                            e.target.value
                                          );
                                        }
                                      }}
                                      style={{ width: "30%" }}
                                      text={CalenderStrings?.answer}
                                    />
                                  </>
                                )}
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-end",
                                    alignSelf: "flex-end",
                                  }}
                                >
                                  <Button
                                    component="label"
                                    variant="contained"
                                    disableElevation
                                    disableRipple
                                    sx={{
                                      height: 60,
                                      backgroundColor: "white",
                                      textTransform: "none",
                                      borderRadius: "8px",
                                      boxShadow: "none",
                                      "&:hover": {
                                        backgroundColor: "transparent",
                                      },
                                    }}
                                  >
                                    <AautiText
                                      weight={"bold"}
                                      title={CalenderStrings?.addFiles}
                                      textStyle={{
                                        color: "#078dda",
                                        textAlign: "end",
                                        cursor: "pointer",
                                      }}
                                    />
                                    <VisuallyHiddenInput
                                      id={`file-upload-${index}`}
                                      type="file"
                                      accept=".jpeg,.jpg,.png"
                                      onChange={(e) =>
                                        handleChangeAddFiles(e, index)
                                      }
                                    />
                                  </Button>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "flex-start",
                                  }}
                                >
                                  {!isEmpty(each?.uploadedFiles) && (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                        flexWrap: "wrap",
                                      }}
                                    >
                                      {each?.uploadedFiles.map((img, index) => (
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            marginRight: "5px",
                                          }}
                                        >
                                          <IoIosCloseCircle
                                            onClick={() =>
                                              handleDeleteImage(index, index)
                                            }
                                            size={14}
                                            color="red"
                                            style={{
                                              position: "relative",
                                              display: "flex",
                                              alignSelf: "flex-end",
                                              bottom: "-12px",
                                              cursor: "pointer",
                                            }}
                                          />
                                          <img
                                            src={img}
                                            alt={`img ${index}`}
                                            style={{
                                              height: "70px",
                                              width: "80px",
                                              borderRadius: "5px",
                                            }}
                                          />
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                </div>
                                <div
                                  key={index}
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                    width: "100%",
                                  }}
                                >
                                  {each.enablePoints && (
                                    <AautiTextInput
                                      value={
                                        each.enablePoints
                                          ? parseInt(each.points) || 0
                                          : 0
                                      }
                                      maxLength={3}
                                      onChange={(e) =>
                                        handlePointsChange(
                                          each,
                                          index,
                                          e.target.value
                                        )
                                      }
                                      style={{ width: "70%" }}
                                      text={CalenderStrings?.points}
                                    />
                                  )}

                                  <FormControlLabel
                                    sx={{
                                      width: "35%",
                                      display: "flex",
                                      alignSelf: "flex-end",
                                      justifyContent: "flex-end",
                                    }}
                                    control={
                                      <Switch
                                        checked={each.enablePoints}
                                        onChange={(e) =>
                                          handleEnablePointsChange(
                                            e,
                                            each,
                                            index,
                                            "enablePoints"
                                          )
                                        }
                                      />
                                    }
                                    label={CalenderStrings?.enablePoints}
                                  />
                                </div>
                              </>
                            )}
                        </div>
                      ))}
                  </div>

                  <div
                    style={{
                      display: "flex",
                      backgroundColor:
                        classDetails?.subscribers.length > 1
                          ? "transparent"
                          : "#F7F7FA",
                      border: "1px solid #E0E0E0",
                      borderRadius: 10,
                      flexDirection: "column",
                      margin: "15px 0px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "2px solid #E0E0E0",
                        padding: 10,
                        width: "100%",
                      }}
                    >
                      <AautiText
                        weight={"bold"}
                        title={CalenderStrings?.assignedTo}
                        size={"normal"}
                      />

                      <FormControl sx={{ width: "40%" }}>
                        <InputLabel id="demo-simple-select-label">
                          {CalenderStrings?.assigned}
                        </InputLabel>
                        <Select
                          disabled={studentSelectionState === "Selected"}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={studentSelectionState}
                          label="Assigned"
                        >
                          {studentSelection.map((each, index) => (
                            <MenuItem
                              value={each}
                              key={index}
                              onClick={() => {
                                setStudentSelectionState(each);

                                if (each === "Selected") {
                                  setSelectedUsers([]);
                                  setStudentsList([]);
                                } else {
                                  setStudentsList(classDetails?.subscribers);
                                }
                              }}
                            >
                              {each}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",

                        padding: 10,
                        width: "100%",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          width:
                            classDetails?.subscribers?.length ===
                            selectedUsers?.length
                              ? "100%"
                              : "90%",
                          overflow: "scroll",
                        }}
                      >
                        {selectedUsers?.map((item, index) => (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              border: "1px solid #E0E0E0",
                              padding: "10px 15px",
                              backgroundColor: "#ffffff",
                              borderRadius: 10,
                              marginRight: "10px",
                            }}
                          >
                            <Avatar
                              alt="profileImage"
                              src={item?.profileImage}
                              sx={{ width: 30, height: 30, mr: 1.5 }}
                            />
                            <AautiText
                              title={item?.displayName}
                              size={"normal"}
                            />
                            {studentSelectionState === "Selected" && (
                              <IoMdClose
                                onClick={() => {
                                  setStudentsList((prev) =>
                                    prev.filter((each) => each._id !== item._id)
                                  );
                                  setSelectedUsers((prev) =>
                                    prev.filter((each) => each._id !== item._id)
                                  );
                                }}
                                size={18}
                                style={{
                                  marginLeft: "15px",
                                  cursor: "pointer",
                                }}
                              />
                            )}
                          </div>
                        ))}
                      </div>
                      {classDetails?.subscribers?.length !==
                        selectedUsers?.length && (
                        <button
                          onClick={() => {
                            setDoneClicked(true);
                            setAskOpenName("Select Users");
                          }}
                          className="add-button-cont-1"
                        >
                          <FiPlus color="#078dda" size={20} />
                          <AautiText
                            title={"Add"}
                            weight={"medium"}
                            size={"normal"}
                          />
                        </button>
                      )}
                    </div>
                  </div>

                  <div className="dateDivStyle1">
                    <div style={{ width: "100%" }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Start Date"
                          disabled
                          value={dayjs(formattedDate)}
                          maxDate={dayjs(
                            classDetails?.myCourse?.batch?.endDate
                          )}
                          minDate={dayjs(formattedDate)}
                          defaultValue={dayjs(formattedDate)}
                          format="DD/MM/YYYY"
                          onChange={(newValue) =>
                            setSaveAssignment({
                              ...saveAssignment,
                              startDate: newValue.toISOString(),
                            })
                          }
                        />
                      </LocalizationProvider>
                    </div>

                    <div style={{ width: "100%" }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          slotProps={{ field: { readOnly: true } }}
                          label="End Date"
                          format="DD/MM/YYYY"
                          minDate={dayjs(formattedDate)}
                          value={
                            popupName === "Edit"
                              ? dayjs(saveAssignment?.endDate)
                              : saveAssignment?.startDate !== ""
                              ? dayjs(formattedDate)
                              : saveAssignment?.endDate !== ""
                              ? dayjs(saveAssignment?.endDate)
                              : dayjs().format("YYYY-MM-DD")
                          }
                          maxDate={dayjs(
                            classDetails?.myCourse?.batch?.endDate
                          )}
                          onChange={(newValue) =>
                            setSaveAssignment({
                              ...saveAssignment,
                              endDate: newValue
                                .add(5, "hours")
                                .add(30, "minutes")
                                .toISOString(),
                            })
                          }
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                  {isEnabled && (
                    <AautiText
                      weight={"bold"}
                      title={`Total Score - ${
                        saveAssignment?.totalPoints || 0
                      }`}
                      size={"normal"}
                      textStyle={{ marginTop: "8px" }}
                    />
                  )}

                  {/* <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "flex-end",
                      marginTop: 20,
                      gap: 15,
                    }}
                  >
                    <JoinButton
                      id={
                        switchChange
                          ? "assignment_update_draft"
                          : "assignment_save_draft"
                      }
                      disabled={
                        popupName !== "Edit"
                          ? saveAssignment?.title === ""
                          : false
                      }
                      onClick={() =>
                        popupName === "Edit" && switchChange
                          ? handleUpdateDraft()
                          : handleDraftAssignment()
                      }
                      outlined
                      textStyle={{
                        color:
                          saveAssignment?.title === "" ? "white" : "#3083EF",
                      }}
                      buttonStyle={{
                        width: 150,

                        border:
                          saveAssignment?.title === ""
                            ? "1px solid lightgrey"
                            : "1px solid #3083EF",

                        display:
                          !switchChange && popupName === "Edit" && "none",
                      }}
                      title={
                        switchChange
                          ? CalenderStrings?.updateToDraft
                          : CalenderStrings?.saveToDraft
                      }
                    />

                    <JoinButton
                      id={switchChange ? "publish" : "update"}
                      disabled={
                        popupName !== "Edit"
                          ? isEmpty(saveAssignment?.title) || // Disable if title is empty
                            isEmpty(questionsArray) ||
                            questionsArray.some(
                              (question) =>
                                question.description === "" ||
                                (question.type === "MCQ" &&
                                  (question.options.some(
                                    (option) => option.text === ""
                                  ) ||
                                    question.correctAnswer === ""))
                            ) ||
                            (enablePoints && points === "")
                          : false ||
                            parseInt(
                              extractSessionNumber(dropObj?.sessionName)
                            ) >
                              parseInt(
                                extractSessionNumber(
                                  selectAssignSession?.sessionName
                                )
                              )
                      }
                      onClick={() =>
                        popupName === "Edit" && switchChange
                          ? handleCreateAssignment()
                          : popupName === "Edit"
                          ? handleUpdateAssignment()
                          : handleCreateAssignment()
                      }
                      buttonStyle={{
                        width: 100,
                      }}
                      // title={popupName === "Edit" ? "Update" : "Publish"}
                      title={
                        popupName === "Edit" && switchChange
                          ? CalenderStrings?.publish
                          : popupName === "Edit"
                          ? CalenderStrings?.update
                          : CalenderStrings?.publish
                      }
                    />
                      </div> */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    {!switchChange &&
                      (popupName !== "Edit" ? (
                        <>
                          <button
                            disabled={disblePublish}
                            className="create-button"
                            style={{
                              backgroundColor: disblePublish
                                ? "lightgrey"
                                : "#3083EF",
                              marginRight: "10px",
                            }}
                            onClick={() => handleCreateAssignment()}
                          >
                            {CalenderStrings?.publish}
                          </button>
                          <button
                            disabled={
                              popupName !== "Edit"
                                ? saveAssignment?.title === ""
                                : false
                            }
                            className="create-button"
                            style={{
                              backgroundColor: disabeDraft
                                ? "lightgrey"
                                : "transparent",
                              marginRight: "10px",
                              border: disabeDraft
                                ? "1px solid lightgrey"
                                : "1px solid #3083EF",
                              color: disabeDraft ? "white" : "#3083EF",
                            }}
                            onClick={() => handleDraftAssignment()}
                          >
                            {CalenderStrings?.saveToDraft}
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            disabled={disabeDraft}
                            className="create-button"
                            style={{
                              backgroundColor: disabeDraft
                                ? "lightgrey"
                                : "#3083EF",
                              marginRight: "10px",
                            }}
                            onClick={() => handleUpdateAssignment()}
                          >
                            {CalenderStrings?.update}
                          </button>
                          <button
                            disabled={
                              popupName !== "Edit"
                                ? saveAssignment?.title === ""
                                : false
                            }
                            className="create-button"
                            style={{
                              backgroundColor: disabeDraft
                                ? "lightgrey"
                                : "transparent",
                              marginRight: "10px",
                              border: disabeDraft
                                ? "1px solid lightgrey"
                                : "1px solid #3083EF",
                              color: disabeDraft ? "white" : "#3083EF",
                            }}
                            onClick={() => handleDraftAssignment()}
                          >
                            {CalenderStrings?.saveToDraft}
                          </button>
                        </>
                      ))}

                    {switchChange && popupName === "Edit" && (
                      <>
                        <button
                          disabled={disblePublish}
                          className="create-button"
                          style={{
                            backgroundColor: disblePublish
                              ? "lightgrey"
                              : "#3083EF",
                            marginRight: "10px",
                          }}
                          onClick={() => handleCreateAssignment()}
                        >
                          {CalenderStrings?.publish}
                        </button>
                        <button
                          disabled={
                            popupName !== "Edit"
                              ? saveAssignment?.title === ""
                              : false
                          }
                          className="create-button"
                          style={{
                            backgroundColor: disabeDraft
                              ? "lightgrey"
                              : "transparent",
                            marginRight: "10px",
                            border: disabeDraft
                              ? "1px solid lightgrey"
                              : "1px solid #3083EF",
                            color: disabeDraft ? "white" : "#3083EF",
                          }}
                          onClick={() => handleUpdateDraft()}
                        >
                          {CalenderStrings?.updateToDraft}
                        </button>
                      </>
                    )}
                  </div>
                </div>
              )}
            </>
          )
        ) : popupName === "View" ? (
          eachLoad ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "30vh",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div style={{ width: "100%" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                  marginBottom: "5px",
                }}
              >
                <AautiText
                  weight={"bold"}
                  title={eachAssign?.title}
                  size={"medium"}
                  textStyle={{
                    color: "#1E1E1E",
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginTop: "15px",
                  overflow: "scroll",
                  width: "90%",
                }}
              >
                {eachAssign?.questions?.map((button, index) => (
                  <div
                    key={index}
                    style={{
                      marginRight: "10px",
                    }}
                  >
                    <button
                      key={index}
                      onClick={() => setActiveIndex(index)}
                      style={{
                        backgroundColor:
                          activeIndex === index ? "#3083EF" : "white",

                        border:
                          activeIndex === index
                            ? "0px solid #3083EF"
                            : "1px solid #3083EF",
                        padding: "10px 15px",
                        outline: "none",
                        borderRadius: "5px",
                        color: activeIndex === index ? "white" : "#3083EF",
                        fontSize: "14px",
                        cursor: "pointer",
                      }}
                    >
                      {index + 1}.{button.type}
                    </button>
                  </div>
                ))}
              </div>
              <div>
                {eachAssign?.questions?.map((streak, index) => (
                  <div>
                    {activeIndex === index && streak.type === "MCQ" && (
                      <>
                        <AautiText
                          weight={"bold"}
                          title={`Question (Score - ${streak?.points})`}
                          size={"normal"}
                          textStyle={{
                            color: "#1E1E1E",
                            marginTop: "10px",
                          }}
                        />
                        <div
                          style={{
                            border: "1px solid lightgrey",
                            borderRadius: "5px",
                            padding: "10px 15px",
                            marginTop: "10px",
                            backgroundColor: "#f0eded",
                          }}
                        >
                          <AautiText
                            weight={"medium"}
                            title={handleDescription(streak?.description)}
                            size={"normal"}
                            textStyle={{
                              color: "#1E1E1E",
                            }}
                          />
                        </div>
                        {!isEmpty(streak?.uploadedFiles) && (
                          <AautiText
                            weight={"bold"}
                            title={`Files`}
                            size={"normal"}
                            textStyle={{
                              color: "#1E1E1E",
                              marginTop: "10px",
                            }}
                          />
                        )}

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            flexWrap: "wrap",
                          }}
                        >
                          {streak?.uploadedFiles.map((each, index) => (
                            <img
                              src={each}
                              alt={`${index}`}
                              style={{
                                height: "60px",
                                width: "70px",
                                borderRadius: "5px",
                                marginRight: "10px",
                              }}
                            />
                          ))}
                        </div>
                        <AautiText
                          weight={"bold"}
                          title={CalenderStrings?.options}
                          size={"normal"}
                          textStyle={{
                            color: "#1E1E1E",
                            marginTop: "10px",
                          }}
                        />
                        <div
                          style={{
                            marginTop: "10px",
                            backgroundColor: "#f0eded",
                            padding: "10px",
                            borderRadius: "5px",
                            border: "1px solid lightgrey",
                          }}
                        >
                          {streak?.options?.map((option) => (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                marginBottom: "10px",
                              }}
                            >
                              <AautiText
                                weight={"normal"}
                                title={`${option?.option}.`}
                                size={"normal"}
                                textStyle={{ marginRight: "5px" }}
                              />

                              {option.image && option?.image !== "" ? (
                                <img
                                  src={option?.image}
                                  alt={option?.text}
                                  style={{
                                    height: "30px",
                                    width: "30px",
                                    borderRadius: "30px",
                                  }}
                                />
                              ) : (
                                <IoCamera
                                  size={14}
                                  style={{ marginLeft: "10px" }}
                                />
                              )}

                              <AautiText
                                weight={"normal"}
                                title={option?.text}
                                size={"normal"}
                                textStyle={{ marginLeft: "8px" }}
                              />
                            </div>
                          ))}
                        </div>
                        <AautiText
                          weight={"medium"}
                          title={`Correct Answer - ${streak?.correctAnswer}`}
                          size={"normal"}
                          textStyle={{ marginTop: "8px", color: "black" }}
                        />
                        <div
                          style={{
                            marginTop: "10px",
                            backgroundColor: "#f0eded",
                            padding: "10px",
                            borderRadius: "5px",
                            border: "1px solid lightgrey",
                          }}
                        >
                          <AautiText
                            weight={"bold"}
                            title={CalenderStrings?.assignedTo}
                            size={"normal"}
                            textStyle={{ marginTop: "8px" }}
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              gap: 10,
                              flexWrap: "wrap",
                              padding: "10px 15px",
                            }}
                          >
                            {eachAssign?.assignedUsers?.map((item, index) => (
                              <div
                                key={index}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  border: "1px solid #E0E0E0",
                                  padding: "10px 15px",
                                  backgroundColor: "#ffffff",
                                  borderRadius: 10,
                                }}
                              >
                                <Avatar
                                  alt="profileImage"
                                  src={item?.profileImage}
                                  sx={{ width: 30, height: 30, mr: 1.5 }}
                                />
                                <AautiText
                                  title={item?.displayName}
                                  size={"normal"}
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              border: "1px solid #f0eded",
                              borderRadius: "5px",
                              padding: "10px",
                              marginTop: "10px",
                              marginRight: "10px",
                            }}
                          >
                            <AautiText
                              weight={"bold"}
                              title={CalenderStrings?.startDate}
                              size={"normal"}
                              textStyle={{ marginTop: "8px" }}
                            />
                            <AautiText
                              weight={"normal"}
                              title={`${moment(streak?.startDate).format(
                                "ddd, DD MMM YYYY"
                              )}`}
                              size={"normal"}
                              textStyle={{ marginBottom: "8px" }}
                            />
                          </div>
                          <div
                            style={{
                              border: "1px solid #f0eded",
                              borderRadius: "5px",
                              padding: "10px",
                              marginTop: "10px",
                            }}
                          >
                            <AautiText
                              weight={"bold"}
                              title={CalenderStrings?.endDate}
                              size={"normal"}
                              textStyle={{ marginTop: "8px" }}
                            />
                            <AautiText
                              weight={"normal"}
                              title={`${moment(streak?.endDate).format(
                                "ddd, DD MMM YYYY"
                              )}`}
                              size={"normal"}
                              textStyle={{ marginBottom: "8px" }}
                            />
                          </div>
                        </div>
                        <AautiText
                          weight={"bold"}
                          title={`Total Score - ${eachAssign?.totalPoints}`}
                          size={"normal"}
                          textStyle={{ marginTop: "8px" }}
                        />
                      </>
                    )}
                    {activeIndex === index && streak.type === "Open Ended" && (
                      <>
                        <AautiText
                          weight={"bold"}
                          title={`Question (Score - ${streak?.points})`}
                          size={"normal"}
                          textStyle={{
                            color: "#1E1E1E",
                            marginTop: "10px",
                          }}
                        />
                        <div
                          style={{
                            border: "1px solid lightgrey",
                            borderRadius: "5px",
                            padding: "10px 15px",
                            marginTop: "10px",
                            backgroundColor: "#f0eded",
                          }}
                        >
                          <AautiText
                            weight={"medium"}
                            title={handleDescription(streak?.description)}
                            size={"normal"}
                            textStyle={{
                              color: "#1E1E1E",
                            }}
                          />
                        </div>
                        <AautiText
                          weight={"bold"}
                          title={`Files`}
                          size={"normal"}
                          textStyle={{
                            color: "#1E1E1E",
                            marginTop: "10px",
                          }}
                        />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            flexWrap: "wrap",
                          }}
                        >
                          {streak?.uploadedFiles.map((each, index) => (
                            <img
                              src={each}
                              alt={`${index}`}
                              style={{
                                height: "60px",
                                width: "70px",
                                borderRadius: "5px",
                                marginRight: "10px",
                              }}
                            />
                          ))}
                        </div>
                        <div
                          style={{
                            marginTop: "10px",
                            backgroundColor: "#f0eded",
                            padding: "10px",
                            borderRadius: "5px",
                            border: "1px solid lightgrey",
                          }}
                        >
                          <AautiText
                            weight={"bold"}
                            title={CalenderStrings?.assignedTo}
                            size={"normal"}
                            textStyle={{ marginTop: "8px" }}
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              gap: 10,
                              flexWrap: "wrap",
                              padding: "10px 15px",
                            }}
                          >
                            {eachAssign?.assignedUsers?.map((item, index) => (
                              <div
                                key={index}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  border: "1px solid #E0E0E0",
                                  padding: "10px 15px",
                                  backgroundColor: "#ffffff",
                                  borderRadius: 10,
                                }}
                              >
                                <Avatar
                                  alt="profileImage"
                                  src={item?.profileImage}
                                  sx={{ width: 30, height: 30, mr: 1.5 }}
                                />
                                <AautiText
                                  title={item?.displayName}
                                  size={"normal"}
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              border: "1px solid #f0eded",
                              borderRadius: "5px",
                              padding: "10px",
                              marginTop: "10px",
                              marginRight: "10px",
                            }}
                          >
                            <AautiText
                              weight={"bold"}
                              title={CalenderStrings?.startDate}
                              size={"normal"}
                              textStyle={{ marginTop: "8px" }}
                            />
                            <AautiText
                              weight={"normal"}
                              title={`${moment(streak?.startDate).format(
                                "ddd, DD MMM YYYY"
                              )}`}
                              size={"normal"}
                              textStyle={{ marginBottom: "8px" }}
                            />
                          </div>
                          <div
                            style={{
                              border: "1px solid #f0eded",
                              borderRadius: "5px",
                              padding: "10px",
                              marginTop: "10px",
                            }}
                          >
                            <AautiText
                              weight={"bold"}
                              title={CalenderStrings?.endDate}
                              size={"normal"}
                              textStyle={{ marginTop: "8px" }}
                            />
                            <AautiText
                              weight={"normal"}
                              title={`${moment(streak?.endDate).format(
                                "ddd, DD MMM YYYY"
                              )}`}
                              size={"normal"}
                              textStyle={{ marginBottom: "8px" }}
                            />
                          </div>
                        </div>
                        <AautiText
                          weight={"bold"}
                          title={`Total Score - ${eachAssign?.totalPoints}`}
                          size={"normal"}
                          textStyle={{ marginTop: "8px" }}
                        />
                      </>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )
        ) : (
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                backgroundColor: "whitesmoke",
                padding: "10px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                borderRadius: "5px",
                marginBottom: "10px",
              }}
            >
              <AautiText
                weight={"bold"}
                title={CapitalizeFirstLetter(scheduleDetails?.sessionName)}
                size={"normal"}
              />
            </div>
            {!resultId && (
              <AautiText
                weight={"bold"}
                title={CalenderStrings?.students}
                size={"normal"}
                textStyle={{ marginBottom: 10 }}
              />
            )}
            {usersList?.map((each, index) => (
              <>
                <div
                  onClick={() => {
                    handleOnClickStudent(each);
                  }}
                  key={index}
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: "10px",
                    borderTop: "1px solid #E0E0E0",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Avatar
                      alt="profileImage"
                      src={each?.profileImage}
                      sx={{ width: 40, height: 40, mr: 1.5 }}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <AautiText
                        weight={"bold"}
                        title={each?.displayName}
                        size={"normal"}
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <GoDotFill
                          color={
                            each?.status === "Pending"
                              ? "red"
                              : each?.status === "Submitted"
                              ? "yellow"
                              : "green"
                          }
                          size={18}
                          style={{ marginRight: "5px" }}
                        />

                        <AautiText title={each?.status} size={"normal"} />
                      </div>
                    </div>
                  </div>
                  {each?.totalPoints !== 0 && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <AautiText
                        title={`${each?.resultPoints}/${each?.totalPoints}`}
                        size={"normal"}
                      />
                      <FaChevronRight size={18} />
                    </div>
                  )}
                </div>
              </>
            ))}
          </div>
        )}
      </AautiDialog>
      <AautiDialog
        titlePosition="left"
        dialogTitle={askopenName}
        open={submissionDialog}
        onClose={() => {
          setSubmissionDialog(false);
          setAnswers([]);
          // setSubmitAssign({});
          setSelectedOption(null);
          setActiveIndex(0);
        }}
      >
        <div>
          {popupName === "Submission" ? (
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  overflow: "scroll",
                  width: "100%",
                }}
              >
                {eachAssign?.questions?.map((button, index) => (
                  <div
                    key={index}
                    style={{
                      marginRight: "10px",
                    }}
                  >
                    <button
                      key={index}
                      onClick={() => setActiveIndex(index)}
                      style={{
                        backgroundColor:
                          activeIndex === index ? "#3083EF" : "white",

                        border:
                          activeIndex === index
                            ? "0px solid #3083EF"
                            : "1px solid #3083EF",
                        padding: "10px 15px",
                        outline: "none",
                        borderRadius: "5px",
                        color: activeIndex === index ? "white" : "#3083EF",
                        fontSize: "14px",
                        cursor: "pointer",
                      }}
                    >
                      {index + 1}.{button.type}
                    </button>
                  </div>
                ))}
              </div>
              {eachAssign?.questions?.map((streak, index) => (
                <div>
                  {activeIndex === index && streak.type === "MCQ" && (
                    <>
                      {streak.points !== 0 && (
                        <AautiText
                          weight={"bold"}
                          title={`Question (Score - ${streak?.points})`}
                          size={"normal"}
                          textStyle={{
                            color: "#1E1E1E",
                            marginTop: "10px",
                          }}
                        />
                      )}

                      <div
                        style={{
                          border: "1px solid lightgrey",
                          borderRadius: "5px",
                          padding: "10px 15px",
                          marginTop: "10px",
                          backgroundColor: "#f0eded",
                        }}
                      >
                        <AautiText
                          weight={"medium"}
                          title={handleDescription(streak?.description)}
                          size={"normal"}
                          textStyle={{
                            color: "#1E1E1E",
                          }}
                        />
                      </div>
                      {!isEmpty(streak?.uploadedFiles) && (
                        <>
                          <AautiText
                            weight={"bold"}
                            title={CalenderStrings?.files}
                            size={"normal"}
                            textStyle={{
                              color: "#1E1E1E",
                              marginTop: "10px",
                            }}
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-start",
                              alignItems: "center",
                            }}
                          >
                            {streak?.uploadedFiles?.map((each, index) => (
                              <img
                                src={each}
                                alt={`${index}img`}
                                style={{
                                  height: "50px",
                                  width: "60px",
                                  borderRadius: "5px",
                                }}
                              />
                            ))}
                          </div>
                        </>
                      )}
                      <AautiText
                        weight={"bold"}
                        title={CalenderStrings?.options}
                        size={"normal"}
                        textStyle={{
                          color: "#1E1E1E",
                          marginTop: "10px",
                        }}
                      />
                      <div
                        style={{
                          marginTop: "10px",
                          backgroundColor: "#f0eded",
                          padding: "10px",
                          borderRadius: "5px",
                          border: "1px solid lightgrey",
                        }}
                      >
                        {streak?.options?.map((option, index) => (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              marginBottom: "10px",
                            }}
                          >
                            <RadioGroup
                              aria-labelledby="demo-radio-buttons-group-label"
                              name={`radio-buttons-group-${streak._id}`}
                              value={
                                submitAssign?.answers?.find(
                                  (answer) => answer.questionId === streak._id
                                )?.description || ""
                              }
                              onChange={(event) =>
                                handleChangeRadio(event, streak, option)
                              }
                            >
                              <FormControlLabel
                                value={option.option}
                                control={<Radio />}
                                label={
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "flex-start",
                                      alignItems: "center",
                                    }}
                                  >
                                    {option.image && (
                                      <img
                                        src={option.image}
                                        alt={option.text}
                                        style={{
                                          height: "30px",
                                          width: "30px",
                                          borderRadius: "30px",
                                          marginLeft: "10px",
                                          marginRight: "10px",
                                        }}
                                      />
                                    )}
                                    <span>{option.text}</span>
                                  </div>
                                }
                              />
                            </RadioGroup>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                  {activeIndex === index && streak.type === "Open Ended" && (
                    <>
                      {streak.points !== 0 && (
                        <AautiText
                          weight={"bold"}
                          title={`Question (Score - ${streak?.points})`}
                          size={"normal"}
                          textStyle={{
                            color: "#1E1E1E",
                            marginTop: "10px",
                          }}
                        />
                      )}

                      <div
                        style={{
                          border: "1px solid lightgrey",
                          borderRadius: "5px",
                          padding: "10px 15px",
                          marginTop: "10px",
                          backgroundColor: "#f0eded",
                        }}
                      >
                        <AautiText
                          weight={"medium"}
                          title={handleDescription(streak?.description)}
                          size={"normal"}
                          textStyle={{
                            color: "#1E1E1E",
                          }}
                        />
                      </div>
                      {!isEmpty(streak?.uploadedFiles) && (
                        <>
                          <AautiText
                            weight={"bold"}
                            title={CalenderStrings?.files}
                            size={"normal"}
                            textStyle={{
                              color: "#1E1E1E",
                              marginTop: "10px",
                            }}
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-start",
                              alignItems: "center",
                            }}
                          >
                            {streak?.uploadedFiles?.map((each, index) => (
                              <img
                                src={each}
                                alt={`${index}img`}
                                style={{
                                  height: "50px",
                                  width: "60px",
                                  borderRadius: "5px",
                                }}
                              />
                            ))}
                          </div>
                        </>
                      )}
                      <AautiTextInput
                        style={{
                          width: "100%",
                          margin: "8px 0px",
                        }}
                        multiline={true}
                        placeholder={CalenderStrings?.enterHere}
                        rows={5}
                        text={
                          <>
                            <AautiText
                              weight={"medium"}
                              title={CalenderStrings?.yourAnswer}
                              size={"normal"}
                              textStyle={{
                                color: "#1E1E1E",
                              }}
                            />
                          </>
                        }
                        value={
                          submitAssign?.answers?.find(
                            (answer) => answer.questionId === streak._id
                          )?.description || ""
                        }
                        onChange={(e) => handleChangeYourAnswer(e, streak)}
                      />

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          flexWrap: "wrap",
                        }}
                      >
                        {streak?.answers?.map((each, index) => {
                          return (
                            each.type === "Open Ended" &&
                            !isEmpty(each.uploadedFiles) &&
                            each.uploadedFiles.map((fdh, index) => (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <IoIosCloseCircle
                                  size={16}
                                  color="red"
                                  style={{ position: "absolute" }}
                                />
                                <img
                                  src={fdh}
                                  alt={`${index}`}
                                  style={{
                                    height: "60px",
                                    width: "70px",
                                    borderRadius: "5px",
                                    marginRight: "10px",
                                  }}
                                />
                              </div>
                            ))
                          );
                        })}
                      </div>
                    </>
                  )}
                </div>
              ))}

              <AautiText
                weight="bold"
                title={`Total Score - ${eachAssign?.questions?.reduce(
                  (total, item) => total + (item.points || 0),
                  0
                )}`}
                size="normal"
                textStyle={{ marginTop: "8px" }}
              />

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  marginTop: "20px",
                }}
              >
                <JoinButton
                  disabled={isSubmitButtonDisabled()}
                  buttonStyle={{ width: 100, height: 40 }}
                  title={CalenderStrings?.submit}
                  onClick={() => handleSubmitAssignment()}
                />
              </div>
            </div>
          ) : (
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                {eachAssign?.questions?.map((button, index) => (
                  <div key={index} style={{ marginRight: "10px" }}>
                    <button
                      key={index}
                      onClick={() => setActiveIndex(index)}
                      style={{
                        backgroundColor:
                          activeIndex === index ? "#3083EF" : "white",

                        border:
                          activeIndex === index
                            ? "0px solid #3083EF"
                            : "1px solid #3083EF",
                        padding: "10px 15px",
                        outline: "none",
                        borderRadius: "5px",
                        color: activeIndex === index ? "white" : "#3083EF",
                        fontSize: "14px",
                        cursor: "pointer",
                      }}
                    >
                      {index + 1}.{button.type}
                    </button>
                  </div>
                ))}
              </div>
              {eachAssign?.questions?.map((streak, index) => (
                <div>
                  {activeIndex === index && streak.type === "MCQ" && (
                    <>
                      <AautiText
                        weight={"bold"}
                        title={CalenderStrings?.question}
                        size={"normal"}
                        textStyle={{
                          color: "#1E1E1E",
                          marginTop: "10px",
                        }}
                      />
                      <div
                        style={{
                          border: "1px solid lightgrey",
                          borderRadius: "5px",
                          padding: "10px 15px",
                          marginTop: "10px",
                          backgroundColor: "#f0eded",
                        }}
                      >
                        <AautiText
                          weight={"medium"}
                          title={handleDescription(streak?.description)}
                          size={"normal"}
                          textStyle={{
                            color: "#1E1E1E",
                          }}
                        />
                      </div>

                      <AautiText
                        weight={"bold"}
                        title={CalenderStrings?.options}
                        size={"normal"}
                        textStyle={{
                          color: "#1E1E1E",
                          marginTop: "10px",
                        }}
                      />
                      <div
                        style={{
                          marginTop: "10px",
                          backgroundColor: "#f0eded",
                          padding: "10px",
                          borderRadius: "5px",
                          border: "1px solid lightgrey",
                        }}
                      >
                        {streak?.options?.map((option, index) => (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              marginBottom: "10px",
                            }}
                          >
                            <div
                              key={option.option}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "10px",
                              }}
                            >
                              <Radio
                                disabled
                                value={option.option}
                                checked={
                                  eachAssign.answers.filter(
                                    (each) => each.type === "MCQ"
                                  )?.[0]?.description === option.option ||
                                  eachAssign.questions.filter(
                                    (each) => each.type === "MCQ"
                                  )?.[0]?.correctAnswer === option.option
                                }
                                onChange={(event) =>
                                  handleChangeRadio(event, streak, option)
                                }
                                sx={{
                                  color:
                                    eachAssign.answers.filter(
                                      (each) => each.type === "MCQ"
                                    )?.[0]?.description ===
                                    eachAssign.questions.filter(
                                      (each) => each.type === "MCQ"
                                    )?.[0]?.correctAnswer
                                      ? "green"
                                      : eachAssign.questions.filter(
                                          (each) => each.type === "MCQ"
                                        )?.[0].correctAnswer === option.option
                                      ? "green"
                                      : "red",
                                  "&.Mui-checked": {
                                    color:
                                      eachAssign.answers.filter(
                                        (each) => each.type === "MCQ"
                                      )?.[0]?.description ===
                                      eachAssign.questions.filter(
                                        (each) => each.type === "MCQ"
                                      )?.[0]?.correctAnswer
                                        ? "green"
                                        : eachAssign.questions.filter(
                                            (each) => each.type === "MCQ"
                                          )?.[0]?.correctAnswer ===
                                          option.option
                                        ? "green"
                                        : "red",
                                  },
                                }}
                              />
                              {option.image && (
                                <img
                                  src={option.image}
                                  alt={option.text}
                                  style={{
                                    height: "30px",
                                    width: "30px",
                                    borderRadius: "30px",
                                    marginLeft: "10px",
                                    marginRight: "10px",
                                  }}
                                />
                              )}
                              <span>{option.text}</span>
                            </div>
                          </div>
                        ))}
                      </div>
                      {clickedUser.status !== "Corrected" &&
                        eachAssign?.questions?.filter(
                          (each) => each.type === "MCQ"
                        )?.[0].points !== 0 && (
                          <>
                            <AautiTextInput
                              readOnly
                              value={streak.points}
                              maxLength={2}
                              onChange={(e) => null}
                              style={{ width: "40%" }}
                              text={CalenderStrings?.points}
                              helperText={`Points - ${
                                submitCorrectionObj?.answers?.find(
                                  (each) => each.questionId === streak._id
                                )?.totalPoints
                              }`}
                            />
                            <AautiTextInput
                              value={
                                submitCorrectionObj?.answers?.find(
                                  (answer) => answer.questionId === streak?._id
                                )?.remarks || ""
                              }
                              onChange={(e) =>
                                handleRemarksChange(e, streak?._id)
                              }
                              style={{ width: "100%" }}
                              text={"Question Remarks"}
                            />
                          </>
                        )}

                      {clickedUser.status === "Corrected" && (
                        <AautiText
                          weight="medium"
                          title={`Score - ${
                            eachAssign?.answers?.filter(
                              (each) => each.type === "MCQ"
                            )?.[0]?.points
                          }/${
                            eachAssign?.questions?.filter(
                              (each) => each.type === "MCQ"
                            )?.[0]?.points
                          }`}
                          size="normal"
                          textStyle={{ marginTop: "15px" }}
                        />
                      )}

                      {clickedUser.status === "Corrected" && (
                        <AautiText
                          weight="bold"
                          title={`Total Score - ${clickedUser?.resultPoints}/${clickedUser?.totalPoints}`}
                          size="normal"
                          textStyle={{ marginTop: "15px" }}
                        />
                      )}
                    </>
                  )}
                  {activeIndex === index && streak.type === "Open Ended" && (
                    <>
                      <AautiText
                        weight={"bold"}
                        title={CalenderStrings?.question}
                        size={"normal"}
                        textStyle={{
                          color: "#1E1E1E",
                          marginTop: "10px",
                        }}
                      />
                      <div
                        style={{
                          border: "1px solid lightgrey",
                          borderRadius: "5px",
                          padding: "10px 15px",
                          marginTop: "10px",
                          backgroundColor: "#f0eded",
                        }}
                      >
                        <AautiText
                          weight={"medium"}
                          title={handleDescription(streak?.description)}
                          size={"normal"}
                          textStyle={{
                            color: "#1E1E1E",
                          }}
                        />
                      </div>
                      <AautiText
                        weight={"bold"}
                        title={`${
                          UserRole === "serviceprovider"
                            ? `${clickedUser.displayName}'s Answer`
                            : "Your Answer"
                        }`}
                        size={"normal"}
                        textStyle={{
                          color: "#1E1E1E",
                          marginTop: "10px",
                        }}
                      />

                      <div
                        style={{
                          border: "1px solid lightgrey",
                          padding: "10px",
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          marginTop: "10px",
                          flexWrap: "wrap",
                        }}
                      >
                        <AautiText
                          weight={"medium"}
                          title={handleDescription(
                            eachAssign?.answers?.filter(
                              (each) => each.type === "Open Ended"
                            )?.[0]?.description
                          )}
                          size={"normal"}
                          textStyle={{
                            color: "#1E1E1E",
                          }}
                        />
                      </div>

                      {clickedUser.status !== "Corrected" &&
                        eachAssign?.questions?.filter(
                          (each) => each.type === "Open Ended"
                        )?.[0].points !== 0 && (
                          <>
                            <AautiTextInput
                              value={
                                submitCorrectionObj?.answers?.find(
                                  (answer) => answer.questionId === streak?._id
                                )?.correctedPoints || 0
                              }
                              maxLength={2}
                              onChange={(e) =>
                                handlePointsCorrectionChange(e, streak?._id)
                              }
                              style={{ width: "40%" }}
                              text={CalenderStrings?.points}
                              helperText={`Points - ${
                                submitCorrectionObj?.answers?.find(
                                  (each) => each.questionId === streak._id
                                )?.totalPoints
                              }`}
                            />
                            <AautiTextInput
                              value={
                                submitCorrectionObj?.answers?.find(
                                  (answer) => answer.questionId === streak?._id
                                )?.remarks || ""
                              }
                              onChange={(e) =>
                                handleRemarksChange(e, streak?._id)
                              }
                              style={{ width: "100%" }}
                              text={"Question Remarks"}
                            />
                          </>
                        )}

                      {clickedUser.status === "Corrected" && (
                        <AautiText
                          weight="medium"
                          title={`Score - ${
                            eachAssign?.answers?.filter(
                              (each) => each.type === "Open Ended"
                            )?.[0]?.points
                          }/${
                            eachAssign?.questions?.filter(
                              (each) => each.type === "Open Ended"
                            )?.[0]?.points
                          }`}
                          size="normal"
                          textStyle={{ marginTop: "15px" }}
                        />
                      )}
                      {clickedUser.status === "Corrected" && (
                        <AautiText
                          weight="bold"
                          title={`Total Score - ${clickedUser?.resultPoints}/${clickedUser?.totalPoints}`}
                          size="normal"
                          textStyle={{ marginTop: "15px" }}
                        />
                      )}
                    </>
                  )}
                </div>
              ))}

              {clickedUser.status !== "Corrected" &&
                clickedUser?.totalPoints !== 0 && (
                  <div>
                    <AautiText
                      weight="bold"
                      title={`Total Score - ${
                        submitCorrectionObj?.answers?.reduce(
                          (total, answer) => total + answer.correctedPoints,
                          0
                        ) || 0
                      }/${submitCorrectionObj?.answers?.reduce(
                        (total, item) => total + (item.totalPoints || 0),
                        0
                      )}`}
                      size="normal"
                      textStyle={{ marginTop: "8px" }}
                    />
                  </div>
                )}
              {clickedUser.status !== "Corrected" &&
                UserRole === "serviceprovider" && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      marginTop: "20px",
                    }}
                  >
                    <JoinButton
                      // disabled={points === ""}
                      buttonStyle={{ width: 100, height: 40 }}
                      title={"Submit"}
                      onClick={() => handleSubmitAssignmentCorrection()}
                    />
                  </div>
                )}
            </div>
          )}
        </div>
      </AautiDialog>

      <AautiDialog
        titlePosition="left"
        dialogTitle={askopenName}
        open={detailsDialog}
        onClose={() => {
          setDetailsDialog(false);
          setActiveIndex(0);
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            {eachAssign?.questions?.map((button, index) => (
              <div key={index} style={{ marginRight: "10px" }}>
                <button
                  key={index}
                  onClick={() => setActiveIndex(index)}
                  style={{
                    backgroundColor:
                      activeIndex === index ? "#3083EF" : "white",

                    border:
                      activeIndex === index
                        ? "0px solid #3083EF"
                        : "1px solid #3083EF",
                    padding: "10px 15px",
                    outline: "none",
                    borderRadius: "5px",
                    color: activeIndex === index ? "white" : "#3083EF",
                    fontSize: "14px",
                    cursor: "pointer",
                  }}
                >
                  {index + 1}.{button.type}
                </button>
              </div>
            ))}
          </div>
          {eachAssign?.questions?.map((streak, index) => (
            <div>
              {activeIndex === index && streak.type === "MCQ" && (
                <>
                  <AautiText
                    weight={"bold"}
                    title={CalenderStrings?.question}
                    size={"normal"}
                    textStyle={{
                      color: "#1E1E1E",
                      marginTop: "10px",
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      flexWrap: "wrap",
                    }}
                  >
                    {streak?.uploadedFiles.map((each, index) => (
                      <img
                        src={each}
                        alt={`${index}`}
                        style={{
                          height: "60px",
                          width: "70px",
                          borderRadius: "5px",
                          marginRight: "10px",
                        }}
                      />
                    ))}
                  </div>
                  <div
                    style={{
                      border: "1px solid lightgrey",
                      borderRadius: "5px",
                      padding: "10px 15px",
                      marginTop: "10px",
                      backgroundColor: "#f0eded",
                    }}
                  >
                    <AautiText
                      weight={"medium"}
                      title={handleDescription(streak?.description)}
                      size={"normal"}
                      textStyle={{
                        color: "#1E1E1E",
                      }}
                    />
                  </div>

                  <AautiText
                    weight={"bold"}
                    title={CalenderStrings?.options}
                    size={"normal"}
                    textStyle={{
                      color: "#1E1E1E",
                      marginTop: "10px",
                    }}
                  />
                  <div
                    style={{
                      marginTop: "10px",
                      backgroundColor: "#f0eded",
                      padding: "10px",
                      borderRadius: "5px",
                      border: "1px solid lightgrey",
                    }}
                  >
                    {streak?.options?.map((option, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <div
                          key={option.option}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "10px",
                          }}
                        >
                          <Radio
                            disabled
                            value={option.option}
                            checked={
                              eachAssign.answers.filter(
                                (each) => each.type === "MCQ"
                              )?.[0]?.description === option.option ||
                              eachAssign.questions.filter(
                                (each) => each.type === "MCQ"
                              )?.[0]?.correctAnswer === option.option
                            }
                            onChange={(event) =>
                              handleChangeRadio(event, streak, option)
                            }
                            sx={{
                              color:
                                eachAssign.answers.filter(
                                  (each) => each.type === "MCQ"
                                )?.[0]?.description ===
                                eachAssign.questions.filter(
                                  (each) => each.type === "MCQ"
                                )?.[0]?.correctAnswer
                                  ? "green"
                                  : eachAssign.questions.filter(
                                      (each) => each.type === "MCQ"
                                    )?.[0]?.correctAnswer === option.option
                                  ? "green"
                                  : "red",
                              "&.Mui-checked": {
                                color:
                                  eachAssign.answers.filter(
                                    (each) => each.type === "MCQ"
                                  )?.[0]?.description ===
                                  eachAssign.questions.filter(
                                    (each) => each.type === "MCQ"
                                  )?.[0]?.correctAnswer
                                    ? "green"
                                    : eachAssign.questions.filter(
                                        (each) => each.type === "MCQ"
                                      )?.[0]?.correctAnswer === option.option
                                    ? "green"
                                    : "red",
                              },
                            }}
                          />

                          {option.image ? (
                            <>
                              <img
                                src={option.image}
                                alt={option.text}
                                style={{
                                  height: "30px",
                                  width: "30px",
                                  borderRadius: "30px",
                                  marginLeft: "10px",
                                  marginRight: "10px",
                                }}
                              />
                              <AautiText
                                weight={"normal"}
                                title={option?.text}
                                size={"semi"}
                                textStyle={{
                                  color: "#1E1E1E",
                                }}
                              />
                            </>
                          ) : (
                            <AautiText
                              weight={"normal"}
                              title={option?.text}
                              size={"semi"}
                              textStyle={{
                                color: "#1E1E1E",
                              }}
                            />
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                  {streak.points !== 0 && (
                    <AautiText
                      weight="medium"
                      title={`Score - ${
                        eachAssign?.answers?.filter(
                          (each) => each.type === "MCQ"
                        )?.[0]?.points
                      }/${
                        eachAssign?.questions?.filter(
                          (each) => each.type === "MCQ"
                        )?.[0]?.points
                      }`}
                      size="normal"
                      textStyle={{ marginTop: "15px" }}
                    />
                  )}
                </>
              )}
              {activeIndex === index && streak.type === "Open Ended" && (
                <>
                  <AautiText
                    weight={"bold"}
                    title={CalenderStrings?.question}
                    size={"normal"}
                    textStyle={{
                      color: "#1E1E1E",
                      marginTop: "10px",
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      flexWrap: "wrap",
                    }}
                  >
                    {streak?.uploadedFiles.map((each, index) => (
                      <img
                        src={each}
                        alt={`${index}`}
                        style={{
                          height: "60px",
                          width: "70px",
                          borderRadius: "5px",
                          marginRight: "10px",
                        }}
                      />
                    ))}
                  </div>
                  <div
                    style={{
                      border: "1px solid lightgrey",
                      borderRadius: "5px",
                      padding: "10px 15px",
                      marginTop: "10px",
                      backgroundColor: "#f0eded",
                    }}
                  >
                    <AautiText
                      weight={"medium"}
                      title={handleDescription(streak?.description)}
                      size={"normal"}
                      textStyle={{
                        color: "#1E1E1E",
                      }}
                    />
                  </div>
                  <AautiText
                    weight={"bold"}
                    title={CalenderStrings?.yourAnswer}
                    size={"normal"}
                    textStyle={{
                      color: "#1E1E1E",
                      marginTop: "10px",
                    }}
                  />

                  <div
                    style={{
                      border: "1px solid lightgrey",
                      padding: "10px",
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      marginTop: "10px",
                      flexWrap: "wrap",
                    }}
                  >
                    <AautiText
                      weight={"medium"}
                      title={handleDescription(
                        eachAssign?.answers?.filter(
                          (each) => each.type === "Open Ended"
                        )?.[0]?.description
                      )}
                      size={"normal"}
                      textStyle={{
                        color: "#1E1E1E",
                      }}
                    />
                  </div>

                  {streak?.remarks && (
                    <AautiTextInput
                      readOnly={true}
                      disable={true}
                      value={null}
                      onChange={(e) => null}
                      style={{ width: "100%" }}
                      text={"Question Remarks"}
                    />
                  )}

                  {/* <AautiTextInput
                    style={{
                      width: "100%",
                      margin: "8px 0px",
                    }}
                    multiline={true}
                    placeholder={CalenderStrings?.enterHere}
                    rows={5}
                    text={
                      <>
                        <AautiText
                          weight={"medium"}
                          title={CalenderStrings?.yourAnswer}
                          size={"normal"}
                          textStyle={{
                            color: "#1E1E1E",
                          }}
                        />
                      </>
                    }
                    value={
                      submitAssign?.answers?.find(
                        (answer) => answer.questionId === streak._id
                      )?.description || ""
                    }
                    onChange={(e) => handleChangeYourAnswer(e, streak)}
                  /> */}

                  {streak.points !== 0 && (
                    <AautiText
                      weight="medium"
                      title={`Score - ${
                        eachAssign?.answers?.filter(
                          (each) => each.type === "Open Ended"
                        )?.[0]?.points
                      }/${
                        eachAssign?.questions?.filter(
                          (each) => each.type === "Open Ended"
                        )?.[0]?.points
                      }`}
                      size="normal"
                      textStyle={{ marginTop: "15px" }}
                    />
                  )}
                </>
              )}
            </div>
          ))}
          <AautiText
            weight="bold"
            title={`Total Score - ${eachAssign?.resultTotalPoints || 0}/${
              eachAssign?.totalPoints || 0
            }`}
            size="normal"
            textStyle={{ marginTop: "8px" }}
          />
        </div>
      </AautiDialog>
    </>
  );
};

export default AssignmentCard;
