import React from "react";
import AautiText from "../globalComponents/AautiText";
import { Avatar, Divider, Tooltip } from "@mui/material";
import AautiTextInput from "../globalComponents/AautiTextInput";
import { isEmpty } from "lodash";
import { setCountryObject } from "../../redux/reducer/appReducer";
import { useDispatch } from "react-redux";
import "./Style.scss";

const CountrySelect = ({
  countryObject,
  handleOnChange,
  filteredCountries,
  closeCountryPop,
}) => {
  const dispatch = useDispatch();

  return (
    <>
      <div className="CountrySelect-div1">
        <div className="CountrySelect-div2">
          <AautiText
            weight={"bold"}
            title={"Currency"}
            className={"CountrySelect-text1"}
          />
          <AautiText
            className={"CountrySelect-text1"}
            size={"small"}
            title={`${countryObject?.name} - ${
              countryObject?.countryCurrency ||
              countryObject?.countryCurrencySymbol ||
              "NA"
            }`}
          />
        </div>
        <div>
          <AautiTextInput
            maxLength={50}
            text={"Search"}
            onChange={(event) => {
              const value = event.target.value.trimStart().replace(/\s+/g, " ");
              handleOnChange(value);
            }}
          />
        </div>
      </div>
      <Divider />
      <div className="CountrySelect-div3">
        {filteredCountries?.map((each, index) => {
          return (
            <div
              onClick={() => {
                localStorage.setItem("country", each?.name);
                dispatch(setCountryObject(each));
                closeCountryPop();
              }}
              key={index}
              className="CountrySelect-div4"
              style={{
                display: "flex",
                justifyContent: "flex-start",
                gap: 10,
                alignItems: "center",
                borderWidth: countryObject?.name === each?.name ? "2px" : "1px",
                borderColor:
                  countryObject?.name === each?.name ? "#000000" : "lightgrey",
              }}
            >
              <Avatar
                variant="rounded"
                alt={each?.name}
                src={each?.flag}
                className="Country-image"
              />

              <div>
                <Tooltip title={each?.name} placement="bottom">
                  <div>
                    <AautiText
                      title={each?.name}
                      textAlign={"left"}
                      textStyle={{
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        WebkitLineClamp: 1,
                        textOverflow: "ellipsis",
                        maxWidth: "100%",
                      }}
                      size={"semi"}
                      weight={"bold"}
                    />
                  </div>
                </Tooltip>
                <AautiText
                  textAlign={"left"}
                  size={"small"}
                  title={`${each?.countryCurrency || each?.name} - ${
                    each?.countryCurrencySymbol || each?.countryCurrency || "NA"
                  }`}
                />
              </div>
            </div>
          );
        })}
        {isEmpty(filteredCountries) && (
          <div className="CountrySelect-div5">
            <AautiText title={"No Countries available"} />
          </div>
        )}
      </div>
    </>
  );
};

export default CountrySelect;
