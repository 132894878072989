import React, { useEffect } from "react";
import AautiTextInput from "../globalComponents/AautiTextInput";
import AautiText from "../globalComponents/AautiText";
import { IoMdInformationCircleOutline } from "react-icons/io";
import "./style.scss";
import { FormControl, FormHelperText, InputAdornment, InputLabel, MenuItem, Select } from "@mui/material";
import JoinButton from "../globalComponents/JoinButton";

const CreateRequest = ({
  payoutList,
  convertPriceWithCurrency,
  banksList,
  selectedBank,
  setSelectedBank,
  userDetails,
  setSelectedAmount,
  selectedAmount,
  chargesInfo,
  payoutSaveApi,
  onCloseHandle,
  convertToMillionBillionsPrice,
  onlyPriceWithCurrency
}) => {
  const countryPriceSymbol = userDetails?.transactionCountrySymbol;
  function maskBankNumber(bankNumber) {
    const maskedSection = bankNumber.slice(0, -4).replace(/\d/g, "X");
    const visibleSection = bankNumber.slice(-4);
    return maskedSection + visibleSection;
  }
  useEffect(() => {
    if (banksList.length === 1) {
      setSelectedBank(banksList[0]);
    } else {
      const primaryBank = banksList.find(bank => bank.isPrimaryAccount);
      if (primaryBank) {
        setSelectedBank(primaryBank);
      }
    }
  }, [banksList]);
  return (
    <div>
      <AautiTextInput
        value={selectedAmount}
        type="number"
        text={"Withdraw amount"}
        important
        style={{ width: "100%" }}
        size="small"
        startAdornment={<InputAdornment position="start">{countryPriceSymbol}</InputAdornment>}
        onChange={(e) => setSelectedAmount(Number(e.target.value))}
        helperText={
          <div className="payOut-helper-div">
            <AautiText
              textAlign={"left"}
              size={"small"}
              weight={"normal"}
              textStyle={{ color: selectedAmount < 100 ? "red" : "#7A797E", }}
              title={`(Minimum withdraw amount - ${countryPriceSymbol}100)`}
            />
            <AautiText
              textStyle={{ color: selectedAmount > payoutList?.availableFunds ? "red" : "#7A797E", }}
              textAlign={"left"}
              size={"small"}
              weight={"normal"}
              title={`(Available Funds - ${(onlyPriceWithCurrency(convertToMillionBillionsPrice(payoutList?.availableFunds)))})`}
            />
          </div>
        }
      />
      <FormControl fullWidth size="small" sx={{ marginTop: "10px" }}>
        <InputLabel id="demo-simple-select-label">
          Select Bank <span style={{ color: "red" }}>*</span>
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedBank?._id || ''}
          label="Select Bank *"
          onChange={(e) => {
            const bank = banksList?.find(b => b._id === e.target.value);
            setSelectedBank(bank);
          }}
        >
          {banksList?.map((each) => (
            <MenuItem key={each._id} value={each._id}>
              {`${each.bankName} - ${maskBankNumber(each.accountNumber)}`}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText sx={{ margin: '5px 0px 0px 0px' }}>
          <div className="payOut-note-div" >
            <IoMdInformationCircleOutline size={25} style={{ marginRight: "8px" }} />
            <AautiText
              textAlign={"left"}
              size={"small"}
              weight={"normal"}
              title={`You can only withdraw the available amount.Tax 5%. Service Charge 5%.`}
            />
          </div>
        </FormHelperText>
      </FormControl>
      {(selectedAmount <= payoutList?.availableFunds && selectedAmount >= 100 && selectedBank) ?
        <>
          <div className="payOut-pop-charges-div">
            {chargesInfo?.map((each, index) =>
              <div key={index} className="payOut-charges-text-div"
                style={{
                  borderTop: each?.chargeType === "Net Pay" ? '1px solid #dedede' : 0,
                  paddingTop: each?.chargeType === "Net Pay" ? 5 : 0
                }}>
                <AautiText textStyle={{ color: '#1e1e1e' }}
                  size={"semi"}
                  weight={each?.chargeType === "Net Pay" ? "bold" : "medium"}
                  title={each?.chargeType}
                />
                <AautiText textStyle={{ color: '#1e1e1e' }}
                  size={"semi"}
                  weight={each?.chargeType === "Net Pay" ? "bold" : "medium"}
                  title={((each?.chargeType !== "Net Pay" && each?.chargeType !== "Withdraw Amount") ? "-" : '') +
                    countryPriceSymbol + each?.charges?.toFixed(2)}
                />
              </div>
            )}
          </div> <AautiText textAlign={"left"}
            size={"semi"}
            weight={"normal"}
            title={'*Transaction charges extra.'}
          /></>
        : null}

      <div className="payOut-pop-submit">
        <JoinButton title={"Submit"}
          disabled={selectedAmount <= 100 || selectedAmount >= payoutList?.availableFunds || !selectedAmount || !selectedBank}
          onClick={() => { payoutSaveApi(); onCloseHandle() }}
          buttonStyle={{ marginTop: 10, display: 'flex', alignSelf: "flex-end" }} />
      </div>
    </div >
  );
};

export default CreateRequest;
